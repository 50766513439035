import { Col, Form, Radio, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { applicationProcess } from "../../store/reducers/user.reducer";
import {
  StyledForm,
  StyledForms,
} from "../Profile-verification/BackgroundCheck";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import AddReference from "./sub-tabs/AddReference";
import { FormWrap, GeneralProfileWrap } from "./sub-tabs/GeneralProfile";
import ApplicantAllDetails from "./ApplicantAllDetails";

function RentalHistory({
  onBackClick,
  onMainTabNext,
  appPayloadData,
  isBankVerified,
  setAppPayloadData,
  checkFillDataFunc,
  activeTab,
}) {
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState(true);
  const [isAddReference, setIsAddReference] = useState(false);
  const [reviewPage, setReviewPage] = useState(false);
  const [rentaldata, setrentaldata] = useState({});
  const dispatch = useDispatch();

  const generaldata = Object.values(appPayloadData?.general_profile || {})
    ?.length
    ? appPayloadData?.general_profile
    : applicationProcessData?.userProfile?.generalProfile;

  const idData = Object.values(appPayloadData?.id_proof || {})?.length
    ? appPayloadData?.id_proof
    : applicationProcessData?.userProfile?.idProof;

  const bankIncome = Object.values(isBankVerified || {})?.length
    ? isBankVerified
    : applicationProcessData?.incomeAndEmployment?.instantBankVerification;

  const manualData = Object.values(
    appPayloadData?.manual_verification_data || {}
  )?.length
    ? appPayloadData?.manual_verification_data
    : applicationProcessData?.incomeAndEmployment?.manualVerification;

  const paystubData = Object.values(
    appPayloadData?.manual_verification_data?.payStubs || {}
  )?.length
    ? appPayloadData?.manual_verification_data?.payStubs
    : applicationProcessData?.incomeAndEmployment?.manualVerification?.payStubs;

  const employerData = appPayloadData?.manual_verification_data?.employerDetails
    ?.length
    ? appPayloadData?.manual_verification_data?.employerDetails
    : applicationProcessData?.incomeAndEmployment?.manualVerification
        ?.employerDetails;

  const checkFilledData = () => {
    if (applicationProcessData || appPayloadData?.id) {
      if (
        !Object.values(generaldata || {})?.length ||
        !Object.values(idData || {})?.length
      ) {
        message.info("Complete General Profile & Id Proof");
        onBackClick("banking-profile");
      } else if (
        !Object.values(bankIncome || {})?.length &&
        !Object.values(manualData || {})?.length
      ) {
        message.info("Complete Income And Employment");
        onBackClick("rental-history");
      } else if (
        !Object.values(bankIncome || {})?.length &&
        Object.values(manualData || {})?.length &&
        (!Object.values(employerData || {})?.length ||
          !Object.values(paystubData || {})?.length)
      ) {
        message.info("Complete Manual Verfication");
        onBackClick("rental-history");
      } else {
        if (isAddReference) {
          reviewPageHandler();
        } else {
          onBackClick("payment");
        }
      }
    }
  };

  // on click submit (submit API)
  const onFinish = (formValue) => {
    setIsSubmit(true);
    formValue.rentedBefore = value;
    if (value) {
      setIsAddReference(formValue.rentedBefore);
      setIsSubmit(false);
    } else {
      let data = {};
      data.rentalHistory = {};
      data.rentalHistory.isPreviousRented = false;
      data.rentalHistory.references = [];
      if (sessionStorage.getItem("applyingProperty")) {
        const unitDetails = JSON.parse(
          sessionStorage.getItem("applyingProperty")
        );
        data.unit_id = unitDetails?.id;
      }
      dispatch(applicationProcess(data)).then(({ payload }) => {
        setIsSubmit(false);
        setrentaldata(payload);
        checkFilledData();
      });
    }
  };

  // default tab based on filled data
  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (applicationProcessData?.rentalHistory) {
        setValue(applicationProcessData?.rentalHistory?.isPreviousRented);
      }

      if (applicationProcessData?.rentalHistory?.references?.length) {
        setIsAddReference(true);
      }
    }
  }, [applicationProcessData]);

  // set default form data
  const onChange = (e) => {
    setValue(e.target.value);
    form.setFieldsValue({
      rentedBefore: e.target.value,
    });
  };

  const onReviewClickBack = () => {
    setReviewPage(false);
  };

  // Tab change back
  const onClickBack = () => {
    setIsAddReference(false);
    setReviewPage(false);
  };

  const reviewPageHandler = () => {
    onBackClick("payment");
  };

  return (
    <>
      {isAddReference ? (
        <AddReference
          checkFilledData={checkFilledData}
          onMainTabNext={onMainTabNext}
          onClickBack={onClickBack}
          key={"addReferenceas"}
          setrentaldata={setrentaldata}
          setAppPayloadData={setAppPayloadData}
          checkFillDataFunc={checkFillDataFunc}
          activeTab={activeTab}
        />
      ) : reviewPage ? (
        <ApplicantAllDetails
          onMainTabNext={onMainTabNext}
          onReviewClickBack={onReviewClickBack}
          rentaldata={rentaldata}
        />
      ) : (
        <GeneralProfileWrap>
          <h1>Have you rented a home or an apartment before?</h1>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
          <FormWrap style={{ bottom: "0" }} mt="0">
            <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form
                  layout="vertical"
                  // requiredMark={"optional"}
                  form={form}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="rentedBefore"
                    // label="First Name"
                  >
                    {/* <Input /> */}
                  </Form.Item>
                  <Form.Item>
                    <Row
                      justify="space-between"
                      style={{ alignItems: "center" }}
                    >
                      <Col
                        xs={10}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="back__btn"
                      >
                        <IoIosArrowBack
                          onClick={() => onBackClick("rental-history")}
                        />
                        <h2 onClick={() => onBackClick("rental-history")}>
                          back
                        </h2>
                      </Col>
                      <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Row justify="end" style={{ alignItems: "center" }}>
                          {/* <Col>
                            <p className="cancel__btn">CANCEL</p>
                          </Col> */}
                          <Col>
                            <StyledButton
                              mt="0"
                              loading={isSubmit}
                              htmlType="submit"
                            >
                              {value ? "next" : "Review Details"}
                            </StyledButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </StyledForms>
            </StyledForm>
          </FormWrap>
        </GeneralProfileWrap>
      )}
    </>
  );
}

export default RentalHistory;
