import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Container } from "../container/Container";

function AboutAvenew() {
// static component
  
  const location = useLocation();
  const { width } = useWindowSize();

  const [showData, setShowData] = useState(true);

  useEffect(() => {
    if (width < 770) {
      setShowData(false);
    } else {
      setShowData(true);
    }
  }, [width, location.pathname]);

  return (
    <AboutAvenewWrap>
      <Container paddings="0">
        <h1>About Avenew</h1>
        <p>
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries.
        </p>
        {showData ? (
          <>
            <h3>All the Lorem Ipsum generators</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h3>Sed ut perspiciatis unde omnis iste natus</h3>
            <p>
              But I must explain to you how all this mistaken idea of denouncing
              pleasure and praising pain was born and I will give you a complete
              account of the system, and expound the actual teachings of the
              great explorer of the truth, the master-builder of human
              happiness.
            </p>
          </>
        ) : (
          <>
            <span onClick={() => setShowData(true)}>Read more</span>
          </>
        )}
      </Container>
    </AboutAvenewWrap>
  );
}

export default AboutAvenew;

export const AboutAvenewWrap = styled.div`
  background: #ffffff;

  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
    color: #2b353f;
    margin-top: 70px;
    margin-bottom: 15px;
    letter-spacing: 0.05em;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #2b353f;
    margin-bottom: 10px;
    letter-spacing: 0.05em;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 40px;
    letter-spacing: 0.05em;
  }
  @media (max-width: 1550px) {
    h1 {
      font-size: 26px;
      margin-top: 50px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 32px;
    }
  }
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    text-align: justify;
    letter-spacing: 0.05em;
    text-decoration-line: underline;

    color: #f67c36;
  }
  @media (max-width: 1440px) {
    padding: 0 30px;
  }
  @media (max-width: 769px) {
    margin-bottom: 40px;
  }
`;

export const NewsLetter = styled.div`
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #2b353f;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    ${"" /* line-height: 24px; */}
    letter-spacing: 0.05em;
    color: #2b353f;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    ${"" /* line-height: 40px; */}
    letter-spacing: 0.05em;
    color: #848687;
  }

  .social__icon {
    margin-top: 36px;
    margin-bottom: 38px;
    justify-content: space-between;
    cursor: pointer;
  }

  a {
    color: #848687;
  }

  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    letter-spacing: 0.05em !important;
    color: #848687 !important;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 25px !important;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #2b353f;
  }

  .ant-menu-item {
    ${"" /* height: 25px !important; */}
  }

  @media (max-width: 1440px) {
    padding: 0 30px 30px;
  }
  @media (max-width: 991.98px) {
    .avenew__bottom__logo {
      text-align: center;
    }
    .icon__wrap {
      justify-content: center;
    }
  }
`;
