import React from "react";

const telescopeSvg = ({ wSize = "44", hSize = "44" }) => {
  return (
    <svg
      width={wSize}
      height={wSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 539">
        <path
          id="Vector"
          d="M2.68787 27.9859L2.27925 27.2582C2.14245 27.0139 2.05363 26.7442 2.01788 26.4645C1.98212 26.1847 2.00012 25.9005 2.07085 25.6279C2.14159 25.3554 2.26367 25.0999 2.43012 24.876C2.59657 24.6522 2.80414 24.4643 3.04096 24.3232L10.5773 19.8415C10.7353 19.747 10.9099 19.6856 11.0909 19.6609C11.272 19.6361 11.456 19.6484 11.6324 19.6972C11.8088 19.7459 11.9742 19.83 12.119 19.9448C12.2638 20.0596 12.3853 20.2028 12.4764 20.3661L13.5791 22.3352C13.7629 22.6642 13.8127 23.055 13.7173 23.4217C13.622 23.7885 13.3894 24.1012 13.0707 24.291L5.53602 28.7727C5.05706 29.0574 4.48821 29.1344 3.95427 28.9869C3.42033 28.8394 2.96489 28.4794 2.68787 27.9859Z"
          stroke="#F67C36"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M13.8717 23.2879L11.707 19.682C11.4148 19.1937 11.3359 18.6136 11.4875 18.0692C11.6391 17.5248 12.0089 17.0608 12.5155 16.7792L23.9507 10.4254C24.118 10.3319 24.3029 10.2712 24.4946 10.2467C24.6863 10.2222 24.8812 10.2344 25.068 10.2826C25.2549 10.3308 25.43 10.414 25.5834 10.5276C25.7368 10.6412 25.8654 10.7828 25.9619 10.9443L28.8625 15.7785C29.0571 16.1036 29.1099 16.4899 29.0093 16.8525C28.9087 17.2151 28.6629 17.5244 28.3259 17.7125L16.888 24.0654C16.6371 24.2056 16.3599 24.2967 16.0724 24.3335C15.7849 24.3703 15.4927 24.3521 15.2125 24.2799C14.9323 24.2076 14.6696 24.0829 14.4395 23.9126C14.2095 23.7424 14.0165 23.5301 13.8717 23.2879Z"
          stroke="#F67C36"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M29.8884 17.3853L25.8107 10.115C25.5319 9.61661 25.4566 9.02442 25.6013 8.46874C25.7459 7.91306 26.0988 7.43939 26.5822 7.15194L34.7612 2.29061C35.2453 2.00301 35.8202 1.92506 36.36 2.07383C36.8997 2.22261 37.3602 2.58597 37.6404 3.08426L41.7181 10.3546C41.8563 10.6014 41.9459 10.8739 41.982 11.1564C42.018 11.439 41.9996 11.7261 41.928 12.0014C41.8563 12.2766 41.7328 12.5346 41.5644 12.7606C41.396 12.9866 41.1861 13.1762 40.9466 13.3185L32.7676 18.1799C32.2834 18.4676 31.7084 18.5456 31.1686 18.3966C30.6288 18.2477 30.1683 17.8839 29.8884 17.3853Z"
          stroke="#F67C36"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M10.2344 41.9993L19.6461 23.1758"
          stroke="#F67C36"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M23.1758 22L32.5875 39.6471"
          stroke="#F67C36"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default telescopeSvg;
