import React, { useEffect, useState } from "react";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import styled from "styled-components";
import { H2Style } from "./MultiApplicationPart";
import { Card, Col, Row, Skeleton } from "antd";
import { DeleteBtn } from "../../../pages/Profile-verification/PriorRentalInformation";
import { AvenewImg, BathSvg, BedSvg, Sqft } from "../../../assets/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { topUnits } from "../../../store/reducers/user.reducer";
import { formattedNumber, toUSCurrency } from "../../../utils/fn";

function DiscoverYourProperty() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allUnits, setAllUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 8,
    total: 0,
  });

  useEffect(() => {
    setLoading(true);
    dispatch(topUnits(pagination)).then(({ payload }) => {
      setLoading(false);
      if (payload?.Data) {
        setAllUnits(payload?.Data);
        setPagination((p) => ({ ...p, total: payload?.total }));
      }
    });
  }, []);

  const slpitFunc = (value) => {
    if (value?.length > 35) {
      return value.slice(0, 35) + "...";
    } else {
      return value;
    }
  };

  return (
    <>
      <MainContainer bgcolor="#26314B" containterHeight="auto">
        <TopTrendingSectionWrap margincomponent="0 auto 100px">
          <Row>
            <Col span={24}>
              <Rectangle className="divider__long" />
            </Col>
          </Row>
          <DiscoverYourPropertyWrap>
            <Row style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <H2Style
                  marginBottom="20px"
                  lineHeight="normal"
                  fColor="#ffffff"
                  paddingBottomxs="20px"
                  lineheightxs="32px"
                >
                  Discover Your Perfect Property <br />
                  with Avenew
                </H2Style>
                <span className="innovative__technology">
                  Our innovative technology aggregates property requirements and
                  applicant qualifications, matching you with properties that
                  align with your unique criteria.
                </span>
              </Col>
              <Col
                style={{ justifyContent: "end", display: "flex" }}
                xs={0}
                sm={0}
                md={0}
                lg={10}
                xl={10}
                xxl={10}
              >
                {/* <CircleWrap>
                  <h3>2560+</h3>
                  <span className="property__listed">
                    Property listed <br />
                    in avenew
                  </span>
                </CircleWrap> */}
              </Col>
            </Row>
          </DiscoverYourPropertyWrap>
          {loading ? (
            <Row gutter={[30, 30]}>
              {Array(9)
                .fill()
                .map((_, id) => (
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <StyledApparCard>
                      <Skeleton.Image
                        active={loading}
                        style={{ width: "100%", marginBottom: "15px" }}
                      ></Skeleton.Image>
                      <StyledApparContentWrapper mh={"0"}>
                        <Skeleton.Input
                          active={loading}
                          style={{
                            width: "100%",
                            height: 15,
                            marginBottom: 10,
                          }}
                        ></Skeleton.Input>
                        <Skeleton.Input
                          active={loading}
                          style={{
                            width: "100%",
                            height: 15,
                          }}
                        ></Skeleton.Input>
                      </StyledApparContentWrapper>
                    </StyledApparCard>
                  </Col>
                ))}
            </Row>
          ) : (
            <Row gutter={[30, 30]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Row gutter={[30, 30]}>
                  {allUnits?.slice(0, 3)?.map((item, idx) => (
                    <Col span={24} key={idx}>
                      <UnitWrap
                        onClick={() => {
                          navigate(`/property-details/${item?.id}`);
                        }}
                      >
                        {idx === 1 ? (
                          ""
                        ) : (
                          <>
                            {item?.images && item?.images?.length ? (
                              <>
                                {item?.images.map((img, idx) => (
                                  <div key={idx}>
                                    {img.isCover === true ? (
                                      <img
                                        style={{ marginBottom: "15px" }}
                                        className="card__1"
                                        alt="example"
                                        src={img.path}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <AvenewImg
                                className="card__1"
                                style={{
                                  background: "#eeeeee",
                                }}
                              />
                            )}
                          </>
                        )}
                        <Row>
                          <Col span={12}>
                            <h2>{slpitFunc(item?.unit_name)}</h2>
                          </Col>
                          <Col span={12}>
                            <h3>{toUSCurrency(item?.price)}</h3>
                          </Col>
                          <Col span={24}>
                            <h4>{slpitFunc(item?.address)}</h4>
                          </Col>
                          <Col span={7}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <BedSvg className="icon" />
                              </p>
                              <h5>Bed : {item?.bed}</h5>
                            </DeleteBtn>
                          </Col>
                          <Col span={7}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <BathSvg className="icon" />
                              </p>
                              <h5>Bath : {item?.bath}</h5>
                            </DeleteBtn>
                          </Col>
                          <Col span={10}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <Sqft className="icon" />
                              </p>
                              <h5>Sq ft : {formattedNumber(item?.sqft)}</h5>
                            </DeleteBtn>
                          </Col>
                        </Row>
                      </UnitWrap>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
                <Row gutter={[30, 30]}>
                  {allUnits?.slice(3, 6)?.map((item, idx) => (
                    <Col span={24} key={idx}>
                      <UnitWrap
                        onClick={() => {
                          navigate(`/property-details/${item?.id}`);
                        }}
                      >
                        {idx === 1 ? (
                          <>
                            {item?.images && item?.images?.length ? (
                              <>
                                {item?.images.map((img, idx) => (
                                  <div key={idx}>
                                    {img.isCover === true ? (
                                      <img
                                        style={{ marginBottom: "15px" }}
                                        className="card__2"
                                        alt="example"
                                        src={img.path}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <AvenewImg
                                className="card__1"
                                style={{
                                  background: "#eeeeee",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <Row>
                          <Col span={12}>
                            <h2>{slpitFunc(item?.unit_name)}</h2>
                          </Col>
                          <Col span={12}>
                            <h3>{toUSCurrency(item?.price)}</h3>
                          </Col>
                          <Col span={24}>
                            <h4>{slpitFunc(item?.address)}</h4>
                          </Col>
                          <Col span={7}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <BedSvg className="icon" />
                              </p>
                              <h5>Bed : {item?.bed}</h5>
                            </DeleteBtn>
                          </Col>
                          <Col span={7}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <BathSvg className="icon" />
                              </p>
                              <h5>Bath : {item?.bath}</h5>
                            </DeleteBtn>
                          </Col>
                          <Col span={10}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <Sqft className="icon" />
                              </p>
                              <h5>Sq ft : {formattedNumber(item?.sqft)}</h5>
                            </DeleteBtn>
                          </Col>
                        </Row>
                      </UnitWrap>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
                <Row gutter={[30, 30]}>
                  {allUnits?.slice(6, 8)?.map((item, idx) => (
                    <Col span={24} key={idx}>
                      <UnitWrap
                        onClick={() => {
                          navigate(`/property-details/${item?.id}`);
                        }}
                      >
                        {item?.images && item?.images?.length ? (
                          <>
                            {item?.images.map((img, idxI) => (
                              <div key={idxI}>
                                {img.isCover === true ? (
                                  <img
                                    style={{ marginBottom: "15px" }}
                                    className={
                                      idx === 0 ? "card__2" : "card__1"
                                    }
                                    alt="example"
                                    src={img.path}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <AvenewImg
                            className="card__1"
                            style={{
                              background: "#eeeeee",
                            }}
                          />
                        )}

                        <Row>
                          <Col span={12}>
                            <h2>{slpitFunc(item?.unit_name)}</h2>
                          </Col>
                          <Col span={12}>
                            <h3>{toUSCurrency(item?.price)}</h3>
                          </Col>
                          <Col span={24}>
                            <h4>{slpitFunc(item?.address)}</h4>
                          </Col>
                          <Col span={7}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <BedSvg className="icon" />
                              </p>
                              <h5>Bed : {item?.bed}</h5>
                            </DeleteBtn>
                          </Col>
                          <Col span={7}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <BathSvg className="icon" />
                              </p>
                              <h5>Bath : {item?.bath}</h5>
                            </DeleteBtn>
                          </Col>
                          <Col span={10}>
                            <DeleteBtn
                              style={{ cursor: "default" }}
                              widthBtn="fit-content"
                              paddingBtn="5px 10px"
                            >
                              <p>
                                <Sqft className="icon" />
                              </p>
                              <h5>Sq ft : {formattedNumber(item?.sqft)}</h5>
                            </DeleteBtn>
                          </Col>
                        </Row>
                      </UnitWrap>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <h2
                onClick={() => navigate("/property-listing")}
                className="view__more"
              >
                View More Properties
              </h2>
            </Col>
          </Row>
        </TopTrendingSectionWrap>
      </MainContainer>
    </>
  );
}

export default DiscoverYourProperty;

export const StyledApparContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  ${"" /* padding: 15px 0 0; */}

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #232333;
    margin-bottom: 0;
    /* min-height: 40px; */
  }
  h4 {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 0px;
    padding-top: 30px;
    p {
      margin-bottom: 0;
    }
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    min-height: 20px;
    display: flex;
    align-items: center;
  }
  .comment_at {
    font-size: 12px;
    line-height: 22px;
    color: #747487;
  }
  span .user_name {
    margin-left: 15px;
  }
  .text a {
    display: table;
    color: #0061a4;
    margin-top: 33px;
  }
`;

export const StyledApparCard = styled(Card)`
  display: flex;
  flex-flow: column;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  .ant-card-body {
    padding: 10px;
  }

  .ant-skeleton-image,
  img {
    height: 210px;
  }

  .ant-skeleton-element,
  .ant-skeleton-element .ant-skeleton-image-svg {
    width: 100%;
  }

  h4 {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 0px;
    p {
      margin-bottom: 0;
    }
  }

  .text a {
    display: table;
    color: #0061a4;
    margin-top: 33px;
  }

  .card__title {
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #323548;
    font-size: 20px;
    font-weight: 700;
    p {
      margin: 0;
    }
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
    .ant-divider-vertical {
      border-left: 1px solid #777777;
      height: 1rem;
    }
  }

  .card__price {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-divider-vertical {
      border-left: 1px solid #777777;
      height: 1rem;
    }
    p {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      color: ${({ theme }) => theme.colors.secondary};
    }
    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #777777;
      svg {
        padding-top: 3px;
      }
    }
  }

  @media (max-width: 1550px) {
    .card__title {
      padding-left: 12px !important;
      padding-right: 12px !important;
      padding-top: 12px;
      padding-bottom: 8px;
      font-size: 16px;
    }
    .card__BHK {
      padding-left: 12px !important;
      padding-right: 12px !important;
      font-size: 12px;
      line-height: 15px;
    }
    .card__price {
      padding-left: 12px !important;
      padding-right: 12px !important;
      padding-top: 8px;
      p {
        font-size: 16px;
      }
      span {
        font-size: 12px;
        line-height: 13px;
      }
    }
  }

  @media (max-width: 380px) {
    .card__price {
      flex-direction: column;
      align-items: start;
    }
  }
`;

const UnitWrap = styled.div`
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  cursor: pointer;

  h5 {
    align-items: center;
    display: flex;
    color: #848687;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.65px;
  }

  .card__1 {
    width: 100%;
    height: 210px;
    border-radius: 5px;
  }
  .card__2 {
    width: 100%;
    height: 360px;
    border-radius: 5px;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #f67c36;
    margin-bottom: 0;
  }

  h3 {
    color: #2b353f;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
    justify-content: end;
    display: flex;
  }

  h4 {
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
  }
`;

const CircleWrap = styled.div`
  display: flex;
  width: 165px;
  border: 2px dotted #f67c36;
  border-radius: 50% 50%;
  height: 165px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  h3 {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }

  span {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`;

const DiscoverYourPropertyWrap = styled.div`
  display: flex;
  padding: 20px 0;

  .innovative__technology {
    color: #8a96b0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .property__listed {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 991.98px) {
    .innovative__technology {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

const MainContainer = styled.div`
  padding-top: 1px;
  width: 100%;
  position: relative;
  background-color: ${({ bgcolor }) => bgcolor || "#ecf2ff"};
  height: ${({ containterHeight }) =>
    containterHeight || "calc(100vh - 110px)"};

  ${
    "" /* overflow: hidden;
  :after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 120%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: perspective(400px) rotateX(45deg);
    -moz-transform: perspective(400px) rotateX(45deg);
    -ms-transform: perspective(400px) rotateX(45deg);
    -o-transform: perspective(400px) rotateX(45deg);
    transform: perspective(9px) rotateY(-20deg) rotateX(-91deg);
    transform: perspective(100px) rotateY(343deg) rotateX(271deg);
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 0%;
    -webkit-transform: perspective(400px) rotateX(45deg);
    -moz-transform: perspective(400px) rotateX(45deg);
    -ms-transform: perspective(400px) rotateX(45deg);
    -o-transform: perspective(400px) rotateX(45deg);
    transform: perspective(100px) rotateY(25deg) rotateX(277deg);
  } */
  }

  .view__more {
    color: #f67c36;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    margin: 30px 0;
  }

  @media (max-width: 1550px) {
    padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};
    .circle__svg svg {
      display: none;
    }
    .square__svg svg,
    .orangeline__svg,
    .halfCircle__svg {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    padding-bottom: 0;
    ${
      "" /* :after {
      top: 6px;
    }
    :before {
      transform: perspective(100px) rotateY(38deg) rotateX(277deg);
    } */
    }
    .view__more {
      margin: 30px 0 60px;
    }
  }
  ${
    "" /* @media (max-width: 425.98px) {
    :after {
      top: 3px;
    }

    :before {
      transform: perspective(100px) rotateY(71deg) rotateX(277deg);
    } */
  }
  }
`;
