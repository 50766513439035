import React from "react";

const FooterEmail = ({
  wSize = "20",
  hSize = "12",
  bgColor = "#ffffff",
  color = "#848687",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1091 0.970233C18.5936 1.24209 11.4316 5.14645 11.1645 5.28707C10.8973 5.42768 10.6255 5.4933 10.2036 5.4933C9.78178 5.4933 9.50993 5.42768 9.24277 5.28707C8.9756 5.14645 1.81369 1.24209 1.29811 0.970233C1.10593 0.871803 0.782524 0.693693 0.712217 0.492147C0.590353 0.135926 0.721592 0 1.24186 0H10.2036H19.1654C19.6857 0 19.8169 0.140613 19.695 0.492147C19.6247 0.69838 19.3013 0.871803 19.1091 0.970233Z"
        fill="#848687"
      />
      <path
        d="M0.890632 1.1809C1.27498 1.37776 4.72469 3.83381 7.00732 5.31025L3.15451 9.64583C3.06077 9.73957 3.01858 9.85207 3.07014 9.90831C3.12639 9.95987 3.24825 9.93175 3.34668 9.84269L7.96818 5.94301C8.66656 6.39298 9.1587 6.70232 9.24307 6.7492C9.60398 6.93199 9.85708 6.95543 10.2039 6.95543C10.5508 6.95543 10.8039 6.93199 11.1648 6.7492C11.2538 6.70232 11.7413 6.39298 12.4397 5.94301L17.0612 9.84269C17.1549 9.93643 17.2815 9.96456 17.3377 9.90831C17.394 9.85675 17.3518 9.73957 17.258 9.64583L13.4099 5.31025C15.6925 3.83381 19.1798 1.37776 19.5641 1.1809C19.9766 0.969983 20 1.2184 20 1.41057C20 1.60274 20 11.0192 20 11.0192C20 11.4551 19.3579 11.9988 18.8985 11.9988H10.2039H1.50933C1.04999 11.9988 0.501602 11.4504 0.501602 11.0192C0.501602 11.0192 0.501602 1.59806 0.501602 1.41057C0.501602 1.21371 0.473479 0.969983 0.890632 1.1809Z"
        fill={color}
      />
    </svg>
  );
};

export default FooterEmail;
