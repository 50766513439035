import React from "react";

const seekerSquare = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="146"
      viewBox="0 0 120 146"
      fill="none"
    >
      <rect x="22.8828" width="96.9806" height="96.9806" fill="#FFFCFA" />
      <path
        d="M5.44464 38.1288C8.45164 38.1288 10.8893 35.6917 10.8893 32.6855C10.8893 29.6792 8.45164 27.2422 5.44464 27.2422C2.43765 27.2422 0 29.6792 0 32.6855C0 35.6917 2.43765 38.1288 5.44464 38.1288Z"
        fill="#FBC8AA"
      />
      <path
        d="M36.6877 32.6834C36.6877 33.7642 36.3672 34.8207 35.7666 35.7193C35.166 36.618 34.3123 37.3183 33.3136 37.7319C32.3148 38.1455 31.2157 38.2537 30.1554 38.0429C29.0951 37.832 28.1213 37.3116 27.3568 36.5474C26.5924 35.7832 26.0718 34.8095 25.8609 33.7494C25.65 32.6894 25.7582 31.5907 26.1719 30.5922C26.5856 29.5937 27.2863 28.7402 28.1852 28.1398C29.084 27.5393 30.1408 27.2188 31.2218 27.2188C31.9404 27.216 32.6524 27.3554 33.3168 27.629C33.9812 27.9026 34.5849 28.3051 35.093 28.8131C35.6011 29.321 36.0037 29.9246 36.2774 30.5888C36.5511 31.2531 36.6906 31.965 36.6877 32.6834Z"
        fill="#FBC8AA"
      />
      <path
        d="M57.0208 38.1288C60.0278 38.1288 62.4655 35.6917 62.4655 32.6855C62.4655 29.6792 60.0278 27.2422 57.0208 27.2422C54.0138 27.2422 51.5762 29.6792 51.5762 32.6855C51.5762 35.6917 54.0138 38.1288 57.0208 38.1288Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.262 32.6834C88.262 33.7642 87.9414 34.8207 87.3408 35.7193C86.7402 36.618 85.8866 37.3183 84.8878 37.7319C83.889 38.1455 82.7899 38.2537 81.7296 38.0429C80.6693 37.832 79.6955 37.3116 78.931 36.5474C78.1666 35.7832 77.646 34.8095 77.4351 33.7494C77.2242 32.6894 77.3324 31.5907 77.7461 30.5922C78.1598 29.5937 78.8605 28.7402 79.7594 28.1398C80.6582 27.5393 81.715 27.2188 82.796 27.2188C83.5146 27.216 84.2266 27.3554 84.891 27.629C85.5555 27.9026 86.1591 28.3051 86.6672 28.8131C87.1754 29.321 87.5779 29.9246 87.8516 30.5888C88.1253 31.2531 88.2648 31.965 88.262 32.6834Z"
        fill="#FBC8AA"
      />
      <path
        d="M10.8893 54.1404C10.8851 55.216 10.5622 56.2663 9.96138 57.1587C9.36057 58.051 8.50879 58.7454 7.51355 59.1542C6.5183 59.5629 5.42416 59.6676 4.36941 59.4552C3.31465 59.2427 2.3465 58.7227 1.58718 57.9606C0.827854 57.1985 0.311417 56.2286 0.103052 55.1733C-0.105313 54.118 0.00383391 53.0245 0.416544 52.0311C0.829254 51.0377 1.52709 50.1889 2.42199 49.5917C3.31689 48.9945 4.36868 48.6758 5.44463 48.6758C6.16142 48.6758 6.87118 48.8173 7.53315 49.0922C8.19511 49.3671 8.79622 49.7699 9.30208 50.2776C9.80794 50.7853 10.2087 51.3879 10.481 52.0508C10.7534 52.7137 10.8921 53.4237 10.8893 54.1404Z"
        fill="#FBC8AA"
      />
      <path
        d="M36.6877 54.1404C36.6877 55.2211 36.3672 56.2777 35.7666 57.1763C35.166 58.075 34.3123 58.7753 33.3136 59.1889C32.3148 59.6025 31.2157 59.7108 30.1554 59.4999C29.0951 59.2891 28.1213 58.7686 27.3568 58.0044C26.5924 57.2401 26.0718 56.2664 25.8609 55.2064C25.65 54.1464 25.7582 53.0477 26.1719 52.0492C26.5856 51.0507 27.2863 50.1972 28.1852 49.5968C29.084 48.9963 30.1408 48.6758 31.2218 48.6758C32.6715 48.6758 34.0617 49.2515 35.0868 50.2763C36.1118 51.3011 36.6877 52.6911 36.6877 54.1404Z"
        fill="#FBC8AA"
      />
      <path
        d="M62.463 54.1404C62.463 55.2221 62.1419 56.2795 61.5403 57.1786C60.9388 58.0777 60.0839 58.778 59.0839 59.191C58.0839 59.604 56.9838 59.7109 55.9229 59.4984C54.862 59.2858 53.8881 58.7632 53.1246 57.9968C52.361 57.2305 51.8422 56.2548 51.6337 55.1934C51.4252 54.132 51.5365 53.0326 51.9534 52.0344C52.3704 51.0363 53.0742 50.1843 53.9759 49.5864C54.8775 48.9885 55.9364 48.6716 57.0184 48.6758C57.7352 48.6758 58.4449 48.8173 59.1069 49.0922C59.7689 49.3671 60.37 49.77 60.8758 50.2777C61.3817 50.7854 61.7824 51.388 62.0548 52.0508C62.3271 52.7137 62.4658 53.4238 62.463 54.1404Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.262 54.1404C88.262 55.2211 87.9414 56.2777 87.3408 57.1763C86.7402 58.075 85.8866 58.7753 84.8878 59.1889C83.889 59.6025 82.7899 59.7108 81.7296 59.4999C80.6693 59.2891 79.6955 58.7686 78.931 58.0044C78.1666 57.2401 77.646 56.2664 77.4351 55.2064C77.2242 54.1464 77.3324 53.0477 77.7461 52.0492C78.1598 51.0507 78.8605 50.1972 79.7594 49.5968C80.6582 48.9963 81.715 48.6758 82.796 48.6758C84.2457 48.6758 85.6359 49.2515 86.661 50.2763C87.6861 51.3011 88.262 52.6911 88.262 54.1404Z"
        fill="#FBC8AA"
      />
      <path
        d="M5.44464 81.1874C8.45164 81.1874 10.8893 78.7503 10.8893 75.7441C10.8893 72.7378 8.45164 70.3008 5.44464 70.3008C2.43765 70.3008 0 72.7378 0 75.7441C0 78.7503 2.43765 81.1874 5.44464 81.1874Z"
        fill="#FBC8AA"
      />
      <path
        d="M36.6876 75.7441C36.6918 76.8258 36.3748 77.8843 35.7767 78.7858C35.1787 79.6872 34.3266 80.3909 33.3283 80.8078C32.3299 81.2246 31.2301 81.3359 30.1684 81.1275C29.1067 80.919 28.1308 80.4003 27.3642 79.6369C26.5977 78.8735 26.0751 77.8999 25.8625 76.8393C25.6498 75.7787 25.7568 74.6788 26.1699 73.679C26.5829 72.6793 27.2835 71.8246 28.1828 71.2232C29.0821 70.6218 30.1397 70.3008 31.2217 70.3008C32.6677 70.3008 34.0547 70.8735 35.0791 71.8937C36.1036 72.914 36.682 74.2985 36.6876 75.7441Z"
        fill="#FBC8AA"
      />
      <path
        d="M57.0208 81.1874C60.0278 81.1874 62.4655 78.7503 62.4655 75.7441C62.4655 72.7378 60.0278 70.3008 57.0208 70.3008C54.0138 70.3008 51.5762 72.7378 51.5762 75.7441C51.5762 78.7503 54.0138 81.1874 57.0208 81.1874Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.2618 75.7441C88.2661 76.8258 87.949 77.8843 87.351 78.7858C86.7529 79.6872 85.9009 80.3909 84.9025 80.8078C83.9041 81.2246 82.8043 81.3359 81.7426 81.1275C80.6809 80.919 79.705 80.4003 78.9385 79.6369C78.1719 78.8735 77.6493 77.8999 77.4367 76.8393C77.2241 75.7787 77.331 74.6788 77.7441 73.679C78.1571 72.6793 78.8577 71.8246 79.757 71.2232C80.6564 70.6218 81.714 70.3008 82.7959 70.3008C84.2419 70.3008 85.6289 70.8735 86.6534 71.8937C87.6778 72.914 88.2562 74.2985 88.2618 75.7441Z"
        fill="#FBC8AA"
      />
      <path
        d="M10.8893 97.2831C10.8893 98.3597 10.5699 99.4121 9.97165 100.307C9.37338 101.202 8.5231 101.9 7.52822 102.312C6.53334 102.724 5.43855 102.832 4.38239 102.622C3.32623 102.412 2.35616 101.893 1.59471 101.132C0.833261 100.371 0.314696 99.4009 0.104613 98.345C-0.10547 97.2892 0.00237833 96.1947 0.414471 95.2001C0.826564 94.2054 1.52442 93.3553 2.41978 92.7572C3.31515 92.1591 4.36778 91.8398 5.44463 91.8398C6.88864 91.8398 8.27348 92.4133 9.29455 93.4341C10.3156 94.4549 10.8893 95.8395 10.8893 97.2831Z"
        fill="#FBC8AA"
      />
      <path
        d="M36.6876 97.2831C36.6918 98.3648 36.3748 99.4234 35.7767 100.325C35.1787 101.226 34.3266 101.93 33.3283 102.347C32.3299 102.764 31.2301 102.875 30.1684 102.667C29.1067 102.458 28.1308 101.939 27.3642 101.176C26.5977 100.413 26.0751 99.4389 25.8625 98.3783C25.6498 97.3177 25.7568 96.2179 26.1699 95.2181C26.5829 94.2183 27.2835 93.3636 28.1828 92.7622C29.0821 92.1608 30.1397 91.8399 31.2217 91.8398C32.6677 91.8398 34.0547 92.4126 35.0791 93.4328C36.1036 94.453 36.682 95.8375 36.6876 97.2831Z"
        fill="#FBC8AA"
      />
      <path
        d="M62.463 97.2832C62.4672 98.3658 62.1497 99.4252 61.5507 100.327C60.9517 101.229 60.0983 101.933 59.0987 102.349C58.0991 102.765 56.9983 102.875 55.936 102.665C54.8738 102.455 53.8978 101.934 53.1321 101.168C52.3664 100.403 51.8455 99.4273 51.6353 98.3653C51.425 97.3033 51.535 96.2028 51.9513 95.2034C52.3677 94.204 53.0716 93.3508 53.9737 92.752C54.8758 92.1531 55.9355 91.8357 57.0184 91.8399C58.4624 91.8399 59.8472 92.4133 60.8683 93.4341C61.8894 94.455 62.463 95.8395 62.463 97.2832Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.2618 97.2831C88.2661 98.3648 87.949 99.4234 87.351 100.325C86.7529 101.226 85.9009 101.93 84.9025 102.347C83.9041 102.764 82.8043 102.875 81.7426 102.667C80.6809 102.458 79.705 101.939 78.9385 101.176C78.1719 100.413 77.6493 99.4389 77.4367 98.3783C77.2241 97.3177 77.331 96.2179 77.7441 95.2181C78.1571 94.2183 78.8577 93.3636 79.757 92.7622C80.6564 92.1608 81.714 91.8399 82.7959 91.8398C84.2419 91.8398 85.6289 92.4126 86.6534 93.4328C87.6778 94.453 88.2562 95.8375 88.2618 97.2831Z"
        fill="#FBC8AA"
      />
      <path
        d="M5.44464 124.266C8.45164 124.266 10.8893 121.829 10.8893 118.822C10.8893 115.816 8.45164 113.379 5.44464 113.379C2.43765 113.379 0 115.816 0 118.822C0 121.829 2.43765 124.266 5.44464 124.266Z"
        fill="#FBC8AA"
      />
      <path
        d="M36.6877 118.824C36.6877 119.905 36.3672 120.961 35.7666 121.86C35.166 122.759 34.3123 123.459 33.3136 123.873C32.3148 124.286 31.2157 124.394 30.1554 124.184C29.0951 123.973 28.1213 123.452 27.3568 122.688C26.5924 121.924 26.0718 120.95 25.8609 119.89C25.65 118.83 25.7582 117.731 26.1719 116.733C26.5856 115.734 27.2863 114.881 28.1852 114.28C29.084 113.68 30.1408 113.359 31.2218 113.359C31.9404 113.357 32.6524 113.496 33.3168 113.77C33.9812 114.043 34.5849 114.446 35.093 114.954C35.6011 115.462 36.0037 116.065 36.2774 116.729C36.5511 117.394 36.6906 118.106 36.6877 118.824Z"
        fill="#FBC8AA"
      />
      <path
        d="M57.0208 124.266C60.0278 124.266 62.4655 121.829 62.4655 118.822C62.4655 115.816 60.0278 113.379 57.0208 113.379C54.0138 113.379 51.5762 115.816 51.5762 118.822C51.5762 121.829 54.0138 124.266 57.0208 124.266Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.262 118.824C88.262 119.905 87.9414 120.961 87.3408 121.86C86.7402 122.759 85.8866 123.459 84.8878 123.873C83.889 124.286 82.7899 124.394 81.7296 124.184C80.6693 123.973 79.6955 123.452 78.931 122.688C78.1666 121.924 77.646 120.95 77.4351 119.89C77.2242 118.83 77.3324 117.731 77.7461 116.733C78.1598 115.734 78.8605 114.881 79.7594 114.28C80.6582 113.68 81.715 113.359 82.796 113.359C83.5146 113.357 84.2266 113.496 84.891 113.77C85.5555 114.043 86.1591 114.446 86.6672 114.954C87.1754 115.462 87.5779 116.065 87.8516 116.729C88.1253 117.394 88.2648 118.106 88.262 118.824Z"
        fill="#FBC8AA"
      />
      <path
        d="M10.8893 140.359C10.8851 141.435 10.5622 142.485 9.96138 143.377C9.36057 144.27 8.50879 144.964 7.51355 145.373C6.5183 145.782 5.42416 145.886 4.36941 145.674C3.31465 145.461 2.3465 144.941 1.58718 144.179C0.827854 143.417 0.311417 142.447 0.103052 141.392C-0.105313 140.337 0.00383391 139.243 0.416544 138.25C0.829254 137.256 1.52709 136.408 2.42199 135.81C3.31689 135.213 4.36868 134.895 5.44463 134.895C6.16142 134.895 6.87118 135.036 7.53315 135.311C8.19511 135.586 8.79622 135.989 9.30208 136.496C9.80794 137.004 10.2087 137.607 10.481 138.269C10.7534 138.932 10.8921 139.642 10.8893 140.359Z"
        fill="#FBC8AA"
      />
      <path
        d="M36.6877 140.359C36.6877 141.44 36.3672 142.496 35.7666 143.395C35.166 144.294 34.3123 144.994 33.3136 145.408C32.3148 145.821 31.2157 145.929 30.1554 145.719C29.0951 145.508 28.1213 144.987 27.3568 144.223C26.5924 143.459 26.0718 142.485 25.8609 141.425C25.65 140.365 25.7582 139.266 26.1719 138.268C26.5856 137.269 27.2863 136.416 28.1852 135.815C29.084 135.215 30.1408 134.895 31.2218 134.895C32.6715 134.895 34.0617 135.47 35.0868 136.495C36.1118 137.52 36.6877 138.91 36.6877 140.359Z"
        fill="#FBC8AA"
      />
      <path
        d="M62.463 140.359C62.463 141.441 62.1419 142.498 61.5403 143.397C60.9388 144.296 60.0839 144.997 59.0839 145.41C58.0839 145.823 56.9838 145.93 55.9229 145.717C54.862 145.504 53.8881 144.982 53.1246 144.216C52.361 143.449 51.8422 142.474 51.6337 141.412C51.4252 140.351 51.5365 139.251 51.9534 138.253C52.3704 137.255 53.0742 136.403 53.9759 135.805C54.8775 135.207 55.9364 134.89 57.0184 134.895C57.7352 134.895 58.4449 135.036 59.1069 135.311C59.7689 135.586 60.37 135.989 60.8758 136.496C61.3817 137.004 61.7824 137.607 62.0548 138.27C62.3271 138.932 62.4658 139.642 62.463 140.359Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.262 140.359C88.262 141.44 87.9414 142.496 87.3408 143.395C86.7402 144.294 85.8866 144.994 84.8878 145.408C83.889 145.821 82.7899 145.929 81.7296 145.719C80.6693 145.508 79.6955 144.987 78.931 144.223C78.1666 143.459 77.646 142.485 77.4351 141.425C77.2242 140.365 77.3324 139.266 77.7461 138.268C78.1598 137.269 78.8605 136.416 79.7594 135.815C80.6582 135.215 81.715 134.895 82.796 134.895C84.2457 134.895 85.6359 135.47 86.661 136.495C87.6861 137.52 88.262 138.91 88.262 140.359Z"
        fill="#FBC8AA"
      />
    </svg>
  );
};

export default seekerSquare;
