import React, { useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import Overview from "./tabs/Overview";
import Amenities from "./tabs/Amenities";
import FloorPlan from "./tabs/FloorPlan";
import Location from "./tabs/Location";
import NearByLocation from "./NearByLocation";
import PticeHistory from "./PriceHistory";

function PropertyDetailsTabs({
  isBtnLoading,
  unitDetails,
  clickToApply,
  clickToMessage,
}) {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("overview");

  return (
    <StyledTabWrapper>
      <StyledTabs
        defaultActiveKey={"overview"}
        activeKey={activeTab}
        onChange={(tabKey) => {
          // setList([]);
          setActiveTab(tabKey);
        }}
      >
        <TabPane tab="Overview" key="overview">
          <Overview
            unitDetails={unitDetails}
            clickToApply={clickToApply}
            clickToMessage={clickToMessage}
            isBtnLoading={isBtnLoading}
          />
        </TabPane>
        <TabPane tab="Amenities" key="amenities">
          <Amenities
            ammenities={unitDetails?.ammenities}
            clickToApply={clickToApply}
            clickToMessage={clickToMessage}
            isBtnLoading={isBtnLoading}
          />
        </TabPane>
        <TabPane tab="Floor Plan" key="floor-plan">
          <FloorPlan
            clickToApply={clickToApply}
            clickToMessage={clickToMessage}
            isBtnLoading={isBtnLoading}
          />
        </TabPane>
        <TabPane tab="Location" key="location">
          <Location
            unitDetails={unitDetails}
            clickToApply={clickToApply}
            clickToMessage={clickToMessage}
            isBtnLoading={isBtnLoading}
          />
        </TabPane>
        <TabPane tab="Near by" key="near-by">
          <NearByLocation
            clickToApply={clickToApply}
            clickToMessage={clickToMessage}
            isBtnLoading={isBtnLoading}
          />
        </TabPane>
        <TabPane tab="Price History" key="price-history">
          <PticeHistory
            clickToApply={clickToApply}
            clickToMessage={clickToMessage}
            isBtnLoading={isBtnLoading}
          />
        </TabPane>
      </StyledTabs>
    </StyledTabWrapper>
  );
}

export default PropertyDetailsTabs;

export const StyledTabWrapper = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 1400px;
    margin: auto;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    bottom: 3px;
  }

  @media (max-width: 1550px) {
    padding: 0 30px;
  }
  @media (max-width: 769px) {
    .ant-tabs > .ant-tabs-nav {
      margin: 0 -20px;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 12px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #2b353f;
    ${
      "" /* @media (max-width: 1200px) {
      padding: 8px 10px 8px 24px !important;
    } */
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.third};
  }
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.third};
    background: transparent;
  }
  .ant-tabs-ink-bar::after {
    content: " ";
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    height: 5px;
    background: ${(props) => props.theme.colors.third};
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transform: translateX(-50%);
  }
  .ant-tabs-nav .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-tab-btn:active {
    color: ${(props) => props.theme.colors.third};
  }
`;
