import React, { useEffect, useState } from "react";
import { Col, Divider, Menu, Row, Select, Spin, Tabs } from "antd";
import styled from "styled-components";
import UserProfileTab from "./UserProfileTab";
import BankingProfile from "./BankingProfile";
import RentalHistory from "./RentalHistory";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../components/container/Container";
import { Link, useLocation } from "react-router-dom";
import Payment from "./Payment";
import StateProfile from "./StateProfile";
import ProfilePdf from "./invoice-pdf/ProfilePdf";
import { pdf } from "@react-pdf/renderer";
import * as FileSaver from "file-saver";
import { upperCase } from "../../utils/fn";
import ApplicantAllDetails from "./ApplicantAllDetails";

function VerificationProcessTabs() {
  const location = useLocation();

  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { applicationProcessData, instantBankVerification } = useSelector(
    (state) => state.user
  );
  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("user-profile");
  const [onTabClick, setOnTabClick] = useState();
  const [isNextProfile, setIsNextProfile] = useState(false);
  const [isBankVerified, setIsBankVerified] = useState({});
  const [isReviewDeiable, setIsReviewDeiable] = useState(true);
  const [appPayloadData, setAppPayloadData] = useState({});
  const [updatedData, setUpdatedData] = useState({});

  const checkFillDataFunc = (data) => {
    setAppPayloadData(data);
  };

  const bankAccountVerification = (data) => {
    setIsBankVerified(data);
  };

  // click to back button
  const onBackClick = (tab) => {
    switch (tab) {
      case "state-profile":
        setActiveTab("payment");
        break;
      case "payment":
        setActiveTab("review-details");
        break;
      case "review-details":
        setActiveTab("rental-history");
        break;
      case "rental-history":
        setActiveTab("banking-profile");
        break;
      case "review-details":
        setActiveTab("review-details");
        break;
      case "banking-profile":
        setActiveTab("user-profile");
        break;

      default:
        break;
    }
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   dispatch(getApplicationProcess()).then(({ payload }) => {
  //     setIsLoading(false);
  //   });
  // }, []);

  // active tab changer
  const onMainTabNext = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (appPayloadData?.id) {
      let obj = {};
      obj.applicationPayment = appPayloadData.payment_data;
      obj.bank_verified_date = appPayloadData.bank_verified_date;
      obj.credit_score_data = appPayloadData.credit_score_data;
      obj.credit_score_verified_date =
        appPayloadData.credit_score_verified_date;
      obj.employment_verified_date = appPayloadData.employment_verified_date;
      obj.id = appPayloadData.id;
      obj.incomeAndEmployment = {};
      obj.incomeAndEmployment.instantBankVerification =
        appPayloadData.bank_verification_data;
      obj.incomeAndEmployment.manualVerification =
        appPayloadData.manual_verification_data;
      obj.id = appPayloadData.id;
      obj.is_checked_tnc = appPayloadData.is_checked_tnc;
      obj.is_credit_score_verified = appPayloadData.is_credit_score_verified;
      obj.is_employment_verified = appPayloadData.is_employment_verified;
      obj.is_general_profile_verified =
        appPayloadData.is_general_profile_verified;
      obj.is_paystub_verified = appPayloadData.is_paystub_verified;
      obj.is_rental_history_verified =
        appPayloadData.is_rental_history_verified;
      obj.is_state_profile_verified = appPayloadData.is_state_profile_verified;
      obj.is_verified = appPayloadData.is_verified;
      obj.paystub_verified_date = appPayloadData.paystub_verified_date;
      obj.rentalHistory = appPayloadData.rental_history;
      obj.rental_history_verified_date =
        appPayloadData.rental_history_verified_date;
      obj.stateProfile = appPayloadData.state_profile;
      obj.unit_id = appPayloadData.unit_id;
      obj.updated_at = appPayloadData.updated_at;
      obj.userProfile = {};
      obj.userProfile.generalProfile = appPayloadData.general_profile;
      obj.userProfile.idProof = appPayloadData.id_proof;
      obj.userProfile.members = appPayloadData.members;
      obj.user_id = appPayloadData.user_id;
      obj.review_user_list = appPayloadData.review_user_list;
      setUpdatedData(obj);
    } else if (applicationProcessData?.id) {
      setUpdatedData(applicationProcessData);
    } else {
      setUpdatedData({});
    }
  }, [appPayloadData]);

  useEffect(() => {
    if (updatedData?.id) {
      if (
        !updatedData?.userProfile ||
        (updatedData?.userProfile &&
          (Object.values(updatedData?.userProfile?.generalProfile || {})
            ?.length === 0 ||
            Object.values(updatedData?.userProfile?.idProof || {})?.length ===
              0)) ||
        (!updatedData?.incomeAndEmployment &&
          updatedData?.incomeAndEmployment?.manualVerification &&
          Object.values(
            updatedData?.incomeAndEmployment?.manualVerification
              ?.employerDetails || {}
          ).length === 0) ||
        (Object.values(
          updatedData?.incomeAndEmployment?.manualVerification || {}
        ).length === 0 &&
          !instantBankVerification?.incomeVerification?.request_id &&
          !Object.values(updatedData?.rentalHistory || {}).length === 0)
      ) {
        setIsReviewDeiable(true);
      } else {
        setIsReviewDeiable(false);
      }
    }
  }, [updatedData]);

  // default selected tabs
  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (
        !applicationProcessData?.userProfile ||
        (applicationProcessData?.userProfile &&
          (Object.values(
            applicationProcessData?.userProfile?.generalProfile || {}
          )?.length === 0 ||
            Object.values(applicationProcessData?.userProfile?.idProof || {})
              ?.length === 0))
      ) {
        setActiveTab("user-profile");
      } else if (
        !applicationProcessData?.incomeAndEmployment ||
        (applicationProcessData?.incomeAndEmployment?.manualVerification &&
          Object.values(
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails || {}
          ).length === 0) ||
        (Object.values(
          applicationProcessData?.incomeAndEmployment?.manualVerification || {}
        ).length === 0 &&
          !instantBankVerification?.incomeVerification?.request_id)
      ) {
        setActiveTab("banking-profile");
      } else if (
        !Object.values(applicationProcessData?.rentalHistory || {}).length === 0
      ) {
        setActiveTab("rental-history");
      } else if (
        Object.values(applicationProcessData?.applicationPayment || {})
          .length === 0
      ) {
        setActiveTab("payment");
        setIsReviewDeiable(false);
      } else {
        setActiveTab("state-profile");
        setIsReviewDeiable(false);
      }
    }
  }, [applicationProcessData]);

  function onOpenChange(keys, children) {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  }

  // rendered menu none used function
  const renderMenu = (menu, className = "") => {
    return menu.map(
      (
        {
          name,
          key,
          to,
          onClick,
          component: Item,
          icon: Icon,
          children,
          toSave,
          className: className,
          divider,
        },
        idx
      ) => {
        return (
          <>
            <Item
              key={key}
              className={className}
              icon={
                Icon && (
                  <MenuIconWrap>
                    <Icon />
                  </MenuIconWrap>
                )
              }
              title={children && name}
              level={idx + 1}
            >
              {children ? (
                renderMenu(children, `sub-children ${key}`)
              ) : to ? (
                <>
                  {/* <Link to={to} onClick={() => localStorage.setItem("orgName", name)}> */}
                  {key === "messages" ? (
                    <Link to={to} onClick={() => {}}>
                      {upperCase(name)}
                    </Link>
                  ) : (
                    <Link to={to} onClick={() => {}}>
                      {upperCase(name)}
                    </Link>
                  )}
                </>
              ) : onClick ? (
                <Link
                  to={window.location.pathname}
                  onClick={() => setActiveKey(name)}
                >
                  {name}
                </Link>
              ) : toSave ? (
                <>
                  {location.pathname.includes("property") ||
                  location.pathname.includes("listings") ? (
                    <Link
                      to={toSave === true ? "/listings" : `/property/${toSave}`}
                      // onClick={() => setLocalId(toSave, name)}
                    >
                      {upperCase(name)}
                    </Link>
                  ) : (
                    <Link
                      to={window.location.pathname}
                      // onClick={() => setLocalId(toSave, name)}
                    >
                      {upperCase(name)}
                    </Link>
                  )}
                </>
              ) : (
                name
              )}
            </Item>
          </>
        );
      }
    );
  };

  // const menuTwo = [
  //   {
  //     name: firstOrgName.charAt(0).toUpperCase() + firstOrgName.slice(1),
  //     key: "org",
  //     component: SubMenu,
  //     icon: MdKeyboardArrowDown,
  //     children: [
  //       {
  //         name: `Invite Applicant`,
  //         // icon: BsPlus,
  //         key: "add-new-org",
  //         component: MenuItem,
  //         // to: "/dashboard",
  //         onClick: onClickFun,
  //         className: "add__org",
  //       },
  //       {
  //         name: `Invite Applicant`,
  //         // icon: BsPlus,
  //         key: "add-new-org",
  //         component: MenuItem,
  //         // to: "/dashboard",
  //         onClick: onClickFun,
  //         className: "add__org",
  //       },
  //       {
  //         name: `Invite Applicant`,
  //         // icon: BsPlus,
  //         key: "add-new-org",
  //         component: MenuItem,
  //         // to: "/dashboard",
  //         onClick: onClickFun,
  //         className: "add__org",
  //       },
  //     ],
  //   },
  // ];

  const onTabClickFn = (key) => {
    if (isNextProfile) {
      setOnTabClick(!onTabClick);
    }
  };

  const stateProfileOpen = (isNext) => {
    setIsNextProfile(isNext);
  };

  // download invoice (PFD format)
  const downloadInvoice = async () => {
    const blob = await pdf(
      <ProfilePdf applicationProcessData={applicationProcessData} />
    ).toBlob();
    FileSaver.saveAs(blob, `Profile Data`);
  };

  return (
    <>
      <MyAccountTabsWrap>
        <Row justify="space-between">
          <Col span={16}>
            <PageTitle>
              <h2>User Profile Verification</h2>
            </PageTitle>
          </Col>
          <Col span={6}>
            {/* <HiDownload />
            <span className="share__class">Download</span> */}
            {/* <Menu
              openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
              triggerSubMenuAction="click"
              mode="horizontal"
            >
              {renderMenu(menuTwo)}
            </Menu> */}
            {/* <TopMenu
              triggerSubMenuAction="click"
              mode="horizontal"
              openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
            >
              {renderMenu(menuTwo)}
            </TopMenu> */}
          </Col>
        </Row>

        {isLoading ? (
          <Container>
            <div
              style={{
                position: "relative",
                minHeight: "calc(100vh - 250px)",
              }}
            >
              <Spin className="ant__spin" size="large" />
            </div>
          </Container>
        ) : (
          <StyledTabWrapper>
            <StyledTabs
              defaultActiveKey={"user-profile"}
              activeKey={activeTab}
              onChange={(tabKey) => {
                // setList([]);
                window.scrollTo(0, 0);
                setActiveTab(tabKey);
              }}
              onTabClick={(key) => onTabClickFn(key)}
              tabPosition="left"
            >
              <TabPane tab="User Profile" key="user-profile">
                <UserProfileTab
                  isLoading={isLoading}
                  onMainTabNext={onMainTabNext}
                  checkFillDataFunc={checkFillDataFunc}
                />
              </TabPane>
              <TabPane tab="Income and Employment" key="banking-profile">
                <BankingProfile
                  checkFillDataFunc={checkFillDataFunc}
                  onMainTabNext={onMainTabNext}
                  onBackClick={onBackClick}
                  bankAccountVerification={bankAccountVerification}
                  activeTabHome={activeTab}
                />
              </TabPane>
              <TabPane tab="Rental History" key="rental-history">
                <RentalHistory
                  activeTab={activeTab}
                  checkFillDataFunc={checkFillDataFunc}
                  isBankVerified={isBankVerified}
                  appPayloadData={appPayloadData}
                  onBackClick={onBackClick}
                  onMainTabNext={onMainTabNext}
                  setAppPayloadData={setAppPayloadData}
                />
              </TabPane>
              <TabPane
                tab="Review Details"
                key="review-details"
                disabled={isReviewDeiable}
              >
                <ApplicantAllDetails
                  appPayloadData={appPayloadData}
                  onMainTabNext={onMainTabNext}
                  onBackClick={onBackClick}
                />
              </TabPane>
              <TabPane disabled={isReviewDeiable} tab="Payment" key="payment">
                <Payment
                  onMainTabNext={onMainTabNext}
                  onBackClick={onBackClick}
                />
              </TabPane>
              <TabPane tab="State Profile" key="state-profile">
                <StateProfile
                  onBackClick={onBackClick}
                  onTabClick={onTabClick}
                  stateProfileOpen={stateProfileOpen}
                />
              </TabPane>
            </StyledTabs>
          </StyledTabWrapper>
        )}
      </MyAccountTabsWrap>
    </>
  );
}

export default VerificationProcessTabs;

export const MenuIconWrap = styled.div`
  // transition: box-shadow 0.1s;
  // height: 54px;
  // width: 54px;
  // background: #f6f7f9;
  // border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  ${"" /* padding-top: 4px; */}
  min-width: 18px !important;
  font-size: 25px !important;
  @media (max-width: 1550px) {
    font-size: 20px !important;
  }
`;

const StyledTabWrapper = styled.div`
  .ant-tabs > .ant-tabs-nav {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
    border-radius: 5px;
    min-height: calc(100vh - 260px);
    padding-top: 20px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    ${"" /* width: 1400px; */}
    margin: auto;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    bottom: 3px;
  }
  .ant-tabs-left > .ant-tabs-content-holder {
    position: relative; /*new postion */
    margin-left: -1px;
    border-left: 1px solid #d9d9d9;
    ${"" /* height: calc(100vh - 400px); */}
    ${"" /* margin-top: -24px; */}
  }

  @media (max-width: 993px) {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      margin: unset !important;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 50px 8px 24px !important;
  }

  .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background: #ecf2ff;
    border-radius: 0;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.secondary};
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  @media (max-width: 1550px) {
    .ant-tabs-tab {
      padding: 8px 40px 8px 24px !important;
    }
  }
`;

const PageTitle = styled.div`
  h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
    color: #2b353f;
    margin-bottom: 50px;
  }
  @media (max-width: 1550px) {
    h2 {
      font-size: 44px;
      line-height: 58px;
      margin-bottom: 35px;
    }
  }
`;
const MyAccountTabsWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 50px 0;

  .ant-menu-vertical.ant-menu-sub:not([class*="-active"]),
  .ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]),
  .ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]) {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
    border-bottom: none;
  }

  /* scrollbar design */
  body::-webkit-scrollbar {
    width: 0.5em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    outline: 1px solid rgb(112, 128, 144);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    body::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    body::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    body::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    padding: 30px;
  }
`;

export const TopMenu = styled(Menu)`
  margin-bottom: 8px;

  border-bottom: none;

  .ant-menu-inline {
    border-right: none !important;
    background: transparent;
    background: rgb(0 0 0 / 3%);
  }
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 2px solid transparent !important;
  }
`;

export const SubMenu = styled(Menu.SubMenu)`
  padding: 0 30px !important;
  overflow: initial !important;
  width: 100% !important;
  .ant-menu-submenu-title:hover {
    background-color: #ffffff !important;
  }

  &.sub-children {
    .ant-menu-submenu-title {
      height: 40px !important;
      .ant-menu-title-content {
        color: #666666;
      }
      .ant-menu-submenu-arrow {
        color: #000000;
      }
    }
  }

  .ant-menu-submenu-title {
    font-weight: bold;
    height: 50px !important;
    overflow: initial !important;
    background: #ffffff;
    color: #000000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-radius: 5px;
    padding: 0 10px !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    &:active {
      background: #ffffff !important;
    }
    &:hover {
      color: #000000;
    }
  }

  .ant-menu-sub {
    .ant-menu-item {
      color: inherit;
      background: transparent !important;
      overflow: initial !important;
      background: rgb(0 0 0 / 3%);
      &:hover {
        color: #ffffff;
      }
      &:after {
        border-right: none !important;
      }
      &:active {
        background: #eeeeee !important;
      }
    }
  }

  &:not(.ant-menu-submenu-selected) {
    color: #666666;
  }

  &:hover {
    .ant-menu-submenu-arrow {
      color: #ffffff !important;
    }
  }

  &.ant-menu-submenu-selected {
    color: #777777;
    .ant-menu-item-selected {
      background: #dee8ff !important;
    }
    .ant-menu-title-content {
      color: #ffffff;
    }
    .ant-menu-submenu-arrow {
      color: #ffffff;
    }
  }

  @media (max-width: 1550px) {
    padding: 0 24px !important;
    .ant-menu-submenu-title {
      height: 40px !important;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
`;

export const MenuItem = styled(Menu.Item)`
  ${"" /* overflow: initial !important; */}
  
 
  &:not(.ant-menu-item-only-child) {
    height: 50px !important;
    color: #666666;
    ${"" /* padding-left: 40px !important; */}

    @media(max-width: 1550px){
    height: 40px !important;
    color: #666666;
    ${"" /* padding-left: 32px !important; */}
    }
  }

  .ant-badge-count{
    top: 13px;
    background: ${({ theme }) => theme.colors.third};
    right: -10px;
    box-shadow: none;
    min-width: 17px;
    height: 17px;
    padding: 0 0px;
    line-height: 19px;
  }

  .ant-menu-title-content {
    font-weight: bold;
    font-size: 18px;
    line-height: 46px;
    color: #666666;
    a {
      line-height: 46px;
      font-weight: 400;
      font-size: 18px;
      color:{({theme}) => theme.colors.forth};
      
    }
    @media (max-width: 1550px){
      font-size: 12px;
      line-height: 36px;
    

    a {
      line-height: 36px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 1px;
      color:{({theme}) => theme.colors.forth};
    }
    }
  }

  &:hover {
    .ant-menu-title-content {
      a {
        color: #000000;
      }
    }
  }

  &.ant-menu-item-selected {
    background: #ffffff !important;
    overflow: hidden !important;
    border-left: none !important;
    &:after {
      border-right: none !important;
    }
    
    .ant-menu-title-content {
      a {
        color: #000000;
      }
    }
  }
`;

export const SidebarDivider = styled(Divider)`
  display: flex !important;
  clear: both !important;
  width: 85% !important;
  margin: auto !important;
  min-width: auto !important;
`;
