import React, { useEffect, useState } from "react";
import { MainContainer } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";
import styled from "styled-components";
import { H2Style } from "./MultiApplicationPart";
import OurClientCard from "./OurClientCard";
import Slider from "react-slick";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

function OurClient() {
  // const { width } = useWindowSize();
  // const [sides, setSides] = useState(3);

  // useEffect(() => {
  //   if (width > 990) {
  //     setSides(3);
  //   } else {
  //     setSides(1);
  //   }
  // }, [width]);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <MainContainer bgcolor="#FFFfff" containterHeight="auto">
      <TopTrendingSectionWrap margincomponent="0 auto 0">
        <OurClientWrap>
          <Row
            // gutter={[40, 40]}
            style={{ alignItems: "center" }}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={7}>
              <Row>
                <Col span={24}>
                  <Rectangle className="divider__long__sm" />
                </Col>
                <Col span={24}>
                  <H2Style
                    className="your__dream"
                    lineHeight="normal"
                    marginBottom="20px"
                    lineheightxs="32px"
                    marginTop="15px"
                    paddingtopxs="12px"
                    paddingBottomxs="25px"
                  >
                    What our clients have to say
                  </H2Style>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              {/* <OurClientCard /> */}
              <Slider {...settings}>
                <div>
                  <h3>
                    <OurClientCard />
                  </h3>
                </div>
                <div>
                  <h3>
                    <OurClientCard />
                  </h3>
                </div>
                <div>
                  <h3>
                    <OurClientCard />
                  </h3>
                </div>
                <div>
                  <h3>
                    <OurClientCard />
                  </h3>
                </div>
                <div>
                  <h3>
                    <OurClientCard />
                  </h3>
                </div>
                <div>
                  <h3>
                    <OurClientCard />
                  </h3>
                </div>
              </Slider>
            </Col>
          </Row>
        </OurClientWrap>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default OurClient;

const OurClientWrap = styled.div`
  overflow: hidden;
  h3 {
    border-radius: 10px;
    background: #fff;
    ${"" /* box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); */}
    padding: 100px 0;
  }

  .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.1);
    ${"" /* box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); */}
    z-index: 1;
  }

  @media (max-width: 991.98px) {
    .divider__long__sm {
      margin: auto;
    }

    .your__dream {
      text-align: center;
    }

    h3 {
      border-radius: 10px;
      background: #fff;
      padding: 70px 0;
    }
  }
`;
