import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationProcess,
  getApplicationProcess,
} from "../../../../store/reducers/user.reducer";
import { RiArrowGoBackLine } from "react-icons/ri";

function WhyRentedHere({
  onYourSpouseNext,
  onBackClickTabs,
  profileSubmit,
  selectedStateProfile,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [undoFields, setUndoFields] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      referred: "yes",
      findUs: "yes",
      findUsPlatform: "On the internet",
    });
  }, []);

  const onFinish = (value) => {
    setIsSubmit(true);

    let data = {};
    data.stateProfile = [];
    let stateData = {};
    stateData.stateName = selectedStateProfile.stateName;
    stateData.id = selectedStateProfile.id;
    stateData.whyYouRentedHere = value;
    data.stateProfile.push(stateData);

    dispatch(applicationProcess(data)).then(({ payload }) => {
      // dispatch(getApplicationProcess());
      if (selectedStateProfile?.id) {
      } else {
        const payloadDat = payload?.stateProfile;
        var stateValue = payloadDat?.filter(function (item) {
          return item["stateName"] === stateData.stateName;
        });
        profileSubmit(stateValue?.length ? stateValue[0] : {}, payloadDat);
      }
      setIsSubmit(false);
      onYourSpouseNext("emergency-contact");
    });
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (selectedStateProfile?.whyYouRentedHere) {
      const whyYouRentedHere = selectedStateProfile?.whyYouRentedHere;

      form.setFieldsValue({
        referred: whyYouRentedHere.referred,
        nameOfLocator: whyYouRentedHere.nameOfLocator,
        nameOfIndividual: whyYouRentedHere.nameOfIndividual,
        nameOfFriend: whyYouRentedHere.nameOfFriend,
        findUs: whyYouRentedHere.findUs,
        findUsPlatform: whyYouRentedHere.findUsPlatform,
      });
    } else {
    }
  }, [selectedStateProfile]);

  const undoHandler = () => {
    if (selectedStateProfile?.whyYouRentedHere) {
      const whyYouRentedHere = selectedStateProfile?.whyYouRentedHere;

      form.setFieldsValue({
        referred: whyYouRentedHere.referred,
        nameOfLocator: whyYouRentedHere.nameOfLocator,
        nameOfIndividual: whyYouRentedHere.nameOfIndividual,
        nameOfFriend: whyYouRentedHere.nameOfFriend,
        findUs: whyYouRentedHere.findUs,
        findUsPlatform: whyYouRentedHere.findUsPlatform,
      });
    } else {
      form.resetFields();
    }
    setUndoFields(false);
  };

  return (
    <GeneralProfileWrap>
      <h1>Additional details</h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              onValuesChange={() => setUndoFields(true)}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="referred"
                  label="Were you referred?"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // showSearch
                    placeholder=""
                    // onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="nameOfLocator"
                  label="Name of locator or rental agency"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="nameOfIndividual"
                  label="Name of individual locator or agent"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nameOfFriend"
                  label="Name of friend or other person"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="findUs"
                  label="Did you find us on your own?"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // showSearch
                    placeholder=""
                    // onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="findUsPlatform"
                  label=" "
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // showSearch
                    placeholder=""
                    // onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value={"On the internet"}>On the internet</Option>
                    {/* <Option value={"no"}>No</Option> */}
                  </Select>
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("why-here")}
                    />
                    <h2 onClick={() => onBackClickTabs("why-here")}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      {undoFields ? (
                        <Col>
                          <p className="cancel__btn" onClick={undoHandler}>
                            <RiArrowGoBackLine />
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default WhyRentedHere;
