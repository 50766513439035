import { Button, Checkbox, Col, Form, Radio, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Alabama,
  Alaska,
  AmericanSamoa,
  AvenewImg,
  BathSvg,
  BedSvg,
} from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../Profile-verification/BackgroundCheck";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap } from "./sub-tabs/GeneralProfile";
import StateProfileTabs from "./sub-tabs/state-profile-tabs/StateProfileTabs";
import states from "./sub-tabs/states.json";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { dateConverter } from "../../utils/fn";

function StateProfile({ stateProfileOpen, onTabClick }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const clickRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [value, setValue] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedStateProfile, setSelectedStateProfile] = useState({});
  const [showData, setShowData] = useState(10);
  const [btnName, setBtnName] = useState("Create Profile");
  const [selectedState, setSelectedState] = useState({});
  const [userStateProfile, setUserStateProfile] = useState([]);
  const [usedStateArr, setUsedStateArr] = useState([]);

  useEffect(() => {
    stateProfileOpen(isNext);
  }, [isNext]);

  useEffect(() => {
    setIsNext(false);
  }, [onTabClick]);

  // set value in state
  const onChange = (e) => {
    setValue(e.target.checked);
  };

  const [stringSort, setStringSort] = useState(100);

  // based on screen size
  useEffect(() => {
    if (width < 769) {
      setStringSort(18);
    } else {
      setStringSort(100);
    }
  }, [width, location.pathname]);

  // slpit function
  const slpitFunc = (value) => {
    if (value?.length > 25) {
      return value.slice(0, stringSort) + "..." + value.slice(-5);
    } else {
      return value;
    }
  };

  // data to JSON.parse
  useEffect(() => {
    if (
      sessionStorage.getItem("applyingProperty") &&
      JSON.parse(sessionStorage.getItem("applyingProperty"))?.id
    ) {
      const item = JSON.parse(sessionStorage.getItem("applyingProperty"));
      if (item?.id) {
        setSelectedItem(item);
      } else {
        setSelectedItem({});
      }
    } else {
    }
  }, [sessionStorage.getItem("applyingProperty")]);

  useEffect(() => {
    setUserStateProfile(applicationProcessData?.stateProfile);
    if (applicationProcessData?.stateProfile?.length) {
      const usedState = applicationProcessData?.stateProfile?.map(
        (val) => val.stateName
      );
      setUsedStateArr(usedState);
    } else {
      form.setFieldsValue({
        stateName: "Texas",
      });
    }
  }, [applicationProcessData]);

  const onFinish = (value) => {
    setSelectedState(value);
    setSelectedStateProfile(value);
    setIsNext(true);
  };

  const onProfileSubmit = (value, fullstate) => {
    setSelectedState(value);
    setUserStateProfile(fullstate);
    if (fullstate?.length) {
      const usedState = fullstate?.map((val) => val.stateName);
      setUsedStateArr(usedState);
      form.resetFields();
    }
  };

  // Form validate messages
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const onValueChange = (value) => {
    // console.log(value);
  };

  useEffect(() => {
    if (applicationProcessData?.stateProfile?.length) {
      if (
        applicationProcessData?.stateProfile[0]?.spouseDetails &&
        applicationProcessData?.stateProfile[0]?.workDetails &&
        applicationProcessData?.stateProfile[0]?.creditHistory &&
        applicationProcessData?.stateProfile[0]?.rentalOrCriminalHistory &&
        applicationProcessData?.stateProfile[0]?.vehicleDetails &&
        applicationProcessData?.stateProfile[0]?.whyYouRentedHere &&
        applicationProcessData?.stateProfile[0]?.emergencyContact
      ) {
        setBtnName("View");
      } else if (applicationProcessData?.stateProfile[0]?.stateName) {
        setBtnName("Continue");
      } else {
        setBtnName("Create Profile");
      }
    }
  }, [applicationProcessData]);

  const btnNameFn = (value) => {
    if (
      value?.spouseDetails &&
      value?.workDetails &&
      value?.creditHistory &&
      value?.rentalOrCriminalHistory &&
      value?.vehicleDetails &&
      value?.whyYouRentedHere &&
      value?.emergencyContact
    ) {
      return "View";
    } else if (value?.stateName) {
      return "Continue";
    } else {
      return "Create Profile";
    }
  };

  const bgcolor = (value, type) => {
    if (type === "back") {
      if (
        value?.spouseDetails &&
        value?.workDetails &&
        value?.creditHistory &&
        value?.rentalOrCriminalHistory &&
        value?.vehicleDetails &&
        value?.whyYouRentedHere &&
        value?.emergencyContact
      ) {
        return "#ECF2FF";
      } else if (value?.stateName) {
        return "#ffe1d0";
      } else {
        return "#ffe1d0";
      }
    } else if (type === "font") {
      if (
        value?.spouseDetails &&
        value?.workDetails &&
        value?.creditHistory &&
        value?.rentalOrCriminalHistory &&
        value?.vehicleDetails &&
        value?.whyYouRentedHere &&
        value?.emergencyContact
      ) {
        return "#4160AC";
      } else if (value?.stateName) {
        return "#f67c36";
      } else {
        return "#f67c36";
      }
    }
  };

  return (
    <>
      {isNext ? (
        <StateProfileTabs
          selectedState={selectedState}
          onProfileSubmit={onProfileSubmit}
          selectedStateProfile={selectedStateProfile}
          onBackClick={() => setIsNext(false)}
        />
      ) : (
        <GeneralProfileWrap>
          <h1>
            It looks like you were looking at the listing below. Would you like
            to continue filling out the Texas profile for that listing? not
            select a state from below.
          </h1>
          {selectedItem?.id ? (
            <UnitBox>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  {selectedItem?.images && selectedItem?.images?.length ? (
                    <>
                      {selectedItem.images.map((img, idx) => (
                        <div key={idx}>
                          {img.isCover === true ? (
                            <img alt="example" src={img.path} />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <AvenewImg
                      className="svg__border"
                      style={{ background: "#eeeeee", height: "175px" }}
                    />
                  )}
                </Col>
                <Col style={{ alignItems: "center", display: "flex" }}>
                  <Row>
                    <Col span={24}>
                      <h2>{selectedItem?.unit_name}</h2>
                    </Col>
                    <Col style={{ display: "flex" }} span={24}>
                      <Row gutter={[10, 10]}>
                        {selectedItem?.bath ? (
                          <Col>
                            <SmallBox>
                              <p>
                                <BathSvg />
                              </p>
                              <span>Bath : {selectedItem?.bath}</span>
                            </SmallBox>
                          </Col>
                        ) : (
                          ""
                        )}
                        {selectedItem?.bed ? (
                          <Col>
                            <SmallBox>
                              <p>
                                <BathSvg />
                              </p>
                              <span>Bed : {selectedItem?.bed}</span>
                            </SmallBox>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <ContinueWrap
                  onClick={() =>
                    navigate(`/unit-application/${selectedItem.id}`)
                  }
                >
                  <p>Continue</p>
                </ContinueWrap>
              </Row>
            </UnitBox>
          ) : (
            ""
          )}

          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                layout="vertical"
                onFinish={onFinish}
                validateMessages={validateMessages}
                form={form}
              >
                <StyledFormItem
                  wh="100"
                  ftColor="#777777"
                  bgColor="#ffffff"
                  boxShadow="0px 0px 20px 0px #0000001A"
                >
                  <Form.Item
                    name="stateName"
                    label="Select State to start a new application, or continue existing application "
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      // defaultValue={usedStateArr?.length ? "" : "Ohio"}
                      showSearch
                      placeholder="State profile"
                      onChange={onValueChange}
                      suffixIcon={<IoIosArrowDown />}
                      // options={states}
                    >
                      {states?.map((val, id) => (
                        <Option
                          key={id}
                          disabled={usedStateArr?.includes(val.value)}
                          value={val.value}
                        >
                          {val.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </StyledFormItem>
                <Form.Item>
                  <Row justify="end">
                    <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={5}>
                      <StyledButton htmlType="submit" loading={isSubmit}>
                        Create Profile
                      </StyledButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
          <ExistingApplication>
            {userStateProfile?.length ? <h2>Existing applications </h2> : ""}

            {userStateProfile &&
              userStateProfile?.map((item, id) => (
                <StateCard key={id}>
                  <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col>
                      <h4>{item.stateName}</h4>
                    </Col>
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <Col style={{ display: "flex", gap: "10px" }}>
                          <h6>Last updated : </h6>
                          <h5>{dateConverter(item?.updatedAt)}</h5>
                        </Col>
                        <Col>
                          <StyledButtonState
                            bgcolor={() => bgcolor(item, "back")}
                            fcolor={() => bgcolor(item, "font")}
                            onClick={() => {
                              setSelectedState(item);
                              setIsNext(true);
                            }}
                          >
                            {btnNameFn(item)}
                          </StyledButtonState>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </StateCard>
              ))}
          </ExistingApplication>
        </GeneralProfileWrap>
      )}
    </>
  );
}

export default StateProfile;

const StyledButtonState = styled(Button)`
  height: 40px;
  width: 115px !important;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  width: ${({ btnwidth }) => btnwidth || "auto"};
  border-radius: 3px;
  background: ${({ bgcolor }) => bgcolor || "#ffe1d0"};
  border-color: ${({ bgcolor }) => bgcolor || "#ffe1d0"};
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffe1d090;
    border-color: ${({ bgcolor }) => bgcolor || "#ffe1d0"}90;
    background: ${({ bgcolor }) => bgcolor || "#ffe1d0"}90;
  }
  span {
    color: ${({ fcolor }) => fcolor || "#f67c36"};
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  @media screen and (max-width: 991.98px) {
    width: ${({ btnwidthsm }) => btnwidthsm || "auto"};
  }
  @media screen and (max-width: 769px) {
    padding: ${({ paddingbtn }) => paddingbtn || "4px 20px"};
  }
`;

const StateCard = styled.div`
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  h4 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
  }

  h6,
  h5 {
    color: #777;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0;
  }

  h5 {
    color: #000;
    margin-right: 20px;
  }
`;

const ExistingApplication = styled.div`
  margin-top: 50px;

  h2 {
    color: #777;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const StateBox = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 12px;

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #eeeeee;
    background-color: #2b353f;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #eeeeee;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #eeeeee;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2b353f;
    border: 1px solid #2b353f;
  }

  .ant-checkbox-inner {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 50%;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
  }
`;

const GeneralProfileWrap = styled.div`
  .view__more {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #4160ac;
    cursor: pointer;
    margin-top: 30px;
  }

  .back__btn h2,
  svg {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #777777;
    margin-left: 10px;
    cursor: pointer;
  }
  h1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: ${({ mb }) => mb || "30px"};
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: ${({ mt }) => mt || "20px"};
    margin-bottom: ${({ mb }) => mb || "15px"};
    color: #777777;
  }

  @media (max-width: 769px) {
    h1 {
      display: ${({ display }) => display || "visible"};
      margin-bottom: ${({ mb }) => mb || "20px"};
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

const SmallBox = styled.div`
  align-items: center;
  display: flex;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 10px 10px 10px 0;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #848687;
    margin-bottom: 0;
  }
  span {
    margin-left: 10px;
    line-height: 24px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #848687;
  }

  @media (max-width: 769px) {
    padding: 5px 5px 5px 0;
  }
`;

const ContinueWrap = styled.div`
  position: absolute;
  right: 10px;
  background: #ffe1d0;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #f67c36;
    margin-bottom: 0;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

const UnitBox = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  position: relative;

  img {
    width: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #2b353f;
  }

  @media (max-width: 769px) {
    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #2b353f;
    }
  }
`;
