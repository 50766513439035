import React, { useState, useEffect } from "react";
import { PrefixStyle } from "../LoginNew";
import { Col, Form, Input, Row, Select, message } from "antd";
import { StyledButton, StyledFormItem } from "../Login";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getApplicationProcess,
  me,
  register,
  verifySignUp,
} from "../../../store/reducers/user.reducer";
import { SelectArrow } from "../../signup/Signup";
import OtpComponent from "../OtpComponent";
import { AccountDone } from "../../../assets/svgs";
import styled from "styled-components";
import { currentDateTime } from "../../../utils/fn";
import { IoIosArrowBack } from "react-icons/io";

const EmailLogin = ({
  onCancel,
  onLogin = console.log(),
  modalState,
  btnValue = "signup",
}) => {
  const { Option } = Select;
  var formRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [loadings, setloadings] = useState(false);
  const [isOptSend, setIsOptSend] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [isFormField, setIsFormField] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");
  const [writenNumber, setWritenNumber] = useState("");
  const [phoneSendOtp, setPhoneSendOtp] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [unitId, setUnitId] = useState("");
  const [signUpValue, setSignUpValue] = useState({});

  // To set Login or signup form w.r.t text passed
  useEffect(() => {
    if (btnValue === "signup") {
      setIsLogin(false);
    } else {
      setIsLogin(true);
    }
  }, [btnValue]);

  // For resend OTP to give phone number
  const onFinishResendOtp = () => {
    let value = {};
    value.phoneNumber = writenNumber;
    value.email = signUpValue.email;
    value.createdAt = signUpValue.createdAt;
    value.firstName = signUpValue.firstName;
    value.lastName = signUpValue.lastName;
    value.updatedAt = signUpValue.updatedAt;
    value.isResend = 1;

    dispatch(register(value)).then(({ payload }) => {
      setPhoneSendOtp(payload);
      setloadings(false);
      setIsOptSend(true);
      message.success("OTP has been sent to your mobile");
    });
  };

  // For login and signup new profile
  const onFinish = async (value) => {
    setloadings(true);
    setEmailValidation("");
    if (value.phoneNumber && !value.email) {
      value.phoneNumber = `+1${value.phoneNumber}`;
      setWritenNumber(value.phoneNumber);

      dispatch(register(value)).then(({ payload }) => {
        if (payload?.Error || payload?.error) {
          setEmailValidation(payload?.Error || payload?.error);
          setloadings(false);
        } else {
          setPhoneSendOtp(payload);
          setloadings(false);
          setIsOptSend(true);
        }
      });
    } else {
      value.phoneNumber = `+1${value.phoneNumber}`;
      value.email = value.email.toLowerCase();
      value.createdAt = currentDateTime;
      value.updatedAt = currentDateTime;
      dispatch(register(value)).then(({ payload }) => {
        if (payload?.Error || payload?.error) {
          setEmailValidation(payload?.Error || payload?.error);
          setloadings(false);
        } else {
          setWritenNumber(value.phoneNumber);
          setIsOptSend(true);
          setSignUpValue(value);
          setloadings(false);
        }
      });
    }
  };

  // default error- if email is wrong or not exist
  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("already exists")
    ) {
      formRef.current.setFields([
        {
          name: "phoneNumber",
          errors: ["Phone Number is already exists"],
        },
      ]);
    } else if (
      emailValidation?.length &&
      emailValidation?.includes("invited Contact Number")
    ) {
      formRef.current.setFields([
        {
          name: "phoneNumber",
          errors: ["Please provide invited contact number"],
        },
      ]);
    } else if (
      emailValidation?.length &&
      emailValidation?.includes("sign up")
    ) {
      setIsLogin(false);
      formRef.current.setFields([
        {
          name: "phoneNumber",
          errors: ["User is not exists"],
        },
      ]);
    } else if (
      emailValidation?.length &&
      emailValidation?.includes("deactivated")
    ) {
      formRef.current.setFields([
        {
          name: "phoneNumber",
          errors: [
            "Your account has been deactivated. Please contact Avenew Team to activate.",
          ],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  // Form validation message
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "Enter a valid email",
      number: "${label} is not a valid number.",
    },
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   // default error- if email is wrong or not exist
  //   useEffect(() => {
  //     if (
  //       emailValidation?.length &&
  //       emailValidation?.includes("User with email")
  //     ) {
  //       formRef.current.setFields([
  //         {
  //           name: "email",
  //           errors: ["Email is not exist"],
  //         },
  //       ]);
  //     }
  //   }, [emailValidation, loadings]);

  const reDirectFun = () => {
    if (params.id) {
      navigate(`/signup/${params.id}`);
    } else {
      navigate(`/signup`);
    }
  };

  // Jsx codde for Prefix for Phone number
  const prefixSelector = (
    <SelectArrow>
      <Form.Item noStyle>
        {/* <span>+1</span> */}
        <PrefixStyle>
          <Select defaultValue="+1">
            <Option value="+1">+1</Option>
          </Select>
        </PrefixStyle>
      </Form.Item>
    </SelectArrow>
  );

  //To set unit-id From local storage
  useEffect(() => {
    if (localStorage.getItem("unit-details")) {
      const id = localStorage.getItem("unit-details");
      setUnitId(id);
    }
  }, [localStorage.getItem("unit-details"), localStorage.getItem("idToken")]);

  //  For OTP validation (user is exist or new user)
  const otpValue = (value) => {
    setVerifyOtp(value);
    setloadingsOtp(true);
    if (value.length < 6) {
      setErrorMessage("Invalid code");
      setloadingsOtp(false);
    } else {
      if (phoneSendOtp?.request === "signIn") {
        dispatch(
          verifySignUp({
            request: "signIn",
            phoneNumber: writenNumber,
            otp: value,
          })
        ).then(({ payload }) => {
          if (payload?.Error || payload?.error) {
            setErrorMessage("Invalid code");
            setloadingsOtp(false);
          } else {
            dispatch(me());
            dispatch(getApplicationProcess()).then(({ payload }) => {
              // onLogin(payload);
            });
            setErrorMessage("");
            setloadingsOtp(false);
            onCancel(false);
          }
        });
      } else {
        dispatch(
          verifySignUp({
            request: "signUp",
            ...signUpValue,
            otp: value,
          })
        ).then(({ payload }) => {
          if (payload?.Error || payload?.error) {
            setErrorMessage("Invalid code");
            setloadingsOtp(false);
          } else {
            // setIsFormField(true);
            dispatch(me());
            dispatch(getApplicationProcess()).then(({ payload }) => {
              // onLogin(payload);
            });
            setErrorMessage("");
            setloadingsOtp(false);
            onCancel(false);
          }
        });
      }
    }
  };

  return (
    <LoginModalWrap mb="20px">
      {isFormField ? (
        <>
          <h2 className="welcome__font">Welcome to Avenew</h2>
          <AccountDone />
          <h4>
            We've sent an email to verify your account. Verify email address to
            activate your account.
          </h4>
          <StyledButton
            onClick={() => {
              onCancel(false);
              navigate("/application-process");
            }}
            htmlType="submit"
            loading={loadings}
          >
            Login
          </StyledButton>
        </>
      ) : otpVerify ? (
        <>
          <h2 className="welcome__font">Welcome Back</h2>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={"optional"}
            validateMessages={validateMessages}
            ref={formRef}
          >
            <StyledFormItem smwh="100">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem wh="100" smwh="100">
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </StyledFormItem>
            <Form.Item>
              <StyledButton htmlType="submit" loading={loadings}>
                Continue
              </StyledButton>
            </Form.Item>
          </Form>
        </>
      ) : isOptSend ? (
        <>
          {isLogin ? (
            <h2 className="welcome__font">Login</h2>
          ) : (
            <h2 className="welcome__font">Signup</h2>
          )}
          <h3>
            Enter the code you have received on your phone number you've
            provided
          </h3>
          <OtpComponent
            errorMessage={errorMessage}
            otpValue={otpValue}
            loadingsOtp={loadingsOtp}
            setIsOptSend={setIsOptSend}
            modalState={modalState}
            onFinishResendOtp={onFinishResendOtp}
          />
          <Row gutter={[10, 10]} style={{ alignItems: "center" }}>
            <Col style={{ display: "flex" }}>
              <IoIosArrowBack
                onClick={() => setIsOptSend(false)}
                className="back__btn"
              />
            </Col>
            <Col>
              <h2 onClick={() => setIsOptSend(false)} className="back__btn">
                back
              </h2>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {isLogin ? (
            <h2 className="welcome__font">Login</h2>
          ) : (
            <h2 className="welcome__font">Signup</h2>
          )}

          <h3>
            For a quick login {isLogin ? "" : "creation,"} enter your phone
            number, then you will receive a one time password (OTP). This
            process will quickly make you a secure user in Avenew.
          </h3>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={"optional"}
            validateMessages={validateMessages}
            ref={formRef}
          >
            {isLogin ? (
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                    },
                    { max: 10, message: "Invalid Phone Number!" },
                    { min: 10, message: "It should be 10 digits" },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    type="number"
                    min="0"
                    pattern="[0-9]*"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </StyledFormItem>
            ) : (
              <>
                <StyledFormItem smwh="100">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        whitespace: true,
                        message: "First Name cannot be empty",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        whitespace: true,
                        message: "First Name cannot be empty",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                      },
                      { max: 10, message: "Invalid Phone Number!" },
                      { min: 10, message: "It should be 10 digits" },
                    ]}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      type="number"
                      min="0"
                      pattern="[0-9]*"
                      onWheel={(e) => e.target.blur()}
                    />
                  </Form.Item>
                </StyledFormItem>
              </>
            )}
            <Form.Item>
              <StyledButton htmlType="submit" loading={loadings}>
                Continue
              </StyledButton>
            </Form.Item>
          </Form>
          {isLogin ? (
            <LoginBottomWrap>
              <p onClick={() => setIsLogin(false)}>
                Don’t Have Account? &nbsp;
                <h2> Signup</h2>
              </p>
            </LoginBottomWrap>
          ) : (
            <LoginBottomWrap>
              <p onClick={() => setIsLogin(true)}>
                Have you account? &nbsp;
                <h2> Login</h2>
              </p>
            </LoginBottomWrap>
          )}
        </>
      )}
    </LoginModalWrap>
  );
};

export default EmailLogin;

const LoginBottomWrap = styled.div`
  text-align: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 0;
    display: flex;
    cursor: pointer;
    justify-content: center;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.third};
    cursor: pointer;
    :hover {
      color: #ff5d00;
    }
  }
`;

const LoginModalWrap = styled.div`
  width: 100%;
  padding: 20px 20px;

  .welcome__font {
    margin-bottom: ${({ mb }) => mb || "0"};
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  .back__btn {
    margin-top: 20px;
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    cursor: pointer;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: ${({ mb }) => mb || "0"};
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  @media (max-width: 576px) {
    padding: 10px 10px;
    .welcome__font {
      font-size: 30px;
    }
  }
`;
