import { Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { StyledTitle } from "../property-details/tabs/floor-plan-modal/FloorPlanModal";
import styled from "styled-components";
import { StyledButton } from "../dashboard/Dashboard.styles";
import { StyledButtonApprove } from "../../components/invitationModal/ApplicationInvitationModal";
import { useDispatch } from "react-redux";
import { applicationProcess } from "../../store/reducers/user.reducer";

const ObtainCreditScoreModal = ({ modalState, onCancel, onMainTabNext }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  const onSubmit = () => {
    setisLoading(true);
    let data = {};
    data.isFetchCRS = 1;
    dispatch(applicationProcess(data)).then(({ payload }) => {
      setisLoading(false);
      onCancel(false);
      onMainTabNext("payment");
    });
  };

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={"800px"}
      footer={null}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <StyleWrap>
        <Row>
          <h2>
            Awesome, In order to prequalify for a unit, kindly allow us to
            obtain your credit score information.
          </h2>
          <Col span={24}>
            <h3>
              You need to finish your state application before being able to
              apply.
            </h3>
          </Col>
          <Row gutter={[20, 20]} style={{ width: "100%" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <StyledButtonApprove
                // loading={isLoadingApprove}
                onClick={() => {
                  onMainTabNext("payment");
                  onCancel(false);
                }}
              >
                Deny
              </StyledButtonApprove>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <StyledButton
                loading={isLoading}
                onClick={() => {
                  onSubmit();
                }}
              >
                Continue
              </StyledButton>
            </Col>
          </Row>
        </Row>
      </StyleWrap>
    </Modal>
  );
};

export default ObtainCreditScoreModal;

const StyleWrap = styled.div`
  width: 100%;
  padding: 30px;

  h2 {
    color: #2b353f;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  h3 {
    color: #2b353f;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 200% */
  }
`;
