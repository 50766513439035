import React from "react";

const waitingApprovalSvg = ({
  wSize = "54",
  hSize = "54",
  bgColor = "#ffffff",
  color = "#666666",
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.2019 15.4287C47.8792 15.4282 47.5618 15.3467 47.2788 15.1917C46.9958 15.0367 46.7561 14.8132 46.5819 14.5416C46.2926 14.0787 45.3283 12.9023 44.2676 11.6488C43.9244 11.2782 43.7401 10.7879 43.7546 10.283C43.769 9.77812 43.9809 9.29906 44.3448 8.94875C44.731 8.61396 45.2344 8.44621 45.7443 8.48238C46.2542 8.51854 46.7289 8.75565 47.064 9.14161C47.5655 9.64304 49.1469 11.5716 49.7448 12.478C50.0202 12.9079 50.114 13.4294 50.0056 13.9283C49.8971 14.4272 49.5953 14.8628 49.1662 15.1395C48.876 15.3196 48.5433 15.4194 48.2019 15.4287Z"
        fill="#F67C36"
      />
      <path
        d="M26.9876 54C22.7675 53.9981 18.6067 53.0069 14.839 51.1062C11.0712 49.2055 7.80147 46.448 5.2921 43.0551C2.78272 39.6622 1.10359 35.7284 0.389433 31.5692C-0.324722 27.41 -0.0540122 23.1414 1.17984 19.1057C2.41369 15.0701 4.57633 11.3799 7.49422 8.33119C10.4121 5.28249 14.004 2.96019 17.9817 1.55065C21.9593 0.141105 26.212 -0.316434 30.3985 0.214745C34.585 0.745923 38.5887 2.25103 42.0883 4.60929C42.5128 4.89572 42.8062 5.33907 42.9038 5.8418C43.0015 6.34453 42.8954 6.86547 42.609 7.29C42.3225 7.71454 41.8792 8.0079 41.3765 8.10555C40.8737 8.20321 40.3528 8.09715 39.9283 7.81072C35.6474 4.92342 30.5239 3.5507 25.3729 3.91096C20.2219 4.27123 15.3393 6.34378 11.502 9.79887C7.66473 13.254 5.09313 17.8931 4.19638 22.9782C3.29962 28.0633 4.12922 33.3023 6.55319 37.8615C8.97716 42.4208 12.8562 46.0384 17.5733 48.1389C22.2903 50.2394 27.5743 50.7021 32.5846 49.4533C37.5949 48.2045 42.0436 45.3159 45.223 41.2473C48.4024 37.1786 50.1298 32.1636 50.1304 27C50.1304 26.4885 50.3336 25.998 50.6953 25.6363C51.057 25.2746 51.5475 25.0714 52.059 25.0714C52.5705 25.0714 53.061 25.2746 53.4227 25.6363C53.7844 25.998 53.9876 26.4885 53.9876 27C53.9876 34.1608 51.1429 41.0284 46.0794 46.0919C41.016 51.1554 34.1484 54 26.9876 54Z"
        fill="#F67C36"
      />
      <path
        d="M17.3447 27.0001C16.2004 27.0001 15.0818 26.6608 14.1303 26.0251C13.1789 25.3893 12.4373 24.4857 11.9994 23.4285C11.5615 22.3713 11.4469 21.208 11.6701 20.0857C11.8934 18.9634 12.4444 17.9325 13.2536 17.1233C14.0627 16.3142 15.0936 15.7631 16.2159 15.5399C17.3383 15.3166 18.5016 15.4312 19.5588 15.8691C20.616 16.307 21.5196 17.0486 22.1553 18.0001C22.7911 18.9515 23.1304 20.0701 23.1304 21.2144C23.1304 22.7489 22.5208 24.2205 21.4358 25.3055C20.3508 26.3906 18.8791 27.0001 17.3447 27.0001ZM17.3447 19.2859C16.9632 19.2859 16.5904 19.399 16.2732 19.6109C15.9561 19.8228 15.7089 20.124 15.5629 20.4764C15.4169 20.8288 15.3787 21.2166 15.4532 21.5907C15.5276 21.9648 15.7113 22.3084 15.981 22.5781C16.2507 22.8479 16.5943 23.0315 16.9684 23.1059C17.3425 23.1804 17.7303 23.1422 18.0827 22.9962C18.4351 22.8502 18.7363 22.603 18.9482 22.2859C19.1601 21.9687 19.2732 21.5959 19.2732 21.2144C19.2732 20.7029 19.0701 20.2124 18.7084 19.8507C18.3467 19.489 17.8562 19.2859 17.3447 19.2859Z"
        fill="#F67C36"
      />
      <path
        d="M36.6303 27.0001C35.486 27.0001 34.3674 26.6608 33.416 26.0251C32.4645 25.3893 31.7229 24.4857 31.285 23.4285C30.8471 22.3713 30.7325 21.208 30.9558 20.0857C31.179 18.9634 31.7301 17.9325 32.5392 17.1233C33.3484 16.3142 34.3793 15.7631 35.5016 15.5399C36.6239 15.3166 37.7872 15.4312 38.8444 15.8691C39.9016 16.307 40.8052 17.0486 41.441 18.0001C42.0767 18.9515 42.416 20.0701 42.416 21.2144C42.416 22.7489 41.8065 24.2205 40.7214 25.3055C39.6364 26.3906 38.1648 27.0001 36.6303 27.0001ZM36.6303 19.2859C36.2489 19.2859 35.876 19.399 35.5589 19.6109C35.2417 19.8228 34.9945 20.124 34.8486 20.4764C34.7026 20.8288 34.6644 21.2166 34.7388 21.5907C34.8132 21.9648 34.9969 22.3084 35.2666 22.5781C35.5363 22.8479 35.88 23.0315 36.2541 23.1059C36.6282 23.1804 37.016 23.1422 37.3684 22.9962C37.7208 22.8502 38.022 22.603 38.2339 22.2859C38.4458 21.9687 38.5589 21.5959 38.5589 21.2144C38.5589 20.7029 38.3557 20.2124 37.994 19.8507C37.6324 19.489 37.1418 19.2859 36.6303 19.2859Z"
        fill="#F67C36"
      />
      <path
        d="M26.9876 42.2156C24.2305 42.2211 21.4995 41.6821 18.9511 40.6297C16.4028 39.5773 14.0874 38.0321 12.1376 36.0827C11.7784 35.7214 11.5768 35.2326 11.5768 34.7231C11.5768 34.2136 11.7784 33.7248 12.1376 33.3634C12.3169 33.1827 12.5302 33.0392 12.7652 32.9413C13.0002 32.8434 13.2523 32.793 13.5069 32.793C13.7615 32.793 14.0136 32.8434 14.2486 32.9413C14.4836 33.0392 14.6969 33.1827 14.8762 33.3634C18.093 36.5668 22.4479 38.3652 26.9876 38.3652C31.5273 38.3652 35.8822 36.5668 39.099 33.3634C39.2783 33.1827 39.4916 33.0392 39.7266 32.9413C39.9616 32.8434 40.2137 32.793 40.4683 32.793C40.7229 32.793 40.975 32.8434 41.21 32.9413C41.445 33.0392 41.6583 33.1827 41.8376 33.3634C42.1968 33.7248 42.3984 34.2136 42.3984 34.7231C42.3984 35.2326 42.1968 35.7214 41.8376 36.0827C39.8878 38.0321 37.5724 39.5773 35.0241 40.6297C32.4757 41.6821 29.7447 42.2211 26.9876 42.2156Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default waitingApprovalSvg;
