import React, { useEffect, useState } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";
import Layout from "./components/layout/Layout";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import { useSelector } from "react-redux";
import PrivateRoute from "./privateRoute/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import PaymentInvoiceSms from "./pages/payment/PaymentInvoiceSms";
import PaymentConfirmation from "./pages/payment-confirmation/PaymentConfirmation";
import LoginNew from "./pages/login/LoginNew";
import NoInternetConnection from "./NoInternetConnection";
import OwnersPage from "./components/layout/newOwnersPage/OwnersPage";
import { useWindowSize } from "./utils/hooks/useWindowSize";
import DashbordNew from "./pages/dashbord-new/DashbordNew";
import ContactUs from "./pages/contact-us/ContactUs";
import OwnersContactUs from "./components/layout/newOwnersPage/ownersContactUsPage/OwnersContactUs";
import SearchSection from "./pages/search/SearchSection";
import CategoryOfProperty from "./pages/category-of-property/CategoryOfProperty";
import PropertyListing from "./pages/property-listing/PropertyListing";
import PropertyDetails from "./pages/property-details/PropertyDetails";

function App() {
  const primary = "#ffffff";
  const secondary = "#4160AC";
  const third = "#F67C36";
  const forth = "#000";
  const fifth = "#2B353F";
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [login, setLogin] = useState(false);
  const [currentPath, setCurrentPath] = useState("/");
  const params = useParams();
  const navigate = useNavigate();

  const theme = React.useMemo(() => ({
    colors: {
      primary: primary,
      secondary: secondary,
      third: third,
      forth: forth,
      fifth: fifth,
    },
  }));

  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      setLogin(true);
    } else {
      setLogin(false);
      setCurrentPath("/");
    }
  }, [login, user, location]);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === `/signup/${params?.id}` ||
      location.pathname.includes("signup")
    ) {
      setCurrentPath("/");
    } else {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (!localStorage.getItem("idToken")) {
      <Navigate to={login} />;
      // navigate("/login");
    }
  }, [localStorage.getItem("idToken")]);

  // main layout divided in two parts header and sidebar
  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSiderMenu");
    document.body.classList.toggle("openSiderMenu");
    document.body.style.overflow = "visible";
  };
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 992) {
      document.body.classList.add("openSiderMenu");
    } else {
      document.body.classList.add("openSiderMenu");
    }
  }, [width, location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <NoInternetConnection>
        <div className="content-wrap-sidebar">
          <div className="back-drop-sidebar" onClick={openSider}></div>
          <Routes>
            <Route
              path="/login"
              element={
                localStorage.getItem("idToken") &&
                localStorage.getItem("user_id") ? (
                  <Navigate to={currentPath} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/i/:id"
              element={
                localStorage.getItem("idToken") &&
                localStorage.getItem("user_id") ? (
                  <Navigate to={currentPath} />
                ) : (
                  // <Login />
                  <LoginNew />
                )
              }
            />

            <Route
              path="/signup/:id"
              element={
                localStorage.getItem("idToken") &&
                localStorage.getItem("user_id") ? (
                  <Navigate to={currentPath} />
                ) : (
                  <Signup />
                )
              }
            />
            <Route
              exact={true}
              path="/invoice/:id"
              element={<PaymentInvoiceSms />}
            />
            <Route
              exact={true}
              path="/payment-confirmation/:id"
              element={<PaymentConfirmation />}
            />

            <Route element={<PrivateRoute auth={login} />}>
              <Route path="*" element={<Layout />} />
            </Route>
            <Route path="/" element={<DashbordNew />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/owners-page" element={<OwnersPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/owners-contact-us" element={<OwnersContactUs />} />

            <Route
              exact={true}
              path="/property-details/:id"
              element={<PropertyDetails />}
            />
            <Route
              exact={true}
              path="/property-listing"
              element={<PropertyListing />}
            />
          </Routes>
        </div>
      </NoInternetConnection>
    </ThemeProvider>
  );
}

export default App;
