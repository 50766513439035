import React, { useEffect, useState } from "react";
import { HeaderWrap, StyledButton } from "./SearchPart";
import styled from "styled-components";
import { Badge, Col, Divider, Dropdown, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MenuSvg } from "../../../assets/svgs";
import {
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "../header/Header";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {
  getApplicationProcess,
  getNotifications,
  logout,
  me,
} from "../../../store/reducers/user.reducer";
import FreeDemoModal from "../newOwnersPage/FreeDemoModal";
import LoginModal from "../../../pages/login/login-modal/LoginModal";

function HomeHeader({
  headermargin = "50px auto 80px",
  headermarginsm = "40px auto 50px",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSideBar, setopenSider] = useState(true);
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const { width } = useWindowSize();

  const [modalState, setModalState] = useState(false);
  const [myrentals, setMyrentals] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [btnValue, setBtnValue] = useState("");
  const [loginModalState, setLoginModalState] = useState(false);

  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSiderMenu");
    if (x.length > 0) {
      document.body.classList.remove("openSiderMenu");
      setopenSider(true);
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.classList.add("openSiderMenu");
      setopenSider(false);
      window.scrollTo(0, 0);
    }
  };

  // for logout
  const handleLogout = () => {
    dispatch(logout());
  };

  // myrentals in header for window screen and hide for mobile view
  // for mobile view myrentals is showing in header dropdown
  useEffect(() => {
    if (width < 425) {
      setMyrentals(true);
    } else {
      setMyrentals(false);
    }
  }, [width, location.pathname]);

  // APIs calling for get user, applications, notifications
  // useEffect(() => {
  //   if (localStorage.getItem("user_id") && !user) {
  //     dispatch(me());
  //     dispatch(getApplicationProcess());
  //     dispatch(getNotifications()).then(({ payload }) => {
  //       setAllNotifications(payload.notifications);
  //     });
  //   }
  // }, []);

  // header dropdown header

  const onApplyNow = (value) => {
    setBtnValue(value);
    setLoginModalState(true);
  };

  const onLogin = (payload) => {
    if (payload?.id) {
      dispatch(me());
      dispatch(getApplicationProcess());
    }
  };

  const menu = (
    <>
      {localStorage.getItem("user_id") && localStorage.getItem("idToken") ? (
        <StyledHeaderDropdownContentWrappeer>
          <StyledHeaderDropdownMenuItem key="0">
            <Link to="/account" className="nav-link">
              Account
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="1">
            <Link to="/favorite" className="nav-link">
              Wishlist
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="2">
            <Link to="/application-process" className="nav-link">
              User Profile Verification
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="3">
            <Link to={user ? "/applications" : "/"} className="nav-link">
              Applications
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="4">
            <Link to="/message" className="nav-link">
              <Badge count={allNotifications?.length}>Messages</Badge>
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="5">
            <Link to="/my-transaction" className="nav-link">
              My Payments or Billing
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="6">
            <Link to="/my-rentals" className="nav-link">
              My Rentals
            </Link>
          </StyledHeaderDropdownMenuItem>
          <Divider />

          <StyledHeaderDropdownMenuItem key="7">
            <Link to="/owners-contact-us" className="nav-link need__help">
              Need a Help?
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="8" bgColor="#f9f9f9">
            <Link
              to="/"
              onClick={handleLogout}
              className="nav-link logout__title"
            >
              {localStorage.getItem("user_id") &&
              localStorage.getItem("idToken")
                ? "Log out"
                : " Login"}
            </Link>
          </StyledHeaderDropdownMenuItem>
        </StyledHeaderDropdownContentWrappeer>
      ) : (
        <StyledHeaderDropdownContentWrappeer>
          <StyledHeaderDropdownMenuItem
            onClick={() => onApplyNow("signin")}
            key="10"
          >
            <h2>Login</h2>
          </StyledHeaderDropdownMenuItem>

          <Divider />
          <StyledHeaderDropdownMenuItem key="11">
            <Link to="/owners-contact-us" className="nav-link need__help">
              Need a Help?
            </Link>
          </StyledHeaderDropdownMenuItem>
        </StyledHeaderDropdownContentWrappeer>
      )}
    </>
  );

  return (
    <HeaderWrap>
      <SearchWrap headermargin={headermargin} headermarginsm={headermarginsm}>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col xs={20} sm={20} md={20} lg={0} xl={0} xxl={0}>
            <LeftSidehWrap>
              <Link to="/" className="nav-link">
                <img
                  src="/images/avenewLogoSearch.png"
                  alt="Avenew Logo"
                  className="avenew_logo"
                />
              </Link>
            </LeftSidehWrap>
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={0}
            xl={0}
            xxl={0}
            className="menu__header"
            onClick={openSider}
          >
            <MenuSvg />
          </Col>
          <Col xs={0} sm={0} md={0} lg={10} xl={12} xxl={12}>
            <LeftSidehWrap>
              <Link to="/" className="nav-link">
                <img
                  src="/images/avenewLogoSearch.png"
                  alt="Avenew Logo"
                  className="avenew_logo"
                />
              </Link>
            </LeftSidehWrap>
          </Col>
          <Col
            className="right__header"
            xs={0}
            sm={0}
            md={0}
            lg={14}
            xl={12}
            xxl={12}
            // style={{
            //   alignItems: "center",
            //   display: "flex",
            //   justifyContent: "end",
            // }}
          >
            <RightSidehWrap>
              <h1 onClick={() => navigate("/owners-page")}>
                List your property here
              </h1>
              <Badge dot={allNotifications?.length}>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                  // overlayClassName="header_dropdown"
                >
                  <img
                    src="/images/userProfileIcon.svg"
                    className="user__icon"
                  />
                </Dropdown>
              </Badge>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
                // overlayClassName="header_dropdown"
              >
                <IoIosArrowDown className="header__arrow" />
              </Dropdown>
              {user || localStorage.getItem("user_id") ? (
                ""
              ) : (
                <StyledButton
                  style={{ marginRight: "20px" }}
                  onClick={() => setModalState(true)}
                >
                  Free Demo
                </StyledButton>
              )}
              {/* <MenuSvg /> */}
            </RightSidehWrap>
          </Col>
        </Row>
      </SearchWrap>

      <LoginModal
        btnValue={btnValue}
        onLogin={onLogin}
        modalState={loginModalState}
        onCancel={(value) => setLoginModalState(value)}
      />

      <FreeDemoModal
        modalState={modalState}
        onCancel={(value) => setModalState(value)}
      />
    </HeaderWrap>
  );
}

export default HomeHeader;

const RightSidehWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  h1 {
    color: #2b353f;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    cursor: pointer;
  }

  .header__arrow {
    font-size: 18px;
    color: #000;
    margin-left: 15px;
    margin-right: 30px;
    cursor: pointer;
  }
  .user__icon {
    cursor: pointer;
    margin-left: 24px;
    display: inline-block;
  }
`;

const SearchWrap = styled.div`
  display: flex;
  width: 1400px;
  ${"" /* margin: 50px auto 80px; */}
  margin: ${({ headermargin }) => headermargin || "50px auto 80px"};
  padding: 0 20px;
  background: #ffffff;
  padding: 15px;
  align-items: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;

  .right__header {
    align-items: center;
    justify-content: end;
    display: flex;
  }

  .menu__header {
    display: none;
  }

  @media (max-width: 1400px) {
    margin: ${({ headermarginsm }) => headermarginsm || "40px auto 50px"};
    width: 100%;
  }

  @media (max-width: 991px) {
    margin: 20px auto 30px;
    padding: 5px 10px;

    .right__header {
      display: none;
    }
    .menu__header {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
`;

const LeftSidehWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    cursor: pointer;
  }

  .avenew_logo {
    width: 265px;
    height: 64px;
  }
  span {
    color: #2b353f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    .avenew_logo {
      width: 210px;
      height: 50px;
    }
  }
  @media (max-width: 991px) {
    .avenew_logo {
      width: 165px;
      height: 40px;
    }
  }
`;
