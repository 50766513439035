import React from "react";
import styled from "styled-components";
import { MainContainer } from "./SearchPart";
import { TopTrendingSectionWrap } from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";

function LogosWrap() {
  return (
    <MainContainer bgcolor="#FFFfff" containterHeight="auto">
      <TopTrendingSectionWrap
        margincomponentxs="0 auto 0"
        margincomponent="0 auto 50px"
      >
        <LogosWrapMain>
          <Row justify="space-between">
            <Col className="logo__img__1">
              <img src="/images/brandlogo1.png" />
            </Col>
            <Col className="logo__img__2">
              <img src="/images/brandlogo2.png" />
            </Col>
            <Col className="logo__img">
              <img src="/images/brandlogo3.png" />
            </Col>
            <Col className="logo__img">
              <img src="/images/brandlogo4.png" />
            </Col>
            <Col className="logo__img">
              <img src="/images/brandlogo5.png" />
            </Col>
          </Row>
        </LogosWrapMain>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default LogosWrap;

const LogosWrapMain = styled.div`
  width: 100%;
  border: 5px solid #ecf2ff;
  background: #fff;
  padding: 50px;

  img {
    width: 100%;
  }

  @media (max-width: 1400px) {
    padding: 35px;
  }
  @media (max-width: 991.98px) {
    .logo__img {
      display: none;
    }
    .logo__img__1 {
      width: 113px;
    }
    .logo__img__2 {
      width: 106px;
    }
  }
`;
