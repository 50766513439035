import { Badge, Button, Divider, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  getApplicationProcess,
  getNotifications,
  logout,
  me,
} from "../../../store/reducers/user.reducer";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {
  HeaderWrap,
  StyledHeaderTop,
  StyledLeftSide,
  StyledRightSide,
} from "./TheHeader.styles";
import io from "socket.io-client";
import LoginModal from "../../../pages/login/login-modal/LoginModal";

// const endPoint = "http://localhost:4000";

var socket;

function Header() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [myrentals, setMyrentals] = useState(false);
  const { width } = useWindowSize();
  const location = useLocation();
  const [socketConnected, setSocketConnected] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [btnValue, setBtnValue] = useState("");
  const [loginModalState, setLoginModalState] = useState(false);
  // // socket connection for notifications and mesasges
  // useEffect(() => {
  //   socket = io(process.env.REACT_APP_SOCKET_URL);
  //   socket.emit("setup", user?.id);
  //   socket.on("connected", () => setSocketConnected(true));
  // }, [user]);

  // myrentals in header for window screen and hide for mobile view
  // for mobile view myrentals is showing in header dropdown
  useEffect(() => {
    if (width < 425) {
      setMyrentals(true);
    } else {
      setMyrentals(false);
    }
  }, [width, location.pathname]);

  // for logout
  const handleLogout = () => {
    dispatch(logout());
  };

  // APIs calling for get user, applications, notifications
  useEffect(() => {
    if (localStorage.getItem("user_id")) {
      dispatch(me());
      dispatch(getApplicationProcess());
      dispatch(getNotifications()).then(({ payload }) => {
        setAllNotifications(payload.notifications);
      });
    }
  }, []);

  // Function to trigger Login Modal
  const onApplyNow = (value) => {
    setBtnValue(value);
    setLoginModalState(true);
  };

  // socket for new notification
  // useEffect(() => {
  //   if (socketConnected) {
  //     socket.on("new notification", (newNotification) => {
  //       setAllNotifications([...allNotifications, ...newNotification]);
  //     });
  //   }
  // });

  // header dropdown header
  const menu = (
    <>
      {localStorage.getItem("user_id") && localStorage.getItem("idToken") ? (
        <StyledHeaderDropdownContentWrappeer>
          <StyledHeaderDropdownMenuItem key="0">
            <Link to={user ? "/account" : "/"} className="nav-link">
              Account
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="1">
            <Link to="/favorite" className="nav-link">
              Wishlist
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="8">
            <Link to="/application-process" className="nav-link">
              User Profile Verification
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="2">
            <Link to={user ? "/applications" : "/"} className="nav-link">
              Applications
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="3">
            <Link to="/message" className="nav-link">
              <Badge count={allNotifications?.length}>Messages</Badge>
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="4">
            <Link to="/my-transaction" className="nav-link">
              My Payments or Billing
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="5">
            <Link to="/my-rentals" className="nav-link">
              My Rentals
            </Link>
          </StyledHeaderDropdownMenuItem>
          <Divider />

          <StyledHeaderDropdownMenuItem key="6">
            <Link to="/owners-contact-us" className="nav-link need__help">
              Need a Help?
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="7" bgColor="#f9f9f9">
            <Link
              to="/"
              onClick={handleLogout}
              className="nav-link logout__title"
            >
              {localStorage.getItem("user_id") &&
              localStorage.getItem("idToken")
                ? "Log out"
                : " Login"}
            </Link>
          </StyledHeaderDropdownMenuItem>
        </StyledHeaderDropdownContentWrappeer>
      ) : (
        <StyledHeaderDropdownContentWrappeer>
          {myrentals ? (
            <StyledHeaderDropdownMenuItem key="7">
              <Link to="/owners-page" className="nav-link">
                Learn More
              </Link>
            </StyledHeaderDropdownMenuItem>
          ) : (
            ""
          )}
          <StyledHeaderDropdownMenuItem
            onClick={() => onApplyNow("signin")}
            key="6"
          >
            <h2>Login</h2>
          </StyledHeaderDropdownMenuItem>

          <Divider />
          <StyledHeaderDropdownMenuItem key="4">
            <Link to="/" className="nav-link need__help">
              Need a Help?
            </Link>
          </StyledHeaderDropdownMenuItem>
        </StyledHeaderDropdownContentWrappeer>
      )}
    </>
  );

  return (
    <StyledHeaderTop className="sticky-header">
      <HeaderWrap>
        <StyledLeftSide>
          <Link to="/" className="nav-link">
            <img src="/images/avenewLogo.svg" className="avenew__title" />
            {/* <AvenewLogo className="avenew__title" /> */}
          </Link>
        </StyledLeftSide>
        <StyledRightSide>
          {!localStorage.getItem("user_id") && !myrentals ? (
            <StyledButton onClick={() => navigate("/owners-page")}>
              Learn More
            </StyledButton>
          ) : (
            ""
          )}
          <Badge dot={allNotifications?.length}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName="header_dropdown"
            >
              <img src="/images/userProfileIcon.svg" className="user__icon" />
              {/* <UserLogo className="user__icon" /> */}
            </Dropdown>
          </Badge>

          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="header_dropdown"
          >
            <IoIosArrowDown className="header__arrow" />
          </Dropdown>
        </StyledRightSide>
        <LoginModal
          btnValue={btnValue}
          modalState={loginModalState}
          onCancel={(value) => setLoginModalState(value)}
        />
      </HeaderWrap>
    </StyledHeaderTop>
  );
}

export default Header;

const StyledButton = styled(Button)`
  height: 50px;
  padding: 4px 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 20px;
    ${"" /* margin-top: 0; */}
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

// HEADER DROPDOWN MENU
export const StyledHeaderDropdownContentWrappeer = styled(Menu)`
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #d3d6dd;
  width: 250px;
  top: 10px;
  ${"" /* padding: 4px 0 0; */}

  .ant-divider-horizontal {
    margin: 15px 30px;
    background: #dddddd;
    width: 75%;
    min-width: 75%;
  }
  .ant-dropdown-menu-item {
    padding: 15px 30px;
    border-radius: 5px;
  }

  .ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content > a {
  }
`;

// HEADER DROPDOWN ITEM
export const MenuItem = styled(Menu.Item)`
  &:hover {
    svg {
      fill: #777777;
    }
  }
`;

export const StyledHeaderDropdownMenuItem = styled(MenuItem)`
  background: ${({ bgColor }) => bgColor || "#fff"};

  .ant-badge-count {
    background: #f67c36;
    right: -10px;
    box-shadow: none;
    min-width: 17px;
    height: 17px;
    padding: 0 0px;
    line-height: 19px;
  }

  .ant-badge {
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    color: #2b353f;
  }
  .need__help {
    font-weight: 400 !important;
    color: #2b353f !important;
  }
  .logout__title {
    font-weight: 400 !important;
    color: #848687 !important;
  }
  .ant-dropdown-menu-item-icon {
    color: #949494;
    font-size: 16px;
    margin-right: 12px;
  }
  .ant-dropdown-menu-title-content > a,
  .ant-dropdown-menu-title-content > h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    color: #2b353f;
  }
  .ant-dropdown-menu-title-content > h2 {
    margin-top: revert;
  }
  .ant-dropdown-menu-title-content > p {
    color: #949494;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
  }
`;
