import { Modal } from "antd";
import React from "react";
import LoginComponent from "./LoginComponent";
import { IoIosClose } from "react-icons/io";
import EmailLogin from "./EmailLogin";

const LoginModal = ({
  modalState,
  onCancel,
  onLogin = console.log(),
  btnValue = "signup",
}) => {
  return (
    <Modal
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      closeIcon={
        <IoIosClose className="modal_close_icon" style={{ color: "#fff" }} />
      }
      footer={null}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      bodyStyle={{
        background: "rgba(0, 0, 0, 0.70)",
        backdropFilter: "blur(5px)",
      }}
      destroyOnClose={true}
    >
      {/* <LoginComponent
        onLogin={onLogin}
        onCancel={onCancel}
        modalState={modalState}
        // setProfileState={setProfileState}
      /> */}
      <EmailLogin
        btnValue={btnValue}
        onLogin={onLogin}
        onCancel={onCancel}
        modalState={modalState}
      />
    </Modal>
  );
};

export default LoginModal;
