import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import MyAccountTabs from "./MyAccountTabs";
import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import MyAccountMobileView from "./MyAccountMobileView";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function MyAccount() {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  // page navigation bar
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/account",
      label: "My Account",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <>
      <MyAccountMain>
        <BreadcrumbTop>
          <BreadCrumbsWrapper>
            <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
          </BreadCrumbsWrapper>
        </BreadcrumbTop>
        <MyAccountTabWrap>
          <MyAccountTabs />
        </MyAccountTabWrap>
        {/* <Footer /> */}
        <FooterSectionHome />
      </MyAccountMain>
      <MobileViewMain>
        <MyAccountMobileView />
      </MobileViewMain>
    </>
  );
}

export default MyAccount;

export const MobileViewMain = styled.div`
  display: none;
  @media (max-width: 769px) {
    display: block;
  }
`;
export const MyAccountMain = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
`;

export const MyAccountTabWrap = styled.div`
  width: 100%;
`;
