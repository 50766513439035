import React, { useState, useEffect } from "react";
import { Form, Input, Select, Col, Row, message } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import data from "../../../Profile-verification/state.json";
import { IoIosArrowBack } from "react-icons/io";
import { PlusSvg, LessSvg } from "../../../../assets/svgs";
import styled from "styled-components";
import {
  InformationWrap,
  ViewAllRental,
} from "../../../Profile-verification/PriorRentalInformation";
import { capitalize } from "../../../../utils/fn";
import {
  applicationProcess,
  getApplicationProcess,
} from "../../../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UnderVerificationModal from "./UnderVerificationModal";

function EmergencyContact({
  onBackClickTabs,
  profileSubmit,
  selectedStateProfile,
  onBackClick,
}) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [citesValue, setCitesValue] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [emergDetails, setEmergDetails] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const [verificationModal, setVerificationModal] = useState(false);
  const [openIndexs, setOpenIndexs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const { Option } = Select;

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const onFinish = (value) => {
    setIsSubmit(true);
    if (value.emergencyName && value.homePhone) {
      setIsSubmit(false);
      setEmergDetails((val) => [...val, value]);
    } else {
      value.reference = emergDetails;
      let data = {};
      data.stateProfile = [];
      let stateData = {};
      stateData.stateName = selectedStateProfile.stateName;
      stateData.id = selectedStateProfile.id;
      stateData.emergencyContact = emergDetails;
      data.stateProfile.push(stateData);

      dispatch(applicationProcess(data)).then(({ payload }) => {
        setIsSubmit(false);
        if (
          sessionStorage.getItem("applyingProperty") &&
          JSON.parse(sessionStorage.getItem("applyingProperty"))?.id
        ) {
          const item = JSON.parse(sessionStorage.getItem("applyingProperty"));
          navigate(`/property-details/${item.id}`);
        } else {
          const payloadDat = payload?.state_profile;
          var stateValue = payloadDat?.filter(function (item) {
            return item["stateName"] === stateData.stateName;
          });
          profileSubmit(stateValue?.length ? stateValue[0] : {}, payloadDat);
          if (
            stateValue[0]?.whyYouRentedHere &&
            stateValue[0]?.vehicleDetails &&
            stateValue[0]?.rentalOrCriminalHistory &&
            stateValue[0]?.creditHistory &&
            stateValue[0]?.workDetails &&
            stateValue[0]?.spouseDetails
          ) {
            setVerificationModal(true);
            message.success("Form submitted successfully");
          }
        }
        // refresh();
      });
    }

    form.resetFields();
  };

  useEffect(() => {
    if (selectedStateProfile?.emergencyContact?.length) {
      setEmergDetails(selectedStateProfile?.emergencyContact);
    }
  }, [selectedStateProfile]);

  const addChange = () => {
    setIsNewAdd(true);
  };

  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  useEffect(() => {
    if (emergDetails?.length) {
      setIsNewAdd(false);
    } else {
      setIsNewAdd(true);
    }
  }, [emergDetails]);

  useEffect(() => {
    if (stateValue) {
      setCitesValue(data[stateValue].sort());
    }
  }, [stateValue]);
  const onChange = (newValue) => {
    setstateValue(newValue);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onCancel = () => {
    form.resetFields();
    setIsNewAdd(false);
  };

  return (
    <>
      <GeneralProfileWrap>
        <h1>Emergency Contact</h1>
        <FormWrap mt="0">
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                validateMessages={validateMessages}
                layout="vertical"
                form={form}
                onFinish={onFinish}
              >
                {emergDetails?.map(
                  (
                    {
                      emergencyName,
                      address,
                      state,
                      city,
                      zipcode,
                      workPhone,
                      homePhone,
                      relation,
                    },
                    idx
                  ) => (
                    <>
                      <ViewAllRental
                        mb={openIndexs?.includes(idx) ? "0" : "25px"}
                        key={idx}
                      >
                        <AddedReference
                          bb={
                            openIndexs?.includes(idx)
                              ? "none"
                              : "1px solid #dddddd"
                          }
                          onClick={() => openIndexFun(idx)}
                        >
                          <Row
                            justify="space-between"
                            style={{ alignItems: "center" }}
                          >
                            <Col>
                              <h5>{capitalize(emergencyName)}</h5>
                            </Col>
                            <Col>
                              {openIndexs?.includes(idx) ? (
                                <>
                                  <LessSvg
                                    style={{ marginBottom: "5px" }}
                                    className="less__svg"
                                  />
                                </>
                              ) : (
                                <>
                                  <PlusSvg />
                                </>
                              )}
                            </Col>
                          </Row>
                        </AddedReference>
                      </ViewAllRental>

                      {openIndexs?.includes(idx) ? (
                        <ViewAddedReference>
                          <Row justify="space-between" gutter={[15, 15]}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Name & Address</h2>
                              <Row justify="space-between" gutter={[2, 2]}>
                                {/* <Col span={8}>
                                  <DetailsBox>
                                    <p>Name</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{name}</p>
                                  </DetailsBox>
                                </Col> */}

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>City</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{city}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>State</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{state}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Zipcode</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{zipcode ? zipcode : `${" "}`}</p>
                                  </DetailsBox>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Contact Details</h2>
                              <Row justify="space-between" gutter={[2, 2]}>
                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Work Phone</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{workPhone}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Home Phone</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{homePhone}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Relation</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{relation}</p>
                                  </DetailsBox>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </ViewAddedReference>
                      ) : (
                        ""
                      )}
                    </>
                  )
                )}
                {isNewAdd ? (
                  <>
                    <StyledFormItem
                      style={{ marginTop: "25px" }}
                      wh="49"
                      fd={"column"}
                      smwh={"100"}
                    >
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Name is required",
                          },
                        ]}
                        name="emergencyName"
                        label="Name"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Address is required",
                          },
                        ]}
                        name="address"
                        label="Address"
                      >
                        <Input />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        name="state"
                        label="State"
                      >
                        <Select
                          showSearch
                          placeholder=""
                          onChange={onChange}
                          suffixIcon={<IoIosArrowDown />}
                        >
                          {states.length &&
                            states.map((state) => (
                              <Option value={state}>{state}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        name="city"
                        label="City"
                      >
                        <Select
                          showSearch
                          placeholder=""
                          // onChange={}
                          suffixIcon={<IoIosArrowDown />}
                        >
                          {citesValue
                            ? citesValue.map((cites) => (
                                <Option value={cites}>{cites}</Option>
                              ))
                            : ""}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                          },
                          { max: 6, message: "Invalid Zip code!" },
                        ]}
                        name="zipcode"
                        label="Zip code"
                      >
                        <Input
                          type="number"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          pattern="[0-9]*"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        className="input__affix"
                        rules={[
                          {
                            required: true,
                          },
                          { max: 10, message: "Invalid Phone Number!" },
                          { min: 10, message: "" },
                        ]}
                        name="workPhone"
                        label="Work phone"
                      >
                        <Input
                          pattern="[0-9]*"
                          type="number"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          // prefix={`+1`}
                          onKeyDown={(e) =>
                            e.key === "." ? e.preventDefault() : ""
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        className="input__affix"
                        rules={[
                          {
                            required: true,
                          },
                          { max: 10, message: "Invalid Phone Number!" },
                          { min: 10, message: "" },
                        ]}
                        name="homePhone"
                        label="Home phone"
                      >
                        <Input
                          pattern="[0-9]*"
                          type="number"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          // prefix={`+1`}
                          onKeyDown={(e) =>
                            e.key === "." ? e.preventDefault() : ""
                          }
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Relationship is required",
                          },
                        ]}
                        name="relation"
                        label="Relationship"
                      >
                        <Input />
                      </Form.Item>
                    </StyledFormItem>
                  </>
                ) : (
                  <InformationWrap>
                    <Form.Item style={{ marginTop: "25px" }}>
                      <Row justify="end">
                        <Col>
                          <Row
                            className="icon__text"
                            block="true"
                            onClick={() => addChange()}
                          >
                            <Col>
                              <PlusSvg />
                            </Col>
                            <Col>
                              <p>Add Emergency Contact</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>
                  </InformationWrap>
                )}
                <Form.Item>
                  <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col
                      xs={10}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="back__btn"
                    >
                      <IoIosArrowBack
                        onClick={() => onBackClickTabs("emergency-contact")}
                      />
                      <h2 onClick={() => onBackClickTabs("emergency-contact")}>
                        back
                      </h2>
                    </Col>
                    <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Row justify="end" style={{ alignItems: "center" }}>
                        {isNewAdd ? (
                          <Col>
                            <p className="cancel__btn" onClick={onCancel}>
                              CANCEL
                            </p>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col xs={10} sm={8} md={8} lg={8} xl={8} xxl={8}>
                          <StyledButton
                            mt="0"
                            loading={isSubmit}
                            htmlType="submit"
                          >
                            Submit
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </FormWrap>
        <UnderVerificationModal
          verificationModal={verificationModal}
          onCancel={(value) => {
            setVerificationModal(value);
            onBackClick();
          }}
        />
      </GeneralProfileWrap>
    </>
  );
}

export default EmergencyContact;

const AddedReference = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-bottom: ${({ bb }) => bb || "1px solid #dddddd"};
  padding: 20px;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    background: #4160ac;
    padding: 2px 5px;
  }

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

const ViewAddedReference = styled.div`
  background: #fafafa;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 20px;
  margin-bottom: 25px;
  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 5px;
  }
`;

const DetailsBox = styled.div`
  background: #ffffff;
  padding: 10px 12px;
  min-height: 37px;
  ${'' /* max-height: 37px; */}
  overflow: auto;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color || "#777777"};
    margin-bottom: 0;
  }
`;
