import React from "react";

const locationSvg = ({ wSize = "50", hSize = "50" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wSize}
      height={hSize}
      viewBox={`0 0 50 50`}
      fill="none"
    >
      <rect width="50" height="50" fill="#F67C36" />
      <path
        d="M34.9466 19.1444C34.3385 19.465 25.891 24.0702 25.5759 24.2361C25.2608 24.4019 24.9401 24.4793 24.4425 24.4793C23.945 24.4793 23.6243 24.4019 23.3092 24.2361C22.9941 24.0702 14.5466 19.465 13.9385 19.1444C13.7118 19.0283 13.3304 18.8182 13.2474 18.5805C13.1037 18.1603 13.2585 18 13.8722 18H24.4425H35.0129C35.6266 18 35.7814 18.1659 35.6376 18.5805C35.5547 18.8237 35.1732 19.0283 34.9466 19.1444Z"
        fill="white"
      />
      <path
        d="M13.4605 19.3928C13.9139 19.625 17.9828 22.5219 20.6752 24.2633L16.1308 29.3771C16.0202 29.4877 15.9704 29.6204 16.0313 29.6867C16.0976 29.7475 16.2413 29.7144 16.3574 29.6093L21.8085 25.0097C22.6322 25.5404 23.2127 25.9053 23.3122 25.9606C23.7379 26.1762 24.0364 26.2038 24.4456 26.2038C24.8547 26.2038 25.1532 26.1762 25.5789 25.9606C25.6839 25.9053 26.2589 25.5404 27.0826 25.0097L32.5337 29.6093C32.6442 29.7199 32.7935 29.7531 32.8598 29.6867C32.9262 29.6259 32.8764 29.4877 32.7659 29.3771L28.227 24.2633C30.9194 22.5219 35.0325 19.625 35.4859 19.3928C35.9724 19.144 36 19.437 36 19.6637C36 19.8903 36 30.997 36 30.997C36 31.5111 35.2426 32.1524 34.7008 32.1524H24.4456H14.1903C13.6485 32.1524 13.0017 31.5056 13.0017 30.997C13.0017 30.997 13.0017 19.8848 13.0017 19.6637C13.0017 19.4315 12.9685 19.144 13.4605 19.3928Z"
        fill="white"
      />
    </svg>
  );
};

export default locationSvg;
