import { Col, DatePicker, Form, Input, Popconfirm, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import data from "../../Profile-verification/state.json";
import moment from "moment";
import { FormWrap, GeneralProfileWrap } from "./GeneralProfile";
import { applicationProcess } from "../../../store/reducers/user.reducer";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  dateConverter,
  formatPhoneNumber,
  formatedValue,
  formattedNumber,
  toUSCurrency,
} from "../../../utils/fn";
import { PlusSvg } from "../../../assets/svgs";
import styled from "styled-components";
import { ViewAllRental } from "../../Profile-verification/PriorRentalInformation";
import { AddedReference, DetailsBox, ViewAddedReference } from "./AddReference";
import { FaEdit, FaTrash } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";

function EmployerDetails({
  onBackClicked,
  onMainTabNext,
  checkFillDataFunc,
  activeTabHome,
  activeTab,
}) {
  const { applicationProcessData } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const monthFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();
  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  const [state, setState] = useState([]);
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const [stateValue, setstateValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditIndex, setIsEditIndex] = useState("");
  const [citesValue, setCitesValue] = useState([]);
  const [undoFields, setUndoFields] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [editValue, setEditValue] = useState({});
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [openIndexs, setOpenIndexs] = useState([]);

  useEffect(() => {
    if (
      applicationProcessData?.incomeAndEmployment?.manualVerification
        ?.employerDetails?.length
    ) {
      setNewSetalFields(
        applicationProcessData?.incomeAndEmployment?.manualVerification
          ?.employerDetails
      );
      setIsNewAdd(false);
    }
  }, [applicationProcessData, activeTabHome, activeTab]);

  // default tab based on filled data
  // useEffect(() => {
  //   if (Object.keys(defaultValue || {}).length) {
  //   } else if (applicationProcessData && applicationProcessData?.id) {
  //     if (
  //       applicationProcessData?.incomeAndEmployment &&
  //       Object.values(
  //         applicationProcessData?.incomeAndEmployment?.manualVerification
  //           ?.employerDetails || {}
  //       ).length
  //     ) {
  //       form.setFieldsValue({
  //         businessName:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.businessName,
  //         address:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.address,
  //         state:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.state,
  //         city: applicationProcessData?.incomeAndEmployment?.manualVerification
  //           ?.employerDetails?.city,
  //         zipcode:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.zipcode,
  //         position:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.position,
  //         workPhone:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification?.employerDetails?.workPhone?.slice(
  //             2
  //           ),
  //         grossMonthlyIncome:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.grossMonthlyIncome,
  //         dateStarted: moment(
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.dateStarted
  //         ),
  //         supervisorsName:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.supervisorsName,
  //         supervisorsNumber:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification?.employerDetails?.supervisorsNumber?.slice(
  //             2
  //           ),
  //       });
  //     }
  //   }
  // }, [applicationProcessData]);

  // const undoHandler = () => {
  //   if (applicationProcessData && applicationProcessData?.id) {
  //     if (
  //       applicationProcessData?.incomeAndEmployment &&
  //       Object.values(
  //         applicationProcessData?.incomeAndEmployment?.manualVerification
  //           ?.employerDetails || {}
  //       )?.length
  //     ) {
  //       form.setFieldsValue({
  //         businessName:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.businessName,
  //         address:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.address,
  //         state:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.state,
  //         city: applicationProcessData?.incomeAndEmployment?.manualVerification
  //           ?.employerDetails?.city,
  //         zipcode:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.zipcode,
  //         position:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.position,
  //         workPhone:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification?.employerDetails?.workPhone?.slice(
  //             2
  //           ),
  //         grossMonthlyIncome:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.grossMonthlyIncome,
  //         dateStarted: moment(
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.dateStarted
  //         ),
  //         supervisorsName:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification
  //             ?.employerDetails?.supervisorsName,
  //         supervisorsNumber:
  //           applicationProcessData?.incomeAndEmployment?.manualVerification?.employerDetails?.supervisorsNumber?.slice(
  //             2
  //           ),
  //       });
  //     }
  //   } else {
  //     form.resetFields();
  //   }
  //   setUndoFields(false);
  // };

  useEffect(() => {
    if (stateValue) {
      setCitesValue(data[stateValue].sort());
    }
  }, [stateValue]);

  // on click submit
  const onFinish = (value) => {
    if (isEdit && undoFields) {
      value.id = editValue.id;
      setIsSubmit(false);
      let editObj = [...newSetalFields];
      let changedObj = editObj[isEditIndex];
      changedObj = value;
      editObj[isEditIndex] = changedObj;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);

      let data = {};
      data.manualVerification = {};
      data.manualVerification.employerDetails = editObj;
      if (sessionStorage.getItem("applyingProperty")) {
        const unitDetails = JSON.parse(
          sessionStorage.getItem("applyingProperty")
        );
        data.unit_id = unitDetails?.id;
      }
      setIsEdit(false);
      dispatch(applicationProcess(data)).then(({ payload }) => {
        setIsSubmit(false);
        setDefaultValue(payload?.manual_verification_data?.employerDetails);
        checkFillDataFunc(payload);
        onCancel();
      });
    } else if (undoFields) {
      setIsSubmit(true);
      let data = {};
      data.manualVerification = {};
      data.manualVerification.employerDetails = [...newSetalFields, value];
      if (sessionStorage.getItem("applyingProperty")) {
        const unitDetails = JSON.parse(
          sessionStorage.getItem("applyingProperty")
        );
        data.unit_id = unitDetails?.id;
      }

      dispatch(applicationProcess(data)).then(({ payload }) => {
        setNewSetalFields(payload?.manual_verification_data?.employerDetails);
        setIsSubmit(false);
        setDefaultValue(payload?.manual_verification_data?.employerDetails);
        checkFillDataFunc(payload);
        onCancel();
      });
    } else {
      onMainTabNext("rental-history");
      onCancel();
    }
  };

  // form validation message
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addChanges = () => {
    form.resetFields();
    setIsNewAdd(true);
  };

  const onCancel = () => {
    form.resetFields();
    setIsNewAdd(false);
    setUndoFields(false);
    setIsEdit(false);
  };

  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  const onDelete = (e, item, idx) => {
    e.stopPropagation();
    const newDeletedVal = newSetalFields?.filter((_, index) => index !== idx);
    setNewSetalFields(newDeletedVal);
    let data = {};
    data.manualVerification = {};
    data.manualVerification.deleted_ids = [item.id];
    dispatch(applicationProcess(data));
  };

  const isEditable = (item, idx) => {
    setEditValue(item);
    setIsEdit(true);
    setIsEditIndex(idx);
    addChanges();
    form.setFieldsValue({
      businessName: item.businessName,
      address: item.address,
      state: item.state,
      city: item.city,
      zipcode: item.zipcode,
      position: item.position,
      workPhone: item.workPhone,
      grossMonthlyIncome: item.grossMonthlyIncome,
      dateStarted: moment(item.dateStarted),
      supervisorsName: item.supervisorsName,
      supervisorsNumber: item.supervisorsNumber,
    });
  };

  return (
    <GeneralProfileWrap>
      <h1>Employer Details</h1>
      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              onValuesChange={() => setUndoFields(true)}
              validateMessages={validateMessages}
            >
              {newSetalFields?.map((item, idx) => (
                <>
                  <ViewAllRental
                    mb={openIndexs?.includes(idx) ? "0" : "25px"}
                    key={idx}
                  >
                    <AddedReference
                      bb={
                        openIndexs?.includes(idx) ? "none" : "1px solid #dddddd"
                      }
                      onClick={() => openIndexFun(idx)}
                    >
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col>
                          <span>{item.businessName}</span>
                        </Col>
                        <Col>
                          <FaEdit
                            onClick={(e) => {
                              e.stopPropagation();
                              isEditable(item, idx);
                            }}
                            className="icon_id"
                          />
                          <Popconfirm
                            key={"del-c"}
                            title="Are you sure?"
                            onConfirm={(e) => onDelete(e, item, idx)}
                            onCancel={(e) => {
                              e.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No"
                            icon={
                              <QuestionCircleOutlined
                                style={{ color: "red" }}
                              />
                            }
                          >
                            <FaTrash
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="icon_id"
                            />
                          </Popconfirm>

                          {openIndexs?.includes(idx) ? (
                            <>
                              <IoIosArrowUp
                                style={{ marginBottom: "0" }}
                                className="less__svg updown__arrow"
                              />
                            </>
                          ) : (
                            <>
                              <IoIosArrowDown className="updown__arrow" />
                            </>
                          )}
                        </Col>
                      </Row>
                    </AddedReference>
                  </ViewAllRental>

                  {openIndexs?.includes(idx) ? (
                    <ViewAddedReference>
                      <Row justify="space-between" gutter={[15, 15]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Address</h2>
                          <Row justify="space-between" gutter={[2, 2]}>
                            <Col span={8}>
                              <DetailsBox>
                                <p>Address</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item.address}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>City</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item.city}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>State</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item.state}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Zipcode</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.zipcode ? item.zipcode : `${" "}`}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={8}>
                              <DetailsBox>
                                <p>Position</p>
                              </DetailsBox>
                            </Col>
                            <Col span={16}>
                              <DetailsBox color="#000000">
                                <p>{item?.position}</p>
                              </DetailsBox>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                          <h2>Employment Details</h2>
                          <Row justify="space-between" gutter={[2, 2]}>
                            <Col span={12}>
                              <DetailsBox>
                                <p>Work Phone</p>
                              </DetailsBox>
                            </Col>
                            <Col span={12}>
                              <DetailsBox color="#000000">
                                <p>{formatPhoneNumber(item.workPhone)}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={12}>
                              <DetailsBox>
                                <p>Gross Income</p>
                              </DetailsBox>
                            </Col>
                            <Col span={12}>
                              <DetailsBox color="#000000">
                                <p>{toUSCurrency(item.grossMonthlyIncome)}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={12}>
                              <DetailsBox>
                                <p>Date Started</p>
                              </DetailsBox>
                            </Col>
                            <Col span={12}>
                              <DetailsBox color="#000000">
                                <p>
                                  {item?.dateStarted
                                    ? dateConverter(item.dateStarted)
                                    : `${" "}`}
                                </p>
                              </DetailsBox>
                            </Col>

                            <Col span={12}>
                              <DetailsBox>
                                <p>Supervisor Name</p>
                              </DetailsBox>
                            </Col>
                            <Col span={12}>
                              <DetailsBox color="#000000">
                                <p>{item.supervisorsName}</p>
                              </DetailsBox>
                            </Col>

                            <Col span={12}>
                              <DetailsBox>
                                <p>Supervisor Number</p>
                              </DetailsBox>
                            </Col>
                            <Col span={12}>
                              <DetailsBox color="#000000">
                                <p>
                                  {formatPhoneNumber(item.supervisorsNumber)}
                                </p>
                              </DetailsBox>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </ViewAddedReference>
                  ) : (
                    ""
                  )}
                </>
              ))}

              {isNewAdd ? (
                <>
                  <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                    <Form.Item
                      name="businessName"
                      label="Business Name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Business Name is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          message: "Address is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </StyledFormItem>
                  <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder=""
                        onChange={onChange}
                        suffixIcon={<IoIosArrowDown />}
                      >
                        {states.length &&
                          states.map((state) => (
                            <Option value={state}>{state}</Option>
                          ))}
                        {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder=""
                        // onChange={}
                        suffixIcon={<IoIosArrowDown />}
                      >
                        {citesValue
                          ? citesValue.map((cites) => (
                              <Option value={cites}>{cites}</Option>
                            ))
                          : ""}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="zipcode"
                      label="Zip code"
                      rules={[
                        {
                          required: false,
                        },
                        { max: 6, message: "Invalid Zip code!" },
                      ]}
                    >
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        min="0"
                        pattern="[0-9]*"
                      />
                    </Form.Item>
                  </StyledFormItem>
                  <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                    <Form.Item
                      name="position"
                      label="Position"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          message: "Position is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className="input__affix"
                      name="workPhone"
                      label="Work Phone"
                      rules={[
                        {
                          required: false,
                        },
                        { max: 10, message: "Invalid Phone Number!" },
                        { min: 10, message: "" },
                      ]}
                    >
                      <Input
                        type="number"
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        // prefix={`+1`}
                        onKeyDown={(e) =>
                          e.key === "." ? e.preventDefault() : ""
                        }
                      />
                    </Form.Item>
                  </StyledFormItem>
                  <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                    <Form.Item
                      className="input__affix"
                      name="grossMonthlyIncome"
                      label="Gross Monthly Income"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        step="0.0001"
                        pattern="[0-9]*"
                        prefix={`$`}
                      />
                    </Form.Item>
                    <Form.Item
                      name="dateStarted"
                      label="Date Started"
                      className="date__picker"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder=""
                        format={monthFormat}
                        // picker="day"
                        // disabledDate={(current) => {
                        //   return moment().add(-1, "days") <= current;
                        // }}
                        allowClear={false}
                      />
                    </Form.Item>
                  </StyledFormItem>
                  <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                    <Form.Item
                      name="supervisorsName"
                      label="Supervisor’s Name"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          message: "Supervisor's Name is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className="input__affix"
                      name="supervisorsNumber"
                      label="Supervisor’s Number"
                      rules={[
                        {
                          required: false,
                        },
                        { max: 10, message: "Invalid Phone Number!" },
                        { min: 10, message: "" },
                      ]}
                    >
                      <Input
                        pattern="[0-9]*"
                        type="number"
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        // prefix={`+1`}
                        onKeyDown={(e) =>
                          e.key === "." ? e.preventDefault() : ""
                        }
                      />
                    </Form.Item>
                  </StyledFormItem>
                </>
              ) : (
                <Form.Item style={{ marginTop: "25px" }}>
                  <AddRef>
                    <Row justify="end">
                      <Col>
                        <Row
                          className="icon__text"
                          block="true"
                          onClick={() => addChanges()}
                        >
                          <Col>
                            <PlusSvg />
                          </Col>
                          <Col>
                            <p>Add Another Employer Details</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </AddRef>
                </Form.Item>
              )}

              <Form.Item>
                <Row justify="space-between" style={{ alignItems: "center" }}>
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack onClick={() => onBackClicked()} />
                    <h2 onClick={() => onBackClicked()}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      {(isNewAdd && newSetalFields?.length) || undoFields ? (
                        <Col>
                          <p className="cancel__btn" onClick={onCancel}>
                            Cancel
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          {isEdit
                            ? "Update"
                            : isNewAdd && undoFields
                            ? "Add"
                            : "Next"}
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default EmployerDetails;

export const AddRef = styled.div`
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #4160ac;
    margin-bottom: 0;
    margin-left: 10px;
    text-transform: uppercase;
  }
`;
