import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../Profile-verification/BackgroundCheck";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "./sub-tabs/GeneralProfile";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { Container } from "../../components/container/Container";
import {
  CardHeader,
  InvoiceWrap,
  PaymentCard,
} from "../payment/PaymentInvoiceSms";
import {
  PayCash,
  PayCashLockSm,
  PaymentConfirm,
  PaymentProcess,
} from "../../assets/svgs";
import styled from "styled-components";
import { createApplicationPaymentRent } from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toUSCurrency } from "../../utils/fn";

function Payment({ onMainTabNext, onBackClick }) {
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amountValue, setAmountValue] = useState("10");
  const [paymentToken, setPaymentToken] = useState("");
  const [states, setStates] = useState([]);
  const [nextYears, setNextYears] = useState([]);
  const [paymentDone, setPaymentDone] = useState({});
  const [paymentStatus, setPaymentStatus] = useState({});

  const { Option } = Select;
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [cashAppPayBtn, setCashAppPayBtn] = useState(false);
  const [cardPayBtn, setCardPayBtn] = useState(false);

  useEffect(() => {
    if (paymentDone?.payment_data) {
      setPaymentStatus(paymentDone?.payment_data?.squareResponse?.status);
    } else {
      setPaymentStatus(
        applicationProcessData?.applicationPayment?.squareResponse?.status
      );
    }
  }, [paymentDone, applicationProcessData]);

  // none use
  useEffect(() => {
    let arr = [];
    for (var input = 1; input <= 12; input++) {
      arr.push(
        input.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
      );
    }

    setStates(arr);
  }, []);

  // get year
  const getYears = () => {
    const startYear = new Date().getFullYear() + 20;
    let currentYear = new Date().getFullYear();
    let years = [];

    for (let i = currentYear; i <= startYear; i++) {
      years.push(i.toString());
    }

    return years;
  };

  // get years data to store in state
  useEffect(() => {
    setNextYears(getYears());
  }, []);

  // on click submit
  const onFinish = (value) => {
    setIsSubmit(true);

    // setTimeout(() => {
    onMainTabNext("state-profile");
    setIsSubmit(false);
    // }, 1000);
  };

  // form validation message
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  //For Card Number formatted inputs
  var cardNum = document.getElementById("cr_no");
  if (cardNum) {
    cardNum.onkeyup = function (e) {
      if (this.value == this.lastValue) return;
      var caretPosition = this.selectionStart;
      var sanitizedValue = this.value.replace(/[^0-9]/gi, "");
      var parts = [];

      for (var i = 0, len = sanitizedValue.length; i < len; i += 4) {
        parts.push(sanitizedValue.substring(i, i + 4));
      }

      for (var i = caretPosition - 1; i >= 0; i--) {
        var c = this.value[i];
        if (c < "0" || c > "9") {
          caretPosition--;
        }
      }
      caretPosition += Math.floor(caretPosition / 4);

      this.value = this.lastValue = parts.join(" ");
      this.selectionStart = this.selectionEnd = caretPosition;
    };
  }

  const cashAppFun = async () => {
    const payment = window.Square.payments(
      process.env.REACT_APP_SQUARE_API_KEY,
      process.env.REACT_APP_SQUARE_LOCATIONID
    );

    const payableAmount = amountValue;

    const finalAmountTostring = payableAmount.toString();

    const cashReq = payment.paymentRequest({
      countryCode: "US",
      currencyCode: "USD",
      total: { amount: finalAmountTostring, label: "Total", pending: false },
    });

    const cashAppPay = await payment.cashAppPay(cashReq, {
      redirectURL: window.location.href,
      referenceId: "my-website-00000001",
    });

    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
      } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
      }

      statusContainer.style.visibility = "visible";
    }

    cashAppPay.addEventListener("ontokenization", (event) => {
      const { tokenResult } = event.detail;
      const tokenStatus = tokenResult.status;
      if (tokenStatus === "OK") {
        const token = tokenResult.token;
        setPaymentToken(token);
      }
    });

    await cashAppPay.attach("#cash-app-pay", {
      shape: "semiround",
      theme: "dark",
      width: "full",
      size: "medium",
    });
  };

  useEffect(() => {
    cashAppFun();
  }, []);

  // ach-bank-tf
  const achClickFun = async () => {
    const appId = process.env.REACT_APP_SQUARE_API_KEY;
    const locationId = process.env.REACT_APP_SQUARE_LOCATIONID;

    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    let ach;
    try {
      ach = await payments.ach();
    } catch (e) {
      console.error("Initializing ACH failed", e);
      return;
    }
    async function tokenize(paymentMethod, options = {}) {
      const tokenResult = await paymentMethod.tokenize(options);
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    }

    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
      } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
      }

      statusContainer.style.visibility = "visible";
    }

    async function handlePaymentMethodSubmission(
      event,
      paymentMethod,
      options
    ) {
      event.preventDefault();

      try {
        achButton.disabled = true;
        const token = await tokenize(paymentMethod, options);
        setPaymentToken(token);
        displayPaymentResults("SUCCESS");
      } catch (e) {
        achButton.disabled = false;
        displayPaymentResults("FAILURE");
        console.error(e.message, "err");
      }
    }

    function getACHOptions(form) {
      const accountHolderName = `shani patel`;

      return { accountHolderName };
    }

    const achButton = document.getElementById("ach-button");
    achButton?.addEventListener("click", async function (event) {
      const achOptions = getACHOptions();
      await handlePaymentMethodSubmission(event, ach, achOptions);
    });
  };

  useEffect(() => {
    window.addEventListener("click", achClickFun);
  }, []);

  // card-payment
  const cardFun = async () => {
    setCardPayBtn(true);
    const appId = process.env.REACT_APP_SQUARE_API_KEY;
    const locationId = process.env.REACT_APP_SQUARE_LOCATIONID;

    async function initializeCard(payments) {
      const card = await payments.card();
      await card.attach("#card-container");

      return card;
    }

    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
      } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
      }

      statusContainer.style.visibility = "visible";
    }

    async function tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    }

    // Checkpoint 2.
    async function handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();

      try {
        cardButton.disabled = true;
        const token = await tokenize(paymentMethod);
        setPaymentToken(token);
        displayPaymentResults("SUCCESS");
        console.debug("Payment Success");
      } catch (e) {
        cardButton.disabled = false;
        displayPaymentResults("FAILURE");
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById("card-button");
    cardButton.addEventListener("click", async function (event) {
      await handlePaymentMethodSubmission(event, card);
    });
  };

  useEffect(() => {}, []);

  const [cardFunCalled, setCardFunCalled] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setCashAppPayBtn(true);
      setCardFunCalled("called");
    }, 3000);
  }, []);

  useEffect(() => {
    if (cardFunCalled != "called") {
      setTimeout(() => {
        setCashAppPayBtn(true);
        setCardFunCalled("called");
      }, 4000);
    }
  }, [cardFunCalled]);

  useEffect(() => {
    if (cashAppPayBtn) {
      cardFun();
    }
    return () => {};
  }, [cashAppPayBtn]);

  useEffect(() => {
    if (paymentToken && applicationProcessData) {
      setLoading(true);
      dispatch(
        createApplicationPaymentRent({
          sourceId: paymentToken,
          amount: "10",
          applicationId: applicationProcessData?.id,
        })
      ).then(({ payload }) => {
        setPaymentDone(JSON.parse(payload.result));
        setLoading(false);
      });
    }
  }, [paymentToken]);

  return (
    <GeneralProfileWrap>
      {paymentStatus === "COMPLETED" ? (
        <PaymentConfirmWrap>
          <PaymentConfirm />
          <h3>
            Profile verification fee of $10 has been successfully completed.
          </h3>
        </PaymentConfirmWrap>
      ) : paymentStatus === "PENDING" ? (
        <PaymentConfirmWrap>
          <PaymentProcess />
          <h3>
            It will take 3-5 business days for the payment to be reflected as
            complete.
          </h3>
        </PaymentConfirmWrap>
      ) : loading ? (
        <div
          style={{
            position: "absolute",
            top: "35%",
            left: "50%",
          }}
        >
          <Spin className="ant__spin" size="large" />
        </div>
      ) : (
        <FormWrap mt="0">
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                layout="vertical"
                // requiredMark={"optional"}
                form={form}
                onFinish={onFinish}
                validateMessages={validateMessages}
              >
                <Container>
                  <Row justify="center">
                    <Col xs={24} sm={18} md={14} lg={14} xl={12} xxl={12}>
                      <PaymentCard marginTop="0">
                        <CardHeader>
                          <PayCash />
                          <div className="balance__amount">
                            <p>
                              This step requires an profile verification fee of
                            </p>
                            <span>
                              &nbsp; &nbsp;
                              {toUSCurrency(Number(10))}
                            </span>
                          </div>
                        </CardHeader>
                        <InvoiceWrap>
                          {!cashAppPayBtn ? (
                            <form style={{ margin: "0" }} id="payment-form">
                              <div className="pay__btn" id="cash-app-pay"></div>
                            </form>
                          ) : (
                            <form style={{ margin: "0" }} id="payment-form">
                              <div className="pay__btn" id="cash-app-pay"></div>
                            </form>
                          )}
                          <Row
                            style={{ alignItems: "center" }}
                            justify="space-between"
                          >
                            <Col span={11}>
                              <Divider />
                            </Col>
                            <Col span={2}>
                              <span>OR</span>
                            </Col>
                            <Col span={11}>
                              <Divider />
                            </Col>
                          </Row>

                          <form id="payment-form">
                            <StyledButtonPay id="ach-button" type="button">
                              Pay with ACH Account
                            </StyledButtonPay>
                          </form>
                          <Row
                            style={{ alignItems: "center" }}
                            justify="space-between"
                          >
                            <Col span={11}>
                              <Divider />
                            </Col>
                            <Col span={2}>
                              <span>OR</span>
                            </Col>
                            <Col span={11}>
                              <Divider />
                            </Col>
                          </Row>

                          {!cardPayBtn ? (
                            <>
                              <form id="payment-form">
                                <div id="card-container"></div>
                                <StyledButtonPay id="card-button" type="button">
                                  Pay with Card
                                </StyledButtonPay>
                              </form>
                              <div id="payment-status-container"></div>
                            </>
                          ) : (
                            <>
                              <form id="payment-form">
                                <div id="card-container"></div>
                                <StyledButtonPay id="card-button" type="button">
                                  Pay with Card
                                </StyledButtonPay>
                              </form>
                              <div id="payment-status-container"></div>
                            </>
                          )}

                          <h2 style={{ marginTop: "20px" }}>
                            <PayCashLockSm style={{ marginRight: "10px" }} />{" "}
                            100% Secure Payment
                          </h2>
                        </InvoiceWrap>
                      </PaymentCard>
                    </Col>
                  </Row>
                </Container>
                {/* <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="creditCardNumber"
                  label="Credit Card Number"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="card-num"
                    placeholder="0000 0000 0000 0000"
                    size="18"
                    id="cr_no"
                    minLength="19"
                    maxLength="19"
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="nameOnCard"
                  label="Name on Card"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Mark Jecno" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  className="input__affix"
                  name="expiryMonth"
                  label="Expiry Month"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="01"
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state, idx) => (
                        <Option key={idx} value={state}>
                          {state.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  className="input__affix"
                  name="expiryYear"
                  label="Expiry Year"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="2023"
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {nextYears.length &&
                      nextYears.map((state, idx) => (
                        <Option key={idx} value={state}>
                          {state}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="cvv"
                  label="CVV"
                  rules={[
                    {
                      required: true,
                    },
                    { min: 3, max: 4, message: "Invalid CVV" },
                  ]}
                >

                  <Input
                    type="password"
                    name="cvv"
                    placeholder="000"
                    size="1"
                    minLength="3"
                    maxLength="4"
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="termsConditions"
                
                >
                  <Checkbox>Terms and conditions</Checkbox>
                </Form.Item>
              </StyledFormItem> */}

                <Form.Item>
                  <Row
                    justify="end"
                    style={{ alignItems: "center", marginTop: "20px" }}
                  >
                    <Col>
                      <p className="cancel__btn">CANCEL</p>
                    </Col>
                    <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                      <StyledButton mt="0" loading={isSubmit} htmlType="submit">
                        next
                      </StyledButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </FormWrap>
      )}
      {paymentStatus === "COMPLETED" ? (
        <FormWrap
          style={{
            bottom: "0",
            width: "100%",
            minHeight: "0px",
            paddingLeft: "0px",
            marginTop: "100px",
          }}
          mt="0"
        >
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                layout="vertical"
                // form={form}
                onFinish={onFinish}
              >
                <Form.Item>
                  <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col
                      xs={10}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="back__btn"
                    >
                      <IoIosArrowBack onClick={() => onBackClick("payment")} />
                      <h2 onClick={() => onBackClick("payment")}>back</h2>
                    </Col>
                    <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Row justify="end" style={{ alignItems: "center" }}>
                        <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                          <StyledButton
                            mt="0"
                            // loading={isSubmit}
                            htmlType="submit"
                          >
                            next
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </FormWrap>
      ) : (
        ""
      )}
    </GeneralProfileWrap>
  );
}

export default Payment;

const PaymentConfirmWrap = styled.div`
  width: 100%;
  svg {
    margin-left: auto !important;
    margin: auto;
    display: flex;
  }
  h3 {
    text-align: center;
    margin-top: 40px;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
  }
`;

const StyledButtonPay = styled(Button)`
  :hover,
  :focus {
    color: #ffffff;
    --tw-bg-opacity: 1;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity));
    border-color: rgb(51 51 51 / var(--tw-bg-opacity));
  }

  :active,
  :disabled,
  :disabled:hover {
    text-decoration: none;
    --tw-bg-opacity: 1;

    background: rgb(51 51 51 / var(--tw-bg-opacity));
    border-color: rgb(51 51 51 / var(--tw-bg-opacity));
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: red;
    background: red;
    text-shadow: none;
    box-shadow: none;
  }

  span {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    ${"" /* text-transform: uppercase !important; */}
    color: #ffffff !important;
    letter-spacing: 1px;
  }

  height: ${({ hg }) => hg || "50px"};
  width: 100%;
  border-radius: 7px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  ${"" /* text-transform: uppercase; */}
  color: #ffffff;
  background: #000;
`;
