import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Steps,
} from "antd";
import React, { useState } from "react";
import { ApplicationsCardTop, BedBathBox, ObjWrap } from "./Applications";
import { MobCard } from "../my-account/Applications";
import {
  AccountCreateLogo,
  AvenewImg,
  BathSvg,
  BedSvg,
  OpsSvg,
  SmileSvg,
  Sqft,
  WaitingApprovalSvg,
  WelcomeHomeSvg,
} from "../../assets/svgs";
import { MobCardDetails } from "../category-of-property/CategoryOfProperty";
import {
  formattedNumber,
  imagePath,
  toUSCurrency,
  upperCase,
} from "../../utils/fn";
import StarRatings from "react-star-ratings";
import { HiDownload } from "react-icons/hi";
import ReactQuill from "react-quill";
import { StyledFormSelect } from "../Profile-verification/BackgroundCheck";
import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";
import { IoIosArrowDown, IoIosClose, IoIosRadioButtonOn } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ImagePreview } from "../../components/ImagePreview";
import { Option } from "antd/lib/mentions";
import SignatureCanvas from "react-signature-canvas";
import {
  AgreeSignMobile,
  AgreeSignRightMobile,
  ApplicationsRightMobile,
  AtchBoxMobile,
  BottomWrapMobile,
  CheckoutWrapMobile,
  DiscountCodeMobile,
  LeasingAgreementMobile,
  PriceCodeMobile,
  PriceComponentMobile,
  RentalPaymentMobile,
  SelectWrapMobile,
  StatusMainMobile,
  StyledButtonMobile,
  StyledUploadPreviewWrapperMobile,
  UploadBoxMobile,
  UploadInputMobile,
  WelcomeHomeWrapMobile,
} from "./ApplicationMobileViewStyle";

const ApplicationsMobileView = ({ allApplicationsArr, statusFtColor }) => {
  const [currentStatus, setCurrentStatus] = useState(0);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState({});
  const [state, setState] = useState([]);

  const { Step } = Steps;
  const navigate = useNavigate();
  const attachmentType = ["image/svg+xml", "application/pdf"];
  const [selectedSignFormat, setSelectedSignFormat] = useState("drawSign");

  const selectChange = (val) => {
    if (val) {
      let selectedApp = allApplicationsArr.filter((vals) => {
        return val === vals?.id;
      });
      setSelectedApplication(selectedApp);
    }
  };

  // Tab change (Apply -> Sign Agreement)
  const onChangeTab = (value) => {
    setCurrentStatus(value);
  };

  return (
    <>
      <ApplicationsRightMobile>
        <h2>{currentStatus === 0 ? "Applications" : "Property Application"}</h2>
        <SelectWrapMobile style={{ display: "flex", alignItems: "baseline" }}>
          <Select
            onChange={(value) => selectChange(value)}
            placeholder="Select Application"
            suffixIcon={<IoIosArrowDown />}
          >
            {allApplicationsArr?.map((items) => (
              <Option value={items?.id}>
                <Row justify="space-between">
                  <Col>{items?.unitDetails?.unit_name}</Col>
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <IoIosRadioButtonOn
                      color={statusFtColor(
                        items?.isUnitDeleted
                          ? "Deleted"
                          : items?.isApprove === 1
                          ? "Approved"
                          : items?.isApprove === 2
                          ? "Denied"
                          : "Awaiting Approval"
                      )}
                    />
                  </Col>
                </Row>
              </Option>
            ))}
          </Select>
        </SelectWrapMobile>
        {selectedApplication?.length ? (
          <>
            <Row
              gutter={[20, 20]}
              style={{ marginTop: "10px", marginBottom: "30px" }}
            >
              <>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                  <MobCard key={selectedApplication?.id}>
                    <Badge.Ribbon
                      text={
                        selectedApplication[0]?.isUnitDeleted
                          ? "Deleted"
                          : selectedApplication[0]?.isApprove === 1
                          ? "Approved"
                          : selectedApplication[0]?.isApprove === 2
                          ? "Denied"
                          : "Awaiting Approval"
                      }
                      color={statusFtColor(
                        selectedApplication[0]?.isUnitDeleted
                          ? "Deleted"
                          : selectedApplication[0]?.isApprove === 1
                          ? "Approved"
                          : selectedApplication[0]?.isApprove === 2
                          ? "Denied"
                          : "Awaiting Approval"
                      )}
                    >
                      <ApplicationsCardTop
                      // onClick={() => navigateParam(items?.id)}
                      >
                        {selectedApplication[0]?.unitDetails?.images &&
                        selectedApplication[0]?.unitDetails?.images?.length ? (
                          <>
                            {selectedApplication[0]?.unitDetails?.images.map(
                              (img, idx) => (
                                <div key={idx}>
                                  {img.isCover === true ? (
                                    <img alt="example" src={img.path} />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <AvenewImg
                            className="svg__border"
                            style={{
                              background: "#eeeeee",
                              height: "100px",
                              width: "100px",
                            }}
                          />
                        )}
                        <MobCardDetails>
                          <Row>
                            <Col>
                              <StarRatings
                                rating={4}
                                starRatedColor="#F67C36"
                                starDimension="15px"
                                starSpacing="3px"
                                // changeRating={this.changeRating}
                                numberOfStars={5}
                                name="rating"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h2>
                                {selectedApplication[0]?.unitDetails?.unit_name}
                              </h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p>
                                {toUSCurrency(
                                  Number(
                                    selectedApplication[0]?.unitDetails?.price
                                  )
                                )}
                              </p>
                            </Col>
                          </Row>
                        </MobCardDetails>
                      </ApplicationsCardTop>
                    </Badge.Ribbon>
                    <BedBathBox>
                      <Row justify="space-between">
                        {selectedApplication[0]?.unitDetails?.bed ? (
                          <Col className="card__bottom">
                            <p>
                              <BedSvg className="icon" />
                            </p>
                            <span>
                              Bed : {selectedApplication[0]?.unitDetails?.bed}
                            </span>
                          </Col>
                        ) : (
                          ""
                        )}
                        {selectedApplication[0]?.unitDetails?.bath ? (
                          <Col className="card__bottom">
                            <p>
                              <BathSvg />
                            </p>
                            <span>
                              Bath : {selectedApplication[0]?.unitDetails?.bath}
                            </span>
                          </Col>
                        ) : (
                          ""
                        )}
                        {selectedApplication[0]?.unitDetails?.sqft ? (
                          <Col className="card__bottom">
                            <p>
                              <Sqft />
                            </p>
                            <span>
                              Sq ft :{" "}
                              {formattedNumber(
                                selectedApplication[0]?.unitDetails?.sqft
                              )}
                            </span>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </BedBathBox>
                  </MobCard>
                </Col>
              </>
            </Row>
            {selectedApplication[0]?.isUnitDeleted === false ? (
              <Steps
                size="small"
                direction="horizontal"
                onChange={onChangeTab}
                current={currentStatus}
              >
                <Step title="Apply" />
                <Step
                  title="Sign Agreement"
                  disabled={
                    selectedApplication[0]?.isApprove === 1 ? false : true
                  }
                />
                <Step
                  title="Move-in Amount"
                  disabled={
                    selectedApplication[0]?.isApprove === 1 ? false : true
                  }
                />
                <Step
                  title="Done"
                  disabled={
                    selectedApplication[0]?.isApprove === 1 ? false : true
                  }
                />
              </Steps>
            ) : (
              ""
            )}
            {currentStatus === 0 ? (
              <>
                {allApplicationsArr?.length ? (
                  <>
                    {selectedApplication[0]?.isUnitDeleted === false ? (
                      <>
                        {selectedApplication[0]?.isApprove === 1 ? (
                          <StatusMainMobile>
                            <Row justify="center">
                              <Col span={24}>
                                <AccountCreateLogo />
                              </Col>
                              <Col span={24}>
                                <h3 className="avenew__signup">
                                  Congratulations!
                                </h3>
                              </Col>
                              <Col span={20}>
                                <h4>
                                  Your application has been approved
                                  successfully
                                </h4>
                              </Col>
                              <Col span={24}>
                                <StyledButtonMobile
                                  htmlType="submit"
                                  onClick={() =>
                                    setCurrentStatus(currentStatus + 1)
                                  }
                                  loading={isNextLoading}
                                >
                                  NEXT
                                </StyledButtonMobile>
                              </Col>
                            </Row>
                          </StatusMainMobile>
                        ) : selectedApplication[0]?.isApprove === 2 ? (
                          <StatusMainMobile>
                            <Row justify="center">
                              <Col span={24}>
                                <OpsSvg />
                              </Col>
                              <Col span={24}>
                                <h3 className="avenew__signup">Opps!</h3>
                              </Col>
                              <Col span={20}>
                                <h4>
                                  Your application has been rejected we can’t
                                  process
                                </h4>
                              </Col>
                              <Col span={24}>
                                <StyledButtonMobile
                                  htmlType="submit"
                                  onClick={() => navigate("/")}
                                  loading={isNextLoading}
                                >
                                  Go to Homepage
                                </StyledButtonMobile>
                              </Col>
                            </Row>
                          </StatusMainMobile>
                        ) : (
                          <StatusMainMobile>
                            <Row justify="center">
                              <>
                                <Col span={24}>
                                  <WaitingApprovalSvg />
                                </Col>
                                <Col span={24}>
                                  <h3 className="avenew__signup">Wait</h3>
                                </Col>
                                <Col span={20}>
                                  <h4>Your application is still pending</h4>
                                </Col>
                                <Col span={24}>
                                  <StyledButtonMobile
                                    htmlType="submit"
                                    onClick={() => navigate("/")}
                                    loading={isNextLoading}
                                  >
                                    Go to Homepage
                                  </StyledButtonMobile>
                                </Col>
                              </>
                            </Row>
                          </StatusMainMobile>
                        )}
                      </>
                    ) : (
                      <StatusMainMobile>
                        <Row justify="center">
                          <Col span={24}>
                            <OpsSvg />
                          </Col>
                          <Col span={24}>
                            <h3 className="avenew__signup">Opps!</h3>
                          </Col>
                          <Col span={20}>
                            <h4>
                              You cannot process. The unit is deleted which you
                              applied
                            </h4>
                          </Col>
                          <Col span={24}>
                            <StyledButtonMobile
                              htmlType="submit"
                              onClick={() => navigate("/")}
                              loading={isNextLoading}
                            >
                              Go to Homepage
                            </StyledButtonMobile>
                          </Col>
                        </Row>
                      </StatusMainMobile>
                    )}
                  </>
                ) : (
                  <>
                    <StatusMainMobile>
                      <Row justify="center">
                        <Col span={24}>
                          <SmileSvg />
                        </Col>
                        <Col span={20}>
                          <h4>You haven't applied to any units yet.</h4>
                        </Col>
                        <Col span={20}>
                          <h5>
                            Click here to get verified so you can start
                            applying!
                          </h5>
                        </Col>
                        <Col span={24}>
                          <StyledButtonMobile
                            htmlType="submit"
                            onClick={() => navigate("/")}
                            loading={isNextLoading}
                          >
                            Let's explore listings
                          </StyledButtonMobile>
                        </Col>
                      </Row>
                    </StatusMainMobile>
                  </>
                )}
              </>
            ) : currentStatus === 1 ? (
              <LeasingAgreementMobile>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h1>Leasing Agreement</h1>
                  {!selectedApplication[0]?.leaseAgreementDoc ? (
                    <Row justify="center">
                      <Col span={24}>
                        <AtchBoxMobile
                          fontColor="#F67C36"
                          style={{ marginBottom: "30px" }}
                          // onClick={() =>
                          //   download(
                          //     selectedApplication?.leaseAgreementDoc.path,
                          //     selectedApplication?.leaseAgreementDoc.name
                          //   )
                          // }
                        >
                          {/* <CgFileDocument /> */}
                          <h4 className="dow__svg">Download</h4>
                          <HiDownload
                            style={{ fontSize: "24px", marginLeft: "10px" }}
                            className="dow__svg"
                          />
                        </AtchBoxMobile>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={24}>
                        <AtchBoxMobile
                          fontColor="#F67C36"
                          style={{ marginBottom: "30px" }}
                          id="btn-export"
                          // onClick={() => {
                          //   // downloadPdf(selectedApplication?.leaseAgreementText)
                          //   exportHTML();
                          // }}
                        >
                          <h4 className="dow__svg">Download</h4>
                          <HiDownload
                            style={{ fontSize: "24px", marginLeft: "10px" }}
                            className="dow__svg"
                          />
                        </AtchBoxMobile>
                      </Col>
                    </Row>
                  )}
                </div>

                <h5>{selectedApplication[0]?.leaseAgreementText}</h5>
                <ReactQuill
                  id="source-html"
                  // value={selectedApplication?.leaseAgreementText}
                  // readOnly={true}
                  theme={"bubble"}
                  // onChange={setValue}
                />

                {selectedApplication[0]?.leaseAgreementDoc ? (
                  <AgreeSignMobile>
                    <AgreeSignRightMobile justifyContent="space-between">
                      <UploadBoxMobile
                        style={{ height: "100px" }}
                        mt="0"
                        // ref={drop}
                        // onClick={(e) => upload.current.click()}
                      >
                        <UploadInputMobile>
                          <h6 className="ant-upload-drag-icon">
                            <AiOutlineUpload className="upload__icon" />
                          </h6>
                          <h6 className="upload__icon">
                            Upload Signed Agreement
                          </h6>
                        </UploadInputMobile>
                      </UploadBoxMobile>
                      <input
                        accept="application/pdf"
                        id="myInput"
                        // multiple={"multiple"}
                        type="file"
                        // ref={upload}
                        style={{ display: "none" }}
                        // onChange={fileChangeHandler}
                      />
                      <div style={{ display: "flex" }}>
                        {state.map((img, idx) => (
                          <StyledUploadPreviewWrapperMobile
                            isPhoto={state.length}
                          >
                            {attachmentType.includes(img.ext) ? (
                              <ObjWrap>
                                <object
                                  // data={img.path}
                                  type="application/pdf"
                                  width="100%"
                                  // height="100%"
                                  height="200"
                                >
                                  <p>
                                    Alternative text - include a link{" "}
                                    <a
                                      // href={img.path}
                                      href={""}
                                    >
                                      to the PDF!
                                    </a>
                                  </p>
                                </object>
                                <h3>
                                  <IoIosClose
                                    onClick={() => setState([])}
                                    className="modal_close_icon"
                                  />
                                </h3>
                              </ObjWrap>
                            ) : (
                              <ImagePreview
                                // key={idx}
                                // src={img.path ? img.path : imagePath(img.path)}
                                height={95}
                                width={95}
                                // onDelete={() => {
                                //   const st = [...state];
                                //   st.splice(idx, 1);
                                //   setState(st);
                                // }}
                                isCoverDisProp={false}
                              />
                            )}
                          </StyledUploadPreviewWrapperMobile>
                        ))}
                      </div>
                      <StyledButtonMobile
                        // disabled={!disabledBtn}
                        // onClick={() => setCurrentStatus(currentStatus + 1)}
                        // onClick={() => onFinish()}

                        htmlType="submit"
                        loading={isNextLoading}
                        className="submit__btn"
                      >
                        NEXT
                      </StyledButtonMobile>
                    </AgreeSignRightMobile>
                  </AgreeSignMobile>
                ) : selectedApplication[0]?.leaseAgreementText ? (
                  <>
                    <AgreeSignMobile display="block">
                      <Checkbox
                      // checked={disabledBtn} onChange={onChange}
                      >
                        Agree & Sign
                      </Checkbox>
                    </AgreeSignMobile>
                    <AgreeSignMobile>
                      <Form
                        style={{ width: "100%" }}
                        layout="vertical"
                        // requiredMark={"optional"}
                        // form={form}
                        // onFinish={onFinish}
                        // validateMessages={validateMessages}
                      >
                        <Row
                          justify="space-between"
                          style={{ alignItems: "center", width: "100%" }}
                        >
                          <Col span={6}>
                            <StyledFormSelect wh="100">
                              <Form.Item
                                name="signFormat"
                                // label="Form of Identification"
                              >
                                <Select
                                  // placeholder="Draw sign"
                                  suffixIcon={<IoIosArrowDown />}
                                  defaultValue="drawSign"
                                  // onChange={(value) => setSelectedSignFormat(value)}
                                >
                                  <Option value="drawSign">Draw sign</Option>
                                  <Option value="uploadSign">
                                    Upload sign
                                  </Option>
                                </Select>
                              </Form.Item>
                            </StyledFormSelect>
                          </Col>
                          <Col span={12}>
                            <Row>
                              <Col
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                span={24}
                              >
                                {selectedSignFormat === "drawSign" ? (
                                  <>
                                    {selectedApplication[0]?.sign?.path ? (
                                      <img
                                        src={selectedApplication[0]?.sign?.path}
                                      />
                                    ) : (
                                      <SignatureCanvas
                                        // ref={sig}
                                        // onEnd={handleSignatureEnd}
                                        penColor="green"
                                        canvasProps={{
                                          // width: 370,
                                          height: 175,
                                          className: "sigCanvas",
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <UploadBoxMobile
                                      style={{ height: "100px", width: "50%" }}
                                      mt="0"
                                      // ref={drop}
                                      // onClick={(e) => upload.current.click()}
                                    >
                                      <UploadInputMobile>
                                        <h6 className="ant-upload-drag-icon">
                                          <AiOutlineUpload className="upload__icon" />
                                        </h6>
                                        <h6 className="upload__icon">
                                          Upload Sign
                                        </h6>
                                      </UploadInputMobile>
                                    </UploadBoxMobile>
                                    <input
                                      accept="image/*"
                                      id="myInput"
                                      // multiple={"multiple"}
                                      type="file"
                                      // ref={upload}
                                      style={{ display: "none" }}
                                      // onChange={fileChangeHandler}
                                    />
                                    <div style={{ display: "flex" }}>
                                      {state.map((img, idx) => (
                                        <StyledUploadPreviewWrapperMobile
                                          isPhoto={state.length}
                                        >
                                          <ImagePreview
                                            key={idx}
                                            src={
                                              img.path
                                                ? img.path
                                                : imagePath(img.path)
                                            }
                                            height={95}
                                            width={95}
                                            onDelete={() => {
                                              const st = [...state];
                                              st.splice(idx, 1);
                                              setState(st);
                                            }}
                                            isCoverDisProp={false}
                                          />
                                        </StyledUploadPreviewWrapperMobile>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </Col>
                              {selectedSignFormat === "drawSign" ? (
                                <>
                                  {selectedApplication[0]?.sign?.path ? (
                                    <Col
                                      style={{
                                        justifyContent: "end",
                                        display: "flex",
                                      }}
                                      span={24}
                                    >
                                      <h5
                                      //  onClick={clearSign}
                                      >
                                        Clear
                                      </h5>
                                    </Col>
                                  ) : (
                                    <Col
                                      style={{
                                        justifyContent: "end",
                                        display: "flex",
                                      }}
                                      span={24}
                                    >
                                      <h5
                                      //  onClick={clear}
                                      >
                                        Clear
                                      </h5>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>
                          <Col span={6}>
                            <AgreeSignRightMobile>
                              <StyledButtonMobile
                                // disabled={!disabledBtn}
                                // loading={isNextLoading}
                                // onClick={() => setCurrentStatus(currentStatus + 1)}
                                htmlType="submit"
                              >
                                NEXT
                              </StyledButtonMobile>
                            </AgreeSignRightMobile>
                          </Col>
                        </Row>
                      </Form>
                    </AgreeSignMobile>
                  </>
                ) : (
                  <AgreeSignMobile>
                    <h1
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        color: "#777777",
                      }}
                    >
                      Waiting for{" "}
                      {selectedApplication[0]?.isApprove === 1
                        ? "Lease Agreement..."
                        : "Approval..."}
                    </h1>
                  </AgreeSignMobile>
                )}
              </LeasingAgreementMobile>
            ) : currentStatus === 2 ? (
              <CheckoutWrapMobile>
                <p>Checkout</p>
                <Row
                  gutter={[20, 20]}
                  style={{ marginTop: "10px", marginBottom: "30px" }}
                >
                  <>
                    <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                      <MobCard key={selectedApplication?.id}>
                        <ApplicationsCardTop
                        // onClick={() => navigateParam(items?.id)}
                        >
                          {selectedApplication[0]?.unitDetails?.images &&
                          selectedApplication[0]?.unitDetails?.images
                            ?.length ? (
                            <>
                              {selectedApplication[0]?.unitDetails?.images.map(
                                (img, idx) => (
                                  <div key={idx}>
                                    {img.isCover === true ? (
                                      <img alt="example" src={img.path} />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <AvenewImg
                              className="svg__border"
                              style={{
                                background: "#eeeeee",
                                height: "100px",
                                width: "100px",
                              }}
                            />
                          )}
                          <MobCardDetails>
                            <Row>
                              <Col>
                                <StarRatings
                                  rating={4}
                                  starRatedColor="#F67C36"
                                  starDimension="15px"
                                  starSpacing="3px"
                                  // changeRating={this.changeRating}
                                  numberOfStars={5}
                                  name="rating"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <h2>
                                  {
                                    selectedApplication[0]?.unitDetails
                                      ?.unit_name
                                  }
                                </h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p>
                                  {toUSCurrency(
                                    Number(
                                      selectedApplication[0]?.unitDetails?.price
                                    )
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </MobCardDetails>
                        </ApplicationsCardTop>
                        <BedBathBox>
                          <Row justify="space-between">
                            {selectedApplication[0]?.unitDetails?.bed ? (
                              <Col className="card__bottom">
                                <p>
                                  <BedSvg className="icon" />
                                </p>
                                <span>
                                  Bed :{" "}
                                  {selectedApplication[0]?.unitDetails?.bed}
                                </span>
                              </Col>
                            ) : (
                              ""
                            )}
                            {selectedApplication[0]?.unitDetails?.bath ? (
                              <Col className="card__bottom">
                                <p>
                                  <BathSvg />
                                </p>
                                <span>
                                  Bath :{" "}
                                  {selectedApplication[0]?.unitDetails?.bath}
                                </span>
                              </Col>
                            ) : (
                              ""
                            )}
                            {selectedApplication[0]?.unitDetails?.sqft ? (
                              <Col className="card__bottom">
                                <p>
                                  <Sqft />
                                </p>
                                <span>
                                  Sq ft :{" "}
                                  {formattedNumber(
                                    selectedApplication[0]?.unitDetails?.sqft
                                  )}
                                </span>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </BedBathBox>
                      </MobCard>
                    </Col>
                  </>
                </Row>
                <PriceComponentMobile>
                  <DiscountCodeMobile>
                    <Input.Group compact>
                      <Input
                        placeholder="Discount Code"
                        style={{
                          width: "calc(100% - 75px)",
                          height: "50px",
                        }}
                      />
                      <Button>Apply</Button>
                    </Input.Group>
                  </DiscountCodeMobile>
                  <PriceCodeMobile>
                    <Row gutter={[20, 20]} justify="space-between">
                      <Col span={12}>
                        <span>Sub Total</span>
                      </Col>
                      <Col span={12}>
                        <p>
                          {toUSCurrency(
                            Number(selectedApplication[0]?.unitDetails?.price)
                          )}
                        </p>
                      </Col>
                      <Col span={12}>
                        <span>Discount</span>
                      </Col>
                      <Col span={12}>
                        <p>{toUSCurrency(Number("0"))}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "40px",
                      }}
                    >
                      <Col span={12}>
                        <span>Total</span>
                      </Col>
                      <Col span={12}>
                        <p>
                          {toUSCurrency(
                            Number(selectedApplication[0]?.unitDetails?.price)
                          )}
                        </p>
                      </Col>
                    </Row>
                  </PriceCodeMobile>
                </PriceComponentMobile>
                <Divider />
                <BottomWrapMobile>
                  <StyledButtonMobile
                    padding="4px 40px"
                    onClick={() => setCurrentStatus(currentStatus + 1)}
                  >
                    Make Payment
                  </StyledButtonMobile>
                  <p>Cancel</p>
                </BottomWrapMobile>
              </CheckoutWrapMobile>
            ) : currentStatus === 3 ? (
              <WelcomeHomeWrapMobile>
                <Row justify="center">
                  <Col>
                    <WelcomeHomeSvg />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={24}>
                    <h2>Welcome Home</h2>
                  </Col>
                  <Col span={24}>
                    <p>
                      Congrats you’re successfully completed the application
                      process
                    </p>
                  </Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col style={{ textAlign: "center" }} span={24}>
                    <span>
                      Mark Jecno is an Avenew Partner. That means you can use
                      avenew to :
                    </span>
                  </Col>
                </Row>
                <RentalPaymentMobile>
                  <Row>
                    <Col style={{ textAlign: "center" }} span={8}>
                      <span>
                        <AiOutlineCheck />
                        Pay Rent
                      </span>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={8}>
                      <span>
                        <AiOutlineCheck />
                        Maintenance Requests
                      </span>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={8}>
                      <span>
                        <AiOutlineCheck />
                        Renew Lease
                      </span>
                    </Col>
                  </Row>
                  <Row
                    gutter={[20, 20]}
                    style={{ marginTop: "30px" }}
                    justify="center"
                  >
                    <Col style={{ textAlign: "center" }} span={24}>
                      <StyledButtonMobile style={{ color: "#fff" }}>
                        set up rental payment
                      </StyledButtonMobile>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={24}>
                      <h5>Not right now</h5>
                    </Col>
                  </Row>
                </RentalPaymentMobile>
              </WelcomeHomeWrapMobile>
            ) : (
              ""
            )}
          </>
        ) : (
          <StatusMainMobile>
            <WaitingApprovalSvg />
            <h2>Select One Application</h2>
          </StatusMainMobile>
        )}
      </ApplicationsRightMobile>
    </>
  );
};

export default ApplicationsMobileView;
