import React, { useEffect, useState } from "react";
import {
  DemoWrap,
  H2Style,
  LaptopSvg,
  MainContainer,
  SideBarLogin,
  SideMainWrap,
  StyledButton,
} from "../dashbord-new/SearchPart";
import { Col, Divider, Form, Row, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { SidebarContainer } from "../../../pages/message/Message";
import HomeHeader from "../dashbord-new/HomeHeader";
import { HeaderSquareSvg, HeaderStyleSvg } from "../../../assets/svgs";
import { H4Tag, H5Tag, SearchBoxWrap } from "../dashbord-new/searchPart.style";

function SearchPartContactUs() {
  const { width } = useWindowSize();
  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [openSideBar, setopenSider] = useState(true);
  const [wSize, setWSize] = useState(1224);
  const [hSize, setHSize] = useState("calc(100vh - 0px)");
  const [brSize, setBrSize] = useState(width);

  useEffect(() => {
    const totalWidth = 1920;
    const newWidth = (100 * width) / totalWidth;
    setWSize(newWidth);

    setBrSize(width - (width * 1) / 100);

    if (width < 1550) {
      setHSize("auto");
    } else {
      setHSize("calc(100vh - 0px)");
    }
  }, [width]);

  return (
    <MainContainer
      openSideBar={openSideBar}
      className="sticky-header-sidebar"
      containterHeight={hSize}
      paddingBottom="50px"
      br={`${brSize}px solid transparent`}
    >
      <HomeHeader />

      {/* <div className="circle__svg">
        <HeaderStyleSvg />
      </div> */}

      <LaptopSvg wSize={wSize}>
        <DemoWrap>
          <Row>
            <Col xs={24} sm={24} md={24} lg={13} xl={13} xxl={13}>
              <H2Style marginBottom="20px" marginBottomSm="20px">
                Stop Searching, Start applying! Find your perfect home, hassle
                free.
              </H2Style>
              <StyledButton style={{ marginLeft: "0px", color: "#ffffff" }}>
                Signup
              </StyledButton>
            </Col>
          </Row>

          {/* <Row gutter={[25, 25]}>
            <Col>
              <H4Tag>1500+</H4Tag>
              <H5Tag>Property Listed</H5Tag>
            </Col>
            <Col>
              <H4Tag>4800+</H4Tag>
              <H5Tag>Application recieved</H5Tag>
            </Col>
          </Row> */}
        </DemoWrap>
        <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
          <div className="laptop__svg__mob">
            <img style={{ width: "100%" }} src="./images/dashbord-img.png" />
          </div>
        </Col>
        <div className="laptop__svg">
          <img src="./images/dashbord-img.png" />
        </div>
      </LaptopSvg>
      <div className="square__svg">
        <HeaderSquareSvg />
      </div>
    </MainContainer>
  );
}

export default SearchPartContactUs;
