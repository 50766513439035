import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FooterLocation } from "../../../assets/svgs";
import { OverviewTab, OverviewTabWrap } from "./Overview";
import { ApplyWrap, StyledButton } from "../PropertyDetails";
import ApplyForPropertyModal from "../apply-for-property-modal/ApplyForPropertyModal";
import { useSelector } from "react-redux";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
// } from "react-google-maps";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function Location({ clickToApply, isBtnLoading, clickToMessage, unitDetails }) {
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);

  // profile is verified or not (store in state)
  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        // applicationProcessData?.rentalHistory &&
        // applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);

  // default values for map co-oridnations
  const defaultProps = {
    center: {
      lat: 37.152686,
      lng: -83.760888,
    },
    zoom: 15,
  };
  const [Markers, setMarker] = useState({
    name: "Current position",
    position: {
      lat: 37.152686,
      lng: -83.760888,
    },
    zoom: 15,
  });
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: Markers.position,
      zoom: Markers.zoom,
      draggable: true,
      dragend: (e) => console.log(e),
      map,
      title: "Hello World!",
    });
    return marker;
  };

  // const Map = () => {
  //   return (
  //     <GoogleMap
  //       defaultZoom={10}
  //       defaultCenter={{ lat: 33.750675, lng: -84.339466 }}
  //     />
  //   );
  // };

  // const WrappedMap = withScriptjs(withGoogleMap(Map));

  return (
    <OverviewTab>
      <OverviewTabWrap>
        <h2>Location</h2>

        <p>
          <FooterLocation /> {unitDetails?.address}
        </p>
        {/* <MapWrap>
          <WrappedMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </MapWrap> */}
      </OverviewTabWrap>
      <ApplyWrap ml={4} pr={2.8}>
        <h2>Interested in this property?</h2>
        <StyledButton
          loading={isBtnLoading}
          onClick={() => {
            clickToMessage();
          }}
          bgColor={({ theme }) => theme.colors.secondary}
        >
          Message
        </StyledButton>
        <StyledButton
          bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
          fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
          onClick={() => {
            clickToApply();
          }}
        >
          {isProfileVerifyed ? "Apply  now" : "Apply  now"}
        </StyledButton>
      </ApplyWrap>
      <ApplyForPropertyModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </OverviewTab>
  );
}

export default Location;

const MapWrap = styled.div`
  height: 50vh;

  @media (max-width: 769px) {
    margin: 0 -30px;
  }
`;
