import React from "react";

const selectSvg = ({
  wSize = "22",
  hSize = "21",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path id="Vector" d="M9 0L0 -3.93402e-07L4.5 7L9 0Z" fill="#D0D0D0" />
    </svg>
  );
};

export default selectSvg;
