import React from "react";

const dreamTriangle = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="90"
      viewBox="0 0 95 90"
      fill="none"
    >
      <path
        d="M93.7505 22.5234V89.2974H26.96L93.7505 22.5234Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
      <path
        d="M68.7915 2V68.774H2.00098L68.7915 2Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default dreamTriangle;
