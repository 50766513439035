import React from "react";

const footerTopSquare = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="124"
      viewBox="0 0 106 124"
      fill="none"
    >
      <path
        d="M105.001 92.054V32.0074L52.9931 2L1.00098 32.0074V92.054L52.9931 122.061L105.001 92.054Z"
        stroke="#1F293F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M94.4958 85.9801V38.0821L52.9939 14.1172L11.5078 38.0821V85.9801L52.9939 109.945L94.4958 85.9801Z"
        stroke="#1F293F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M83.9889 79.9217V44.1406L52.993 26.25L21.9971 44.1406V79.9217L52.993 97.8123L83.9889 79.9217Z"
        stroke="#1F293F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M73.4995 73.8636V50.1993L52.9938 38.3672L32.5039 50.1993V73.8636L52.9938 85.6957L73.4995 73.8636Z"
        stroke="#1F293F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M62.9924 67.8053V56.2579L52.9927 50.5L43.0088 56.2579V67.8053L52.9927 73.5632L62.9924 67.8053Z"
        stroke="#1F293F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default footerTopSquare;
