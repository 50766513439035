import styled from "styled-components";

// return styled component
export const Container = styled.div`
  max-width: ${({ mWid }) => mWid || "1400"}px;
  padding: ${({ padding }) => padding || "0"}px;
  margin: auto;

  .ant__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "30px"};
  }
`;
