import React from "react";

const opsSvg = ({
  wSize = "56",
  hSize = "56",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 54C42.3594 54 54 42.3594 54 28C54 13.6406 42.3594 2 28 2C13.6406 2 2 13.6406 2 28C2 42.3594 13.6406 54 28 54Z"
        stroke="#F25B5B"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M18.25 25.832C20.0449 25.832 21.5 24.377 21.5 22.582C21.5 20.7871 20.0449 19.332 18.25 19.332C16.4551 19.332 15 20.7871 15 22.582C15 24.377 16.4551 25.832 18.25 25.832Z"
        fill="#F25B5B"
      />
      <path
        d="M37.75 25.832C39.5449 25.832 41 24.377 41 22.582C41 20.7871 39.5449 19.332 37.75 19.332C35.9551 19.332 34.5 20.7871 34.5 22.582C34.5 24.377 35.9551 25.832 37.75 25.832Z"
        fill="#F25B5B"
      />
      <path
        d="M18.25 25.832C20.0449 25.832 21.5 24.377 21.5 22.582C21.5 20.7871 20.0449 19.332 18.25 19.332C16.4551 19.332 15 20.7871 15 22.582C15 24.377 16.4551 25.832 18.25 25.832Z"
        fill="#F25B5B"
      />
      <path
        d="M37.75 25.832C39.5449 25.832 41 24.377 41 22.582C41 20.7871 39.5449 19.332 37.75 19.332C35.9551 19.332 34.5 20.7871 34.5 22.582C34.5 24.377 35.9551 25.832 37.75 25.832Z"
        fill="#F25B5B"
      />
      <path
        d="M39.2667 40.9991C38.1214 39.0259 36.4778 37.388 34.5005 36.2495C32.5233 35.111 30.2817 34.5117 28.0001 34.5117C25.7185 34.5117 23.4768 35.111 21.4996 36.2495C19.5223 37.388 17.8788 39.0259 16.7334 40.9991"
        stroke="#F25B5B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default opsSvg;
