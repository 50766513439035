import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import ApplyForPropertyModal from "../apply-for-property-modal/ApplyForPropertyModal";
import { ApplyWrap, StyledButton } from "../PropertyDetails";
import { useSelector } from "react-redux";
import { formattedNumber, toUSCurrency } from "../../../utils/fn";

function Overview({ isBtnLoading, unitDetails, clickToApply, clickToMessage }) {
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);
  const [oweriewLength, setOweriewLength] = useState(400);

  const location = useLocation();
  const { width } = useWindowSize();

  const [showData, setShowData] = useState(true);

  // sizes for responsive screens
  useEffect(() => {
    if (width < 770) {
      setShowData(false);
    } else {
      setShowData(true);
    }
  }, [width, location.pathname]);

  // profile is verified or not (store in state)
  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        // applicationProcessData?.rentalHistory &&
        // applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);

  const slpitFunc = (value) => {
    if (value?.length > oweriewLength) {
      return (
        <>
          <p style={{ marginBottom: "0" }}>
            {value.slice(0, oweriewLength)}...
          </p>
          <p
            onClick={() => {
              setOweriewLength(value?.length);
            }}
            className="view__all"
          >
            View More
          </p>
        </>
      );
    } else {
      return <p>{value}</p>;
    }
  };

  return (
    <>
      <OverviewTab>
        <OverviewTabWrap>
          <h2>Overview</h2>
          {unitDetails?.description ? (
            <>
              {slpitFunc(unitDetails.description)}
              {/* {unitDetails?.description?.length > 400 ? (
                <>
                  <p>{slpitFunc(unitDetails.description)}</p> <p>View All</p>
                </>
              ) : (
                <p>{slpitFunc(unitDetails.description)}</p>
              )} */}
            </>
          ) : (
            ""
          )}

          <UnitType>
            <Row gutter={[10, 10]} justify="space-between">
              {unitDetails?.unitType ? (
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <p>Unit Type</p>
                  <span> {unitDetails?.unitType}</span>
                </Col>
              ) : (
                ""
              )}
              {unitDetails?.bed && unitDetails?.bath ? (
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <p>Beds & Baths</p>
                  <span>
                    {unitDetails.bed} Beds & {unitDetails?.bath} Baths
                  </span>
                </Col>
              ) : (
                ""
              )}
              {unitDetails?.unitNumber ? (
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <p>Unit Number</p>
                  <span>{unitDetails?.unitNumber}</span>
                </Col>
              ) : (
                ""
              )}
              {unitDetails?.sqft ? (
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <p>Sq ft</p>
                  <span>{formattedNumber(unitDetails.sqft)}</span>
                </Col>
              ) : (
                ""
              )}
              {unitDetails?.diposit ? (
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <p>Deposit Amount </p>
                  <span>{toUSCurrency(Number(unitDetails.diposit))}</span>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </UnitType>
        </OverviewTabWrap>
        <ApplyWrap ml={4} pr={2.8}>
          <h2>Interested in this property?</h2>
          <StyledButton
            loading={isBtnLoading}
            onClick={() => {
              clickToMessage();
            }}
            bgColor={({ theme }) => theme.colors.secondary}
          >
            Message
          </StyledButton>
          <StyledButton
            bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
            fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
            onClick={() => {
              clickToApply();
            }}
          >
            Apply now
          </StyledButton>
        </ApplyWrap>
        <ApplyForPropertyModal
          onCancel={(value) => setModalState(value)}
          modalState={modalState}
          // from={adminRoutes.TEAM}
          onSubmit={() => setModalStates(true)}
        />
      </OverviewTab>
    </>
  );
}

export default Overview;

export const UnitType = styled.div`
  p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: justify !important;
    color: #848687 !important;
    margin-bottom: 11px !important;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    text-align: justify;
  }
`;
export const OverviewTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1400px;
  margin: auto;
  padding: 50px 0;
  @media (max-width: 1550px) {
    padding: 30px 0;
  }
`;

export const OverviewTabWrap = styled.div`
  width: 70%;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: justify;
    color: #000000;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: ${({ mb }) => mb || "35px"};
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #848687;
  }
  .view__all {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: justify;
    color: #4160ac;
    margin-bottom: 0px;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 28px;
      line-height: 28px;
    }
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;
