import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import { Badge } from "antd";
import { IoIosBed } from "react-icons/io";
import { FaBath } from "react-icons/fa";
import { DepositSvg, SqFtSvg } from "../../../assets/svgs";
import FloorPlanModal from "./floor-plan-modal/FloorPlanModal";
import { ApplyWrap, StyledButton } from "../PropertyDetails";
import ApplyForPropertyModal from "../apply-for-property-modal/ApplyForPropertyModal";
import { OverviewTab } from "./Overview";
import { useSelector } from "react-redux";

function FloorPlan({ clickToApply, isBtnLoading, clickToMessage }) {
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);

  const [modalStateApply, setModalStateApply] = useState(false);
  const [modalStateApplys, setModalStatesApply] = useState(false);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);

  // profile is verified or not (store in state)
  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        // applicationProcessData?.rentalHistory &&
        // applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);

  return (
    <>
      <OverviewTab>
        <FloorPlanTabWrap>
          <h2>Floor Plan</h2>
          <FloorPlanCards>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <Badge.Ribbon text="#895625" placement="start">
                  <FloorPlanCard onClick={() => setModalState(true)}>
                    <PlanBox>
                      <ImageWrap>
                        <img src="/images/floor-plan.png" />
                      </ImageWrap>
                      <BoxDetails>
                        <h2>E6</h2>
                        <Row>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <IoIosBed />
                            <p>Beds : 02</p>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <FaBath />

                            <p>Baths : 02</p>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <SqFtSvg />
                            <p>Sq. ft : 1500</p>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <DepositSvg />
                            <p>Deposit : $1563.00</p>
                          </Col>
                        </Row>
                      </BoxDetails>
                    </PlanBox>
                    <PriceBox>
                      <Row justify="space-between">
                        <Col>
                          <span>Only 2 Available</span>
                        </Col>
                        <Col>
                          <p>$5898.00 - $6265.00</p>
                        </Col>
                      </Row>
                    </PriceBox>
                  </FloorPlanCard>
                </Badge.Ribbon>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                <Badge.Ribbon text="#995896" placement="start">
                  <FloorPlanCard onClick={() => setModalState(true)}>
                    <PlanBox>
                      <ImageWrap>
                        <img src="/images/floor-plan.png" />
                      </ImageWrap>
                      <BoxDetails>
                        <h2>A7</h2>
                        <Row>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <IoIosBed />
                            <p>Beds : 02</p>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <FaBath />
                            <p>Baths : 02</p>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <SqFtSvg />
                            <p>Sq. ft : 1500</p>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={12}
                            xxl={12}
                            className="title__icon"
                          >
                            <DepositSvg />
                            <p>Deposit : $1563.00 </p>
                          </Col>
                        </Row>
                      </BoxDetails>
                    </PlanBox>
                    <PriceBox>
                      <Row justify="space-between">
                        <Col>
                          <span>Only 1 Available</span>
                        </Col>
                        <Col>
                          <p>$5898.00 - $6265.00</p>
                        </Col>
                      </Row>
                    </PriceBox>
                  </FloorPlanCard>
                </Badge.Ribbon>
              </Col>
            </Row>
          </FloorPlanCards>
        </FloorPlanTabWrap>
        <ApplyWrap ml={4} pr={2.8}>
          <h2>Interested in this property?</h2>
          <StyledButton
            loading={isBtnLoading}
            onClick={() => {
              clickToMessage();
            }}
            bgColor={({ theme }) => theme.colors.secondary}
          >
            Message
          </StyledButton>
          <StyledButton
            bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
            fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
            onClick={() => {
              clickToApply();
            }}
          >
            {isProfileVerifyed ? "Apply  now" : "Apply  now"}
          </StyledButton>
        </ApplyWrap>
      </OverviewTab>
      <FloorPlanModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
      <ApplyForPropertyModal
        onCancel={(value) => setModalStateApply(value)}
        modalState={modalStateApply}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStatesApply(true)}
      />
    </>
  );
}

export default FloorPlan;

export const PriceBox = styled.div`
  background: #f9f9f9;
  padding: 10px;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.third};
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.secondary};
  }
  .ant-row {
    align-items: center;
  }
`;
export const BoxDetails = styled.div`
  padding: 5px 0 0 20px;
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.forth};
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
  }
  .title__icon {
    display: flex;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }

  @media (max-width: 769px) {
    h2 {
      margin-bottom: 10px !important;
      font-weight: 700 !important;
      font-size: 18px !important;
      line-height: 22px !important;
    }
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px !important;
    }
  }
`;
export const PlanBox = styled.div`
  display: flex;
  padding: 10px;
`;
export const ImageWrap = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 15px 5px;
`;
export const FloorPlanCards = styled.div`
  .ant-ribbon {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const FloorPlanCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.4s;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
`;

export const FloorPlanTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1400px;
  margin: auto;
`;

export const FloorPlanTabWrap = styled.div`
  width: 70%;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: justify;
    color: #000000;
    margin-bottom: 20px;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;
