import React from "react";
import SearchPartContactUs from "../../components/layout/contact-us/SearchPartContactUs";

function ContactUs() {
  return (
    <div>
      <SearchPartContactUs />
    </div>
  );
}

export default ContactUs;
