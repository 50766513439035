import { Breadcrumb } from "antd";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import {
  MobileViewMain,
  MyAccountMain,
  MyAccountTabWrap,
} from "../my-account/MyAccount";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import VerificationProcessMobileView from "./verification-mobile/VerificationProcessMobileView";
import VerificationProcessTabs from "./VerificationProcessTabs";
import { getApplicationProcess } from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function VerificationProcess() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  // page navigation bar
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/application-process",
      label: "User Profile Verification",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <>
      <MyAccountMain>
        <VerificationProcessWrap>
          <BreadcrumbTop>
            <BreadCrumbsWrapper>
              <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
            </BreadCrumbsWrapper>
          </BreadcrumbTop>
        </VerificationProcessWrap>
        <MyAccountTabWrap>
          <VerificationProcessTabs />
        </MyAccountTabWrap>
        {/* <Footer /> */}
        <FooterSectionHome />
      </MyAccountMain>

      <MobileViewMain>
        <VerificationProcessMobileView />
      </MobileViewMain>
    </>
  );
}

export default VerificationProcess;

const VerificationProcessWrap = styled.div`
  width: 100%;
  @media (max-width: 769px) {
    display: none;
  }
`;
