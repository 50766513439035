import React from "react";
import { CardDetails, UnitCard } from "./UnitApplication";
import { AvenewImg } from "../../assets/svgs";
import { Col, Row, Skeleton } from "antd";

function UnitModalSkeleton() {
  return (
    <UnitCard>
      <AvenewImg
        className="svg__border"
        style={{
          background: "#eeeeee",
          height: "150px",
          width: "150px",
        }}
      />
      <CardDetails>
        <Row>
          <Col span={24}>
            <Skeleton.Input
              active
              style={{
                width: "100%",
                height: 20,
                marginBottom: 20,
              }}
            ></Skeleton.Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Skeleton.Input
              active
              style={{
                width: "100%",
                height: 20,
                marginBottom: 20,
              }}
            ></Skeleton.Input>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="card__bottom">
            <Skeleton.Input
              active
              style={{
                width: "100%",
                height: 20,
                // marginBottom: 20,
              }}
            ></Skeleton.Input>
          </Col>
        </Row>

        <p className="price__pos">
          <Skeleton.Input
            active
            style={{
              width: "100%",
              height: 20,
              // marginBottom: 20,
            }}
          ></Skeleton.Input>
        </p>
      </CardDetails>
    </UnitCard>
  );
}

export default UnitModalSkeleton;
