import React from "react";

const checkBox = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="2.5"
        width="19"
        height="19"
        fill="white"
        stroke="#DDDDDD"
      />
      <path d="M2 11.3793L9.11905 19L25 2" stroke="#F67C36" strokeWidth="3" />
    </svg>
  );
};

export default checkBox;
