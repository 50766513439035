import { Button, Col, Input, Menu, Row } from "antd";
import React from "react";
import { SendEmail } from "../../footer/Footer";
import {
  EmailLogo,
  FacebookSvg,
  FooterCall,
  FooterEmail,
  FooterLocation,
  InstaGram,
  LinkedLn,
  SendMail,
  TwitterLogo,
} from "../../../assets/svgs";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HeaderWrap } from "./TopSearchSection";

const FooterSection = () => {
  return (
    <>
      <FooterMainCover>
        <HeaderWrap>
          <FooterWrap>
            <NewsLetterWrap>
              <Row justify="space-between" style={{ alignItems: "center" }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={14}>
                  <h2>Subscribe to our newsletter and stay updated</h2>
                </Col>
                <Col
                  className="footer__email"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={10}
                  xxl={10}
                >
                  <SendEmail>
                    <Input.Group compact>
                      <Input
                        prefix={<EmailLogo />}
                        placeholder="Enter Your Email"
                        style={{
                          width: "calc(100% - 52px)",
                          height: "50px",
                        }}
                        type="email"
                      />
                      <Button>
                        <SendMail />
                      </Button>
                    </Input.Group>
                  </SendEmail>
                </Col>
              </Row>
            </NewsLetterWrap>
            <NewsLetter>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                  {/* <Menu
                    // onClick={onClick}
                    mode="inline"
                    // items={items}
                    selectable={false}
                  /> */}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="avenew__bottom__logo"
                >
                  <Row style={{ alignItems: "center" }}>
                    <Col xs={12} sm={12} md={12} lg={24} xl={24} xxl={24}>
                      <img
                        src="/images/ave_logo_darkBg.png"
                        className="avenew__title"
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={24} xl={24} xxl={24}>
                      <div className="social__icon">
                        <h3>Follow us</h3>
                        <Row gutter={[16, 16]} className="icon__wrap">
                          <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                            <FacebookSvg wSize="34" hSize="34" />
                          </Col>
                          <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                            <TwitterLogo />
                          </Col>
                          <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                            <LinkedLn />
                          </Col>
                          <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                            <InstaGram />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <div>
                    <FooterRectWrap />
                    <h2>Quick Link</h2>
                    <p>
                      <Link to="/about-us">Feature</Link>
                    </p>
                    <p>
                      <Link to="/">Pricing</Link>
                    </p>
                    <p>
                      <Link to="/owners-contact-us">Contact Us</Link>
                    </p>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <div>
                    <FooterRectWrap />
                    <h2>Policy</h2>
                    <p>
                      <Link to="/">Privacy policy</Link>
                    </p>
                    <p>
                      <Link to="/">Terms and Condition</Link>
                    </p>
                    <p>
                      <Link to="/">Payment Policy</Link>
                    </p>
                    <p>
                      <Link to="/">Property Owner Policy</Link>
                    </p>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FooterRectWrap />
                  <h2>Get into Touch</h2>
                  <Row gutter={[0, 20]}>
                    <Col xs={2} sm={1} md={1} lg={4} xl={3} xxl={3}>
                      <p>
                        <FooterLocation color={"#9EA6BA"} />
                      </p>
                    </Col>
                    <Col span={20}>
                      <p>4517 Washington Ave. Manchester, Kentucky 39495</p>
                    </Col>
                  </Row>
                  <Row gutter={[0, 20]}>
                    <Col xs={2} sm={1} md={1} lg={4} xl={3} xxl={3}>
                      <p>
                        <FooterCall color={"#9EA6BA"} />
                      </p>
                    </Col>
                    <Col span={20}>
                      <p> +1 123 456 779</p>
                    </Col>
                  </Row>
                  <Row gutter={[0, 20]}>
                    <Col xs={2} sm={1} md={1} lg={4} xl={3} xxl={3}>
                      <p>
                        <FooterEmail color={"#9EA6BA"} />
                      </p>
                    </Col>
                    <Col span={20}>
                      <p>support@avenew.app</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </NewsLetter>
          </FooterWrap>
        </HeaderWrap>
      </FooterMainCover>
      <FooterBottom>
        <HeaderWrap>
          <FooterBottomWrap>
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col>
                <h4>© 2025 Avenew.com</h4>
              </Col>
              <Col>
                <h5>Site Map</h5>
              </Col>
            </Row>
          </FooterBottomWrap>
        </HeaderWrap>
      </FooterBottom>
    </>
  );
};

export default FooterSection;

const FooterMainCover = styled.div`
  width: 100%;
  background: #26314b;
`;

const FooterBottom = styled.div`
  width: 100%;
  background-color: #1d263c;
`;

const FooterWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;

  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }
`;

const FooterBottomWrap = styled.div`
  width: 1400px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
  }
  h5 {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
  }
  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }
`;

const FooterRectWrap = styled.div`
  width: 25px;
  height: 5px;
  margin-bottom: 10px;
  background-color: #4160ac;
`;

const NewsLetterWrap = styled.div`
  width: 100%;
  padding: 20px 75px;
  margin-bottom: 70px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);

  .footer__email {
    justify-content: end;
    display: flex;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 0;
  }

  @media (max-width: 1550px) {
    margin-bottom: 55px;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media (max-width: 1400px) {
    padding: 30px;
    margin-bottom: 30px;

    h2 {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }

  @media (max-width: 991px) {
    padding: 20px;
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }
  }
`;

const NewsLetter = styled.div`
  width: 100%;
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #8f97ac;
  }

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.05em;
    color: #2b353f;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    ${"" /* line-height: 24px; */}
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  svg {
    color: #9ea6ba;
    margin-top: 7px;
  }

  p {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    ${"" /* line-height: 40px; */}
    letter-spacing: 0.05em;
    color: #9ea6ba;
  }

  .social__icon {
    margin-top: 36px;
    margin-bottom: 38px;
    justify-content: space-between;
    cursor: pointer;
  }

  a {
    color: #9ea6ba;
  }

  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    letter-spacing: 0.05em !important;
    color: #848687 !important;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 25px !important;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #2b353f;
  }

  .ant-menu-item {
    ${"" /* height: 25px !important; */}
  }

  @media (max-width: 1440px) {
    padding: 0;
  }
  @media (max-width: 424px) {
    img {
      width: 100%;
    }
  }
  @media (max-width: 991.98px) {
    .avenew__bottom__logo {
      width: 100%;
      text-align: center;
    }
    .icon__wrap {
      justify-content: center;
    }
  }
`;
