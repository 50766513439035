import React, { useCallback, useState, Fragment, useEffect } from "react";
import HomeHeader from "../../components/layout/dashbord-new/HomeHeader";
import {
  MainContainer,
  SideBarLogin,
  SideMainWrap,
  SidebarContainer,
  SiderBarMobile,
  StyledButton,
} from "../../components/layout/dashbord-new/SearchPart";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  BackTop,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import styled from "styled-components";
import { TopTrendingSectionWrap } from "../../components/layout/newLandingPage/TopTrendingSection";
import { AvenewImg, FcApproval, SelectSvg } from "../../assets/svgs";
import { MapData } from "../category-of-property/mapData";
import GoogleMapReact from "google-map-react";
import GoogleMap from "google-map-react";
import MyMarker from "./MyMarker";
import { DeleteBtn } from "../Profile-verification/PriorRentalInformation";
import { Container } from "../../components/container/Container";
import {
  getApplicationProcess,
  logout,
  me,
  topUnits,
} from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  backToTopStyle,
  formattedNumber,
  toUSCurrency,
  upperCase,
} from "../../utils/fn";
import { ImgWrap } from "../../components/featuredProperties/Cards/UnitSection";
import data from "../../pages/Profile-verification/state.json";
import useDebounce from "../../utils/hooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../../components/footer/Footer";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";
import LoginModal from "../login/login-modal/LoginModal";
import ApplyingToProperty from "../../components/applyingToProperty/ApplyingToProperty";
import UnderVerificationModal from "../verification-process/sub-tabs/state-profile-tabs/UnderVerificationModal";
import { MdCancel, MdKeyboardArrowUp, MdOutlineCancel } from "react-icons/md";
import SideBarMobile from "../../components/layout/dashbord-new/SideBarMobile";

function PropertyListing() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verificationModal, setVerificationModal] = useState(false);
  const [modalStateApplyingProp, setModalStateApplyingProp] = useState(false);
  const { applicationProcessData, user } = useSelector((state) => state.user);
  const [openSideBar, setopenSider] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [allUnits, setAllUnits] = useState([]);
  const [hoveredId, setHoveredId] = useState("");
  const [loginModalState, setLoginModalState] = useState(false);
  const [unitDetails, setUnitDetails] = useState({});
  const [topUnitsDataLoading, setTopUnitsDataLoading] = useState(false);
  const [btnValue, setBtnValue] = useState("");
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 9,
    total: 0,
  });

  const fetchMoreData = () => {
    setPagination((p) => ({ ...p, pageNumber: p.pageNumber + 1 }));
    if (
      searchParams.get("cityState") ||
      searchParams.get("propertyType") ||
      searchParams.get("priceRange")
    ) {
      dispatch(
        topUnits({
          ...pagination,
          ...{ pageNumber: pagination.pageNumber + 1 },
          ...(searchParams.get("cityState")
            ? { cityState: searchParams.get("cityState") }
            : {}),
          ...(searchParams.get("propertyType")
            ? { propertyType: searchParams.get("propertyType") }
            : {}),
          ...(searchParams.get("priceRange")
            ? { priceRange: convertCurrency(searchParams.get("priceRange")) }
            : {}),
        })
      ).then(({ payload }) => {
        if (payload?.Data) {
          setAllUnits((val) => [...val, ...payload?.Data]);
          setPagination((p) => ({ ...p, total: payload?.total }));
        }
      });
    } else {
      dispatch(
        topUnits({ ...pagination, pageNumber: pagination.pageNumber + 1 })
      ).then(({ payload }) => {
        if (payload?.Data) {
          setAllUnits((val) => [...val, ...payload?.Data]);
          setPagination((p) => ({ ...p, total: payload?.total }));
        }
      });
    }
  };

  const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8,
  };

  // to auto scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const points = [
    // {
    //   id: 1,
    //   title: "Round Pond",
    //   lat: 33.836466,
    //   lng: -84.549665,
    //   img: "/images/card1.png",
    // },
    // {
    //   id: 2,
    //   title: "The Long Water",
    //   lat: 33.497196,
    //   lng: -84.32679,
    //   img: "/images/card2.png",
    // },
    // {
    //   id: 3,
    //   title: "The Serpentine",
    //   lat: 33.680933,
    //   lng: -84.653597,
    //   img: "/images/card3.png",
    // },
  ];

  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };

  const handleHeaderChange = (enable) => {
    setShowHeader(enable);
  };

  function convertCurrency(inputString) {
    return inputString.replace(/%24/g, "$");
  }

  // get all units data (result in card view)
  useEffect(() => {
    setTopUnitsDataLoading(true);
    if (
      searchParams.get("cityState") ||
      searchParams.get("propertyType") ||
      searchParams.get("priceRange")
    ) {
      form.setFieldsValue({
        cityState: searchParams.get("cityState"),
        propertyType: searchParams.get("propertyType"),
        priceRange: searchParams.get("priceRange"),
      });

      dispatch(
        topUnits({
          ...pagination,
          ...(searchParams.get("cityState")
            ? { cityState: searchParams.get("cityState") }
            : {}),
          ...(searchParams.get("propertyType")
            ? { propertyType: searchParams.get("propertyType") }
            : {}),
          ...(searchParams.get("priceRange")
            ? { priceRange: convertCurrency(searchParams.get("priceRange")) }
            : {}),
        })
      ).then(({ payload }) => {
        setTopUnitsDataLoading(false);
        if (payload?.Data) {
          setAllUnits(payload?.Data);
          setPagination((p) => ({ ...p, total: payload?.total }));
        }
      });
    } else {
      dispatch(topUnits(pagination)).then(({ payload }) => {
        setTopUnitsDataLoading(false);
        if (payload?.Data) {
          setAllUnits(payload?.Data);
          setPagination((p) => ({ ...p, total: payload?.total }));
        }
      });
    }
  }, []);

  const handleFormChange = (changedValues, allValues) => {
    setTopUnitsDataLoading(true);
    navigate({
      pathname: `/property-listing`,
      search: createSearchParams({
        ...(allValues?.cityState ? { cityState: allValues?.cityState } : {}),
        ...(allValues?.propertyType
          ? { propertyType: allValues?.propertyType }
          : {}),
        ...(allValues?.priceRange ? { priceRange: allValues?.priceRange } : {}),
      }).toString(),
    });

    setPagination({
      pageNumber: 1,
      pageSize: 9,
      total: 0,
    });

    dispatch(
      topUnits({
        ...pagination,
        ...{ pageNumber: 1 },
        ...(allValues.propertyName
          ? { propertyName: allValues.propertyName }
          : {}),
        ...(allValues.cityState ? { cityState: allValues.cityState } : {}),
        ...(allValues.propertyType
          ? { propertyType: allValues.propertyType }
          : {}),
        ...(allValues.priceRange ? { priceRange: allValues.priceRange } : {}),
      })
    ).then(({ payload }) => {
      setTopUnitsDataLoading(false);
      if (payload?.Data) {
        setAllUnits(payload?.Data);
        setPagination((p) => ({ ...p, total: payload?.total }));
      }
    });
  };

  const [citesValue, setCitesValue] = useState([]);
  const [searchCityValue, setSearchCityValue] = useState("");

  // debouncing the value to reflect latest result after a given interval of time
  const debouncedSearchValue = useDebounce(searchCityValue, 800);

  // debouncing the city value
  useEffect(() => {
    if (
      debouncedSearchValue?.length &&
      !citesValue.includes(debouncedSearchValue)
    ) {
      setCitesValue((val) => [...val, debouncedSearchValue]);
    }
  }, [searchCityValue, debouncedSearchValue]);

  function flatDeep(arr, d = 1) {
    if (!Array.isArray(arr)) {
      return arr;
    }
    return d > 0
      ? arr.reduce((acc, val) => acc.concat(flatDeep(val, d - 1)), [])
      : arr.slice();
  }

  useEffect(() => {
    const cityArr = Object.values(data);
    const value = flatDeep(cityArr, cityArr.length);
    let uniqueChars = [...new Set(value)];
    setCitesValue(uniqueChars);
  }, []);

  // filtering the array of city upon input search
  const filterFunction = (input) => {
    const filter = input.toUpperCase();
    var arr = [];

    for (let i = 0; i < citesValue.length; i++) {
      const txtValue = citesValue[i];
      if (txtValue.toUpperCase().startsWith(filter)) {
        arr.push(txtValue);
      } else {
      }
    }
    setCitesValue(arr);
  };

  // setting city value upon selecting
  const handleChange = (value) => {
    if (value?.length === 0) {
      const cityArr = Object.values(data);
      const value = flatDeep(cityArr, cityArr.length);
      let uniqueChars = [...new Set(value)];
      setCitesValue(uniqueChars);
    } else {
      filterFunction(value);
    }

    if (value.length > 3) {
      setSearchCityValue(upperCase(value.toLowerCase()));
    }
  };

  const siginFun = (value) => {
    setBtnValue(value);
    setLoginModalState(true);
  };

  const onApplyNow = (id) => {
    if (user) {
      localStorage.setItem("unit-details", id);
      if (applicationProcessData?.id) {
        if (
          applicationProcessData?.userProfile &&
          applicationProcessData?.stateProfile?.length &&
          applicationProcessData?.applicationPayment
          // &&
          // applicationProcessData?.is_verified
        ) {
          navigate(`/unit-application/${id}`);
        } else if (
          applicationProcessData?.userProfile &&
          applicationProcessData?.stateProfile?.length &&
          applicationProcessData?.applicationPayment
          // &&
          // !applicationProcessData?.is_verified
        ) {
          setVerificationModal(true);
        } else {
          setModalStateApplyingProp(true);
          const findUnit = allUnits?.filter((item) => {
            return item["id"] === id;
          });
          setUnitDetails(findUnit[0] || {});
        }
      } else {
        setModalStateApplyingProp(true);
      }
    } else {
      localStorage.setItem("unit-details", id);
      setLoginModalState(true);
    }
  };

  const onLogin = (payload) => {
    if (payload?.id) {
      if (
        payload?.userProfile &&
        payload?.stateProfile &&
        payload?.applicationPayment
      ) {
        navigate(`/unit-application/${localStorage.getItem("unit-details")}`);
      } else {
        navigate({
          pathname: `/property-details/${localStorage.getItem("unit-details")}`,
          search: createSearchParams({
            apply: localStorage.getItem("unit-details"),
          }).toString(),
        });
        // navigate(`/property-details/${localStorage.getItem("unit-details")}`);
      }
    } else {
      // navigate(`/property-details/${localStorage.getItem("unit-details")}`);
      navigate({
        pathname: `/property-details/${localStorage.getItem("unit-details")}`,
        search: createSearchParams({
          apply: localStorage.getItem("unit-details"),
        }).toString(),
      });
    }
  };

  // APIs calling for get user, applications, notifications
  useEffect(() => {
    if (localStorage.getItem("user_id") && !user?.id) {
      dispatch(me());
      dispatch(getApplicationProcess());
    }
  }, []);

  const slpitFunc = (value) => {
    if (value?.length > 35) {
      return value.slice(0, 35) + "...";
    } else {
      return value;
    }
  };

  const contentAppove = (
    <div>
      <H4Title>
        You prequalify for this listing based on the requirements set by the
        landlord.
      </H4Title>
    </div>
  );
  const contentCancel = (
    <div>
      <H4Title>
        You do not meet the requirements set by the landlord to qualify for this
        listing. You may still communicate with the building and attempt to
        apply.
      </H4Title>
    </div>
  );

  return (
    <>
      <MainContainer
        openSideBar={openSideBar}
        className="sticky-header-sidebar"
        // paddingBottom="50px"
        containterHeight="auto"
        paddingbotom="20px"
      >
        <SideBarMobile onApplyNow={siginFun} />
        <HomeHeader
          headermargin="40px auto 30px"
          headermarginsm="40px auto 30px"
        />
        <TopTrendingSectionWrap
          paddingcomponent="0"
          margincomponentxs="0 auto 0"
          margincomponentsm="0 auto 0"
        >
          <Row style={{ alignItems: "center" }}>
            <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
              <FormWrap>
                <Form
                  layout="vertical"
                  form={form}
                  onValuesChange={handleFormChange}
                  // onFinish={onFinish}
                  // validateMessages={validateMessages}
                >
                  <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                      <Form.Item label="Location" name="cityState">
                        {/* <Input placeholder="Type here city or state" /> */}
                        <Select
                          placeholder="Type here city or state"
                          suffixIcon={<SelectSvg />}
                          showSearch
                          onSearch={handleChange}
                          allowClear
                        >
                          {citesValue
                            ? citesValue.sort().map((cites, id) => (
                                <Option key={id} value={cites}>
                                  {cites}
                                </Option>
                              ))
                            : ""}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                      <Form.Item
                        wh="100"
                        name="propertyType"
                        label="Property Type"
                      >
                        <Select
                          placeholder="Select any property type"
                          suffixIcon={<SelectSvg />}
                          allowClear
                        >
                          <Option value="Single-family home">
                            Single-family home
                          </Option>
                          <Option value="Condominium (Condo)">
                            Condominium (Condo)
                          </Option>
                          <Option value="Townhouse">Townhouse</Option>
                          <Option value="Apartment">Apartment</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                      <Form.Item name="priceRange" label="Price Range">
                        <Select
                          placeholder="Choose any price range"
                          suffixIcon={<SelectSvg />}
                          allowClear
                        >
                          <Option value="0-499">$0-$499</Option>
                          <Option value="500-999">$500-$999</Option>
                          <Option value="1000-1999">$1000-$1999</Option>
                          <Option value="2000-2999">$2000-$2999</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                      <Form.Item name="propertyName" label="Property Name">
                        <Input
                          className="custom-input"
                          placeholder="Search by name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </FormWrap>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <FilterWrap>
                <Row gutter={[30, 30]} justify="end">
                  {/* <Col>
                    <SMbtn>
                      <MsgFilterSvg fill="#2B353F" />
                      <p>Filter</p>
                    </SMbtn>
                  </Col> */}
                  <Col>
                    <SMbtn>
                      <Switch
                        checked={showHeader}
                        onChange={handleHeaderChange}
                      />
                      <p>Show Map</p>
                    </SMbtn>
                  </Col>
                </Row>
              </FilterWrap>
            </Col>
          </Row>
        </TopTrendingSectionWrap>
      </MainContainer>
      <PropertyListingWrap>
        {showHeader ? (
          <Row>
            <Col
              style={{ minHeight: "100vh" }}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
            >
              <MapWrap style={{ height: "100vh", width: "100%" }}>
                <GoogleMap
                  bootstrapURLKeys={{
                    key: "AIzaSyBgMshusM-bvKyKgOluOHFUeHUaQhvky5o",
                    language: "en",
                    region: "US",
                  }}
                  defaultCenter={{ lat: 33.836466, lng: -84.549665 }}
                  defaultZoom={10}
                  distanceToMouse={distanceToMouse}
                >
                  {points.map(({ lat, lng, id, title, img }) => {
                    return (
                      <MyMarker
                        key={id}
                        lat={lat}
                        lng={lng}
                        text={id}
                        tooltip={title}
                        img={img}
                      />
                    );
                  })}
                </GoogleMap>
              </MapWrap>
            </Col>
            <Col
              id="scrollableDiv"
              style={{ overflowY: "scroll", maxHeight: "100vh" }}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Row>
                <Col span={24}>
                  <p className="show__properties">
                    Showing{" "}
                    <b className="bolt__font">{pagination?.total} Properties</b>{" "}
                    in search result
                  </p>
                </Col>
              </Row>

              <InfiniteScroll
                dataLength={allUnits?.length}
                next={fetchMoreData}
                hasMore={
                  pagination.total > pagination.pageNumber * pagination.pageSize
                }
                loader={
                  <Container>
                    <div
                      style={{
                        position: "relative",
                        minHeight: "calc(100vh - 800px)",
                      }}
                    >
                      <Spin className="ant__spin" size="large" />
                    </div>
                  </Container>
                }
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "#747487",
                    }}
                  >
                    <span>
                      {allUnits?.length > 0
                        ? "Yay! You have all caught up!"
                        : "There is no Unit."}
                    </span>
                  </p>
                }
              >
                {topUnitsDataLoading ? (
                  <Container>
                    <div
                      style={{
                        position: "relative",
                        minHeight: "calc(100vh - 300px)",
                      }}
                    >
                      <Spin className="ant__spin" size="large" />
                    </div>
                  </Container>
                ) : (
                  <Row>
                    {allUnits?.map(
                      (
                        {
                          id,
                          unit_name,
                          price,
                          bed,
                          bath,
                          sqft,
                          images,
                          address,
                          min_credit_score,
                          city,
                          state,
                          zipcode,
                        },
                        _id
                      ) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={8}
                          key={_id}
                        >
                          <UnitWrap
                            onClick={() => {
                              navigate(`/property-details/${id}`);
                            }}
                            // onMouseOver={(e) => {
                            //   setHoveredId(id);
                            // }}
                            // onMouseOut={(e) => {
                            //   setHoveredId("");
                            // }}
                          >
                            <ImgWrap>
                              {images && images?.length ? (
                                <>
                                  {images.map((img, idx) => (
                                    <div key={idx}>
                                      {img.isCover === true ? (
                                        <img
                                          style={{
                                            height: "160px",
                                          }}
                                          alt="example"
                                          src={img.path}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <AvenewImg
                                  className="svg__border"
                                  style={{
                                    background: "#eeeeee",
                                    height: "160px",
                                  }}
                                />
                              )}
                            </ImgWrap>
                            <Row style={{ marginTop: "15px" }}>
                              <Col span={18}>
                                <h2>{unit_name}</h2>
                              </Col>
                              <Col span={6}>
                                <h3>{toUSCurrency(price)}</h3>
                              </Col>
                              <Col span={24}>
                                {address ? <h4>{address}</h4> : <h4> {"-"}</h4>}
                                {city || state || zipcode ? (
                                  <h4
                                    style={{
                                      marginBottom: "15px",
                                    }}
                                  >
                                    {city ? `${city},` : ""}{" "}
                                    {state ? state : ""}{" "}
                                    {zipcode ? zipcode : ""}
                                  </h4>
                                ) : (
                                  <h4
                                    style={{
                                      marginBottom: "15px",
                                    }}
                                  >
                                    -
                                  </h4>
                                )}
                              </Col>
                              <Col span={24}>
                                <Row
                                  gutter={[2, 2]}
                                  style={{ alignItems: "center" }}
                                >
                                  {user &&
                                  min_credit_score &&
                                  applicationProcessData?.credit_score_data
                                    ?.length ? (
                                    <Col span={3}>
                                      {Number(min_credit_score) <
                                      Number(
                                        applicationProcessData
                                          ?.credit_score_data[0].score
                                      ) ? (
                                        <Popover
                                          placement="left"
                                          content={contentAppove}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <FcApproval />
                                          </div>
                                        </Popover>
                                      ) : (
                                        <Popover
                                          placement="left"
                                          content={contentCancel}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <MdCancel className="cancel__score" />
                                          </div>
                                        </Popover>
                                      )}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  <Col
                                    span={
                                      min_credit_score &&
                                      applicationProcessData?.credit_score_data
                                        ?.length
                                        ? 21
                                        : 24
                                    }
                                  >
                                    <Row justify="space-between">
                                      <Col>
                                        <DeleteBtn
                                          style={{ cursor: "default" }}
                                          widthBtn="fit-content"
                                          paddingBtn="5px 10px"
                                        >
                                          <h5>Bed : {bed}</h5>
                                        </DeleteBtn>
                                      </Col>
                                      <Col>
                                        <DeleteBtn
                                          style={{ cursor: "default" }}
                                          widthBtn="fit-content"
                                          paddingBtn="5px 10px"
                                        >
                                          <h5>Bath : {bath}</h5>
                                        </DeleteBtn>
                                      </Col>
                                      <Col>
                                        <DeleteBtn
                                          style={{ cursor: "default" }}
                                          widthBtn="fit-content"
                                          paddingBtn="5px 10px"
                                        >
                                          <h5>
                                            Sq ft : {formattedNumber(sqft)}
                                          </h5>
                                        </DeleteBtn>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </UnitWrap>
                          {/* {hoveredId === id ? (
                            <HoverComponent
                              onMouseOver={(e) => {
                                setHoveredId(id);
                              }}
                              onMouseOut={(e) => {
                                setHoveredId("");
                              }}
                            >
                              <ApplyButton onClick={() => onApplyNow(id)}>
                                {user ? " Apply" : "Login To Apply"}
                              </ApplyButton>
                              <ApplyButton
                                onClick={() => {
                                  navigate(`/property-details/${id}`);
                                }}
                              >
                                Details
                              </ApplyButton>
                            </HoverComponent>
                          ) : (
                            ""
                          )} */}
                        </Col>
                      )
                    )}
                  </Row>
                )}
              </InfiniteScroll>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Row>
                <Col span={24}>
                  <p className="show__properties">
                    Showing{" "}
                    <b className="bolt__font">{pagination?.total} Properties</b>{" "}
                    in search result
                  </p>
                </Col>
              </Row>
              <InfiniteScroll
                dataLength={allUnits?.length}
                next={fetchMoreData}
                hasMore={
                  pagination.total > pagination.pageNumber * pagination.pageSize
                }
                loader={
                  <Container>
                    <div
                      style={{
                        position: "relative",
                        minHeight: "calc(100vh - 800px)",
                      }}
                    >
                      <Spin className="ant__spin" size="large" />
                    </div>
                  </Container>
                }
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "#747487",
                    }}
                  >
                    <span>
                      {allUnits?.length > 0
                        ? "Yay! You have all caught up!"
                        : "There is no Unit."}
                    </span>
                  </p>
                }
              >
                {false ? (
                  <Container>
                    <div
                      style={{
                        position: "relative",
                        minHeight: "calc(100vh - 500px)",
                      }}
                    >
                      <Spin className="ant__spin" size="large" />
                    </div>
                  </Container>
                ) : (
                  <Row>
                    {allUnits?.map(
                      ({
                        id,
                        unit_name,
                        price,
                        bed,
                        bath,
                        sqft,
                        images,
                        address,
                      }) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          xl={6}
                          xxl={6}
                          key={id}
                        >
                          <UnitWrap
                            onClick={() => {
                              navigate(`/property-details/${id}`);
                            }}
                          >
                            <ImgWrap>
                              {images && images?.length ? (
                                <>
                                  {images.map((img, idx) => (
                                    <div key={idx}>
                                      {img.isCover === true ? (
                                        <img
                                          style={{
                                            height: "160px",
                                          }}
                                          alt="example"
                                          src={img.path}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <AvenewImg
                                  className="svg__border"
                                  style={{
                                    background: "#eeeeee",
                                    height: "160px",
                                  }}
                                />
                              )}
                            </ImgWrap>
                            <Row style={{ marginTop: "15px" }}>
                              <Col span={12}>
                                <h2>{unit_name}</h2>
                              </Col>
                              <Col span={12}>
                                <h3>{toUSCurrency(price)}</h3>
                              </Col>
                              <Col span={24}>
                                {address ? <h4>{address}</h4> : <h4> {"-"}</h4>}
                              </Col>
                              <Col span={24}>
                                <Row justify="space-between">
                                  <Col>
                                    <DeleteBtn
                                      style={{ cursor: "default" }}
                                      widthBtn="fit-content"
                                      paddingBtn="5px 10px"
                                    >
                                      <h5>Bed : {bed}</h5>
                                    </DeleteBtn>
                                  </Col>
                                  <Col>
                                    <DeleteBtn
                                      style={{ cursor: "default" }}
                                      widthBtn="fit-content"
                                      paddingBtn="5px 10px"
                                    >
                                      <h5>Bath : {bath}</h5>
                                    </DeleteBtn>
                                  </Col>
                                  <Col>
                                    <DeleteBtn
                                      style={{ cursor: "default" }}
                                      widthBtn="fit-content"
                                      paddingBtn="5px 10px"
                                    >
                                      <h5>Sq ft : {formattedNumber(sqft)}</h5>
                                    </DeleteBtn>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </UnitWrap>
                        </Col>
                      )
                    )}
                  </Row>
                )}
              </InfiniteScroll>
            </Col>
          </Row>
        )}
      </PropertyListingWrap>
      <FooterSectionHome />
      <ApplyingToProperty
        unitDetails={unitDetails}
        onCancel={(value) => setModalStateApplyingProp(value)}
        modalState={modalStateApplyingProp}
      />

      <LoginModal
        btnValue={btnValue}
        onLogin={onLogin}
        modalState={loginModalState}
        onCancel={(value) => setLoginModalState(value)}
      />

      <UnderVerificationModal
        verificationModal={verificationModal}
        onCancel={(value) => setVerificationModal(value)}
      />

      <BackTop>
        <div style={backToTopStyle}>
          <MdKeyboardArrowUp />
        </div>
      </BackTop>
    </>
  );
}

export default PropertyListing;

const ApplyButton = styled(Button)`
  height: 40px;
  font-size: 16px;
  color: #ffffff;
  width: ${({ btnwidth }) => btnwidth || "auto"};
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;
  border-radius: 5px;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1400px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 991.98px) {
    width: ${({ btnwidthsm }) => btnwidthsm || "auto"};
  }
  @media screen and (max-width: 769px) {
    padding: ${({ paddingbtn }) => paddingbtn || "4px 20px"};
  }
`;

const HoverComponent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  padding: 15px;
  margin: 5px;
  background: rgba(0, 0, 0, 0.75);
  width: 97%;
  text-align: center;
  height: -webkit-fill-available;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  border-radius: 5px;

  @media (max-width: 991.98px) {
    margin: 5px 10px;
  }
`;

const UnitWrap = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
  margin: 5px;
  position: relative;

  .cancel__score {
    font-size: 25px;
    color: #c6cace;
  }

  h5 {
    align-items: center;
    display: flex;
    color: #848687;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.65px;
  }

  .card__1 {
    width: 100%;
    height: 210px;
    border-radius: 5px;
  }
  .card__2 {
    width: 100%;
    height: 360px;
    border-radius: 5px;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #f67c36;
    margin-bottom: 0;
  }

  h3 {
    color: #2b353f;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
    justify-content: end;
    display: flex;
  }

  h4 {
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }

  @media (max-width: 991.98px) {
    margin: 5px 10px;
  }
`;

const H4Title = styled.h4`
  color: #777;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  max-width: 250px;

  .cancel__score {
    font-size: 25px;
    color: #c6cace;
  }
`;

const MapWrap = styled.div`
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover {
    display: none;
  }
`;

const PropertyListingWrap = styled.div`
  ${"" /* height: 100vh; */}

  div::-webkit-scrollbar {
    display: none;
  }

  margin-bottom: 50px;

  .bolt__font {
    color: #2b353f;
    font-weight: 700;
  }

  .show__properties {
    color: #777;
    font-size: 14px;
    margin-bottom: 0;
    padding: 30px 10px;
  }
`;

const FilterWrap = styled.div`
  padding-top: 20px;
`;

const SMbtn = styled.div`
  display: flex;
  gap: 12px;
  cursor: pointer;

  .ant-switch-checked {
    background: #4160ac;
  }

  p {
    color: #2b353f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
`;

const FormWrap = styled.div`
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    box-shadow: none !important;
  }

  .input__affix input {
    border-left: none !important;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background: #fff;
  }

  .input__affix
    .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #fff;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #fff;
  }

  .input__affix .ant-input-affix-wrapper-status-error .ant-input-prefix {
    color: #000000;
  }

  .ant-picker,
  input {
    width: 100%;
    height: 50px !important;
    background: #eee;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }

  .ant-form-item {
    .ant-form-item-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector,
    .ant-picker {
      width: 100%;
      height: 50px;
      background: #fff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }

  .description__box {
    padding: 14px 20px;
  }

  @media (max-width: 1550px) {
    .ant-form-item {
      .ant-select-selector,
      .ant-picker,
      input {
        height: 40px;
        background: #fff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        align-items: center;
        @media screen and (max-width: 595px) {
          width: 100%;
        }
      }
      .ant-select-selection-placeholder {
        color: #aaaaaa;
      }
    }

    .description__box {
      padding: 14px 20px;
    }
  }

  @media screen and (max-width: 991.98px) {
    .ant-form .ant-form-item {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 624px) {
    flex-direction: ${({ fd }) => fd || "column"};
    align-items: center;
  }

  input {
    width: 100%;
    height: 50px;
    height: ${({ inputHeight }) => inputHeight || "50px"};

    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
  }

  .ant-form-item-label {
    color: #848687;
    font-size: 14px;
  }

  .ant-input::placeholder {
    color: #aaaaaa;
    font-size: 16px;
  }
`;
