import React from "react";
import styled from "styled-components";

const TopSection = () => {
  return;
  <TopSectionWrap></TopSectionWrap>;
};

export default TopSection;

export const TopSectionWrap = styled.div`
  max-width: 100%;
  marging: 0px 260px;
`;
