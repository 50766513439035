import React from "react";
import { MainContainer } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";
import styled from "styled-components";
import { H2Style } from "./MultiApplicationPart";
import { CheckBox, HalfCircle, OrangeLineSvg } from "../../../assets/svgs";
import { ImageWrpa } from "./WelcomeToAvenew";

function FindQualify() {
  const checkBoxText = [
    "Rental History",
    "Background and Criminal Check",
    "Credit History",
    "Income Requirements and Employment Verification",
    "Pet Restrictions",
  ];

  return (
    <MainContainer bgcolor="#ffffff" containterHeight="auto">
      <TopTrendingSectionWrap margincomponent="0 auto 100px">
        <BottomSection>
          <Row justify="space-between" className="find__out">
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
              <Row>
                <Col span={24}>
                  <Rectangle className="divider__long" />
                </Col>
              </Row>
              <H2Style
                lineheightxs="32px"
                lineHeight="50px"
                marginBottom="30px"
                marginTop="20px"
                paddingtopxs="10px"
                paddingBottomxs="20px"
              >
                Find out where you will qualify based on the following
              </H2Style>
              <Row gutter={[0, 15]} style={{ alignItems: "center" }}>
                {checkBoxText?.map((item, idx) => (
                  <Col span={24} id={idx}>
                    <Row gutter={[20, 20]}>
                      <Col xs={3} sm={3} md={3} lg={2} xl={2} xxl={2}>
                        <CheckBox />
                      </Col>
                      <Col xs={21} sm={21} md={21} lg={22} xl={22} xxl={22}>
                        <p>{item}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
              <ImageWrpa>
                <img className="main__img" src="/images/find-check.png" />
              </ImageWrpa>
            </Col>
          </Row>
        </BottomSection>
        <div className="halfCircle__svg">
          <HalfCircle />
        </div>
        <div className="orangeline__svg">
          <OrangeLineSvg />
        </div>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default FindQualify;

const BottomSection = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;

  p {
    color: #2b353f;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
  }

  .divider__long {
    margin-top: 100px;
  }

  @media (max-width: 1400px) {
    .divider__long {
      margin-top: 50px;
    }
  }
  @media (max-width: 991.98px) {
    margin-bottom: 40px;
    p {
      font-size: 16px;
      font-weight: 400;
    }

    .divider__long {
      margin-top: 50px;
    }

    .find__out {
      flex-direction: column-reverse;
    }
  }
`;
