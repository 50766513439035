import { Col, Form, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ImagePreview } from "../../../components/ImagePreview";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
  UploadBox,
  UploadInput,
} from "../../Profile-verification/BackgroundCheck";
import { FormWrap, GeneralProfileWrap } from "./GeneralProfile";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { applicationProcess } from "../../../store/reducers/user.reducer";

function IdProof({ onMainTabNext, onBackClickTabs, checkFillDataFunc }) {
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);

  const [form] = Form.useForm();
  const { Option } = Select;
  const drop = React.useRef(null);
  const uploadFront = React.useRef(null);
  const uploadBack = React.useRef(null);
  const [state, setState] = useState([]);
  const [stateFront, setStateFront] = useState([]);
  const [stateBack, setStateBack] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [idTypeValue, setIdTypeValue] = useState("Driving License");
  const [valueChanged, setValueChanged] = useState(false);
  const [newUploadedData, setNewUploadedData] = useState(
    applicationProcessData
  );

  useEffect(() => {
    setNewUploadedData(applicationProcessData);
  }, [applicationProcessData]);

  const onFinish = (value) => {
    if (valueChanged) {
      let data = {};
      if (sessionStorage.getItem("applyingProperty")) {
        const unitDetails = JSON.parse(
          sessionStorage.getItem("applyingProperty")
        );
        data.unit_id = unitDetails?.id;
      } else if (applicationProcessData?.unit_id) {
        data.unit_id = applicationProcessData?.unit_id;
      }
      data.idProof = value;
      setIsSubmit(true);
      dispatch(applicationProcess(data)).then(({ payload }) => {
        checkFillDataFunc(payload);
        setNewUploadedData(payload);
        onMainTabNext("banking-profile");
        setIsSubmit(false);
      });
    } else {
      onMainTabNext("banking-profile");
    }
    setValueChanged(false);
  };

  useEffect(() => {
    if (newUploadedData && newUploadedData?.id) {
      if (
        newUploadedData?.userProfile &&
        newUploadedData?.userProfile?.idProof?.IDtype
      ) {
        setStateFront([newUploadedData?.userProfile?.idProof?.frontProof]);
        setStateBack([newUploadedData?.userProfile?.idProof?.backProof]);
        setIdTypeValue(newUploadedData?.userProfile?.idProof?.IDtype);
        form.setFieldsValue({
          IDtype: newUploadedData?.userProfile?.idProof?.IDtype,
          frontProof: newUploadedData?.userProfile?.idProof?.frontProof,
          backProof: newUploadedData?.userProfile?.idProof?.backProof,
        });
      }
    }
  }, [newUploadedData]);

  const imagePath = (path, isStatic = false) => {
    if (isStatic) return path;
    return process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}${path}`
      : path;
  };

  const fileChangeHandlerFront = async (event) => {
    // console.log(event.target.files);

    event.preventDefault();
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setStateFront((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          let fileExtension = [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "jfif",
            "tiff",
          ];
          let filename = file.name;
          let fileExt = filename
            .substring(filename.lastIndexOf(".") + 1)
            .toLowerCase();
          if (fileExtension.includes(fileExt)) {
            return await newFun(file);
          } else {
            return message.warning("Please upload Image file");
          }
        })
      ));
  };
  const fileChangeHandlerBack = async (event) => {
    event.preventDefault();
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setStateBack((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          let fileExtension = [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "jfif",
            "tiff",
          ];
          let filename = file.name;
          let fileExt = filename
            .substring(filename.lastIndexOf(".") + 1)
            .toLowerCase();
          if (fileExtension.includes(fileExt)) {
            return await newFun(file);
          } else {
            return message.warning("Please upload Image file");
          }
        })
      ));
  };

  const fileChangeHandlerDrag = (files) => {
    // console.log(files, "files");
    setState((s) => [
      ...s,
      ...Array.from(files).map((file) => ({
        image: file,
        path: URL.createObjectURL(file),
      })),
    ]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    form.setFieldsValue({
      frontProof: stateFront[0],
      backProof: stateBack[0],
      IDtype: idTypeValue,
      // identification: "Driving License",
    });
  }, [form, stateBack, stateFront]);

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
  }, []);

  const selectIDtype = (val) => {
    setIdTypeValue(val);
    if (val === newUploadedData?.userProfile?.idProof?.IDtype) {
      if (newUploadedData && newUploadedData?.id) {
        if (
          newUploadedData?.userProfile &&
          newUploadedData?.userProfile?.idProof?.IDtype
        ) {
          setStateFront([newUploadedData?.userProfile?.idProof?.frontProof]);
          setStateBack([newUploadedData?.userProfile?.idProof?.backProof]);
          form.setFieldsValue({
            IDtype: newUploadedData?.userProfile?.idProof?.IDtype,
            frontProof: newUploadedData?.userProfile?.idProof?.frontProof,
            backProof: newUploadedData?.userProfile?.idProof?.backProof,
          });
        }
      }
    } else {
      setStateFront([]);
      setStateBack([]);
      const uploadFrontValue = document.getElementById("frontImg");
      uploadFrontValue.value = null;
      const uploadBackValue = document.getElementById("backImg");
      uploadBackValue.value = null;
      form.setFieldsValue({
        IDtype: val,
      });
    }
  };

  const deleteHandlerFront = () => {
    const uploadFrontValue = document.getElementById("frontImg");
    uploadFrontValue.value = null;
  };

  const deleteHandlerBack = () => {
    const uploadBackValue = document.getElementById("backImg");
    uploadBackValue.value = null;
  };

  return (
    <GeneralProfileWrap display={"none"}>
      <h1>ID Proof</h1>

      <FormWrap>
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item name="IDtype" label="ID type">
                  <Select
                    placeholder="ID type"
                    suffixIcon={<IoIosArrowDown />}
                    onChange={(val) => {
                      selectIDtype(val);
                    }}
                    options={[
                      {
                        value: "Driving License",
                        label: "Driving License",
                      },
                      {
                        value: "State ID",
                        label: "State ID",
                      },
                      // {
                      //   value: "Health Card",
                      //   label: "Health Card",
                      // },
                    ]}
                  />
                  {/* <Option value="Driving License">Driving License</Option>
                    <Option value="Passport">Passport</Option>
                    <Option value="Health Card">Health Card</Option>
                  </Select> */}
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="frontProof"
                  label="Front"
                  rules={[
                    {
                      required: true,
                      message: "Identification Picture is required.",
                    },
                  ]}
                >
                  <UploadBoxLen isPhoto={stateFront.length}>
                    <UploadBox
                      height="100px"
                      heightSm="80px"
                      mt="0"
                      ref={drop}
                      onClick={(e) => {
                        uploadFront.current.click();
                        setValueChanged(true);
                      }}
                    >
                      <UploadInput background="#ffffff">
                        <p className="ant-upload-drag-icon">
                          <AiOutlineUpload className="upload__icon" />
                        </p>
                        <p
                          className="ant-upload-text"
                          style={{ fontSize: "16px" }}
                        >
                          Upload
                        </p>
                      </UploadInput>
                    </UploadBox>
                  </UploadBoxLen>

                  <input
                    accept="image/*"
                    id="frontImg"
                    multiple={false}
                    type="file"
                    ref={uploadFront}
                    style={{ display: "none" }}
                    onChange={fileChangeHandlerFront}
                  />
                  <ShowUplaodImg isPhoto={stateFront?.length}>
                    {stateFront?.map((img, idx) => (
                      <ImagePreview
                        isCoverDisProp={false}
                        key={idx}
                        src={img.path ? img.path : ""}
                        height={"auto"}
                        width={"auto"}
                        onDelete={() => {
                          const st = [...stateFront];
                          st.splice(idx, 1);
                          setStateFront(st);
                          deleteHandlerFront();
                          // upload.current.value = null;
                        }}
                      />
                    ))}
                  </ShowUplaodImg>
                </Form.Item>
                <Form.Item
                  name="backProof"
                  label="Back"
                  rules={[
                    {
                      required: true,
                      message: "Identification Picture is required.",
                    },
                  ]}
                >
                  <UploadBoxLen isPhoto={stateBack.length}>
                    <UploadBox
                      height="100px"
                      heightSm="80px"
                      mt="0"
                      ref={drop}
                      onClick={(e) => {
                        uploadBack.current.click();
                        setValueChanged(true);
                      }}
                    >
                      <UploadInput background="#ffffff">
                        <p className="ant-upload-drag-icon">
                          <AiOutlineUpload className="upload__icon" />
                        </p>
                        <p
                          className="ant-upload-text"
                          style={{ fontSize: "16px" }}
                        >
                          Upload
                        </p>
                      </UploadInput>
                    </UploadBox>
                  </UploadBoxLen>

                  <input
                    accept="image/*"
                    id="backImg"
                    multiple={false}
                    type="file"
                    ref={uploadBack}
                    style={{ display: "none" }}
                    onChange={fileChangeHandlerBack}
                  />

                  <ShowUplaodImg isPhoto={stateBack.length}>
                    {stateBack?.map((img, idx) => (
                      <ImagePreview
                        isCoverDisProp={false}
                        key={idx}
                        src={img.path ? img.path : ""}
                        height={"auto"}
                        width={"auto"}
                        onDelete={() => {
                          const st = [...stateBack];
                          st.splice(idx, 1);
                          setStateBack(st);
                          deleteHandlerBack();
                          // upload.current.value = null;
                        }}
                      />
                    ))}
                  </ShowUplaodImg>
                </Form.Item>
              </StyledFormItem>
              <Form.Item>
                <Row justify="space-between" style={{ alignItems: "center" }}>
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("id-proof")}
                    />
                    <h2 onClick={() => onBackClickTabs("id-proof")}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      {/* <Col>
                        <p className="cancel__btn">CANCEL</p>
                      </Col> */}
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default IdProof;

export const ShowUplaodImg = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  width: auto;
  height: auto;
  border: 1px dashed #777777;

  img {
    width: 100%;
  }

  @media (max-width: 769px) {
    width: auto;
    height: auto;
  }
`;
export const UploadBoxLen = styled.div`
  display: ${({ isPhoto }) => (isPhoto ? "none" : "flex")};
`;
