import React from "react";
import { MainContainer } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";
import { H2Style } from "./MultiApplicationPart";
import styled from "styled-components";
import { CircleWrap } from "../../../assets/svgs";

function ArtHomebuying() {
  return (
    <MainContainer bgcolor="#ffffff" containterHeight="auto">
      <TopTrendingSectionWrap margincomponent="0 auto 100px">
        <Row>
          <Col span={24}>
            <Rectangle />
          </Col>
        </Row>
        <Row>
          <Col>
            <H2Style
              marginBottomSm="30px"
              marginTopSm="10px"
              marginBottom="100px"
              lineheightxs="32px"
              paddingBottomxs="30px"
            >
              The Art of Homebuying Simplified <br />
              Our App Guides You Through Each Step
            </H2Style>
          </Col>
        </Row>
        <ArtHomebuyingWrap>
          <Row gutter={[30, 30]} justify="space-between">
            <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={7}>
              <img src="/images/circle1.png" />
              <h2>Complete a Single Application</h2>
              <p>
                Fill out a comprehensive application capturing all necessary
                information about your rental preferences, employment details,
                and background, in one simple form.
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={7}>
              <img src="/images/circle2.png" />
              <h2>Qualification and Matching Process</h2>
              <p>
                Our advanced system analyzes your qualifications and matches
                them with the specific criteria set by property owners,
                instantly providing you with a curated list of properties you
                are eligible for based on your preferences.
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={7}>
              <img src="/images/circle3.png" />
              <h2>Explore and Choose Your Ideal Home</h2>
              <p>
                Review the suggested properties, which include detailed
                information about amenities, locations, and rental terms.
                Compare options and select the ones that best align with your
                needs, giving you the freedom to choose your ideal home.
              </p>
            </Col>
          </Row>
          {/* <CircleWrap /> */}
        </ArtHomebuyingWrap>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default ArtHomebuying;

const ArtHomebuyingWrap = styled.div`
  width: 100%;
  position: relative;

  p {
    color: #848687;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
  }

  h2 {
    color: #2b353f;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  img {
    margin-bottom: 40px;
  }

  @media (max-width: 1400px) {
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }

    img {
      display: flex;
      margin: 0 auto 20px;
    }
    .ant-col {
      text-align: left;
    }
  }
`;
