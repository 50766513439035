import {
  BackTop,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Menu,
  message,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import {
  AvenewImg,
  FooterCall,
  FooterLocation,
  MsgSvg,
  NoRental,
} from "../../assets/svgs";
import { Container } from "../../components/container/Container";
import Footer from "../../components/footer/Footer";
import { getInvoicesUnit, me } from "../../store/reducers/user.reducer";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import { NoInvoiceWrap } from "../unit-invoices/UnitInvoice";
import { backToTopStyle, toUSCurrency } from "../../utils/fn";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function MyRentals() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { loading } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // page navigation bar
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/my-rentals",
      label: "My Rentals",
      isLink: false,
    },
  ];
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const [tenantsData, setTenantsData] = useState([]);

  // getting data from redux store.
  // filter data from add invitaion (needed only invitaionRequest === "approved")
  useEffect(() => {
    if (user) {
      const val = user?.invitations.filter((item) => {
        return item.invitaionRequest === "approved";
      });

      setTenantsData(val);
    }
  }, [user]);

  function getItem(label, key, children, type) {
    return {
      key,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Quick Links", "sub1", [
      getItem(<Link to="/account">My Account</Link>),
      getItem(<Link to="/privacy-policy">Privacy Policy</Link>),
      getItem(<Link to="/terms-conditions">Terms and Conditions</Link>),
      getItem(<Link to="/about-us">About us</Link>),
      getItem("Career"),
      getItem("Sitemap"),
    ]),
  ];

  const [modalState, setModalState] = useState(false);
  const [isloading, setIsloading] = useState(true);

  const onCancel = () => {
    setModalState(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 700);
    // if (localStorage.getItem("user_id")) {
    //   setIsloading(true);
    //   console.log("called");
    //   dispatch(me()).then((res) => {
    //     console.log("first");
    //     setTimeout(() => {
    //       setIsloading(false);
    //     }, 700);
    //   });
    // }
  }, []);

  const [svgIwdth, setsvgIwdth] = useState(500);

  // svg size change with responsive screen
  useEffect(() => {
    if (width < 991.98) {
      setsvgIwdth(300);
    } else {
      setsvgIwdth(500);
    }
  }, [width, location.pathname]);

  // For creating new chat with admin
  const chatCreate = (value) => {
    if (value.unit_id) {
      navigate({
        pathname: `/message`,
        search: createSearchParams({
          id: value.unit_id,
        }).toString(),
      });
    } else {
      message.info("Tenant hasn't responded to your invitation yet.");
    }
  };

  return (
    <>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>
      {isloading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <Container paddings="0">
          <CardWrap>
            {/* <Row gutter={[30, 30]}>
              {tenantsData.map((item, id) => (
                <Col key={id} xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <CardContaint>
                    <img src="/images/rentalsImg.png" />
                    <CardDiscriptionWrap>
                      <div className="rating__star">
                        <StarRatings
                          rating={3}
                          starRatedColor="#F67C36"
                          starDimension="17px"
                          starSpacing="5px"
                          numberOfStars={5}
                          name="rating"
                        />
                      </div>
                      <p className="card__title">{item.apartment_name}</p>
                      <Divider />
                      <Row>
                        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                          <p className="unit__title">{item.unit_name}</p>
                          <h3>
                            <FooterLocation /> &nbsp; &nbsp;{item.address}
                          </h3>
                          <h3 className="contact__number">
                            <FooterCall /> &nbsp; &nbsp;{item.phoneNumber}
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                          <NewsLetter>
                            <Menu
                              mode="inline"
                              items={[
                                getItem(item.unit_name, "Asf", [
                                  getItem(
                                    <h3>
                                      <FooterLocation /> &nbsp; &nbsp;
                                      {item.address}
                                    </h3>
                                  ),
                                  getItem(
                                    <h3 className="contact__number">
                                      <FooterCall /> &nbsp; &nbsp;
                                      {item.phoneNumber}
                                    </h3>
                                  ),
                                ]),
                              ]}
                              selectable={false}
                            />
                          </NewsLetter>
                          <Divider />
                        </Col>
                      </Row>

                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col>
                          <h4>{toUSCurrency(Number(item.rate))}</h4>
                        </Col>
                        <Col style={{ display: "flex" }}>
                          <h5>Balance:</h5>{" "}
                          <span>
                            &nbsp;
                            {toUSCurrency(
                              Number(item.rate) - Number(item.paidAmount)
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={[10, 10]} style={{ marginTop: "35px" }}>
                        <Col xs={4} sm={2} md={4} lg={4} xl={3} xxl={3}>
                          <StyledButton
                            icon={<MsgSvg style={{ marginTop: "3px" }} />}
                          />
                        </Col>
                        <Col xs={20} sm={22} md={20} lg={20} xl={21} xxl={21}>
                          <StyledButtonPay
                            onClick={() => {
                              localStorage.setItem("isLoad", "true");
                            }}
                          >
                            <a href={`/payment/${item.id}`}>PAY</a>
                          </StyledButtonPay>
                        </Col>
                      </Row>
                    </CardDiscriptionWrap>
                  </CardContaint>
                </Col>
              ))}
            </Row> */}
            {tenantsData.length ? (
              <Row gutter={[30, 30]}>
                {tenantsData?.map((item, id) => (
                  <Col key={id} xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                    <CardContaint>
                      {item?.unitObj?.images &&
                      item?.unitObj?.images?.length ? (
                        <>
                          {item?.unitObj?.images.map((img, idx) => (
                            <>
                              {img.isCover === true ? (
                                <img alt="example" src={img.path} />
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <AvenewImg
                          className="svg__border"
                          style={{ background: "#eeeeee", height: "175px" }}
                        />
                      )}
                      <CardDiscriptionWrap>
                        <div className="rating__star">
                          <StarRatings
                            rating={3}
                            starRatedColor="#F67C36"
                            starDimension="17px"
                            starSpacing="5px"
                            // changeRating={this.changeRating}
                            numberOfStars={5}
                            name="rating"
                          />
                        </div>
                        <p className="card__title">{item.apartment_name}</p>
                        <Divider />
                        <Row>
                          <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                            <p className="unit__title">{item?.unit_name}</p>
                            <h3>
                              <FooterLocation /> &nbsp; &nbsp;{item.address}
                            </h3>
                            <h3 className="contact__number">
                              <FooterCall /> &nbsp; &nbsp;{item.phoneNumber}
                            </h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                            <NewsLetter>
                              <Menu
                                // onClick={onClick}
                                mode="inline"
                                items={[
                                  getItem(item?.unit_name, "Asf", [
                                    getItem(
                                      <h3>
                                        <FooterLocation /> &nbsp; &nbsp;
                                        {item.address}
                                      </h3>
                                    ),
                                    getItem(
                                      <h3 className="contact__number">
                                        <FooterCall /> &nbsp; &nbsp;
                                        {item.phoneNumber}
                                      </h3>
                                    ),
                                  ]),
                                ]}
                                selectable={false}
                              />
                            </NewsLetter>
                            <Divider />
                          </Col>
                        </Row>

                        <Row
                          justify="space-between"
                          style={{ alignItems: "center" }}
                        >
                          <Col>
                            <h4>{toUSCurrency(Number(item.rate))}</h4>
                          </Col>
                          <Col style={{ display: "flex" }}>
                            <h5>Balance:</h5>{" "}
                            <span>
                              &nbsp;
                              {toUSCurrency(Number(item.balance))}
                            </span>
                          </Col>
                        </Row>
                        <Row gutter={[10, 10]} style={{ marginTop: "35px" }}>
                          <Col xs={4} sm={2} md={4} lg={4} xl={3} xxl={3}>
                            <StyledButton
                              onClick={() => chatCreate(item)}
                              icon={<MsgSvg style={{ marginTop: "3px" }} />}
                            />
                          </Col>
                          <Col xs={20} sm={22} md={20} lg={20} xl={21} xxl={21}>
                            <StyledButtonPay
                              onClick={() => {
                                // navigate(`/payment/${item.id}`);
                                localStorage.setItem("isLoad", "true");
                                localStorage.setItem("unit_id", item.unit_id);
                                localStorage.setItem("invitaion_id", item.id);
                              }}
                            >
                              <Link to={`/my-rentals/${item.unit_id}`}>
                                Pay Rent
                              </Link>
                              {/* PAY */}
                            </StyledButtonPay>
                            {/* <form id="payment-form">
                            <div className="pay__btn" id="cash-app-pay"></div>
                          </form> */}
                            {/* <form id="payment-form">
                            <div id="cash-app-pay"></div>
                          </form> */}
                          </Col>
                        </Row>
                      </CardDiscriptionWrap>
                    </CardContaint>
                  </Col>
                ))}
              </Row>
            ) : (
              <NoInvoiceWrap>
                <NoRental hSize={svgIwdth} wSize={svgIwdth} />
                <h1>No Rentals Available</h1>
              </NoInvoiceWrap>
            )}
          </CardWrap>

          {/* <Footer /> */}
          <FooterSectionHome />

          <BackTop>
            <div style={backToTopStyle}>
              <MdKeyboardArrowUp />
            </div>
          </BackTop>
        </Container>
      )}
    </>
  );
}

export default MyRentals;

export const StyledButtonPay = styled(Button)`
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;

  span {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 0;
    margin-top: 0;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;
const StyledButton = styled(Button)`
  height: 40px;
  width: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 0;
    margin-top: 0;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

const CardDiscriptionWrap = styled.div`
  padding: 10px;

  ${
    "" /* .pay__btn > button {
    background-color: red !important;
    height: 50px !important;
  } */
  }

  h5,
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
    color: #848687;
  }
  span {
    color: #e74c4c;
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 0;
  }
  .contact__number {
    margin-bottom: 30px;
  }
  .rating__star {
    padding-top: 20px;
  }

  .card__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    margin-bottom: 0;
  }
  .ant-divider {
    background: #dddddd;
    width: 95%;
    min-width: 95%;
    margin: 24px auto;
  }
  .unit__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 24px;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #848687;
    margin-bottom: 18px;
  }
`;

const CardContaint = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  img {
    width: 100%;
    max-height: 175px;
  }
  .svg__border {
    width: 100%;
    height: auto;
  }
  .svg__border {
    border-radius: 10px 10px 0 0;
  }
`;

const CardWrap = styled.div`
  width: 100%;
  padding: 30px 0 100px 0;

  @media (max-width: 1550px) {
    padding: 30px;
  }
  @media (max-width: 765px) {
    padding: 15px;
  }
`;

const NewsLetter = styled.div`
  .ant-menu-item {
    white-space: break-spaces;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #848687;
    margin-bottom: -2px;
  }
  .contact__number {
    margin-bottom: 0;
  }

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #2b353f;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    ${"" /* line-height: 24px; */}
    letter-spacing: 0.05em;
    color: #2b353f;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    ${"" /* line-height: 40px; */}
    letter-spacing: 0.05em;
    color: #848687;
  }

  .social__icon {
    margin-top: 36px;
    margin-bottom: 38px;
    justify-content: space-between;
    cursor: pointer;
  }

  a {
    color: #848687;
  }

  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    letter-spacing: 0.05em !important;
    color: #848687 !important;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 5px !important;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #2b353f;
  }

  .ant-menu-item {
    ${"" /* height: 25px !important; */}
  }

  @media (max-width: 1440px) {
    ${"" /* padding: 0 30px 30px; */}
  }
  @media (max-width: 991.98px) {
    .avenew__bottom__logo {
      text-align: center;
    }
    .icon__wrap {
      justify-content: center;
    }
  }
`;
