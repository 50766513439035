import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../container/Container";
import { Col, Row } from "antd";
import { FaHeart } from "react-icons/fa";
import TextTransition, { presets } from "react-text-transition";

function ImproveOuService() {

  //  static names of feedback
  const names = [
    {
      id: 1,
      name: "Mark Jecno",
      feedback:
        "Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      id: 2,
      name: "Jecno Mark",
      feedback:
        "Ad dolore dignissimos asperiores dicta facere optio quod commodi nam tempore recusandae. Rerum sed nulla eum vero expedita ex delectus rem",
    },
    {
      id: 3,
      name: "John Mark",
      feedback:
        "Consequatur rerum amet fuga expedita sunt et tempora saepe? Iusto nihil explicabo perferendis quos provident delectus ducimus reiciendis provident",
    },
  ];

  const [newName, setnewName] = useState("");

  // random index on names
  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setnewName(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <ImproveOuServiceWrap>
      <Container padding="100" paddings="30px 30px 100px 30px">
        <Row className="heart__icon">
          <Col>
            <FaHeart />
          </Col>
        </Row>
        <Row justify="space-between" className="help__improve">
          <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={8}>
            <h1>Help us improve our service</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
            <div>
              <TextTransition springConfig={presets.wobbly}>
                <p>{newName.feedback}</p>
                <span>{newName.name}</span>
              </TextTransition>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
            <img src="/images/profilegirl.png" alt="profilegirl" />
          </Col>
        </Row>
      </Container>
      {/* <Row>
        <Col className="profile__pic">
          <img src="/images/profilegirl.png" alt="profilegirl" />
        </Col>
      </Row> */}
    </ImproveOuServiceWrap>
  );
}

export default ImproveOuService;

export const ImproveOuServiceWrap = styled.div`
  background: #2b353f;

  .text-transition {
    height: auto !important;
  }

  img {
    position: absolute;
    ${'' /* left: 170px;
    bottom: -100px; */}
  }
  svg {
    color: #c0392b;
    font-size: 28px;
  }

  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    letter-spacing: 0.05em;
  }
  span {
    height: 32px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #f67c36;
    letter-spacing: 0.05em;
  }
  @media (max-width: 1550px) {
    h1 {
      font-size: 40px;
      line-height: 45px;
    }

    p,
    span {
      font-size: 18px;
    }
  }
  @media (max-width: 991.98px) {
    img {
      width: 120px;
      ${'' /* left: 50px;
      bottom: -50px;
      position: absolute;
      top: 0;
      bottom: 0; */}
      top: 40px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .heart__icon {
      justify-content: center;
    }
    .help__improve {
      text-align: center;
    }
    .profile__pic {
      width: 100%;
    }
  }

  @media (max-width: 769px) {
    .heart__icon {
      justify-content: center;
      padding-top: 40px;
      justify-content: center;
    }
  }
`;
