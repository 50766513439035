import { Button } from "antd";
import styled from "styled-components";

export const ApplicationsWrapMobile = styled.div`
  width: 100%;
  padding: 20px 50px;
  max-height: 500px;
  overflow-y: scroll;

  /* scrollbar design */
  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(140, 153, 166, 0.8);
    outline: 1px solid rgb(140, 153, 166, 0.8);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    padding: 20px 10px;
  }

  @media (max-width: 767px) {
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;

export const ApplicationsRightMobile = styled.div`
  width: 100%;
  padding: 0 0;

  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #2b353f;
    margin-bottom: 10px;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #dee8ff;
    border-color: #dee8ff;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #4160ac;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #dee8ff;
    border-color: #dee8ff;
  }
  .ant-steps {
    overflow: auto;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #4160ac;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #eee;
    border-color: #eee;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #2b353f;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #2b353f;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #ddd;
  }

  .ant-steps-vertical {
    display: flex;
    flex-direction: row;
  }
  .ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after,
  .ant-steps-item-tail {
    display: none !important;
  }
`;

export const StatusMainMobile = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 35px;
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    text-align: center;
    color: #2b353f;
    margin-bottom: 18px;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    color: #f67c36;
    margin-bottom: 15px;
  }
  svg {
    margin-bottom: 35px;
  }

  @media (max-width: 1550px) {
    h5 {
      font-size: 16px;
      line-height: 17px;
      margin-bottom: 28px;
    }

    svg {
      margin-bottom: 28px;
    }
  }
  ${
    "" /* @media (max-width: 991px) {
    margin-top: 50px;
    margin-bottom: 20px;
  } */
  }
`;

export const StyledButtonMobile = styled(Button)`
  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  margin-left: ${({ marginLeft }) => marginLeft || "0"};

  padding: ${({ padding }) => padding || "4px 35px"};
  height: ${({ hg }) => hg || "50px"};
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
`;

export const LeasingAgreementMobile = styled.div`
  margin-top: 50px;

  .ql-clipboard {
    display: none;
  }

  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    color: #2b353f;
    margin-bottom: 20px;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0px;
  }
`;

export const AtchBoxMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  svg {
    font-size: 32px;
    color: ${({ fontColor }) => fontColor || "#4160ac"};
  }
  h5,
  h4 {
    cursor: pointer;
    margin-left: 15px;
    color: ${({ fontColor }) => fontColor || "#4160ac"};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .dow__svg {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #f67c36;
  }

  @media (max-width: 1550px) {
    h5 {
      font-size: 16px;
      line-height: 20px;
      margin-left: 5px;
    }
  }
`;

export const AgreeSignMobile = styled.div`
  display: ${({ display }) => display || "flex"};
  justify-content: space-between;
  align-items: center;

  img {
    width: 100px;
  }
  .ant-form-item {
    margin-bottom: auto;
  }

  canvas {
    width: 80%;

    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .ant-checkbox {
    background: #ffffff;
    border: 1px solid #2b353f;
    border-radius: 3px;
  }

  .submit__btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    ${"" /* color: #000000; */}
  }
  h5 {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }
`;

export const AgreeSignRightMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${"" /* justify-content: ${({ justifyContent }) => justifyContent || ""}; */}
  ${"" /* width: ${({ width }) => width || ""}; */}

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    cursor: pointer;
  }

  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

export const UploadBoxMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #777777;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
  ${"" /* height: 50px; */}
  height: ${({ height }) => height || "50px"};
  margin-top: ${({ mt }) => mt || "33px"};

  @media (max-width: 1550px) {
    height: ${({ heightSm }) => heightSm || "40px"};
  }
`;

export const UploadInputMobile = styled.div`
  display: flex;
  height: 100%;
  background: ${({ background }) => background || "#eeeeee"};
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  p,
  h6 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
  }
  .upload__icon {
    color: #777777;
  }
  .ant-upload-text {
    padding-left: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
  }
  .ant-upload-drag-icon {
    padding-right: 5px;
    padding-top: 2px;
  }
`;

export const StyledUploadPreviewWrapperMobile = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  flex-wrap: wrap;
  margin-top: 10px;

  .ant-image {
    width: 100% !important;
  }
  img {
    width: 100%;
  }

  .ant-image-mask {
    border-radius: 5px;
  }

  img {
    object-fit: cover;
    border-radius: 5px;
    ${"" /* overflow: hidden; */}
    padding: 5px;
  }

  @media (max-width: 575px) {
  }
`;

export const CheckoutWrapMobile = styled.div`
  margin-top: 50px;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 20px;
  }
`;

export const ApplicationsCardsMobile = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.4s;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
  img {
    width: 150px;
    height: 150px;
  }
`;

export const CardDetailsMobile = styled.div`
  padding: 10px 15px;
  width: ${({ cardWidth }) => cardWidth || ""};
  h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    svg {
      margin-right: 10px;
    }
  }
`;

export const PriceComponentMobile = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const DiscountCodeMobile = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .ant-btn {
    height: 50px;
    border-left: 0;
    color: #f67c36;
    background: #eeeeee;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #f67c36;
    border-color: #d9d9d9;
    background: #eeeeee;
  }

  .ant-input:hover {
    border-right: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: #eeeeee;
    border-right: none;
  }
  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px #eeeeee;
    border-right-width: 0;
    outline: 0;
  }
`;

export const PriceCodeMobile = styled.div`
  width: 100%;

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #848687;
  }
  p {
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 29px;
    text-align: right !important;
    color: #2b353f;
    margin-bottom: 0;
  }
`;

export const BottomWrapMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0 90px 0;
  text-align: left;
  align-items: center;

  p {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #848687 !important;
    margin-bottom: 0 !important;
    text-align: end;
    cursor: pointer;
  }
`;

export const WelcomeHomeWrapMobile = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 50px;

  .ant-divider-horizontal {
    background: #eeeeee;
  }

  svg {
    width: -webkit-fill-available;
  }

  h2 {
    margin-top: 30px;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 20px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #f67c36;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #2b353f;
  }
`;

export const RentalPaymentMobile = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 20px;

  svg {
    margin-right: 10px;
  }
  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    cursor: pointer;
  }
`;

export const SelectWrapMobile = styled.div`
  width: 100%;
  .ant-select-selection-item {
    display: flex;
    align-items: baseline;
  }
  .ant-row,
  .ant-row-space-between {
    width: 100%;
  }

  .ant-select {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .ant-select-selector {
    border-radius: 5px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 50px;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
  }
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
`;
