import React, { useState, useEffect } from "react";
import LoginNew, { PrefixStyle } from "../LoginNew";
import { Form, Input, Modal, Select, message } from "antd";
import { StyledButton, StyledFormItem } from "../Login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createUser,
  getApplicationProcess,
  me,
  register,
  verifySignUp,
} from "../../../store/reducers/user.reducer";
import { SelectArrow } from "../../signup/Signup";
import OtpComponent from "../OtpComponent";
import { AccountDone } from "../../../assets/svgs";
import styled from "styled-components";
import { currentDateTime } from "../../../utils/fn";

const LoginComponent = ({
  onCancel,
  onLogin = console.log(),
  setProfileState,
  modalState,
}) => {
  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  var formRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [loadings, setloadings] = useState(false);
  const [isOptSend, setIsOptSend] = useState(false);
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [isFormField, setIsFormField] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");
  const [writenNumber, setWritenNumber] = useState("");
  const [phoneSendOtp, setPhoneSendOtp] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [unitId, setUnitId] = useState("");

  // For resend OTP to give phone number
  const onFinishResendOtp = () => {
    let value = {};
    value.phoneNumber = writenNumber;

    dispatch(register(value)).then(({ payload }) => {
      setPhoneSendOtp(payload);
      setloadings(false);
      setIsOptSend(true);
      message.success("OTP has been sent to your mobile");
    });
  };

  // For login and signup new profile
  const onFinish = async (value) => {
    setloadings(true);
    if (value.phoneNumber) {
      value.phoneNumber = `+1${value.phoneNumber}`;
      setWritenNumber(value.phoneNumber);
      dispatch(register(value)).then(({ payload }) => {
        setPhoneSendOtp(payload);
        setloadings(false);
        setIsOptSend(true);
      });
      // setTimeout(() => {
      //   setloadings(false);
      //   setIsOptSend(true);
      // }, 2000);
    } else {
      value.phoneNumber = writenNumber;
      value.createdAt = currentDateTime;
      value.updatedAt = currentDateTime;
      dispatch(createUser(value)).then((res) => {
        setloadings(false);
        setIsFormField(true);
        // navigate("/");
      });
    }
  };

  // Form validation message
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // default error- if email is wrong or not exist
  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("User with email")
    ) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email is not exist"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  const reDirectFun = () => {
    if (params.id) {
      navigate(`/signup/${params.id}`);
    } else {
      navigate(`/signup`);
    }
  };

  const prefixSelector = (
    <SelectArrow>
      <Form.Item noStyle>
        {/* <span>+1</span> */}
        <PrefixStyle>
          <Select defaultValue="+1">
            <Option value="+1">+1</Option>
          </Select>
        </PrefixStyle>
      </Form.Item>
    </SelectArrow>
  );

  useEffect(() => {
    if (localStorage.getItem("unit-details")) {
      const id = localStorage.getItem("unit-details");
      setUnitId(id);
    }
  }, [localStorage.getItem("unit-details"), localStorage.getItem("idToken")]);

  //  For OTP validation (user is exist or new user)
  const otpValue = (value) => {
    setVerifyOtp(value);
    setloadingsOtp(true);
    if (value.length < 6) {
      setErrorMessage("Invalid code");
      setloadingsOtp(false);
    } else {
      if (phoneSendOtp?.request === "signIn") {
        dispatch(
          verifySignUp({
            request: "signIn",
            phoneNumber: writenNumber,
            otp: value,
          })
        ).then(({ payload }) => {
          if (payload?.Error || payload?.error) {
            setErrorMessage("Invalid code");
            setloadingsOtp(false);
          } else {
            dispatch(me());
            dispatch(getApplicationProcess()).then(({ payload }) => {
              onLogin(payload);
            });
            setErrorMessage("");
            setloadingsOtp(false);
            onCancel(false);
          }
        });
      } else {
        dispatch(
          verifySignUp({
            request: "signUp",
            phoneNumber: writenNumber,
            otp: value,
          })
        ).then(({ payload }) => {
          if (payload?.Error || payload?.error) {
            setErrorMessage("Invalid code");
            setloadingsOtp(false);
          } else {
            dispatch(me());
            dispatch(getApplicationProcess()).then(({ payload }) => {
              onLogin(payload);
            });
            setErrorMessage("");
            setloadingsOtp(false);
            setOtpVerify(true);
          }
        });
      }
    }
  };

  return (
    <>
      <LoginModalWrap mb="20px">
        {isFormField ? (
          <>
            <h2>Welcome to Avenew</h2>
            <AccountDone />
            <h3 className="success__text">Registered Successfully</h3>
            <StyledButton
              onClick={() => {
                onCancel(false);
                navigate("/application-process");
              }}
              htmlType="submit"
              loading={loadings}
            >
              Okay
            </StyledButton>
          </>
        ) : otpVerify ? (
          <>
            <h2>Welcome Back</h2>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              validateMessages={validateMessages}
              ref={formRef}
            >
              <StyledFormItem smwh="100">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <Form.Item>
                <StyledButton htmlType="submit" loading={loadings}>
                  Continue
                </StyledButton>
              </Form.Item>
            </Form>
          </>
        ) : isOptSend ? (
          <>
            <h2>Welcome Back</h2>
            <h3>
              Enter the code you have received on your phone number you've
              provided
            </h3>
            <OtpComponent
              errorMessage={errorMessage}
              otpValue={otpValue}
              loadingsOtp={loadingsOtp}
              setIsFormField={setIsFormField}
              setIsOptSend={setIsOptSend}
              modalState={modalState}
              onFinishResendOtp={onFinishResendOtp}
            />
          </>
        ) : (
          <>
            <h2>Welcome Back</h2>
            <h3>
              Please enter your phone number to access the Application, On which
              You will recieve OTP.{" "}
            </h3>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              validateMessages={validateMessages}
              ref={formRef}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                    },
                    { max: 10, message: "Invalid Phone Number!" },
                    { min: 10, message: "" },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    type="number"
                    min="0"
                    pattern="[0-9]*"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </StyledFormItem>
              <Form.Item>
                <StyledButton htmlType="submit" loading={loadings}>
                  COntinue
                </StyledButton>
              </Form.Item>
            </Form>
          </>
        )}
      </LoginModalWrap>
    </>
  );
};

export default LoginComponent;

const LoginModalWrap = styled.div`
  width: 100%;
  padding: 30px 30px;

  h2 {
    margin-bottom: ${({ mb }) => mb || "0"};
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: ${({ mb }) => mb || "0"};
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  .success__text {
    display: flex;
    justify-content: center;
  }
  svg {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  @media (max-width: 576px) {
    h2 {
      font-size: 30px;
    }
  }
`;
