import React, { useEffect, useState } from "react";
import { Col, Form, Input, message, Popconfirm, Radio, Row, Table } from "antd";
import { FormWrap, GeneralProfileWrap } from "./GeneralProfile";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { EditSvg, PlusSvg } from "../../../assets/svgs";
import { InformationWrap } from "../../Profile-verification/PriorRentalInformation";
import useDebounce from "../../../utils/hooks/useDebounce";
import styled from "styled-components";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationProcess,
  getApplicationProcess,
  inviteMember,
} from "../../../store/reducers/user.reducer";
import { ApartmentTable } from "../../MyTransaction/MyTransaction";
import { FaTrash } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { BiSend } from "react-icons/bi";
import { upperCase } from "../../../utils/fn";

function Members({ onGeneralProfileNext }) {
  const { applicationProcessData } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const location = useLocation();
  const [form2] = Form.useForm();
  const [value, setValue] = useState(true);
  const [totalAdults, setTotalAdults] = useState("");
  const [responsiveMT, setResponsiveMT] = useState("");
  const [totalAdultsDetails, setTotalAdultsDetails] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isInvoied, setIsInvoied] = useState(false);
  const [isEditOn, setIsEditOn] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const [selectedEditValue, setSelectedEditValue] = useState("");
  const [phoneLength, setPhoneLength] = useState(0);
  const [fielsWidth, setfielsWidth] = useState(40);

  useEffect(() => {
    form.setFieldsValue({
      adults: totalAdults,
    });
  }, [totalAdults]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // To set default value of radio button
  useEffect(() => {
    if (totalAdultsDetails?.length) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [totalAdultsDetails]);

  const onFinish = (val) => {
    if (isEditOn) {
      let dataObject = {};
      val.isInvoiedMember = false;
      val.memberNumber = `+1${val.memberNumber}`;
      const getValues = [...totalAdultsDetails];
      const editobjectIdx = getValues[selectedEditValue];
      let editsValue = { ...editobjectIdx };
      editsValue.memberName = val.memberName;
      editsValue.memberNumber = val.memberNumber;
      getValues[selectedEditValue] = editsValue;
      setTotalAdultsDetails(getValues);

      // getValues[selectedEditValue] = val;

      const newData = getValues.map((values) => {
        return {
          isInvoiedMember: values.isInvoiedMember,
          memberName: values.memberName,
          memberNumber: values.memberNumber,
        };
      });
      dataObject.members = newData;
      setIsEditOn(false);
      dispatch(applicationProcess(dataObject)).then(({ payload }) => {
        // dispatch(getApplicationProcess());
      });
    } else {
      if (totalAdultsDetails?.length || Object.values(val)?.length) {
        let dataObject = {};
        let data = [...totalAdultsDetails];
        val.isInvoiedMember = false;
        val.memberNumber = `+1${val.memberNumber}`;
        if (sessionStorage.getItem("applyingProperty")) {
          const unitDetails = JSON.parse(
            sessionStorage.getItem("applyingProperty") || {}
          );
          dataObject.unit_id = unitDetails?.id;
        }
        data.push(val);
        const newData = data.map((values) => {
          return {
            isInvoiedMember: values.isInvoiedMember,
            memberName: values.memberName,
            memberNumber: values.memberNumber,
          };
        });
        dataObject.members = newData;
        setTotalAdultsDetails((value) => [...value, val]);
        dispatch(applicationProcess(dataObject)).then(({ payload }) => {
          // dispatch(getApplicationProcess());
        });
      } else {
        let data = {};
        data.members = [];
        // setIsSubmit(true);
        dispatch(applicationProcess(data)).then(({ payload }) => {
          setIsSubmit(false);
          // dispatch(getApplicationProcess());

          if (payload) {
            onGeneralProfileNext("general-profile");
          }
        });
      }
    }
    form2.resetFields();
  };

  const onNext = (value) => {
    onGeneralProfileNext("general-profile");
  };

  useEffect(() => {
    if (width < 993) {
      setResponsiveMT("20px");
    } else {
      setResponsiveMT("0");
    }
  }, [width, location.pathname]);
  useEffect(() => {
    if (width > 1150) {
      setfielsWidth(40);
    } else {
      setfielsWidth(37);
    }
  }, [width, location.pathname]);

  const editValue = (record, idx) => {
    setSelectedEditValue(idx);
    setIsEditOn(true);
    setIsNewAdd(true);
    form2.setFieldsValue({
      memberNumber: record?.memberNumber?.slice(2),
      memberName: record?.memberName,
    });
  };

  const onInvite = (value, id) => {
    setIsInvoied(true);
    let data = {};
    data.phoneNumber = value?.memberNumber;
    data.invitedName = user?.firstName + " " + user?.lastName;
    data.applicationId = applicationProcessData?.id;
    data.invitationStatus = "send";
    message.success("Member Invited Successfully");
    dispatch(inviteMember(data)).then((res) => {
      setIsInvoied(false);
      let totalAdults = [...totalAdultsDetails];
      let newArr = totalAdults.map((val, idx) => {
        if (id === idx) {
          let newVal = { ...val };
          newVal.isInvoiedMember = true;
          newVal.invitation = [{ invitationStatus: "send" }];
          return newVal;
        } else {
          return val;
        }
      });
      setTotalAdultsDetails(newArr);
      let dataObject = {};
      const newData = newArr.map((values) => {
        return {
          isInvoiedMember: values.isInvoiedMember,
          memberName: values.memberName,
          memberNumber: values.memberNumber,
        };
      });
      dataObject.members = newData;
      dispatch(applicationProcess(dataObject)).then(({ payload }) => {});
    });
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const addChanges = () => {
    // setTotalAdults(Number(totalAdults) + 1);
    setIsNewAdd(true);
    setIsEditOn(false);
  };

  useEffect(() => {
    if (totalAdultsDetails?.length) {
      setIsNewAdd(false);
    } else {
      setIsNewAdd(true);
    }
  }, [totalAdultsDetails]);

  const debouncedSearchValue = useDebounce(totalAdults, 800);

  // useEffect(() => {
  //   let arr = [];
  //   for (var i = 1; i <= debouncedSearchValue; i++) {
  //     arr.push({
  //       memberName: null,
  //       memberNumber: null,
  //       isInvoiedMember: false,
  //     });
  //   }
  //   setTotalAdultsDetails(arr);
  // }, [debouncedSearchValue]);

  useEffect(() => {
    if (
      applicationProcessData &&
      applicationProcessData?.userProfile &&
      applicationProcessData?.userProfile?.members?.length
    ) {
      const members = applicationProcessData?.userProfile?.members;
      setTotalAdultsDetails(members);
      form.setFieldsValue({
        adults: members?.length,
      });
    } else {
      // setValue(false);
    }
  }, [applicationProcessData]);

  const onInputChange = (value) => {
    setTotalAdults(value);
  };
  const inputChange = () => {
    var cardNum = document.getElementById("ph_no");

    if (cardNum) {
      cardNum.onkeyup = function (e) {
        this.value = this.value.replace(/[^0-9\.]/g, "");
      };
      setPhoneLength(cardNum.value.length);
    }
  };

  const inputNameChange = (membersName, id) => {
    let totalAdults = [...totalAdultsDetails];
    let newArr = totalAdults.map((val, idx) => {
      if (id === idx) {
        let newVal = { ...val };
        newVal.memberName = membersName;
        return newVal;
      } else {
        return val;
      }
    });
    setTotalAdultsDetails(newArr);

    // let changedObj = totalAdultsDetails[id];
    // changedObj["memberName"] = membersName;
    // totalAdultsDetails[id] = changedObj;
    // setTotalAdultsDetails(totalAdultsDetails);
    // setTotalAdultsDetails((val) => [...val]);
  };
  const inputNumberChange = (number, id) => {
    let totalAdults = [...totalAdultsDetails];
    let newArr = totalAdults.map((val, idx) => {
      if (id === idx) {
        let newVal = { ...val };
        newVal.memberNumber = `+1${number}`;
        return newVal;
      } else {
        return val;
      }
    });
    setTotalAdultsDetails(newArr);
  };

  const onDelete = (record, idx) => {
    let arr = [...totalAdultsDetails];
    let newArr = [];
    arr.map((val, id) => {
      if (id === idx) {
      } else {
        newArr.push(val);
      }
    });
    setTotalAdults(newArr?.length);
    setTotalAdultsDetails(newArr);

    let dataObject = {};
    let data = [...newArr];
    const newData = data.map((values) => {
      return {
        isInvoiedMember: values.isInvoiedMember,
        memberName: values.memberName,
        memberNumber: values.memberNumber,
      };
    });
    dataObject.members = newData;
    dispatch(applicationProcess(dataObject)).then(({ payload }) => {});
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const invitaionstatus = (key) => {
    switch (key) {
      case "send":
        return "rgb(79 139 78)";
      case "accept":
        return "#3650f6";
      case "reject":
        return "red";
      case "cancel":
        return "orange";
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "memberName",
      key: "memberName",
      width: "40%",
      render: (_, record) => <span>{upperCase(record.memberName)}</span>,
    },
    {
      title: "Contact Number",
      dataIndex: "memberNumber",
      key: "memberNumber",
      width: "30%",
      render: (_, record) => (
        <span>{formatPhoneNumber(record.memberNumber)}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "isInvoiedMember",
      key: "isInvoiedMember",
      width: "20%",
      render: (_, record) => (
        <span
          style={{
            color: invitaionstatus(
              record.invitation?.length
                ? record.invitation[0].invitationStatus
                : ""
            ),
          }}
        >
          {upperCase(
            record.invitation?.length
              ? record.invitation[0].invitationStatus === "cancel"
                ? "viewed"
                : record.invitation[0].invitationStatus === "send"
                ? "sent"
                : record.invitation[0].invitationStatus === "accept"
                ? "accepted"
                : record.invitation[0].invitationStatus === "reject"
                ? "rejected"
                : ""
              : ""
          )}
        </span>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      width: "10%",
      render: (_, record, idx) => {
        return (
          <>
            {!isEditOn && (
              <div style={{ display: "flex" }}>
                <EditIconWrap
                  onClick={(event) => {
                    editValue(record, idx);
                    event.stopPropagation();
                  }}
                >
                  <EditSvg />
                </EditIconWrap>
                <Popconfirm
                  key={"del-c"}
                  title="Are you sure?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    onDelete(record, idx);
                  }}
                  onCancel={(e) => {
                    e.stopPropagation();
                  }}
                  okText="Yes"
                  cancelText="No"
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <EditIconWrap
                    pr="0"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <FaTrash />
                  </EditIconWrap>
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Invitation",
      dataIndex: "Invitation",
      key: "Invitation",
      width: "20%",
      render: (_, record, idx) => (
        <span onClick={() => onInvite(record, idx)} className="send__icon">
          <BiSend />
        </span>
      ),
    },
  ];

  return (
    <GeneralProfileWrap mb="15px">
      <h1>Would you like to invite other members?</h1>

      <InformationWrap>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No, It‘s just me</Radio>
        </Radio.Group>

        {value ? (
          <FormWrap marginTop="30px" mt="0">
            {/* <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form
                  layout="vertical"
                  // requiredMark={"optional"}
                  form={form}
                  // onFinish={onFinish}
                  validateMessages={validateMessages}
                >
                  <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                    <Form.Item
                      className="input__affix"
                      name="adults"
                      label="How many adults are staying with you?"
                      rules={[
                        {
                          required: true,
                          message:
                            "How many adults are staying with you is required.",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => onInputChange(e.target.value)}
                        type="text"
                        size="18"
                        id="cr_no"
                        minLength="0"
                        maxLength="2"
                      />
                    </Form.Item>
                  </StyledFormItem>
                </Form>
              </StyledForms>
            </StyledForm> */}

            <h1>Invite members</h1>

            <ApartmentTable>
              <Table
                scroll={{ x: 700 }}
                // scroll={{ x: 1200 }}
                columns={columns}
                dataSource={[...totalAdultsDetails]}
                // // pagination={pagination}
                pagination={false}
                // bordered
                // loading={loadings}
                // onChange={handleTableChange}
              />
            </ApartmentTable>

            <StyledForm
              style={{ marginTop: "25px" }}
              wh="100"
              smwh="100"
              xswh="100"
              xxswh="100"
            >
              <StyledForms>
                <Form
                  layout="vertical"
                  form={form2}
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                >
                  {/* Add here */}

                  {isNewAdd ? (
                    <StyledFormItem
                      style={{ marginTop: responsiveMT }}
                      wh="42"
                      flexDirectionSmwh={fielsWidth}
                      fd={"column"}
                      smwh={"100"}
                    >
                      <Form.Item
                        name="memberName"
                        label={`Member name`}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Member name required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="memberNumber"
                        label="Contact Number"
                        className="input__affix"
                        rules={[
                          {
                            required: true,
                          },
                          { max: 10, message: "Invalid Contact Number!" },
                          { min: 10, message: "" },
                        ]}
                      >
                        <Input
                          // onChange={() => inputChange()}
                          // id="ph_no"
                          // minLength="10"
                          // maxLength="10"
                          onKeyDown={(e) =>
                            e.key === "." ? e.preventDefault() : ""
                          }
                          onWheel={(e) => e.target.blur()}
                          // prefix={`+1`}
                          type="number"
                          min="0"
                          pattern="[0-9]*"
                        />
                      </Form.Item>

                      <BtnWithEdit>
                        <StyledButton
                          padding={"4px 30px"}
                          backgroundcolor="#F67C36"
                          mt="30px"
                          btnwidthedit="100%"
                          htmlType="submit"
                          // disabled={phoneLength === 10 ? false : true}
                        >
                          {isEditOn ? "Save" : "Add"}
                        </StyledButton>
                      </BtnWithEdit>
                    </StyledFormItem>
                  ) : (
                    <Form.Item style={{ marginTop: "25px" }}>
                      <Row justify="end">
                        <Col>
                          <Row
                            className="icon__text"
                            block="true"
                            // onClick={() => setIsAdd(!isAdd)}
                            onClick={() => addChanges()}
                          >
                            <Col>
                              <PlusSvg />
                            </Col>
                            <Col>
                              <p>Add a new Member</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Row
                      justify="end"
                      style={{ alignItems: "center", marginTop: "20px" }}
                    >
                      {isNewAdd ? (
                        <Col>
                          <p
                            onClick={() => {
                              setIsNewAdd(false);
                              form2.resetFields();
                              setIsEditOn(false);
                            }}
                            className="cancel__btn"
                          >
                            Cancel
                          </p>
                        </Col>
                      ) : (
                        " "
                      )}
                      <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          onClick={onNext}
                          // htmlType="submit"
                        >
                          Next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </StyledForms>
            </StyledForm>
          </FormWrap>
        ) : (
          <FormWrap>
            <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form onFinish={onFinish}>
                  <Form.Item>
                    <Row
                      justify="end"
                      style={{ alignItems: "center", marginTop: "20px" }}
                    >
                      {/* <Col>
                        <p
                          onClick={() => {
                            setIsNewAdd(false);
                            form2.resetFields();
                          }}
                          className="cancel__btn"
                        >
                          Cancel
                        </p>
                      </Col> */}
                      <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          // htmlType="submit"
                          onClick={onNext}
                        >
                          Next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </StyledForms>
            </StyledForm>
          </FormWrap>
        )}
      </InformationWrap>
    </GeneralProfileWrap>
  );
}

export default Members;

export const EditIconWrap = styled.div`
  cursor: pointer;
  color: #777777;
  font-size: 16px;
  ${"" /* padding-right: 35px; */}
  padding-right: ${({ pr }) => pr || "25px"};
`;

export const BtnWithEdit = styled.div`
  h2 {
    font-weight: 400;
    color: #848687;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 0 8px;
    margin-bottom: 0;
    text-align: end;
    cursor: pointer;
  }
`;
