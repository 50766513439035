import React, { useState } from "react";
import { Badge, Col, Divider, Modal, Row, Table } from "antd";
import { FaHeart } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import { BathSvg, BedSvg, DepositSvg, Sqft } from "../../../../assets/svgs";

function FloorPlanModal({ modalState, onCancel }) {
  // none used component
  const [size, setSize] = useState(380);

  const columns = [
    {
      title: "Units",
      dataIndex: "units",
    },
    {
      title: "Price ",
      dataIndex: "price",
    },
    {
      title: "Sq. ft ",
      dataIndex: "sqft",
    },
    {
      title: "Available ",
      dataIndex: "available",
    },
  ];
  const dataSource = [
    {
      key: "1",
      units: "#895",
      sqft: "1250",
      price: "$126.00",
      available: "Now",
    },
    {
      key: "2",
      units: "#895",
      sqft: "1250",
      price: "$126.00",
      available: "Now",
    },
    {
      key: "3",
      units: "#895",
      sqft: "1250",
      price: "$126.00",
      available: "Now",
    },
    {
      key: "4",
      units: "#895",
      sqft: "1250",
      price: "$126.00",
      available: "Now",
    },
  ];

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={"1200px"}
      footer={null}
      style={{ borderRadius: "5px", top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <StyledTitle>Floor Plan</StyledTitle>
      <ContentWrap>
        <Row gutter={[30, 30]}>
          <Col xs={24} sm={7} md={7} lg={13} xl={13} xxl={13}>
            <LeftWrap>
              <Badge.Ribbon text="#895625" placement="start">
                <img alt="example" src="images/FloorPlanLeftWrap.png" />
              </Badge.Ribbon>
              <div className="top-right">
                <FaHeart />
              </div>
            </LeftWrap>
          </Col>

          <Col xs={24} sm={17} md={17} lg={11} xl={11} xxl={11}>
            <Row gutter={[30, 30]}>
              <Col span={24}>
                <FloorPlan>
                  <Row justify="space-between" className="title__avail">
                    <Col>
                      <h2>E6</h2>
                    </Col>
                    <Col>
                      <span>Only 2 Available</span>
                    </Col>
                  </Row>
                  <Row gutter={[15, 15]}>
                    <Col span={12} className="card__bottom">
                      <BedSvg />
                      <h4>Bed : 02</h4>
                    </Col>
                    <Col span={12} className="card__bottom">
                      <BathSvg />
                      <h4>Bath : 02</h4>
                    </Col>
                    <Col span={12} className="card__bottom">
                      <Sqft />
                      <h4>Sq ft : 1500</h4>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      className="card__bottom"
                    >
                      <DepositSvg />
                      <h4>Deposit : $1563.00</h4>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col>
                      <h3>$5898.00 - $6265.00</h3>
                    </Col>
                  </Row>
                </FloorPlan>
              </Col>
              <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                <UnitTable>
                  <Table
                    columns={columns}
                    // rowKey={(record) => record.login.uuid}
                    dataSource={dataSource}
                    // loading={loading}
                    // onChange={this.handleTableChange}
                    // scroll={{ x: 1000, y: size }}
                    // scroll={{ x: 1000 }}
                    // scroll={{ y: 350 }}
                  />
                </UnitTable>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={0}>
            <UnitTable>
              <Table
                columns={columns}
                // rowKey={(record) => record.login.uuid}
                dataSource={dataSource}
                // loading={loading}
                // onChange={this.handleTableChange}
                scroll={{ x: 500 }}
                // scroll={{ x: 1000 }}
                // scroll={{ y: 350 }}
              />
            </UnitTable>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={24} sm={24} md={24} lg={0}>
            <UnitTable>
              <Table
                columns={columns}
                // rowKey={(record) => record.login.uuid}
                dataSource={dataSource}
                // loading={loading}
                // onChange={this.handleTableChange}
                // scroll={{ x: 1000, y: size }}
                // scroll={{ x: 1000 }}
                // scroll={{ y: 350 }}
              />
            </UnitTable>
          </Col>
        </Row> */}
      </ContentWrap>
    </Modal>
  );
}

export default FloorPlanModal;

export const UnitTable = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 10px 10px 0 0 !important;
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(even) {
    background-color: #eeeeee;
  }

  .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 10px 10px 0 0 !important;
  }
  .ant-table-container .ant-table-body table tr:nth-of-type(even) {
    background-color: #eeeeee;
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.fifth};
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #848687;
    background-color: ${({ theme }) => theme.colors.fifth} !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px !important;
  }
  .ant-table-tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 10px !important;
  }
  .ant-table-tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 10px !important;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    display: none;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
`;
export const FloorPlan = styled.div`
  min-width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 30px;
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 0;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    color: #4160ac;
  }
  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #f67c36;
  }
  .title__avail {
    align-items: center;
    margin-bottom: 30px;
  }
  .card__bottom {
    display: flex;
    align-items: center;
    color: #848687;
    svg {
      color: #848687;
      margin-right: 10px;
    }
  }
  .ant-divider-horizontal {
    margin: 30px 0 20px 0;
    background: #dddddd;
  }

  @media (max-width: 1550px) {
    padding: 20px 25px;
    h2 {
      font-size: 24px;
      line-height: 31px;
    }
    h3 {
      font-size: 18px;
      line-height: 22px;
    }
    h4 {
      font-weight: 700;
    }
    .title__avail {
      margin-bottom: 24px;
    }
    .ant-divider-horizontal {
      margin: 24px 0 16px 0;
    }
  }
  @media (max-width: 769px) {
    padding: 15px;
  }
`;
export const LeftWrap = styled.div`
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
  .ant-ribbon {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
    svg {
      color: rgba(0, 0, 0, 0.7);
      font-size: 28px;
      cursor: pointer;
    }
  }
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.forth};
  width: 100%;

  @media (max-width: 1550px) {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 25px;
  }
  @media (max-width: 575.98px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;
