import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, DatePicker, Row, Select, Table } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getTransection } from "../../store/reducers/user.reducer";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import Footer from "../../components/footer/Footer";
import { CSVLink } from "react-csv";
import { toUSCurrency } from "../../utils/fn";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function MyTransaction() {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loadings, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [downloadTransaction, setDownloadTransaction] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: "small",
  });

  // page navigation bar
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/my-transaction",
      label: "My Transaction",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // Get all transactions (API calling)
  useEffect(() => {
    if (user) {
      setLoading(true);
      dispatch(getTransection()).then(({ payload }) => {
        setLoading(false);
        if (payload && Object.values(payload)) {
          let arr = Object.values(payload);
          setPaymentData(arr);
          setfilterData(arr);
        }
      });
    }
  }, [user]);

  // date format
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day} ${month}, ${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  // Payment type (WALLET, CARD, BANK_ACCOUNT)
  const sourceTypeFun = (type) => {
    switch (type) {
      case "WALLET":
        return "Cash App";
      case "CARD":
        return "Card";
      case "BANK_ACCOUNT":
        return "ACH";

      default:
        return type;
    }
  };

  // to lower case word
  const lowerCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  // CSS- change color behalf of on status
  const statusFtColor = (status) => {
    switch (status) {
      case "Awaiting Approval":
        return "#F78341";
      case "Waiting for Lease":
        return "#4160AC";
      case "Deposit Pending":
        return "#FFC123";
      case "PENDING":
        return "#F78341";
      case "COMPLETED":
        return "#56C274";
      case "Denied":
        return "#2B353F";
      default:
        return "#aaaaaa";
    }
  };

  // Table columns
  const columns = [
    {
      title: "Tx ID",
      dataIndex: "transactionId",
      key: "transactionId",
      // width: "15%",
      render: (_, record) => <span>#{record?.transactionId}</span>,
    },

    {
      title: "Recipient",
      dataIndex: "apartment_name",
      key: "apartment_name",
      width: 300,
      render: (_, record) => (
        <span>
          {record?.unit_name}, {record.apartment_name}
        </span>
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "sourceType",
      key: "sourceType",
      render: (_, record) => (
        <span>{sourceTypeFun(record?.squareResponse?.sourceType)}</span>
      ),
    },
    {
      title: "Payment Details",
      dataIndex: "paymentDetails",
      key: "sourceType",
      width: 250,
      render: (_, record) => (
        <>
          {record.squareResponse.cardDetails ? (
            <span>
              {lowerCase(record.squareResponse.cardDetails?.card?.cardBrand)}{" "}
              {lowerCase(record.squareResponse.cardDetails?.card?.cardType)}, *
              {record.squareResponse.cardDetails?.card?.last4}
            </span>
          ) : record.squareResponse.bankAccountDetails ? (
            <span>
              {lowerCase(
                record.squareResponse.bankAccountDetails?.accountOwnershipType
              )}{" "}
              {lowerCase(
                record.squareResponse.bankAccountDetails?.achDetails
                  ?.accountType
              )}
              , *
              {lowerCase(
                record.squareResponse.bankAccountDetails?.achDetails
                  ?.accountNumberSuffix
              )}
            </span>
          ) : (
            <span></span>
          )}
        </>
      ),
    },
    {
      title: "Payment Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <span>{dateConverter(record.squareResponse.createdAt)}</span>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => (
        <span>
          {toUSCurrency(record.squareResponse.totalMoney.amount / 100)}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <span style={{ color: statusFtColor(record.squareResponse.status) }}>
          {record.squareResponse.status}
        </span>
      ),
    },

    {
      title: "Balance",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => <span>{toUSCurrency(record.balance)}</span>,
    },
    // {
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   // width: "8%",
    //   render: (_, record) => {
    //     return (
    //       <EditIconWrap
    //         onClick={(event) => {
    //           editValue(record);
    //           event.stopPropagation();
    //         }}
    //       >
    //         <EditSvg />
    //       </EditIconWrap>
    //     );
    //   },
    // },
  ];

  const allPropUnit = [
    {
      transactionId: "#YHU6565HYU",
      recipient: "Brandy wine Valley",
      sourceType: "Pay",
      createdAt: "01 Sep, 2022",
      amount: "$896.00",
      depositBalance: "-",
      amount: "$785.00",
    },
  ];

  // Table pagination
  const fetchData = (params = {}) => {
    setLoading(true);
    setPagination({
      ...params.pagination,
      // total: 20, // 200 is mock data, you should read it from server
      total: allPropUnit.length,
    });
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);

    //   });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  // Table sort
  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  useEffect(() => {
    if (paymentData?.length) {
      let arr = [];
      let obj = {};

      // set custom data (#, $, uppercase, etc.)
      paymentData.map((item) => {
        obj.Tx_ID = `#${item?.transactionId}`;
        obj.Recipient = `${item?.unit_name}, ${item.apartment_name}`;
        obj.Payment_Type = sourceTypeFun(item.squareResponse.sourceType);
        obj.Payment_Details = item.squareResponse.cardDetails
          ? `${lowerCase(
              item.squareResponse.cardDetails?.card?.cardBrand
            )} ${lowerCase(item.squareResponse.cardDetails?.card?.cardType)},*${
              item.squareResponse.cardDetails?.card?.last4
            }`
          : item.squareResponse.bankAccountDetails
          ? `${lowerCase(
              item.squareResponse.bankAccountDetails?.accountOwnershipType
            )}
        ${lowerCase(
          item.squareResponse.bankAccountDetails?.achDetails?.accountType
        )},*${lowerCase(
              item.squareResponse.bankAccountDetails?.achDetails
                ?.accountNumberSuffix
            )}`
          : "";
        obj.Payment_Date = dateConverter(item.squareResponse.createdAt);
        obj.Paid_Amount = toUSCurrency(
          item.squareResponse.totalMoney.amount / 100
        );
        obj.Status = item.squareResponse.status;
        obj.Balance = toUSCurrency(item.balance);
        arr.push(obj);
        obj = {};
      });
      setDownloadTransaction(arr);
    } else {
      setDownloadTransaction([]);
    }
  }, [paymentData]);

  const headers = [
    { label: "Tx ID", key: "Tx_ID" },
    { label: "Recipient", key: "Recipient" },
    { label: "Payment Type", key: "Payment_Type" },
    { label: "Payment Details", key: "Payment_Details" },
    { label: "Payment Date", key: "Payment_Date" },
    { label: "Paid Amount", key: "Paid_Amount" },
    { label: "Status", key: "Status" },
    { label: "Balance", key: "Balance" },
  ];

  const onSearch = (date) => {
    if (date?.length) {
      let sDate = new Date(date[0]).toLocaleDateString();
      let eDate = new Date(date[1]).toLocaleDateString();

      let filtered = paymentData.filter((item) => {
        let tarnsDate = new Date(
          item?.squareResponse?.createdAt
        ).toLocaleDateString();
        return tarnsDate >= sDate && tarnsDate <= eDate;
      });
      setfilterData(filtered);
    } else {
      setfilterData(paymentData);
    }
  };

  return (
    <div>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>
      <MyTransactionWrap>
        {/* <CSVLink
          filename="avenew-transactions.csv"
          data={downloadTransaction}
          headers={headers}
        >
          Download me
        </CSVLink> */}
        <div style={{ width: "100%" }}>
          <Row gutter={[10, 10]} justify="space-between">
            <Col>
              <Row gutter={[10, 10]} justify="space-between">
                <Col style={{ marginRight: "20px" }}>
                  <Select
                    defaultValue="custom"
                    // onChange={handleChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value="custom">Custom</Option>
                  </Select>
                </Col>
                <Col style={{ marginRight: "20px" }}>
                  <DatePicker.RangePicker
                    format="DD MMM, YYYY"
                    onChange={onSearch}
                    // disabledDate={disabledDate}
                    allowClear={true}
                  />
                </Col>
                {/* <Col style={{ marginRight: "20px" }}>
                  <DatePicker
                    format="DD MMM, YYYY"
                    // disabledDate={disabledDate}
                    allowClear={false}
                  />
                </Col> */}
              </Row>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <CSVLink
                filename="avenew-transactions.csv"
                data={downloadTransaction}
                headers={headers}
              >
                <StyledButton>Download Billing Summary</StyledButton>
              </CSVLink>
            </Col>
          </Row>
        </div>
        <ApartmentTable>
          <Table
            // scroll={{ x: 800, y: 650 }}
            scroll={{ x: 1200 }}
            columns={columns}
            dataSource={[...filterData]}
            // pagination={pagination}
            pagination={false}
            loading={loadings}
            onChange={handleTableChange}
          />
        </ApartmentTable>
      </MyTransactionWrap>

      {/* <Footer /> */}
      <FooterSectionHome />
    </div>
  );
}

export default MyTransaction;

export const ApartmentTable = styled.div`
  margin-top: 30px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .send__icon,
  .send__icon svg {
    justify-content: center;
    display: flex;
    font-size: 22px;
    color: #f67c36;
  }
  .ant-table {
    ${"" /* height: 100vh; */}
  }
  .ant-table-tbody > tr.ant-table-placeholder {
    min-height: calc(100vh - 345px) !important;
  }

  .ant-table-body {
    min-height: calc(100vh - 345px) !important;

    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  }
  .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }
  .ant-table-tbody {
    ${"" /* height: calc(100vh - 245px); */}
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
    cursor: pointer;
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background-color: ${({ theme, background }) =>
      background || theme.colors.forth} !important;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  height: 40px;
  padding: 4px 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 20px;
    ${"" /* margin-top: 0; */}
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

const MyTransactionWrap = styled.div`
  ${"" /* display: flex; */}
  width: 1400px;
  margin: 0 auto;
  padding: 50px 0 100px 0;
  min-height: calc(100vh - 250px);

  .ant-select {
    min-width: 130px;
  }
  .ant-select-selector {
    border-radius: 5px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    border: none;
    height: 40px;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }

  .ant-picker {
    width: 100%;
    border: none;
    height: 40px;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }

  .ant-picker-input > input {
    border: none;
  }

  .ant-picker-input input::placeholder,
  .ant-picker-suffix {
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 1500px) {
    padding: 30px;
  }
  @media (max-width: 1400px) {
    width: 100%;
  }
`;
