import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Dropdown, Form, Input, Row, Select } from "antd";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import styled from "styled-components";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import {
  BenchMarkSvg,
  HeaderSquareSvg,
  HeaderStyleSvg,
  MenuSvg,
  PropDetailSvg,
  SelectSvg,
} from "../../../assets/svgs";
import {
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "../header/Header";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {
  H4Tag,
  H5Tag,
  PTag,
  SearchBox,
  SearchBoxWrap,
  SearchButton,
  SearchButtonXs,
  SearchFormDiv,
  SearchFormItem,
} from "./searchPart.style";
import HomeHeader from "./HomeHeader";
import data from "../../../pages/Profile-verification/state.json";
import { upperCase } from "../../../utils/fn";
import useDebounce from "../../../utils/hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../../pages/login/login-modal/LoginModal";
import {
  getApplicationProcess,
  logout,
  me,
  topUnits,
} from "../../../store/reducers/user.reducer";
import SideBarMobile from "./SideBarMobile";

const SearchPart = () => {
  const { width } = useWindowSize();
  const { user } = useSelector((state) => state.user);
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [wSize, setWSize] = useState(100);
  const [hSize, setHSize] = useState("calc(100vh - 0px)");
  const [brSize, setBrSize] = useState(width);
  const [citesValue, setCitesValue] = useState([]);
  const [searchCityValue, setSearchCityValue] = useState("");
  const [btnValue, setBtnValue] = useState("");
  const [loginModalState, setLoginModalState] = useState(false);

  // debouncing the value to reflect latest result after a given interval of time
  const debouncedSearchValue = useDebounce(searchCityValue, 800);

  // debouncing the city value
  useEffect(() => {
    if (
      debouncedSearchValue?.length &&
      !citesValue.includes(debouncedSearchValue)
    ) {
      setCitesValue((val) => [...val, debouncedSearchValue]);
    }
  }, [searchCityValue, debouncedSearchValue]);

  // Log out function
  const handleLogout = () => {
    dispatch(logout());
  };

  function flatDeep(arr, d = 1) {
    if (!Array.isArray(arr)) {
      return arr;
    }
    return d > 0
      ? arr.reduce((acc, val) => acc.concat(flatDeep(val, d - 1)), [])
      : arr.slice();
  }

  useEffect(() => {
    const cityArr = Object.values(data);
    const value = flatDeep(cityArr, cityArr.length);
    let uniqueChars = [...new Set(value)];
    setCitesValue(uniqueChars);
  }, []);

  // filtering the array of city upon input search
  const filterFunction = (input) => {
    const filter = input.toUpperCase();
    var arr = [];

    for (let i = 0; i < citesValue.length; i++) {
      const txtValue = citesValue[i];
      if (txtValue.toUpperCase().startsWith(filter)) {
        arr.push(txtValue);
      } else {
      }
    }
    setCitesValue(arr);
  };

  // setting city value upon selecting
  const handleChange = (value) => {
    if (value?.length === 0) {
      const cityArr = Object.values(data);
      const value = flatDeep(cityArr, cityArr.length);
      let uniqueChars = [...new Set(value)];
      setCitesValue(uniqueChars);
    } else {
      filterFunction(value);
    }

    if (value.length > 3) {
      setSearchCityValue(upperCase(value.toLowerCase()));
    }
  };

  useEffect(() => {
    const totalWidth = 1920;
    const newWidth = (100 * width) / totalWidth;
    setWSize(newWidth);

    setBrSize(width - (width * 1) / 100);

    if (width < 1550) {
      // setHSize("auto");
    } else {
      setHSize("calc(100vh - 0px)");
    }
  }, [width]);

  const [openSideBar, setopenSider] = useState(true);

  const onFinish = (value) => {
    // navigate("/property-listing");
    navigate({
      pathname: `/property-listing`,
      search: createSearchParams({
        ...(value?.cityState ? { cityState: value?.cityState } : {}),
        ...(value?.propertyType ? { propertyType: value?.propertyType } : {}),
        ...(value?.priceRange ? { priceRange: value?.priceRange } : {}),
      }).toString(),
    });
  };

  const onApplyNow = (value) => {
    setBtnValue(value);
    setLoginModalState(true);
  };

  const onLogin = (payload) => {
    if (payload?.id) {
      dispatch(me());
      dispatch(getApplicationProcess());
    }
  };

  return (
    <SearchPartMain>
      <MainContainerSearch
        openSideBar={openSideBar}
        className="sticky-header-sidebar"
        containterHeight={hSize}
        paddingBottom="50px"
        br={`${brSize}px solid transparent`}
      >
        <SideBarMobile onApplyNow={onApplyNow} />
        <HomeHeader />

        <div className="circle__svg">
          <HeaderStyleSvg />
        </div>
        <LaptopSvg wSize={wSize}>
          <DemoWrap>
            <Row>
              <Col xs={24} sm={24} md={24} lg={13} xl={13} xxl={13}>
                <H2Style
                  marginbottomxs="10px"
                  marginBottom="20px"
                  marginBottomSm="20px"
                >
                  Stop Searching, Start applying! Find your perfect home, hassle
                  free.
                </H2Style>
                {user || localStorage.getItem("user_id") ? (
                  ""
                ) : (
                  <Row gutter={[20, 20]}>
                    <Col>
                      <StyledButton
                        onClick={() => onApplyNow("signup")}
                        style={{ marginLeft: "0px", color: "#ffffff" }}
                      >
                        Signup
                      </StyledButton>
                    </Col>
                    <Col>
                      <StyledButton
                        onClick={() => onApplyNow("signin")}
                        style={{ marginLeft: "0px", color: "#ffffff" }}
                      >
                        Login
                      </StyledButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                <SearchBoxWrap>
                  <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    // validateMessages={validateMessages}
                  >
                    <Row gutter={[1, 1]}>
                      <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
                        <SearchBox>
                          <PTag>Location</PTag>
                          <SearchFormDiv smwh="100">
                            <SearchFormItem
                              wh="100"
                              name="cityState"
                              // label="Where you want?"
                              // rules={[
                              //   {
                              //     required: true,
                              //   },
                              // ]}
                            >
                              {/* <Input placeholder="Type here city or state" /> */}

                              <Select
                                placeholder="Type here city or state"
                                suffixIcon={<SelectSvg />}
                                showSearch
                                onSearch={handleChange}
                                allowClear
                              >
                                {citesValue
                                  ? citesValue.sort().map((cites, id) => (
                                      <Option key={id} value={cites}>
                                        {cites}
                                      </Option>
                                    ))
                                  : ""}
                              </Select>
                            </SearchFormItem>
                          </SearchFormDiv>
                        </SearchBox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
                        <SearchBox>
                          <PTag>Property Type</PTag>
                          <SearchFormDiv smwh="100">
                            <SearchFormItem
                              wh="100"
                              name="propertyType"
                              // label="Where you want?"
                              // rules={[
                              //   {
                              //     required: true,
                              //   },
                              // ]}
                            >
                              <Select
                                placeholder="Select any property type"
                                allowClear
                                suffixIcon={<SelectSvg />}
                                // defaultValue="drawSign"
                                // onChange={(value) => setSelectedSignFormat(value)}
                              >
                                <Option value="Single-family home">
                                  Single-family home
                                </Option>
                                <Option value="Condominium (Condo)">
                                  Condominium (Condo)
                                </Option>
                                <Option value="Townhouse">Townhouse</Option>
                                <Option value="Apartment">Apartment</Option>
                              </Select>
                            </SearchFormItem>
                          </SearchFormDiv>
                        </SearchBox>
                      </Col>
                      <Col
                        style={{ display: "flex", alignItems: "center" }}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="search__btn"
                      >
                        <SearchBox>
                          <PTag>Price Range</PTag>
                          <SearchFormDiv smwh="100">
                            <SearchFormItem
                              wh="100"
                              name="priceRange"
                              // label="Where you want?"
                              // rules={[
                              //   {
                              //     required: true,
                              //   },
                              // ]}
                            >
                              <Select
                                placeholder="Choose any price range"
                                allowClear
                                suffixIcon={<SelectSvg />}
                                // defaultValue="drawSign"
                                // onChange={(value) => setSelectedSignFormat(value)}
                              >
                                <Option value="0-499">$0-$499</Option>
                                <Option value="500-999">$500-$999</Option>
                                <Option value="1000-1999">$1000-$1999</Option>
                                <Option value="2000-2999">$2000-$2999</Option>
                              </Select>
                            </SearchFormItem>
                          </SearchFormDiv>
                        </SearchBox>
                        <SearchButton htmlType="submit">
                          <FiSearch />
                        </SearchButton>
                      </Col>
                    </Row>
                  </Form>
                </SearchBoxWrap>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                {/* <Row gutter={[25, 25]}>
                  <Col>
                    <H4Tag>1500+</H4Tag>
                    <H5Tag>Property Listed</H5Tag>
                  </Col>
                  <Col>
                    <H4Tag>4800+</H4Tag>
                    <H5Tag>Application recieved</H5Tag>
                  </Col>
                </Row> */}
              </Col>
              <Col
                className="search__btn__xs"
                xs={4}
                sm={4}
                md={4}
                lg={0}
                xl={0}
                xxl={0}
              >
                <Form
                  onFinish={onFinish}
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  // validateMessages={validateMessages}
                >
                  <SearchButtonXs htmlType="submit">
                    <FiSearch />
                  </SearchButtonXs>
                </Form>
              </Col>
            </Row>
          </DemoWrap>
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
            <div className="laptop__svg__mob">
              <img style={{ width: "100%" }} src="./images/dashbord-img.png" />
            </div>
          </Col>
          <div className="laptop__svg">
            <img src="./images/dashbord-img.png" />
          </div>
        </LaptopSvg>
        {/* <div className="square__svg">
          <HeaderSquareSvg />
        </div> */}
      </MainContainerSearch>

      <LoginModal
        btnValue={btnValue}
        onLogin={onLogin}
        modalState={loginModalState}
        onCancel={(value) => setLoginModalState(value)}
      />

      <div className="square__svg">
        <HeaderSquareSvg />
      </div>
    </SearchPartMain>
  );
};

export default SearchPart;

const SearchPartMain = styled.div`
  .square__svg svg {
    margin-top: -150px;
    top: 0;
    overflow: 0;
    z-index: 1;
    position: sticky;
    margin-left: 80px;
  }

  @media (max-width: 1550px) {
    .square__svg svg {
      width: 110px;
      margin-top: -130px;
      margin-left: 25px;
    }
  }

  @media (max-width: 991.98px) {
    .square__svg svg {
      margin-top: -120px;
      width: 90px;
    }
  }
`;

// HEADER ALL WRAPPER
export const StyledHeaderTop = styled.div`
  height: 80px;
  background-color: #aaaaaa;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  padding: 0 30px;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 1550px) {
    height: 65px;
  }

  @media (max-width: 991.98px) {
    padding: 0 15px;
    width: 100%;
  }
`;

export const HeaderWrap = styled.div`
  @media (max-width: 1400px) {
    padding: 0 30px;
  }
  @media (max-width: 991.98px) {
    padding: 0 15px;
  }
`;

export const LaptopSvg = styled.div`
  width: 100%;
  ${"" /* position: relative; */}

  .laptop__svg img {
    position: absolute;
    bottom: 0;
    right: 0;
    zoom: ${({ wSize }) => wSize || "100"}%;
  }

  @media (max-width: 1440px) {
    .laptop__svg img {
      width: 50%;
    }
  }

  @media (max-width: 991.98px) {
    .laptop__svg__mob {
      margin: -70px 0 0 auto;
      width: 65%;
    }

    .laptop__svg img,
    .laptop__svg {
      display: none;
    }
  }
`;

const MainContainerSearch = styled.div`
  padding-top: 1px;
  width: 100%;
  position: relative;
  background-color: ${({ bgcolor }) => bgcolor || "#ecf2ff"};
  height: ${({ containterHeight }) => containterHeight || "calc(100vh - 0px)"};

  overflow: hidden;
  :after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 120%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: perspective(400px) rotateX(45deg);
    -moz-transform: perspective(400px) rotateX(45deg);
    -ms-transform: perspective(400px) rotateX(45deg);
    -o-transform: perspective(400px) rotateX(45deg);
    transform: perspective(9px) rotateY(-20deg) rotateX(-91deg);
    transform: perspective(100px) rotateY(343deg) rotateX(271deg);
  }

  .footer__top__square {
    position: absolute;
    top: 20%;
    right: 3%;
  }
  .footer__circle {
    position: absolute;
    top: 0;
    right: 50%;
  }
  .circle__svg svg {
    position: absolute;
    top: 100px;
  }
  .dream__square {
    position: absolute;
    bottom: 30px;
    left: 70px;
  }
  .seeker__square {
    position: absolute;
    bottom: 100px;
    left: 30px;
  }
  .dream__circle {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
  .seeker__circle {
    position: absolute;
    bottom: 10px;
    right: 0;
  }

  .halfCircle__svg {
    position: absolute;
    top: 60px;
    left: 80px;
  }
  .orangeline__svg {
    position: absolute;
    top: 60px;
    right: 80px;
  }

  .square__svg svg {
    position: absolute;
    bottom: 0;
    left: 80px;
    z-index: 1;
  }

  .mob__image {
    display: flex;
    margin: auto;
    @media (max-width: 991.98px) {
      padding: 30px 0;
    }
  }

  @media (max-width: 1550px) {
    padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};

    .square__svg svg {
      bottom: -60px;
      left: 20px;
    }

    .orangeline__svg {
      top: 0;
      right: 20px;
    }

    .halfCircle__svg {
      top: 0;
      left: 20px;
    }
  }

  @media (max-width: 991.98px) {
    padding-bottom: ${({ paddingbotom }) => paddingbotom || 0};

    :after {
      top: 5px;
    }
    .square__svg svg {
      width: 90px;
    }

    .footer__circle {
      position: absolute;
      top: 150px;
      right: 60%;
    }

    .footer__top__square {
      position: absolute;
      top: 20px;
      right: 3%;
    }

    .seeker__square {
      position: absolute;
      bottom: auto;
      left: 30px;
      top: 150px;
    }

    .dream__circle {
      position: absolute;
      bottom: auto;
      top: 120px;
      right: 0;
    }

    .dream__circle svg {
      width: 117px;
      height: 116px;
    }

    .dream__square {
      position: absolute;
      bottom: auto;
      top: 50%;
      left: 25px;
    }

    .dream__square svg {
      width: 89px;
      height: 82px;
    }
  }

  @media (max-width: 842.98px) {
    height: calc(100vh - 50px);
  }
  @media (max-width: 750.98px) {
    height: calc(100vh - 70px);
  }
  @media (max-width: 580.98px) {
    height: calc(100vh - 100px);
  }
  @media (max-width: 570.98px) {
    height: calc(100vh - 150px);
  }
  @media (max-width: 425.98px) {
    height: calc(100vh - 164px);
  }
  @media (max-width: 328.98px) {
    height: calc(100vh - 90px);
  }
`;

export const MainContainer = styled.div`
  padding-top: 1px;
  width: 100%;
  position: relative;
  background-color: ${({ bgcolor }) => bgcolor || "#ecf2ff"};
  height: ${({ containterHeight }) =>
    containterHeight || "calc(100vh - 110px)"};

  .footer__left__square {
    position: absolute;
    top: 40%;
    left: 0;
  }
  .footer__bottom__square {
    position: absolute;
    bottom: 65px;
    right: 0;
  }
  .footer__top__square {
    position: absolute;
    top: 20%;
    right: 3%;
  }
  .footer__circle {
    position: absolute;
    top: 0;
    right: 50%;
  }
  .circle__svg svg {
    position: absolute;
    top: 100px;
  }
  .dream__square {
    position: absolute;
    bottom: 30px;
    left: 70px;
  }
  .seeker__square {
    position: absolute;
    bottom: 100px;
    left: 30px;
  }
  .dream__circle {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
  .seeker__circle {
    position: absolute;
    bottom: 10px;
    right: 0;
  }

  .halfCircle__svg {
    position: absolute;
    top: 60px;
    left: 80px;
  }
  .orangeline__svg {
    position: absolute;
    top: 60px;
    right: 80px;
  }

  .square__svg svg {
    position: absolute;
    bottom: 0;
    left: 80px;
    z-index: 1;
  }

  .mob__image {
    display: flex;
    margin: auto;
    @media (max-width: 991.98px) {
      padding: 30px 0;
    }
  }

  @media (max-width: 1550px) {
    padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};

    .square__svg svg {
      bottom: -60px;
      left: 20px;
    }

    .orangeline__svg {
      top: 0;
      right: 20px;
    }

    .halfCircle__svg {
      top: 0;
      left: 20px;
    }

    ${
      "" /* .circle__svg svg {
      display: none;
    }
    .square__svg svg,
    .orangeline__svg,
    .halfCircle__svg {
      display: none;
    } */
    }
  }

  @media (max-width: 991.98px) {
    ${"" /* padding-bottom: 0; */}
    padding-bottom: ${({ paddingbotom }) => paddingbotom || 0};
    .square__svg svg {
      width: 90px;
    }

    .footer__circle {
      position: absolute;
      top: 150px;
      right: 60%;
    }

    .footer__top__square {
      position: absolute;
      top: 20px;
      right: 3%;
    }

    .seeker__square {
      position: absolute;
      bottom: auto;
      left: 30px;
      top: 150px;
    }

    .dream__circle {
      position: absolute;
      bottom: auto;
      top: 120px;
      right: 0;
    }

    .dream__circle svg {
      width: 117px;
      height: 116px;
    }

    .dream__square {
      position: absolute;
      bottom: auto;
      top: 50%;
      left: 25px;
    }

    .dream__square svg {
      width: 89px;
      height: 82px;
    }
  }
`;

export const H2Style = styled.h2`
  font-weight: 700;
  color: ${({ fColor }) => fColor || "#2b353f"};
  font-size: ${({ fontSize }) => fontSize || "50px"};
  line-height: ${({ lineHeight }) => lineHeight || "70px"};
  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  padding-top: ${({ paddingtop }) => paddingtop || 0};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: ${({ lineHeight }) => lineHeight || "40px"};
    margin-top: ${({ marginTopSm }) => marginTopSm || 0};
    margin-bottom: ${({ marginBottomSm }) => marginBottomSm || 0};
    padding-top: ${({ paddingtopSm }) => paddingtopSm || 0};
    padding-bottom: ${({ paddingBottomSm }) => paddingBottomSm || 0};
  }

  @media screen and (max-width: 991.98px) {
    font-size: ${({ fontsizexs }) => fontsizexs || "24px"};
    margin-bottom: ${({ marginbottomxs }) => marginbottomxs || 0};
    margin-top: ${({ margintopxs }) => margintopxs || 0};
  }
`;

export const DemoWrap = styled.div`
  width: 1400px;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  z-index: 2;

  h3 {
    color: #848687;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin: 50px 0 0;
  }

  @media screen and (max-width: 1400px) {
    width: auto;
    padding: 0 45px;
  }

  @media screen and (max-width: 991.98px) {
    padding: 0 15px;

    .search__btn__xs {
      justify-content: end;
      display: flex;
    }

    h3 {
      margin: 0;
      color: #848687;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 10px;
    }
    span {
      font-size: 14px !important;
    }
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  padding: 4px 30px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  width: ${({ btnwidth }) => btnwidth || "auto"};
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1400px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 991.98px) {
    width: ${({ btnwidthsm }) => btnwidthsm || "auto"};
  }
  @media screen and (max-width: 769px) {
    padding: ${({ paddingbtn }) => paddingbtn || "4px 20px"};
  }
`;
