import React from "react";
import { CardWrapper, CardWrapWrap } from "./CardStyle";
import { cardDummyData } from "./dummy.data";
import UnitSection from "./UnitSection";

function Cards({ allUnits, fromSearch = true, loadingUnit = false }) {
  return (
    <>
      <CardWrapper>
        <CardWrapWrap>
          <UnitSection
            fromSearch={fromSearch}
            allUnits={allUnits}
            cardDummyData={cardDummyData}
            loadingUnit={loadingUnit}
          />
        </CardWrapWrap>
      </CardWrapper>
    </>
  );
}

export default Cards;
