import React from "react";

const orangeLineSvg = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="176"
      viewBox="0 0 113 176"
      fill="none"
    >
      <path d="M70.3086 20.1602H0V175.107H70.3086V20.1602Z" fill="#FFF6F1" />
      <path d="M26.6742 0H23.2588V154.947H26.6742V0Z" fill="#FBC8AA" />
      <path d="M43.9289 0H40.5137V154.947H43.9289V0Z" fill="#FBC8AA" />
      <path d="M61.2102 0H57.7949V154.947H61.2102V0Z" fill="#FBC8AA" />
      <path d="M78.4651 0H75.0498V154.947H78.4651V0Z" fill="#FBC8AA" />
      <path d="M95.7465 0H92.3311V154.947H95.7465V0Z" fill="#FBC8AA" />
      <path d="M112.999 0H109.584V154.947H112.999V0Z" fill="#FBC8AA" />
    </svg>
  );
};

export default orangeLineSvg;
