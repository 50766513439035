import React from "react";

const halfCircle = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="141"
      viewBox="0 0 96 141"
      fill="none"
    >
      <path
        d="M25.9643 140.038C27.6721 140.038 29.0566 138.654 29.0566 136.947C29.0566 135.24 27.6721 133.855 25.9643 133.855C24.2565 133.855 22.8721 135.24 22.8721 136.947C22.8721 138.654 24.2565 140.038 25.9643 140.038Z"
        fill="#DAE4F9"
      />
      <path
        d="M34.5185 136.45C34.3745 135.639 34.5588 134.803 35.0307 134.128C35.5026 133.453 36.2235 132.992 37.0349 132.849C37.8463 132.705 38.6816 132.889 39.3571 133.361C40.0327 133.832 40.4932 134.553 40.6371 135.364C40.7811 136.176 40.5969 137.011 40.1249 137.686C39.653 138.361 38.9319 138.822 38.1205 138.966C37.3091 139.11 36.4738 138.925 35.7983 138.454C35.1228 137.982 34.6625 137.261 34.5185 136.45Z"
        fill="#DAE4F9"
      />
      <path
        d="M45.9326 133.952C45.7943 133.57 45.7327 133.164 45.7513 132.758C45.7699 132.352 45.8684 131.954 46.041 131.586C46.2136 131.218 46.4569 130.887 46.7572 130.613C47.0575 130.34 47.4089 130.127 47.7912 129.989C48.1735 129.851 48.5794 129.789 48.9855 129.808C49.3916 129.827 49.7902 129.925 50.1582 130.097C50.5263 130.27 50.8568 130.513 51.1308 130.814C51.4049 131.114 51.617 131.465 51.7553 131.847C51.8964 132.227 51.9606 132.631 51.9442 133.036C51.9278 133.441 51.8311 133.839 51.6597 134.206C51.4883 134.573 51.2455 134.902 50.9457 135.175C50.6459 135.448 50.2949 135.658 49.9131 135.794C49.5309 135.935 49.1243 136 48.717 135.984C48.3097 135.968 47.9096 135.872 47.5396 135.701C47.1697 135.529 46.8372 135.287 46.5614 134.987C46.2856 134.687 46.072 134.335 45.9326 133.952Z"
        fill="#DAE4F9"
      />
      <path
        d="M56.7539 129.512C56.5481 129.163 56.4138 128.776 56.3586 128.375C56.3034 127.973 56.3284 127.565 56.4325 127.173C56.5366 126.782 56.7176 126.414 56.9648 126.093C57.212 125.772 57.5205 125.503 57.8725 125.302C58.4012 124.993 59.0099 124.848 59.6213 124.885C60.2328 124.922 60.8194 125.139 61.3069 125.51C61.7944 125.881 62.1607 126.388 62.3594 126.968C62.5581 127.547 62.5803 128.172 62.423 128.764C62.2658 129.356 61.9362 129.888 61.4761 130.292C61.0161 130.697 60.4463 130.955 59.839 131.035C59.2317 131.115 58.6143 131.013 58.0651 130.742C57.5158 130.471 57.0594 130.043 56.7539 129.512Z"
        fill="#DAE4F9"
      />
      <path
        d="M66.6236 123.262C66.0958 122.634 65.8391 121.822 65.91 121.005C65.981 120.188 66.3739 119.432 67.0021 118.905C67.6303 118.377 68.4423 118.12 69.2597 118.191C70.0771 118.262 70.8328 118.655 71.3607 119.283C71.8456 119.933 72.0736 120.738 72.0007 121.546C71.9279 122.353 71.5593 123.105 70.9659 123.657C70.3368 124.178 69.527 124.429 68.7133 124.355C67.8997 124.281 67.1485 123.888 66.6236 123.262Z"
        fill="#DAE4F9"
      />
      <path
        d="M75.2422 115.439C74.9298 115.176 74.6725 114.854 74.4851 114.492C74.2976 114.129 74.1838 113.733 74.1502 113.327C74.1165 112.92 74.1638 112.511 74.2891 112.123C74.4144 111.734 74.6154 111.375 74.8804 111.064C75.1401 110.753 75.4591 110.497 75.8189 110.31C76.1786 110.123 76.5719 110.009 76.976 109.975C77.38 109.942 77.7867 109.989 78.1724 110.114C78.5581 110.238 78.9151 110.439 79.2227 110.703C79.8488 111.227 80.2418 111.978 80.3158 112.792C80.3897 113.605 80.1386 114.415 79.6175 115.044C79.065 115.637 78.3129 116.006 77.5054 116.078C76.698 116.151 75.892 115.923 75.2422 115.439Z"
        fill="#DAE4F9"
      />
      <path
        d="M82.3806 106.162C82.0274 105.958 81.718 105.686 81.4704 105.362C81.2228 105.038 81.0419 104.668 80.9379 104.274C80.834 103.879 80.8092 103.468 80.8649 103.064C80.9205 102.66 81.0554 102.271 81.262 101.92C81.465 101.568 81.7354 101.259 82.0577 101.011C82.38 100.764 82.748 100.582 83.1405 100.477C83.533 100.372 83.9426 100.345 84.3456 100.397C84.7486 100.45 85.1372 100.582 85.4893 100.785C85.8413 100.988 86.1498 101.258 86.3974 101.58C86.645 101.903 86.8267 102.271 86.9321 102.663C87.0375 103.055 87.0646 103.465 87.0118 103.868C86.9589 104.27 86.8271 104.659 86.6241 105.011C86.1834 105.692 85.5062 106.187 84.7229 106.399C83.9395 106.612 83.1053 106.527 82.3806 106.162Z"
        fill="#DAE4F9"
      />
      <path
        d="M87.8083 95.704C87.2318 95.4969 86.7299 95.1233 86.3661 94.6306C86.0023 94.1378 85.793 93.5481 85.765 92.9363C85.7369 92.3245 85.8912 91.7181 86.2083 91.1941C86.5254 90.6701 86.9912 90.2521 87.5463 89.9931C88.1014 89.7342 88.721 89.6459 89.3264 89.7394C89.9318 89.833 90.4958 90.1043 90.9467 90.5188C91.3977 90.9333 91.7154 91.4723 91.8595 92.0676C92.0036 92.6629 91.9675 93.2875 91.7559 93.8623C91.62 94.244 91.4096 94.595 91.1369 94.8947C90.8642 95.1945 90.5346 95.4371 90.1673 95.6084C89.8 95.7798 89.4025 95.8765 88.9976 95.8929C88.5926 95.9093 88.1883 95.8451 87.8083 95.704Z"
        fill="#DAE4F9"
      />
      <path
        d="M91.3627 84.6869C90.7614 84.581 90.2048 84.2996 89.7633 83.878C89.3217 83.4565 89.0149 82.9137 88.8814 82.318C88.748 81.7224 88.7939 81.1005 89.0133 80.5309C89.2328 79.9613 89.6161 79.4695 90.1148 79.1173C90.6135 78.7651 91.2053 78.5684 91.8156 78.5519C92.426 78.5355 93.0274 78.7 93.5444 79.0248C94.0613 79.3496 94.4705 79.8202 94.7204 80.3772C94.9702 80.9341 95.0496 81.5526 94.9484 82.1545C94.8808 82.5569 94.7341 82.9419 94.517 83.2874C94.2998 83.6328 94.0164 83.9319 93.6831 84.1673C93.3498 84.4027 92.973 84.5698 92.5748 84.659C92.1766 84.7482 91.7646 84.7576 91.3627 84.6869Z"
        fill="#DAE4F9"
      />
      <path
        d="M92.9077 73.1087C94.6155 73.1087 95.9999 71.7246 95.9999 70.0173C95.9999 68.3099 94.6155 66.9258 92.9077 66.9258C91.1999 66.9258 89.8154 68.3099 89.8154 70.0173C89.8154 71.7246 91.1999 73.1087 92.9077 73.1087Z"
        fill="#DAE4F9"
      />
      <path
        d="M92.4131 61.4332C91.811 61.5343 91.1925 61.455 90.6354 61.2052C90.0783 60.9554 89.6077 60.5463 89.2828 60.0295C88.9579 59.5127 88.7933 58.9113 88.8097 58.3011C88.8262 57.6909 89.0228 57.0993 89.3751 56.6007C89.7274 56.1022 90.2195 55.719 90.7892 55.4996C91.359 55.2802 91.981 55.2343 92.5768 55.3677C93.1726 55.5012 93.7154 55.808 94.1371 56.2494C94.5587 56.6909 94.8403 57.2472 94.9462 57.8484C95.017 58.2502 95.0073 58.6621 94.9181 59.0602C94.8289 59.4583 94.6619 59.8349 94.4264 60.1681C94.1909 60.5014 93.8917 60.7848 93.5462 61.0019C93.2006 61.219 92.8156 61.3655 92.4131 61.4332Z"
        fill="#DAE4F9"
      />
      <path
        d="M89.9141 50.0243C89.5342 50.1654 89.13 50.2297 88.7251 50.2133C88.3201 50.1969 87.9224 50.1002 87.5551 49.9288C87.1879 49.7575 86.8582 49.5148 86.5856 49.215C86.3129 48.9153 86.1024 48.5643 85.9665 48.1826C85.755 47.6078 85.7191 46.9832 85.8631 46.3879C86.0072 45.7926 86.3247 45.2536 86.7757 44.8391C87.2267 44.4246 87.7908 44.1533 88.3962 44.0597C89.0016 43.9661 89.6212 44.0545 90.1763 44.3135C90.7315 44.5725 91.197 44.9905 91.5141 45.5145C91.8313 46.0385 91.9855 46.6449 91.9575 47.2567C91.9294 47.8685 91.7203 48.4581 91.3565 48.9509C90.9927 49.4436 90.4906 49.8172 89.9141 50.0243Z"
        fill="#DAE4F9"
      />
      <path
        d="M85.4726 39.2351C84.9416 39.5355 84.3336 39.6722 83.725 39.6279C83.1165 39.5836 82.5346 39.3603 82.0527 38.9861C81.5709 38.6119 81.2106 38.1037 81.0171 37.5252C80.8236 36.9467 80.8055 36.3238 80.9653 35.7351C81.1251 35.1464 81.4556 34.6181 81.915 34.2167C82.3744 33.8153 82.9422 33.5588 83.5471 33.4794C84.1521 33.4 84.7671 33.5014 85.3146 33.7707C85.8621 34.0399 86.3176 34.4651 86.6239 34.9926C86.9894 35.7172 87.074 36.5513 86.8615 37.3345C86.6489 38.1176 86.1543 38.7945 85.4726 39.2351Z"
        fill="#DAE4F9"
      />
      <path
        d="M79.2219 29.367C78.5928 29.888 77.7829 30.139 76.9693 30.065C76.1557 29.9911 75.4045 29.5983 74.8796 28.9723C74.6155 28.6648 74.4152 28.3078 74.2903 27.9222C74.1654 27.5366 74.1185 27.13 74.1522 26.7261C74.1858 26.3221 74.2995 25.929 74.4865 25.5693C74.6735 25.2097 74.9301 24.8908 75.2414 24.6312C75.5518 24.3663 75.9115 24.1653 76.2999 24.04C76.6883 23.9147 77.0977 23.8675 77.5044 23.9012C77.9111 23.9348 78.3072 24.0486 78.6698 24.236C79.0323 24.4234 79.3541 24.6806 79.6167 24.9929C80.1016 25.6425 80.3294 26.4482 80.2565 27.2555C80.1837 28.0627 79.8153 28.8147 79.2219 29.367Z"
        fill="#DAE4F9"
      />
      <path
        d="M71.3605 20.7177C71.1137 21.0649 70.7977 21.3573 70.4325 21.5767C70.0673 21.796 69.6608 21.9376 69.2382 21.9924C68.8157 22.0472 68.3863 22.0141 67.9771 21.8952C67.568 21.7763 67.1879 21.5743 66.8606 21.3016C66.5333 21.0289 66.2659 20.6914 66.0751 20.3105C65.8844 19.9297 65.7745 19.5135 65.7523 19.0881C65.73 18.6627 65.7959 18.2373 65.9458 17.8386C66.0958 17.4399 66.3264 17.0765 66.6235 16.7711C67.1513 16.1431 67.9072 15.7505 68.7246 15.6796C69.5421 15.6086 70.3541 15.8653 70.9822 16.393C71.6104 16.9207 72.0031 17.6762 72.0741 18.4934C72.145 19.3106 71.8883 20.1225 71.3605 20.7506V20.7177Z"
        fill="#DAE4F9"
      />
      <path
        d="M62.1157 13.4836C61.9127 13.8356 61.6423 14.1442 61.32 14.3917C60.9977 14.6393 60.6297 14.8208 60.2372 14.9262C59.8447 15.0315 59.4353 15.0586 59.0323 15.0058C58.6293 14.9529 58.2407 14.8212 57.8887 14.6183C57.5366 14.4153 57.2279 14.1449 56.9803 13.8227C56.7328 13.5004 56.551 13.1326 56.4456 12.7402C56.3403 12.3478 56.3133 11.9384 56.3661 11.5355C56.419 11.1326 56.5506 10.7441 56.7536 10.3922C57.1637 9.68127 57.8394 9.16238 58.6322 8.94959C59.4249 8.73681 60.2699 8.84756 60.9809 9.25751C61.692 9.66747 62.2109 10.343 62.4237 11.1356C62.6366 11.9281 62.5258 12.7727 62.1157 13.4836Z"
        fill="#DAE4F9"
      />
      <path
        d="M51.7532 8.18907C51.6174 8.57082 51.4069 8.92167 51.1342 9.22144C50.8615 9.5212 50.5319 9.76389 50.1647 9.93524C49.7974 10.1066 49.3997 10.2033 48.9947 10.2197C48.5897 10.2361 48.1856 10.1719 47.8057 10.0308C47.0568 9.71601 46.4509 9.13527 46.1047 8.40054C45.7585 7.66582 45.6965 6.82899 45.9306 6.05128C46.2141 5.27934 46.7928 4.6517 47.5392 4.30631C48.2857 3.96092 49.1389 3.92608 49.9111 4.20956C50.6832 4.49304 51.311 5.07154 51.6565 5.81783C52.0019 6.56412 52.0368 7.41714 51.7532 8.18907Z"
        fill="#DAE4F9"
      />
      <path
        d="M40.6371 4.63886C40.4932 5.45005 40.0327 6.17084 39.3571 6.64267C38.6816 7.1145 37.8463 7.29876 37.0349 7.15484C36.2235 7.01092 35.5026 6.55063 35.0307 5.87527C34.5588 5.19991 34.3745 4.36479 34.5185 3.55361C34.7068 2.76396 35.1804 2.07176 35.8485 1.61032C36.5165 1.14889 37.3316 0.950792 38.137 1.05409C38.9438 1.19834 39.6603 1.65695 40.1291 2.32914C40.5979 3.00133 40.7807 3.83208 40.6371 4.63886Z"
        fill="#DAE4F9"
      />
      <path
        d="M25.9643 6.18295C27.6721 6.18295 29.0566 4.79884 29.0566 3.09147C29.0566 1.3841 27.6721 0 25.9643 0C24.2565 0 22.8721 1.3841 22.8721 3.09147C22.8721 4.79884 24.2565 6.18295 25.9643 6.18295Z"
        fill="#DAE4F9"
      />
      <path
        d="M17.3783 3.61765C17.486 4.21965 17.4126 4.84003 17.1675 5.40035C16.9225 5.96067 16.5168 6.43571 16.0016 6.76543C15.4864 7.09515 14.885 7.26476 14.2735 7.25271C13.6619 7.24064 13.0676 7.04745 12.5658 6.69768C12.064 6.34791 11.6773 5.85729 11.4545 5.28774C11.2317 4.71819 11.183 4.0954 11.3143 3.49811C11.4457 2.90083 11.7512 2.35583 12.1924 1.9322C12.6335 1.50857 13.1903 1.22527 13.7926 1.11814C14.5996 0.974577 15.4306 1.1572 16.103 1.62589C16.7753 2.09457 17.2341 2.81099 17.3783 3.61765Z"
        fill="#DAE4F9"
      />
      <path
        d="M5.96203 6.05264C6.10243 6.43487 6.16616 6.84106 6.14956 7.2479C6.13297 7.65474 6.03652 8.05426 5.86546 8.42379C5.69439 8.79331 5.45217 9.1256 5.15268 9.40155C4.85319 9.6775 4.50218 9.89179 4.11986 10.0322C3.73754 10.1725 3.33135 10.2362 2.9244 10.2196C2.51746 10.203 2.11784 10.1064 1.74822 9.9354C1.37861 9.76438 1.04623 9.52232 0.770216 9.2229C0.494198 8.92349 0.27996 8.57266 0.139561 8.19044C-0.0945352 7.41272 -0.0325586 6.5759 0.313639 5.84117C0.659836 5.10644 1.26579 4.52571 2.01466 4.21093C2.3946 4.06984 2.79872 4.00564 3.20369 4.02204C3.60866 4.03844 4.00637 4.13512 4.37365 4.30648C4.74092 4.47783 5.07052 4.72041 5.34322 5.02018C5.61592 5.31994 5.82615 5.6709 5.96203 6.05264Z"
        fill="#DAE4F9"
      />
      <path
        d="M11.292 135.366C11.3992 134.764 11.6824 134.207 12.1062 133.766C12.5299 133.325 13.075 133.02 13.6725 132.889C14.2699 132.757 14.8927 132.806 15.4624 133.029C16.0321 133.251 16.5231 133.638 16.8729 134.14C17.2228 134.641 17.4158 135.235 17.4279 135.847C17.4399 136.458 17.2704 137.06 16.9406 137.575C16.6108 138.09 16.1355 138.495 15.5751 138.74C15.0146 138.985 14.3941 139.059 13.7919 138.951C12.9851 138.807 12.2686 138.348 11.7998 137.676C11.331 137.004 11.1484 136.173 11.292 135.366Z"
        fill="#DAE4F9"
      />
    </svg>
  );
};

export default halfCircle;
