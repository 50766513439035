import React, { useEffect, useState } from "react";
import Cards from "../../components/featuredProperties/Cards/Cards";
import { useDispatch, useSelector } from "react-redux";

function SearchSection() {
  const dispatch = useDispatch();
  const [allUnits, setAllUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const { topUnitsData } = useSelector((state) => state.user);

  // get all units data (result in card view)
  // useEffect(() => {
  //   if (topUnitsData && Object.values(topUnitsData)?.length) {
  //     setAllUnits(Object.values(topUnitsData));
  //   } else {
  //     setLoading(true);
  //     dispatch(
  //       topUnitsAPI({
  //         organization_id: "b6e63465-b77e-463d-8676-25f2e7c4e5be",
  //       })
  //     ).then(({ payload }) => {
  //       setLoading(false);
  //       if (payload) {
  //         setAllUnits(Object.values(payload));
  //       }
  //     });
  //   }
  // }, []);

  return (
    <div>
      <Cards loadingUnit={loading} fromSearch={false} allUnits={allUnits} />
    </div>
  );
}

export default SearchSection;
