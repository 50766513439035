import React, { useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import Applications from "./Applications";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import Profile from "./Profile";

function MyAccountTabs() {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("profile");

  return (
    <>
      <MyAccountTabsWrap>
        <PageTitle>
          <h2>My Account</h2>
        </PageTitle>
        <StyledTabWrapper>
          <StyledTabs
            defaultActiveKey={"profile"}
            activeKey={activeTab}
            onChange={(tabKey) => {
              // setList([]);
              setActiveTab(tabKey);
            }}
            tabPosition="left"
          >
            <TabPane tab="Profile" key="profile">
              <Profile activeTab={activeTab} />
            </TabPane>
            <TabPane tab="Applications" key="applications">
              <Applications />
            </TabPane>
            {/* <TabPane tab="Change Password" key="change-password">
              <ChangePassword />
            </TabPane> */}
            <TabPane tab="Delete Account" key="delete-account">
              <DeleteAccount />
            </TabPane>
          </StyledTabs>
        </StyledTabWrapper>
      </MyAccountTabsWrap>
    </>
  );
}

export default MyAccountTabs;

export const StyledTabWrapper = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    ${"" /* width: 1400px; */}
    margin: auto;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    bottom: 3px;
  }
  .ant-tabs-left > .ant-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid #d9d9d9;
    ${"" /* height: calc(100vh - 400px); */}
    margin-top: -24px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 150px 8px 24px !important;
  }

  .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 18px;
    color: #aaaaaa;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background: #ecf2ff;
    border-radius: 5px 0px 0px 5px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.secondary};
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  @media (max-width: 1550px) {
    .ant-tabs-tab {
      padding: 8px 120px 8px 24px !important;
    }
  }
`;

export const PageTitle = styled.div`
  h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
    color: #000000;
    margin-bottom: 50px;
  }
  @media (max-width: 1550px) {
    h2 {
      font-size: 44px;
      line-height: 58px;
      margin-bottom: 35px;
    }
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 32px;
      line-height: 30px;
    }
  }
`;
export const MyAccountTabsWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 50px 100px;

  /* scrollbar design */
  body::-webkit-scrollbar {
    width: 0.5em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    outline: 1px solid rgb(112, 128, 144);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    body::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    body::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    body::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    padding: 30px;
  }
`;
