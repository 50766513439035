import React from "react";

const propDetailSvg = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5393_1063)">
        <path
          d="M28.5 16.6951V6.631L21.8666 0H0V38H28.5V37.9299C33.8426 37.3362 37.9976 32.813 38 27.3125C37.9976 21.8096 33.8426 17.2852 28.5 16.6951ZM21.3726 2.86544L25.6334 7.125H21.3726V2.86544ZM2.375 35.625V2.37263H19V9.49881H26.125V16.6951C24.0445 16.9254 22.1445 17.7519 20.6007 19H4.75V21.375H18.4264C17.9372 22.1065 17.5441 22.9057 17.2461 23.75H4.75V26.125H16.6951C16.6511 26.5157 16.625 26.9099 16.625 27.3125C16.625 30.6719 18.1783 33.6668 20.6019 35.625H2.375ZM27.3125 35.4861C22.7988 35.4754 19.1472 31.8238 19.1377 27.3125C19.1472 22.7988 22.7988 19.1472 27.3125 19.1366C31.8238 19.1472 35.4754 22.7988 35.4861 27.3125C35.4754 31.8238 31.8238 35.4754 27.3125 35.4861ZM23.75 14.25H4.75V16.625H23.75V14.25Z"
          fill="#F67C36"
        />
        <path
          d="M26.125 33.25H28.5V26.125H26.125V33.25ZM26.125 21.375V23.75H28.5V21.375H26.125Z"
          fill="#F67C36"
        />
      </g>
      <defs>
        <clipPath id="clip0_5393_1063">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default propDetailSvg;
