import React from "react";
import { MainContainer } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";
import styled from "styled-components";
import { H2Style } from "./MultiApplicationPart";
import { CheckBox, HalfCircle } from "../../../assets/svgs";

function WelcomeToAvenew() {
  const checkBoxText = [
    "Rental History",
    "Background and Criminal Check",
    "Credit History",
    "Income Requirements and Employment Verification",
    "Pet Restrictions",
  ];

  return (
    <MainContainer bgcolor="#ffffff" containterHeight="auto">
      <TopTrendingSectionWrap margincomponent="140px auto 100px">
        <TopSection>
          <Row className="welcome__contant" justify="space-between">
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
              <ImageWrpa>
                <img className="main__img" src="/images/welcome-avenew.png" />
                {/* <img className="circle__img" src="/images/image-line.png" /> */}
                {/* <UnitSmallWrap>
                <Row gutter={[10, 10]} style={{ lignItems: "center" }}>
                  <Col>
                    <img src="/images/unitSmallImg.png" />
                  </Col>
                  <Col>
                    <p>Green wood villa</p>
                    <span>2 bed | 2 Bath | 650 Sqft</span>
                  </Col>
                </Row>
              </UnitSmallWrap> */}
              </ImageWrpa>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
              <Row>
                <Col span={24}>
                  <Rectangle className="divider__long" />
                </Col>
              </Row>
              <H2Style
                lineheightxs="32px"
                lineHeight="50px"
                marginBottom="20px"
                marginTop="20px"
                paddingBottomxs="20px"
                paddingtopxs="10px"
              >
                Welcome to Avenew we're dedicated to transforming the way you
                search for rental properties.
              </H2Style>
              <SpanTag>
                We understand that searching for a new home can be a
                time-consuming and often frustrating process. That's why we've
                created a platform that simplifies the application journey,
                allowing you to apply just once and discover which properties
                you qualify for. Gone are the days of submitting multiple
                applications and waiting anxiously for responses.
              </SpanTag>
            </Col>
          </Row>
        </TopSection>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default WelcomeToAvenew;

const SpanTag = styled.span`
  color: #848687;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  @media (max-width: 991.98px) {
    line-height: 24px;
  }
`;

const TopSection = styled.div`
  width: 100%;
  margin-bottom: 100px;

  .divider__long {
    margin-top: 100px;
  }

  @media (max-width: 1400px) {
    margin-bottom: 50px;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 35px;

    .divider__long {
      margin-top: 35px;
    }
    ${"" /* display: none; */}

    ${
      "" /* .welcome__contant {
      flex-direction: column-reverse;
    } */
    }
  }
`;

const UnitSmallWrap = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px 50px 5px 5px;
  position: absolute;
  left: 0;
  top: 0;

  p {
    color: #2b353f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
  span {
    color: #848687;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const ImageWrpa = styled.div`
  ${
    "" /* justify-content: end;
  display: flex;
  position: relative; */
  }
  .main__img {
    width: 100%;
  }
  .circle__img {
    position: absolute;
    height: 580px;
    border-radius: inherit;
    object-fit: fill;
    width: 76%;
    top: -15px;
    right: -19px;
  }
`;
