import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import data from "../../../Profile-verification/state.json";
import {
  applicationProcess,
  getApplicationProcess,
} from "../../../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RiArrowGoBackLine } from "react-icons/ri";
import { formatedValue } from "../../../../utils/fn";

function YourSpouse({
  onYourSpouseNext,
  profileSubmit,
  onBackClick,
  selectedStateProfile,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [undoFields, setUndoFields] = useState(false);

  const monthFormat = "MM/DD/YYYY";
  const { Option } = Select;

  // Function to validate 2 digit after decimal for Height and Weight
  const decimalValueValidate = (value = "") => {
    if (value.length) {
      let stringValue = value.toString();
      let replaceZero = stringValue.replace("^(d*.d*?[1-9])0+$", "");
      let fixed2 = Number(replaceZero).toFixed(2);
      return parseFloat(fixed2);
    } else {
      return value;
    }
  };

  const onFinish = (value) => {
    let data = {};
    data.stateProfile = [];
    value.height = decimalValueValidate(value.height);
    value.weight = decimalValueValidate(value.weight);
    value.monthlyIncome = formatedValue(value.monthlyIncome);
    let stateData = {};
    stateData.stateName = selectedStateProfile.stateName;
    stateData.id = selectedStateProfile.id;
    stateData.spouseDetails = value;
    data.stateProfile.push(stateData);
    setIsSubmit(true);
    dispatch(applicationProcess(data)).then(({ payload }) => {
      // dispatch(getApplicationProcess());
      if (selectedStateProfile?.id) {
      } else {
        const payloadDat = payload?.state_profile;
        var stateValue = payloadDat?.filter(function (item) {
          return item["stateName"] === stateData.stateName;
        });
        profileSubmit(stateValue?.length ? stateValue[0] : {}, payloadDat);
      }
      setIsSubmit(false);
      onYourSpouseNext("your-work");
    });
  };

  // useEffect(() => {
  //   if (
  //     applicationProcessData?.stateProfile &&
  //     applicationProcessData?.stateProfile?.length &&
  //     applicationProcessData?.stateProfile[0]?.spouseDetails
  //   ) {
  //     const spouseDetails =
  //       applicationProcessData?.stateProfile[0]?.spouseDetails;

  //     form.setFieldsValue({
  //       fullName: spouseDetails?.fullName,
  //       lastName: spouseDetails?.lastName,
  //       spouseSocialSecurity: spouseDetails?.spouseSocialSecurity,
  //       birthdate: spouseDetails?.birthdate
  //         ? moment(spouseDetails?.birthdate)
  //         : "",
  //       height: spouseDetails?.height,
  //       weight: spouseDetails?.weight,
  //       sex: spouseDetails?.sex,
  //       eyeColor: spouseDetails?.eyeColor,
  //       hairColor: spouseDetails?.hairColor,
  //       USCitizen: spouseDetails?.USCitizen,
  //       presentEmployer: spouseDetails?.presentEmployer,
  //       address: spouseDetails?.address,
  //       state: spouseDetails?.state,
  //       city: spouseDetails?.city,
  //       zipcode: spouseDetails?.zipcode,
  //       workPhone: spouseDetails?.workPhone,
  //       positions: spouseDetails?.positions,
  //       monthlyIncome: spouseDetails?.monthlyIncome,
  //       begenJob: spouseDetails?.begenJob
  //         ? moment(spouseDetails?.begenJob)
  //         : "",
  //       supervisorNameAndPhone: spouseDetails?.supervisorNameAndPhone,

  //       // invoiceCreatedDate: moment(selectedInvoice?.invoiceCreatedDate),
  //       // dueDate: moment(selectedInvoice?.dueDate),
  //     });
  //   } else {
  //   }
  // }, [applicationProcessData]);

  useEffect(() => {
    if (selectedStateProfile?.spouseDetails) {
      const spouseDetails = selectedStateProfile?.spouseDetails;

      form.setFieldsValue({
        fullName: spouseDetails?.fullName,
        lastName: spouseDetails?.lastName,
        spouseSocialSecurity: spouseDetails?.spouseSocialSecurity,
        birthdate: spouseDetails?.birthdate
          ? moment(spouseDetails?.birthdate)
          : "",
        height: spouseDetails?.height,
        weight: spouseDetails?.weight,
        sex: spouseDetails?.sex,
        eyeColor: spouseDetails?.eyeColor,
        hairColor: spouseDetails?.hairColor,
        USCitizen: spouseDetails?.USCitizen,
        presentEmployer: spouseDetails?.presentEmployer,
        address: spouseDetails?.address,
        state: spouseDetails?.state,
        city: spouseDetails?.city,
        zipcode: spouseDetails?.zipcode,
        workPhone: spouseDetails?.workPhone,
        positions: spouseDetails?.positions,
        monthlyIncome: spouseDetails?.monthlyIncome,
        begenJob: spouseDetails?.begenJob
          ? moment(spouseDetails?.begenJob)
          : "",
        supervisorNameAndPhone: spouseDetails?.supervisorNameAndPhone,

        // invoiceCreatedDate: moment(selectedInvoice?.invoiceCreatedDate),
        // dueDate: moment(selectedInvoice?.dueDate),
      });
    } else {
    }
  }, [selectedStateProfile]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    if (stateValue) {
      setCitesValue(data[stateValue].sort());
    }
  }, [stateValue]);

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  const undoHandler = () => {
    if (selectedStateProfile?.spouseDetails) {
      const spouseDetails = selectedStateProfile?.spouseDetails;

      form.setFieldsValue({
        fullName: spouseDetails?.fullName,
        lastName: spouseDetails?.lastName,
        spouseSocialSecurity: spouseDetails?.spouseSocialSecurity,
        birthdate: spouseDetails?.birthdate
          ? moment(spouseDetails?.birthdate)
          : "",
        height: spouseDetails?.height,
        weight: spouseDetails?.weight,
        sex: spouseDetails?.sex,
        eyeColor: spouseDetails?.eyeColor,
        hairColor: spouseDetails?.hairColor,
        USCitizen: spouseDetails?.USCitizen,
        presentEmployer: spouseDetails?.presentEmployer,
        address: spouseDetails?.address,
        state: spouseDetails?.state,
        city: spouseDetails?.city,
        zipcode: spouseDetails?.zipcode,
        workPhone: spouseDetails?.workPhone,
        positions: spouseDetails?.positions,
        monthlyIncome: spouseDetails?.monthlyIncome,
        begenJob: spouseDetails?.begenJob
          ? moment(spouseDetails?.begenJob)
          : "",
        supervisorNameAndPhone: spouseDetails?.supervisorNameAndPhone,

        // invoiceCreatedDate: moment(selectedInvoice?.invoiceCreatedDate),
        // dueDate: moment(selectedInvoice?.dueDate),
      });
    } else {
      form.resetFields();
    }
    setUndoFields(false);
  };

  return (
    <GeneralProfileWrap>
      <h1>Co-applicant personal details</h1>

      <FormWrap style={{ position: "static" }} mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              onValuesChange={() => setUndoFields(true)}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="fullName"
                  label="Full name (Exactly same on gov. ID proof)"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Full name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Formal last name (maiden and married)"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Formal last name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="spouseSocialSecurity"
                  label="Co-applicant social security"
                  rules={[
                    // {
                    //   required: true,
                    // },
                    { max: 9, message: "Invalid Number" },
                    { min: 9, message: "" },
                  ]}
                >
                  <Input
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="birthdate"
                  label="Birthdate"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    disabledDate={(current) => {
                      return moment().subtract(18, "years") <= current;
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="height"
                  label="Height (Foot)"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input type="number" min="0" step="0.01" pattern="[0-9]*" />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="weight"
                  label="Weight (lb)"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    step="0.01"
                    pattern="[0-9]*"
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="sex"
                  label="Sex"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // defaultValue="Male"
                    placeholder="Sex"
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                  {/* <Input /> */}
                </Form.Item>
                <Form.Item
                  name="eyeColor"
                  label="Eye color"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // defaultValue="Male"
                    placeholder="Eye Color"
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value="Black">Black</Option>
                    <Option value="Brown">Brown</Option>
                    <Option value="Blue">Blue</Option>
                    <Option value="Green">Green</Option>
                    <Option value="Gray">Gray</Option>
                    <Option value="Blond">Hazzel</Option>
                  </Select>
                  {/* <Input /> */}
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="hairColor"
                  label="Hair color"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // defaultValue="Male"
                    placeholder="Hair Color"
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value="Black">Black</Option>
                    <Option value="Brown">Brown</Option>
                    <Option value="White">White</Option>
                    <Option value="Gray">Gray or Partially Gray</Option>
                    <Option value="Blond">Blond</Option>
                  </Select>
                  {/* <Input /> */}
                </Form.Item>
              </StyledFormItem>

              <h1>Co-applicant company details</h1>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="USCitizen"
                  label="Are you U.S. citizen?"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // showSearch
                    placeholder=""
                    // onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="presentEmployer"
                  label="Present employer"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="address"
                  label="Address"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="state"
                  label="State"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                    {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    // onChange={}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {citesValue
                      ? citesValue.map((cites) => (
                          <Option value={cites}>{cites}</Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="Zip code"
                  rules={[{ max: 6, message: "Invalid Zip code!" }]}
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    pattern="[0-9]*"
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  className="input__affix"
                  name="workPhone"
                  label="Work Phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                  rules={[
                    { max: 10, message: "Invalid Phone Number!" },
                    { min: 10, message: "" },
                  ]}
                >
                  <Input
                    type="number"
                    // prefix={`+1`}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                    pattern="[0-9]*"
                  />
                </Form.Item>
                <Form.Item
                  name="positions"
                  label="Positions"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="monthlyIncome"
                  label="Gross monthly income"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    prefix={`$`}
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    step="0.0001"
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="begenJob"
                  label="Date you begin this job "
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") <= current;
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  name="supervisorNameAndPhone"
                  label="Supervisor’s name and phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack onClick={() => onBackClick()} />
                    <h2 onClick={() => onBackClick()}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      {undoFields ? (
                        <Col>
                          <p className="cancel__btn" onClick={undoHandler}>
                            <RiArrowGoBackLine />
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default YourSpouse;
