import React, { useEffect, useState } from "react";
import { MainContainer } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";
import styled from "styled-components";
import { UserAvatar } from "../../../ui/Avatar";
import { LineCircleSvg, LineSvg } from "../../../assets/svgs";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

function MultiApplicationPart() {
  const { width } = useWindowSize();
  const [wSize, setWSize] = useState(100);

  useEffect(() => {
    const totalWidth = 1920;
    const newWidth = (100 * width) / totalWidth;
    setWSize(newWidth);
  }, [width]);

  return (
    <MainContainer bgcolor="#ffffff" containterHeight="auto">
      <TopTrendingSectionWrap>
        <Row>
          <Col span={24}>
            <Rectangle />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <H2Style
              paddingBottomxs="20px"
              paddingtopxs="10px"
              lineheightxs="32px"
              marginTop="20px"
              marginBottom="70px"
              marginBottomSm="40px"
              marginBottomxm="10px"
            >
              Don’t waste time on multiple applications apply once and find your
              match.
            </H2Style>
          </Col>
        </Row>
        <AvePlatform wSize={wSize}>
          <Row>
            <Col
              style={{
                zIndex: "1",
              }}
              span={7}
            >
              <p>More then 2M+ Properties available in Avenew Platform</p>
              <span>
                Boost revenue, gain your profit from your listed property
              </span>
            </Col>
            <Col
              span={9}
              style={{
                alignItems: "center",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <AvatarWrap>
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/1image.png"
                />
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/2image.png"
                />
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/3image.png"
                />
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/4image.png"
                />
              </AvatarWrap>
              <h3>
                Loved by Customers <br /> across 30+ cities
              </h3>
            </Col>
          </Row>
          <img className="house__women" src="/images/houseWomen.png" />
                    <div className="line__svg">
            <LineSvg />
          </div>
          <div className="lineCircle__svg">
            <LineCircleSvg />
          </div>
        </AvePlatform>
        <img className="house__women__xs" src="/images/houseWomen.png" />
        <AvePlatformSm>
          <p>
            More then <b>2M+</b> Properties available in Avenew Platform
          </p>
          <span>Boost revenue, gain your profit from your listed property</span>
          <Row justify="space-between" style={{ alignItems: "center" }}>
            <Col>
              <AvatarWrap
                style={{
                  marginTop: "15px",
                }}
              >
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/1image.png"
                />
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/2image.png"
                />
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/3image.png"
                />
                <UserAvatar
                  className="userName__icon"
                  size={45}
                  src="/images/4image.png"
                />
              </AvatarWrap>
            </Col>
            <Col>
              <h3>
                Loved by Customers <br /> across 30+ cities
              </h3>
            </Col>
          </Row>
          <div className="line__svg__sm">
            <LineSvg />
          </div>
        </AvePlatformSm>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default MultiApplicationPart;

const AvatarWrap = styled.div`
  align-items: center;
  display: flex;

  img {
    border: 2px solid #ffffff;
  }
  span {
    margin-left: -15px;
  }
`;

const AvePlatformSm = styled.div`
  display: none;

  @media (max-width: 991.98px) {
    display: block;
    position: relative;
    width: 100%;
    background: #ecf2ff;
    padding: 80px 30px 20px 30px;
    margin-top: -65px;

    .line__svg__sm svg {
      position: absolute;
      bottom: 0;
      right: 10px;
      width: 80px;
      height: 78px;
    }

    p {
      color: #2b353f;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
    }

    h3 {
      color: #2b353f;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 15px;
      margin-bottom: 0;
      z-index: 1;
      position: relative;
    }

    span {
      color: #848687;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }
`;

const AvePlatform = styled.div`
  width: 100%;
  background: #ecf2ff;
  padding: 20px 30px;
  position: relative;

  .line__svg svg {
    position: absolute;
    top: 0;
    left: 50%;
  }

  .lineCircle__svg svg {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .house__women {
    position: absolute;
    right: 0;
    bottom: 20px;
    zoom: ${({ wSize }) => wSize || "100"}%;
  }

  h3 {
    color: #2b353f;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    margin-left: 25px;
    z-index: 1;
  }

  span {
    color: #848687;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0;
  }

  p {
    color: #2b353f;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const H2Style = styled.h2`
  font-weight: 700;
  color: ${({ fColor }) => fColor || "#2b353f"};
  font-size: ${({ fontSize }) => fontSize || "40px"};
  line-height: ${({ lineHeight }) => lineHeight || "60px"};
  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  padding-top: ${({ paddingtop }) => paddingtop || 0};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: ${({ lineHeight }) => lineHeight || "45px"};
    margin-top: ${({ marginTopSm }) => marginTopSm || 0};
    margin-bottom: ${({ marginBottomSm }) => marginBottomSm || 0};
    padding-top: ${({ paddingtopSm }) => paddingtopSm || 0};
    padding-bottom: ${({ paddingBottomSm }) => paddingBottomSm || 0};
  }
  @media screen and (max-width: 991.98px) {
    z-index: 1;
    position: relative;

    font-size: 24px;
    line-height: ${({ lineheightxs }) => lineheightxs || "45px"};
    padding-top: ${({ paddingtopxs }) => paddingtopxs || 0};
    padding-bottom: ${({ paddingBottomxs }) => paddingBottomxs || 0};
    margin-bottom: ${({ marginBottomxm }) => marginBottomxm || 0};
  }
`;
