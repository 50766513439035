import React from "react";
import { TopTrendingSectionWrap } from "../../newLandingPage/TopTrendingSection";
import styled from "styled-components";
import { Col, Row } from "antd";
import { H2Style } from "../SearchPart";

function ApplicationProcessTabs() {
  return (
    <TopTrendingSectionWrap margincomponent="0 auto 0">
      <ApplicationProcessTabsWrap>
        <Row style={{ alignItems: "center" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <img src="/images/property-application.png" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <H2Style marginBottom="30px" marginTop="10px">
              Streamlined Application Process
            </H2Style>
            <p>
              Avenew simplifies the rental application process by allowing
              individuals to apply just once. By completing a single
              application, you save time and effort compared to submitting
              multiple applications for different properties. This streamlining
              of the application process reduces redundancy and improves
              efficiency.
            </p>
          </Col>
        </Row>
      </ApplicationProcessTabsWrap>
    </TopTrendingSectionWrap>
  );
}

export default ApplicationProcessTabs;

export const ApplicationProcessTabsWrap = styled.div`
  padding-top: 70px;

  img {
    width: 100%;
  }

  h2 {
    color: #2b353f;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
    padding-left: 40px;
    width: 80%;
  }

  p {
    color: #848687;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-left: 40px;
    line-height: 28px;
    width: 74%;
  }

  @media (max-width: 1400px) {
    padding-top: 0;

    h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 15px;
      padding-left: 40px;
      width: 80%;
    }
  }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      padding-left: 0;
      margin-top: 30px;
      width: 100%;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      padding-left: 0;
      line-height: 28px;
      width: 100%;
    }
  }
`;
