import React from "react";

const halfcircleTop = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="206"
      height="132"
      viewBox="0 0 206 132"
      fill="none"
    >
      <path
        d="M102.989 122.148C154.463 122.148 196.19 80.4307 196.19 28.9703C196.19 -22.4901 154.463 -64.207 102.989 -64.207C51.5162 -64.207 9.78906 -22.4901 9.78906 28.9703C9.78906 80.4307 51.5162 122.148 102.989 122.148Z"
        stroke="#FBC8AA"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M133.158 101.775C173.379 85.1191 192.479 39.0193 175.819 -1.19165C159.159 -41.4026 113.047 -60.4977 72.8264 -43.8418C32.6054 -27.1859 13.5056 18.9138 30.1657 59.1248C46.8258 99.3358 92.9368 118.431 133.158 101.775Z"
        stroke="#FBC8AA"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M165.677 43.7118C173.857 9.06138 152.392 -25.6581 117.733 -33.8364C83.0736 -42.0146 48.3455 -20.5547 40.1652 14.0958C31.9849 48.7462 53.4502 83.4657 88.1093 91.644C122.768 99.8222 157.496 78.3623 165.677 43.7118Z"
        stroke="#FBC8AA"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M152.547 37.0452C156.983 9.72185 138.424 -16.0236 111.094 -20.4588C83.7637 -24.894 58.0119 -6.33942 53.5756 20.9839C49.1393 48.3072 67.6984 74.0525 95.0285 78.4877C122.359 82.9229 148.11 64.3685 152.547 37.0452Z"
        stroke="#FBC8AA"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M102.989 64.7465C122.754 64.7465 138.776 48.7283 138.776 28.9689C138.776 9.20957 122.754 -6.80859 102.989 -6.80859C83.2251 -6.80859 67.2031 9.20957 67.2031 28.9689C67.2031 48.7283 83.2251 64.7465 102.989 64.7465Z"
        stroke="#FBC8AA"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M111.197 48.77C122.137 44.2399 127.332 31.7014 122.8 20.7647C118.269 9.828 105.727 4.63452 94.788 9.16466C83.8486 13.6948 78.6538 26.2331 83.185 37.1698C87.7163 48.1066 100.258 53.3002 111.197 48.77Z"
        stroke="#FBC8AA"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default halfcircleTop;
