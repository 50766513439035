import {
  Badge,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import HeaderSearch from "../../components/headerSearch/HeaderSearch";
import { UserAvatar } from "../../ui/Avatar";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { CgFileDocument } from "react-icons/cg";
import { BiDotsVerticalRounded, BiSend } from "react-icons/bi";
import { StyledButtonPay } from "../my-rentals/MyRentals";
import {
  AttachmentSvg,
  AvenewImg,
  MsgFilterSvg,
  NoMessages,
} from "../../assets/svgs";
import { FiPaperclip } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  createMessage,
  getChats,
  getMessages,
  getMessagesUnit,
} from "../../store/reducers/user.reducer";
import { ImagePreview } from "../../components/ImagePreview";
import { capitalize, imagePath, upperCase } from "../../utils/fn";
import io from "socket.io-client";
import { IoIosArrowDown } from "react-icons/io";
import FuzzySearch from "fuzzy-search";
import { MdCancel } from "react-icons/md";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

// const endPoint = "http://localhost:4000";
// declaration of socket variable
var socket;

function Message() {
  const dispatch = useDispatch();
  const attachmentType = ["image/svg+xml", "application/pdf"];
  const upload = React.useRef(null);
  const [state, setState] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [avatarWidth, setAvatarWidth] = useState(70);
  const [selectedChat, setSelectedChat] = useState({});
  const [adminChats, setAdminChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [unitMessages, setUnitMessages] = useState([]);
  const [sortMessages, setSortMessages] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [newSortMess, setNewSortMess] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedChatId, setSelectedChatId] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isChatLoading, setIsChatloading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const { width } = useWindowSize();
  const [lastMessage, setLastMessage] = useState({});
  const { Option } = Select;
  const plainOptions = ["Unit", "Name"];
  const Search = Input.Search;

  const [selectedFilter, setSelectedFilter] = useState(["Unit"]);

  const onChangeFilter = (checkedValues) => {
    setSelectedFilter(checkedValues);
  };

  // function updateScroll() {
  //   var element = document.getElementById("yourDivID");
  //   element.scrollTop = element.scrollHeight;
  // }

  // useEffect(() => {
  //   updateScroll();
  // }, []);

  // socket connection
  // useEffect(() => {
  //   socket = io(process.env.REACT_APP_SOCKET_URL);
  //   socket.emit("setup", user?.id);
  //   socket.on("connected", () => setSocketConnected(true));
  // }, [user]);

  // Download PDF (Attachment)
  const download = (e, name) => {
    const link = document.createElement("a");
    // link.setAttribute("download", "images.jfif");
    link.href = e;

    document.body.appendChild(link);
    link.click();
  };

  // Get all chat data
  useEffect(() => {
    if (localStorage.getItem("user_id")) {
      setIsloading(true);
      dispatch(getChats()).then(({ payload }) => {
        setAdminChats(payload?.chats);
        // setSelectedChat(payload?.chats[0]);
        setIsloading(false);
      });
    }
  }, []);

  // Store chat ID in local storage
  useEffect(() => {
    if (selectedChat && Object.keys(selectedChat || {}).length) {
      localStorage.setItem("chat_id", selectedChat.id);
      localStorage.setItem("admin_user_id", selectedChat?.adminUser?.id);
    }
  }, [selectedChat]);

  // useEffect(() => {
  //   if (localStorage.getItem("admin_user_id")) {
  //     dispatch(getMessagesUnit()).then(({ payload }) => {
  //       const val = payload?.result.sort(function (a, b) {
  //         return (
  //           new Date(b.latestMessage.created_at) -
  //           new Date(a.latestMessage.created_at)
  //         );
  //       });
  //       setUnitMessages(val);
  //       if (searchParams?.get("id")) {
  //         setSelectedUnit(searchParams.get("id"));
  //         localStorage.setItem("chat_id", searchParams.get("id"));
  //       } else {
  //         setSelectedUnit(val[0]?.id);
  //         localStorage.setItem("chat_id", val[0]?.id);
  //       }
  //     });
  //   }
  // }, [localStorage.getItem("admin_user_id")]);

  // Get chat data from getting  chat ID in local
  useEffect(() => {
    if (localStorage.getItem("chat_id")) {
      setIsChatloading(true);
      dispatch(getMessages()).then(({ payload }) => {
        setMessages(payload.messages);
        // socket.emit("join chat", localStorage.getItem("chat_id"));
      });
    }
  }, [localStorage.getItem("chat_id")]);

  // message recieved from socket
  // useEffect(() => {
  //   if (socketConnected) {
  //     socket.on("message recieved", (newMessage) => {
  //       if (
  //         selectedChat &&
  //         localStorage.getItem("chat_id") === newMessage?.chat?.id &&
  //         user.id != newMessage?.chat?.latestMessage?.sender
  //       ) {
  //         setMessages([...newMessage.message, ...newSortMess]);
  //       }
  //     });
  //   }
  // });

  //  sort message data last to first
  useEffect(() => {
    if (messages?.length) {
      setIsChatloading(false);
      const val = messages.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setSortMessages(val);
    } else {
      setIsChatloading(false);
      setSortMessages([]);
      setNewSortMess([]);
    }
  }, [messages]);

  //  sort message data last to first
  useEffect(() => {
    if (adminChats?.length) {
      const val = adminChats.sort(function (a, b) {
        return (
          new Date(b.latestMessage?.created_at) -
          new Date(a.latestMessage?.created_at)
        );
      });
      if (searchParams?.get("id")) {
        localStorage.setItem("chat_id", searchParams.get("id"));
        dispatch(getMessages()).then(({ payload }) => {
          setInputValue("");
          setSelectedChat(payload?.chat);
        });
      } else {
        setInputValue("");
        setSelectedChat(val[0]);
        // localStorage.setItem("chat_id", val[0].id);
      }
    }
  }, [adminChats]);

  // send a message to admin, typing effect
  const onFinish = () => {
    if (inputValue?.length && !state.length) {
      const value = {};
      value.chat_id = selectedChat?.id;
      value.replyTo = "";
      value.sender = user?.id;
      value.receiver = selectedChat?.owner_id;
      value.message = inputValue;
      value.messageType = "Message";
      value.attachment_URL = "";

      setSortMessages((val) => [value, ...val]);
      // socket.emit("typing", localStorage.getItem("chat_id"));
      dispatch(createMessage(value)).then(({ payload }) => {
        setLastMessage(payload.message[0]);
        // socket.emit("new message", payload);
        // socket.emit("stop typing", localStorage.getItem("chat_id"));
        // socket.emit("send notification", payload.notification);
      });
      setInputValue("");
    } else if (state.length) {
      const value = {};
      value.chat_id = selectedChat?.id;
      value.replyTo = "";
      value.sender = user?.id;
      value.message = inputValue;
      value.receiver = selectedChat?.owner_id;
      value.messageType = "Attachment";
      value.attachments = state;

      setSortMessages((val) => [value, ...val]);
      // socket.emit("typing", localStorage.getItem("chat_id"));
      dispatch(createMessage(value)).then(({ payload }) => {
        // socket.emit("new message", payload);
        // socket.emit("stop typing", localStorage.getItem("chat_id"));
        // socket.emit("send notification", payload.notification);
        setState([]);
        setLastMessage(payload.message[0]);
      });
      setInputValue("");
    }
  };

  // screen responsive screen
  useEffect(() => {
    if (width < 1550) {
      setAvatarWidth(56);
    } else {
      setAvatarWidth(70);
    }
  }, [width, location.pathname]);

  // when anyone typing on type bar then socket connect to typing to show on Admin side
  const getInputValue = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.length) {
      // socket.emit("typing", localStorage.getItem("chat_id"));
    }
  };

  // when anyone stop typing on type bar then socket connect to stop typing to show on Admin side
  useEffect(() => {
    const handler = setTimeout(() => {
      // socket.emit("stop typing", localStorage.getItem("chat_id"));
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // socket connected if Admin message typing message
  // useEffect(() => {
  //   if (socketConnected) {
  //     socket.on("typing", (value) => {
  //       if (value === localStorage.getItem("chat_id")) {
  //         setIsTyping(true);
  //       }
  //     });
  //   }
  // });

  // socket connected if Admin message stop typing message
  // useEffect(() => {
  //   if (socketConnected) {
  //     socket.on("stop typing", () => {
  //       setIsTyping(false);
  //     });
  //   }
  // });

  // Page navigation header
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/message",
      label: "Message",
      isLink: false,
    },
  ];
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // Get message time and date (Date formate)
  const getTime = (date) => {
    const ms = new Date(date);
    let newTime = ms.toLocaleTimeString("en-US");
    let hour = newTime.split(":")[0];
    let amPm = newTime.split(" ")[1];
    let seconds = newTime.split(":")[2].replace(amPm, "");

    let noAmPm = newTime.replace(amPm, "");
    let noAmPmSeconds = newTime.replace(":" + seconds, "");
    let noSeconds = newTime.replace(":" + seconds, " ");

    if (parseInt(hour) < 9) {
      newTime = "0" + newTime;
      noAmPm = "0" + noAmPm;
      noSeconds = "0" + noSeconds;
      noAmPmSeconds = "0" + noAmPmSeconds;
    }

    return noAmPmSeconds;
  };

  // Get message time and date (Date formate)
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day} ${month}, ${year}`;
  }

  // Get date converter
  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  // char data filter for get last message
  useEffect(() => {
    if (Object.keys(lastMessage || {}).length && adminChats?.length) {
      let arr = [...adminChats];
      let lMessageSet = adminChats.filter((item) => {
        return item["id"] === lastMessage?.chat_id;
      });
      if (lMessageSet?.length) {
        lMessageSet[0].latestMessage.message = lastMessage?.message;
        adminChats?.map((value, id) => {
          if (value.id === lMessageSet[0].id) {
            arr[id] = lMessageSet[0];
          }
        });
        setAdminChats([...arr]);
      }
    } else {
      setAdminChats([]);
    }
  }, [lastMessage]);

  const MAX_LENGTH = 4;

  // uploaded image convert to base 64
  const fileChangeHandler = async (event) => {
    if (Array.from(event.target.files).length > MAX_LENGTH) {
      event.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  // grag and drop image convert to base 64
  const fileChangeHandlerDrag = async (files) => {
    if (Array.from(files).length > MAX_LENGTH) {
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      files.preventDefault();
      return;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    await Promise.all(
      Array(...files).map(async (img) => {
        const convertImg = await convertToBase64(img);
        setState((s) => [
          ...s,
          {
            file: convertImg,
            path: URL.createObjectURL(img),
            name: img.name,
            ext: img.type,
          },
        ]);
      })
    );
  };

  // for image (darg and drop) over
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // for image (darg and drop) value store in state
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);

  // React.useEffect(() => {
  //   drop.current.addEventListener("dragover", handleDragOver);
  //   drop.current.addEventListener("drop", handleDrop);
  // }, []);

  // get images in sort form from message data
  useEffect(() => {
    if (sortMessages?.length) {
      const arr = [];
      let arr2 = [];
      sortMessages.map((item) => {
        if (item.attachment_URL) {
          if (arr2?.length) {
            const lastArr = arr2[arr2.length - 1];
            if (lastArr.sender != item.sender) {
              arr.push(arr2);
              arr2 = [];
            }
          }
          arr2.push(item);
        } else {
          if (arr2?.length) {
            arr.push(arr2);
            arr2 = [];
          }
          arr.push(item);
        }
      });
      setNewSortMess(arr);
    }
  }, [sortMessages]);

  //  side bar for mobile view (default dehaviour)
  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSider");
    if (x.length > 0) {
      document.body.classList.remove("openSider");
    } else {
      document.body.classList.add("openSider");
    }
  };

  //  side bar for mobile view (default dehaviour)
  const openSiderDivider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSider");
    document.body.classList.toggle("openSider");
  };

  const [stringSort, setStringSort] = useState(8);

  // How many words after slpit word (Avenew deve...) for responsive
  useEffect(() => {
    if (width < 768) {
      setStringSort(8);
    } else {
      setStringSort(20);
    }
  }, [width, location.pathname]);

  // How many words after slpit word (Avenew deve...)
  const slpitFunc = (value) => {
    if (value?.length > 28) {
      return value.slice(0, stringSort) + "..." + value.slice(-8);
    } else {
      return value;
    }
  };

  // default chat (Open default chat when user come on page)
  useEffect(() => {
    if (searchParams.get("id")) {
      setSelectedChatId(searchParams.get("id"));
      setSelectedUnit(searchParams.get("id"));
    }
  }, []);

  // add unit id usin admin chats (throw filter mathod)
  // useEffect(() => {
  //   if (selectedChatId && adminChats?.length) {
  //     const selectedId = adminChats.filter((item) => {
  //       return item["unit_id"] === selectedChatId;
  //     });
  //     setSelectedChat(selectedId[0]);
  //   }
  // }, [selectedChatId, adminChats]);

  // for change unit (select unit)
  const selectChange = (value) => {
    setSelectedUnit(value);
    localStorage.setItem("chat_id", value);
    const sele = unitMessages?.filter((item) => {
      return item["id"] === value;
    });
    setSelectedChat(sele[0]);
  };

  const [searchBarText, setSearchBarText] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const searcher = new FuzzySearch(
    adminChats,
    [
      "adminUser.firstName",
      "adminUser.lastName",
      "adminUser.email",
      "latestMessage",
      "unitDetails.unit_name",
    ],
    {
      caseSensitive: true,
    }
  );

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
    const result = searcher.search(e.target.value);
    setSearchResult(result);
  };

  const emitEmpty = () => {
    setSearchBarText("");
    setSearchResult([]);
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  return (
    <div style={{ background: "#ECF2FF" }}>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>
      {isLoading ? (
        <Container>
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : adminChats?.length ? (
        <>
          <Container>
            <Row gutter={[5, 5]}>
              <Col xs={0} sm={0} md={8} lg={11} xl={7} xxl={8}>
                <LeftWrap>
                  <h1>Messages</h1>
                  <div className="message__box">
                    <TableOneWrap
                      style={{ width: "100%" }}
                      searchHeight="35px"
                      sHeight="45px"
                      boxHeight="0"
                    >
                      <Search
                        ref={(ele) => searchBarText === ele}
                        // suffix={suffix}
                        onChange={onSearch}
                        placeholder="Search..."
                        value={searchBarText}
                        onPressEnter={onSearch}
                        allowClear
                      />
                    </TableOneWrap>
                    <Popover
                      placement="bottom"
                      trigger="click"
                      // onClick={(event) => {
                      //   event.stopPropagation();
                      // }}
                      content={
                        <FilterBtnPopover>
                          <Checkbox.Group
                            options={plainOptions}
                            defaultValue={["Unit"]}
                            onChange={onChangeFilter}
                          />
                        </FilterBtnPopover>
                      }
                    >
                      <FilterBtn>
                        <MsgFilterSvg />
                      </FilterBtn>
                    </Popover>
                    {/* <HeaderSearch bgcolor="#eeeeee" /> */}
                  </div>
                  <MessagerWrap>
                    {searchResult?.length || searchBarText?.length ? (
                      <>
                        {[...searchResult]?.map((item, id) => (
                          <>
                            <AdminMessage
                              key={id}
                              background={
                                selectedChat?.id === item.id
                                  ? "#ECF2FF"
                                  : "#ffffff"
                              }
                              onClick={() => {
                                setInputValue("");
                                setSelectedChat(item);
                                localStorage.setItem("chat_id", item.id);
                              }}
                            >
                              <UserAvatar
                                className="userName__icon"
                                size={avatarWidth}
                                src={item?.adminUser?.image?.path}
                                fullName={`${item.adminUser.firstName || ""} ${
                                  item.adminUser.lastName || ""
                                }`}
                              />
                              <MessageName>
                                {selectedFilter?.includes("Name") && (
                                  <h2>
                                    {upperCase(item.adminUser.firstName)}{" "}
                                    {upperCase(item.adminUser.lastName)}
                                    <Badge
                                      count={item?.unseenMessageCount}
                                    ></Badge>
                                  </h2>
                                )}

                                {selectedFilter?.includes("Unit") && (
                                  <h2>
                                    {upperCase(item.unitDetails?.unit_name)}
                                  </h2>
                                )}
                                {item?.latestMessage?.message ? (
                                  <p>
                                    {slpitFunc(item?.latestMessage?.message)}
                                  </p>
                                ) : (
                                  <p>Chat Joined</p>
                                )}
                              </MessageName>
                            </AdminMessage>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {[...adminChats]?.map((item, id) => (
                          <>
                            <AdminMessage
                              key={id}
                              background={
                                selectedChat?.id === item.id
                                  ? "#ECF2FF"
                                  : "#ffffff"
                              }
                              onClick={() => {
                                setSelectedUnit("");
                                setState([]);
                                setInputValue("");
                                setSelectedChat(item);
                                localStorage.setItem("chat_id", item.id);
                              }}
                            >
                              <UserAvatar
                                className="userName__icon"
                                size={avatarWidth}
                                src={item?.adminUser?.image?.path}
                                fullName={`${item.adminUser.firstName || ""} ${
                                  item.adminUser.lastName || ""
                                }`}
                              />
                              <MessageName>
                                {selectedFilter?.includes("Name") && (
                                  <h2>
                                    {upperCase(item.adminUser.firstName)}{" "}
                                    {upperCase(item.adminUser.lastName)}
                                    <Badge
                                      count={item?.unseenMessageCount}
                                    ></Badge>
                                  </h2>
                                )}

                                {selectedFilter?.includes("Unit") && (
                                  <h2>
                                    {upperCase(item.unitDetails?.unit_name)}
                                  </h2>
                                )}
                                {item?.latestMessage?.message ? (
                                  <p>
                                    {slpitFunc(item?.latestMessage?.message)}
                                  </p>
                                ) : (
                                  <p>Chat Joined</p>
                                )}
                              </MessageName>
                            </AdminMessage>
                          </>
                        ))}
                      </>
                    )}
                  </MessagerWrap>
                </LeftWrap>
              </Col>
              <Col xs={0} sm={0} md={16} lg={13} xl={17} xxl={16}>
                <RightWrap>
                  <ActiveName>
                    <UserAvatar
                      className="userName__icon"
                      size={50}
                      src={selectedChat?.adminUser?.image?.path}
                      fullName={`${selectedChat?.adminUser?.firstName || ""} ${
                        selectedChat?.adminUser?.lastName || ""
                      }`}
                    />
                    <ActiveMessageName>
                      <h2>
                        {upperCase(selectedChat?.unitDetails?.unit_name)}
                        <h3>{upperCase(selectedChat?.unit_address)}</h3>
                      </h2>
                      {/* <h2>
                        {upperCase(selectedChat?.adminUser?.firstName)}{" "}
                        {upperCase(selectedChat?.adminUser?.lastName)} */}
                      {/* <Divider type="vertical" /> reenprenk Hostel */}

                      {/* </h2> */}
                      {/* <BiDotsVerticalRounded /> */}
                    </ActiveMessageName>
                  </ActiveName>
                  <ActiveChat>
                    <Row gutter={[2, 2]}>
                      <Col span={24}>
                        {/* <ActiveProperty>
                          <UserAvatar
                            className="userName__icon"
                            size={35}
                            // src={state?.path}
                            fullName={`${selectedChat?.unit_name || ""}`}
                          />
                          <ActiveProName>
                            <h2>{upperCase(selectedChat?.unit_name)}</h2>
                            <BtnPrice>
                              <h2>$593.00</h2>
                              <StyledButtonPay>See Details</StyledButtonPay>
                            </BtnPrice>
                          </ActiveProName>
                        </ActiveProperty> */}

                        {/* {unitMessages?.length ? (
                          <SelectUnitWrap
                            style={{ height: "50px" }}
                            placeholder="Select Propety"
                            onChange={selectChange}
                            defaultValue={
                              selectedUnit
                                ? selectedUnit
                                : [...unitMessages][0]?.id
                            }
                            value={
                              selectedUnit
                                ? selectedUnit
                                : [...unitMessages][0]?.id
                            }
                            suffixIcon={<IoIosArrowDown />}
                            // allowClear
                          >
                            {unitMessages?.map((item, idx) => (
                              <Option key={idx} value={item.id}>
                                <OptionContent>
                                  <AvenewImg
                                    style={{
                                      background: "#eeeeee",
                                      height: "40px",
                                      width: "40px",
                                      borderRadius: "50%",
                                      marginRight: "15px",
                                    }}
                                  />
                                  <span className="unit_name">
                                    {capitalize(item.unitDetails.unitType)}
                                  </span>
                                  <Divider type="vertical" />
                                  <span className="unit_name">
                                    {capitalize(item.unitDetails.unit_name)}
                                  </span>
                                </OptionContent>
                              </Option>
                            ))}
                          </SelectUnitWrap>
                        ) : (
                          ""
                        )} */}
                      </Col>
                      <Col span={24}>
                        <ChatBox>
                          <div style={{ display: "flex" }}>
                            {state.map((img, idx) => (
                              <StyledUploadPreviewWrapper
                                isPhoto={state.length}
                              >
                                {attachmentType.includes(img.ext) ? (
                                  <object
                                    data={img.path}
                                    type="application/pdf"
                                    width="100%"
                                    // height="100%"
                                    height="400"
                                  >
                                    <p>
                                      Alternative text - include a link{" "}
                                      <a href={img.path}>to the PDF!</a>
                                    </p>
                                  </object>
                                ) : (
                                  <ImagePreview
                                    key={idx}
                                    src={
                                      img.path ? img.path : imagePath(img.path)
                                    }
                                    height={95}
                                    width={95}
                                    onDelete={() => {
                                      const st = [...state];
                                      st.splice(idx, 1);
                                      setState(st);
                                    }}
                                    isCoverDisProp={false}
                                  />
                                )}
                              </StyledUploadPreviewWrapper>
                            ))}
                          </div>
                          {isTyping ? (
                            <LeftMessage background="#eeeeee">
                              <div className="typing">
                                <div className="typing__dot"></div>
                                <div className="typing__dot"></div>
                                <div className="typing__dot"></div>
                              </div>
                            </LeftMessage>
                          ) : (
                            ""
                          )}

                          {isChatLoading ? (
                            <Spin className="ant__spin" size="large" />
                          ) : (
                            <>
                              {newSortMess?.length ? (
                                <>
                                  {newSortMess?.map((item, id) => (
                                    <>
                                      <div key={id}>
                                        {item.sender === user?.id ? (
                                          <>
                                            {Array.isArray(item) ? (
                                              <>
                                                <RightMessage>
                                                  <Row
                                                    style={{ width: "100%" }}
                                                    gutter={[2, 2]}
                                                  >
                                                    {item.map((img, idx) => (
                                                      <>
                                                        {img.sender ===
                                                        user?.id ? (
                                                          <>
                                                            {attachmentType.includes(
                                                              img.attachment_ext ||
                                                                img.ext
                                                            ) ? (
                                                              <AtchBox
                                                                onClick={() =>
                                                                  download(
                                                                    img.attachment_URL,
                                                                    img.message
                                                                  )
                                                                }
                                                              >
                                                                <CgFileDocument />
                                                                <h5>
                                                                  {slpitFunc(
                                                                    img.message ||
                                                                      img.name
                                                                  )}
                                                                </h5>
                                                              </AtchBox>
                                                            ) : (
                                                              <>
                                                                {idx < 3 ? (
                                                                  <Col
                                                                    span={
                                                                      item?.length ===
                                                                      1
                                                                        ? "24"
                                                                        : "12"
                                                                    }
                                                                  >
                                                                    <StyledUploadPreviewWrapper
                                                                      isPhoto={
                                                                        item.length
                                                                      }
                                                                    >
                                                                      <ImagePreview
                                                                        key={
                                                                          idx
                                                                        }
                                                                        src={
                                                                          img.attachment_URL
                                                                        }
                                                                        downloadImgUrl={
                                                                          img.attachment_URL
                                                                        }
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          padding:
                                                                            "2px",
                                                                          minHeight:
                                                                            "200px",
                                                                          maxHeight:
                                                                            "200px",
                                                                        }}
                                                                        isCoverDisProp={
                                                                          false
                                                                        }
                                                                        isDeleteDisProp={
                                                                          false
                                                                        }
                                                                        isDownload={
                                                                          true
                                                                        }
                                                                      />
                                                                    </StyledUploadPreviewWrapper>
                                                                  </Col>
                                                                ) : (
                                                                  <Col span="5">
                                                                    <StyledUploadPreviewWrapper
                                                                      isPhoto={
                                                                        item.length
                                                                      }
                                                                    >
                                                                      <ImagePreview
                                                                        key={
                                                                          idx
                                                                        }
                                                                        src={
                                                                          img.path
                                                                            ? img.path
                                                                            : imagePath(
                                                                                img.path
                                                                              )
                                                                        }
                                                                        downloadImgUrl={
                                                                          img.path
                                                                        }
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          padding:
                                                                            "2px",
                                                                          minHeight:
                                                                            "200px",
                                                                          maxHeight:
                                                                            "200px",
                                                                        }}
                                                                        isDeleteDisProp={
                                                                          false
                                                                        }
                                                                        isCoverDisProp={
                                                                          false
                                                                        }
                                                                        isDownload={
                                                                          true
                                                                        }
                                                                      />
                                                                    </StyledUploadPreviewWrapper>
                                                                  </Col>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ))}
                                                  </Row>
                                                </RightMessage>
                                              </>
                                            ) : item?.attachments ? (
                                              <>
                                                <RightMessage width={"45%"}>
                                                  <Row
                                                    style={{ width: "100%" }}
                                                    gutter={[2, 2]}
                                                  >
                                                    {item?.attachments.map(
                                                      (img, idx) => (
                                                        <>
                                                          {attachmentType.includes(
                                                            img.attachment_ext ||
                                                              img.ext
                                                          ) ? (
                                                            <AtchBox
                                                              onClick={() =>
                                                                download(
                                                                  img.attachment_URL,
                                                                  img.message
                                                                )
                                                              }
                                                            >
                                                              <CgFileDocument />
                                                              <h5>
                                                                {slpitFunc(
                                                                  img.message ||
                                                                    img.name
                                                                )}
                                                              </h5>
                                                            </AtchBox>
                                                          ) : (
                                                            <>
                                                              {idx < 3 ? (
                                                                <Col
                                                                  span={
                                                                    item
                                                                      ?.attachments
                                                                      ?.length ===
                                                                    1
                                                                      ? "24"
                                                                      : "12"
                                                                  }
                                                                >
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item
                                                                        ?.attachments
                                                                        ?.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.path
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.path
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              ) : (
                                                                <Col span="12">
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item
                                                                        ?.attachments
                                                                        ?.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.path
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.path
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </Row>
                                                  <p>
                                                    {getTime(
                                                      item.created_at
                                                        ? item.created_at
                                                        : new Date()
                                                    )}
                                                  </p>
                                                </RightMessage>
                                                {item.message ? (
                                                  <RightMessage>
                                                    <h2>{item.message}</h2>
                                                    <p>
                                                      {getTime(
                                                        item.created_at
                                                          ? item.created_at
                                                          : new Date()
                                                      )}
                                                    </p>
                                                  </RightMessage>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            ) : (
                                              <RightMessage>
                                                <h2>{item.message}</h2>
                                                <p>
                                                  {getTime(
                                                    item.created_at
                                                      ? item.created_at
                                                      : new Date()
                                                  )}
                                                </p>
                                              </RightMessage>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {Array.isArray(item) ? (
                                              <>
                                                {item[0]?.sender ===
                                                user?.id ? (
                                                  <RightMessage width={"45%"}>
                                                    <Row
                                                      style={{ width: "100%" }}
                                                      gutter={[2, 2]}
                                                    >
                                                      {item.map((img, idx) => (
                                                        <>
                                                          {img.sender ===
                                                          user?.id ? (
                                                            <>
                                                              {attachmentType.includes(
                                                                img.attachment_ext ||
                                                                  img.ext
                                                              ) ? (
                                                                <AtchBox
                                                                  onClick={() =>
                                                                    download(
                                                                      img.attachment_URL,
                                                                      img.message
                                                                    )
                                                                  }
                                                                >
                                                                  <CgFileDocument />
                                                                  <h5>
                                                                    {slpitFunc(
                                                                      img.message ||
                                                                        img.name
                                                                    )}
                                                                  </h5>
                                                                </AtchBox>
                                                              ) : (
                                                                <>
                                                                  {idx < 3 ? (
                                                                    <Col
                                                                      key={idx}
                                                                      span={
                                                                        item?.length ===
                                                                        1
                                                                          ? "24"
                                                                          : "12"
                                                                      }
                                                                    >
                                                                      <StyledUploadPreviewWrapper
                                                                        isPhoto={
                                                                          item.length
                                                                        }
                                                                      >
                                                                        <ImagePreview
                                                                          key={
                                                                            idx
                                                                          }
                                                                          src={
                                                                            img.attachment_URL
                                                                          }
                                                                          downloadImgUrl={
                                                                            img.attachment_URL
                                                                          }
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            padding:
                                                                              "2px",
                                                                            minHeight:
                                                                              "200px",
                                                                            maxHeight:
                                                                              "200px",
                                                                          }}
                                                                          isCoverDisProp={
                                                                            false
                                                                          }
                                                                          isDeleteDisProp={
                                                                            false
                                                                          }
                                                                          isDownload={
                                                                            true
                                                                          }
                                                                        />
                                                                      </StyledUploadPreviewWrapper>
                                                                    </Col>
                                                                  ) : (
                                                                    <Col span="12">
                                                                      <StyledUploadPreviewWrapper
                                                                        isPhoto={
                                                                          item.length
                                                                        }
                                                                      >
                                                                        <ImagePreview
                                                                          key={
                                                                            idx
                                                                          }
                                                                          src={
                                                                            img.attachment_URL
                                                                          }
                                                                          downloadImgUrl={
                                                                            img.attachment_URL
                                                                          }
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            padding:
                                                                              "2px",
                                                                            minHeight:
                                                                              "200px",
                                                                            maxHeight:
                                                                              "200px",
                                                                          }}
                                                                          isDeleteDisProp={
                                                                            false
                                                                          }
                                                                          isCoverDisProp={
                                                                            false
                                                                          }
                                                                          isDownload={
                                                                            true
                                                                          }
                                                                        />
                                                                      </StyledUploadPreviewWrapper>
                                                                    </Col>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </>
                                                      ))}
                                                    </Row>
                                                    <p>
                                                      {getTime(
                                                        item.created_at
                                                          ? item.created_at
                                                          : new Date()
                                                      )}
                                                    </p>
                                                  </RightMessage>
                                                ) : (
                                                  <LeftMessage width={"45%"}>
                                                    <Row
                                                      style={{ width: "100%" }}
                                                      gutter={[2, 2]}
                                                    >
                                                      {item.map((img, idx) => (
                                                        <>
                                                          {attachmentType.includes(
                                                            img.attachment_ext ||
                                                              img.ext
                                                          ) ? (
                                                            <AtchBox
                                                              fontColor="#2b353f"
                                                              onClick={() =>
                                                                download(
                                                                  img.attachment_URL,
                                                                  img.message
                                                                )
                                                              }
                                                            >
                                                              <CgFileDocument />
                                                              <h5>
                                                                {slpitFunc(
                                                                  img.message ||
                                                                    img.name
                                                                )}
                                                              </h5>
                                                            </AtchBox>
                                                          ) : (
                                                            <>
                                                              {idx < 3 ? (
                                                                <Col
                                                                  key={idx}
                                                                  span={
                                                                    item.length ===
                                                                    1
                                                                      ? "24"
                                                                      : "12"
                                                                  }
                                                                >
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.attachment_URL
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.attachment_URL
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              ) : (
                                                                <Col span="12">
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.attachment_URL
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.attachment_URL
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      ))}
                                                    </Row>
                                                    <p>
                                                      {getTime(
                                                        item.created_at
                                                          ? item.created_at
                                                          : new Date()
                                                      )}
                                                    </p>
                                                  </LeftMessage>
                                                )}
                                              </>
                                            ) : item?.attachments ? (
                                              <>
                                                {item?.attachments.map(
                                                  (img, idx) => (
                                                    <>
                                                      <RightMessage
                                                        key={idx}
                                                        width={"45%"}
                                                      >
                                                        <Row
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                          gutter={[2, 2]}
                                                        >
                                                          {attachmentType.includes(
                                                            img.attachment_ext ||
                                                              img.ext
                                                          ) ? (
                                                            <AtchBox
                                                              onClick={() =>
                                                                download(
                                                                  img.attachment_URL,
                                                                  img.message
                                                                )
                                                              }
                                                            >
                                                              <CgFileDocument />
                                                              <h5>
                                                                {slpitFunc(
                                                                  img.message ||
                                                                    img.name
                                                                )}
                                                              </h5>
                                                            </AtchBox>
                                                          ) : (
                                                            <>
                                                              {idx < 3 ? (
                                                                <Col
                                                                  span={
                                                                    item
                                                                      ?.attachments
                                                                      ?.length ===
                                                                    1
                                                                      ? "24"
                                                                      : "12"
                                                                  }
                                                                >
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item
                                                                        ?.attachments
                                                                        ?.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.path
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.path
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              ) : (
                                                                <Col span="12">
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item
                                                                        ?.attachments
                                                                        ?.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.path
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.path
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              )}
                                                            </>
                                                          )}
                                                        </Row>
                                                        <p>
                                                          {getTime(
                                                            item.created_at
                                                              ? item.created_at
                                                              : new Date()
                                                          )}
                                                        </p>
                                                      </RightMessage>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <LeftMessage>
                                                <h2>{item.message}</h2>
                                                <p>
                                                  {getTime(
                                                    item.created_at
                                                      ? item.created_at
                                                      : new Date()
                                                  )}
                                                </p>
                                              </LeftMessage>
                                            )}
                                          </>
                                        )}
                                      </div>

                                      {/* {item.created_at ? (
                            <>
                              {id === newSortMess?.length - 1 ? (
                                <DateWrap>
                                  <span>
                                    {dateConverter(item.created_at)}
                                  </span>
                                </DateWrap>
                              ) : (
                                <>
                                  {id === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      {new Date(
                                        sortMessages[id - 1]?.created_at
                                          ? sortMessages[id - 1]
                                              ?.created_at
                                          : item.created_at
                                      )
                                        .toISOString()
                                        .slice(0, 10) ===
                                      new Date(item.created_at)
                                        .toISOString()
                                        .slice(0, 10) ? (
                                        ""
                                      ) : (
                                        <DateWrap>
                                          <span>
                                            {dateConverter(
                                              item.created_at
                                            )}
                                          </span>
                                        </DateWrap>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )} */}

                                      {item.created_at ? (
                                        <>
                                          {id === newSortMess?.length - 1 ? (
                                            <DateWrap>
                                              <span>
                                                {dateConverter(item.created_at)}
                                              </span>
                                            </DateWrap>
                                          ) : (
                                            <>
                                              {id === 0 ? (
                                                ""
                                              ) : (
                                                <>
                                                  {new Date(
                                                    sortMessages[id - 1]
                                                      ?.created_at
                                                      ? sortMessages[id - 1]
                                                          ?.created_at
                                                      : item.created_at
                                                  )
                                                    .toISOString()
                                                    .slice(0, 10) ===
                                                  new Date(item.created_at)
                                                    .toISOString()
                                                    .slice(0, 10) ? (
                                                    ""
                                                  ) : (
                                                    <DateWrap>
                                                      <span>
                                                        {dateConverter(
                                                          item.created_at
                                                        )}
                                                      </span>
                                                    </DateWrap>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <NoMessageWrap>
                                  <NoMessages />
                                  <h2>No Message Available</h2>
                                </NoMessageWrap>
                              )}
                            </>
                          )}
                        </ChatBox>
                        <TextBox>
                          <input
                            accept="application/pdf, application/vnd.ms-excel, image/*"
                            id="myInput"
                            multiple={"multiple"}
                            type="file"
                            ref={upload}
                            style={{ display: "none" }}
                            onChange={fileChangeHandler}
                            max="5"
                          />
                          {/* <AttachMent>
                            <AttachmentSvg />
                          </AttachMent>
                          <AttachMent onClick={(e) => upload.current.click()}>
                            <FiPaperclip />
                          </AttachMent>

                          <SendEmail ref={drop}>
                            <Input.Group compact>
                              <Input
                                // disabled={state?.length}
                                onChange={getInputValue}
                                placeholder="Write a Message"
                                style={{
                                  width: "calc(100% - 52px)",
                                  height: "50px",
                                }}
                                onPressEnter={() => onFinish()}
                                value={inputValue}
                              />
                              <Button
                                onClick={() => onFinish()}
                                disabled={
                                  state?.length
                                    ? !state?.length
                                    : !inputValue.length
                                }
                              >
                                <BiSend />
                              </Button>
                            </Input.Group>
                          </SendEmail> */}
                          <SendEmail ref={drop}>
                            {/* <Input.Group compact> */}
                            <Input.TextArea
                              // disabled={state?.length}
                              onChange={getInputValue}
                              placeholder="Type Your message"
                              // style={{
                              //   width: "calc(100% - 52px)",
                              //   height: "120px",
                              // }}
                              onPressEnter={() => onFinish()}
                              value={inputValue}
                            />
                            {/* </Input.Group> */}
                          </SendEmail>
                          <Row
                            gutter={[10, 10]}
                            style={{
                              marginLeft: "10px",
                              // paddingTop: "10px",
                            }}
                          >
                            <Col span={20}>
                              <MsgBtn
                                onClick={() => onFinish()}
                                disabled={
                                  state?.length
                                    ? !state?.length
                                    : !inputValue.length
                                }
                              >
                                Send <BiSend />
                              </MsgBtn>
                            </Col>
                            <Col span={24}>
                              <Row>
                                <Col>
                                  {/* <AttachMent>
                                    <AttachmentSvg />
                                  </AttachMent> */}
                                </Col>
                                <Col>
                                  <AttachMent
                                    onClick={(e) => upload.current.click()}
                                  >
                                    <FiPaperclip />
                                  </AttachMent>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TextBox>
                      </Col>
                    </Row>
                  </ActiveChat>
                </RightWrap>
              </Col>
            </Row>
          </Container>
          <Row style={{ background: "#fff" }}>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
              <ActiveName br="0" bs="none" onClick={openSider}>
                <UserAvatar
                  className="userName__icon"
                  size={50}
                  src={selectedChat?.adminUser?.image?.path}
                  fullName={`${selectedChat?.adminUser?.firstName || ""} ${
                    selectedChat?.adminUser?.lastName || ""
                  }`}
                />
                <ActiveMessageName>
                  <h2>
                    {upperCase(selectedChat?.adminUser?.firstName)}{" "}
                    {upperCase(selectedChat?.adminUser?.lastName)}
                    {/* <Divider type="vertical" /> reenprenk Hostel */}
                  </h2>
                  <IoIosArrowDown />
                </ActiveMessageName>
              </ActiveName>
              <UnitDetails>
                <img src="/images/property1.png" />
                <h2>Sub unit 2</h2>
              </UnitDetails>

              <ActiveChat>
                <ChatBox>
                  <div style={{ display: "flex" }}>
                    {state.map((img, idx) => (
                      <StyledUploadPreviewWrapper isPhoto={state.length}>
                        <ImagePreview
                          key={idx}
                          src={img.path ? img.path : imagePath(img.path)}
                          height={95}
                          width={95}
                          onDelete={() => {
                            const st = [...state];
                            st.splice(idx, 1);
                            setState(st);
                          }}
                          isCoverDisProp={false}
                        />
                      </StyledUploadPreviewWrapper>
                    ))}
                  </div>
                  {isTyping ? (
                    <LeftMessage background="#eeeeee">
                      <div className="typing">
                        <div className="typing__dot"></div>
                        <div className="typing__dot"></div>
                        <div className="typing__dot"></div>
                      </div>
                    </LeftMessage>
                  ) : (
                    ""
                  )}

                  {isChatLoading ? (
                    <Spin className="ant__spin" size="large" />
                  ) : (
                    <>
                      {newSortMess?.map((item, id) => (
                        <>
                          <div key={id}>
                            {item.sender === user?.id ? (
                              <>
                                {Array.isArray(item) ? (
                                  <>
                                    <RightMessage>
                                      <Row
                                        style={{ width: "100%" }}
                                        gutter={[2, 2]}
                                      >
                                        {item.map((img, idx) => (
                                          <>
                                            {img.sender === user?.id ? (
                                              <>
                                                {attachmentType.includes(
                                                  img.attachment_ext || img.ext
                                                ) ? (
                                                  <AtchBox
                                                    onClick={() =>
                                                      download(
                                                        img.attachment_URL,
                                                        img.message
                                                      )
                                                    }
                                                  >
                                                    <CgFileDocument />
                                                    <h5>
                                                      {slpitFunc(
                                                        img.message || img.name
                                                      )}
                                                    </h5>
                                                  </AtchBox>
                                                ) : (
                                                  <>
                                                    {idx < 3 ? (
                                                      <Col
                                                        span={
                                                          item?.length === 1
                                                            ? "24"
                                                            : "12"
                                                        }
                                                      >
                                                        <StyledUploadPreviewWrapper
                                                          isPhoto={item.length}
                                                        >
                                                          <ImagePreview
                                                            key={idx}
                                                            src={
                                                              img.attachment_URL
                                                            }
                                                            downloadImgUrl={
                                                              img.attachment_URL
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              padding: "2px",
                                                              minHeight:
                                                                "200px",
                                                              maxHeight:
                                                                "200px",
                                                            }}
                                                            isCoverDisProp={
                                                              false
                                                            }
                                                            isDeleteDisProp={
                                                              false
                                                            }
                                                            isDownload={true}
                                                          />
                                                        </StyledUploadPreviewWrapper>
                                                      </Col>
                                                    ) : (
                                                      <Col span="5">
                                                        <StyledUploadPreviewWrapper
                                                          isPhoto={item.length}
                                                        >
                                                          <ImagePreview
                                                            key={idx}
                                                            src={
                                                              img.path
                                                                ? img.path
                                                                : imagePath(
                                                                    img.path
                                                                  )
                                                            }
                                                            downloadImgUrl={
                                                              img.path
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              padding: "2px",
                                                              minHeight:
                                                                "200px",
                                                              maxHeight:
                                                                "200px",
                                                            }}
                                                            isDeleteDisProp={
                                                              false
                                                            }
                                                            isCoverDisProp={
                                                              false
                                                            }
                                                            isDownload={true}
                                                          />
                                                        </StyledUploadPreviewWrapper>
                                                      </Col>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ))}
                                      </Row>
                                    </RightMessage>
                                  </>
                                ) : item?.attachments ? (
                                  <>
                                    <RightMessage width={"75%"}>
                                      <Row
                                        style={{ width: "100%" }}
                                        gutter={[2, 2]}
                                      >
                                        {item?.attachments.map((img, idx) => (
                                          <>
                                            {attachmentType.includes(
                                              img.attachment_ext || img.ext
                                            ) ? (
                                              <AtchBox
                                                onClick={() =>
                                                  download(
                                                    img.attachment_URL,
                                                    img.message
                                                  )
                                                }
                                              >
                                                <CgFileDocument />
                                                <h5>
                                                  {slpitFunc(
                                                    img.message || img.name
                                                  )}
                                                </h5>
                                              </AtchBox>
                                            ) : (
                                              <>
                                                {idx < 3 ? (
                                                  <Col
                                                    span={
                                                      item?.attachments
                                                        ?.length === 1
                                                        ? "24"
                                                        : "12"
                                                    }
                                                  >
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isCoverDisProp={false}
                                                        isDeleteDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                ) : (
                                                  <Col span="12">
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isDeleteDisProp={false}
                                                        isCoverDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </Row>
                                      <p>
                                        {getTime(
                                          item.created_at
                                            ? item.created_at
                                            : new Date()
                                        )}
                                      </p>
                                    </RightMessage>
                                    {item.message ? (
                                      <RightMessage>
                                        <h2>{item.message}</h2>
                                        <p>
                                          {getTime(
                                            item.created_at
                                              ? item.created_at
                                              : new Date()
                                          )}
                                        </p>
                                      </RightMessage>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <RightMessage>
                                    <h2>{item.message}</h2>
                                    <p>
                                      {getTime(
                                        item.created_at
                                          ? item.created_at
                                          : new Date()
                                      )}
                                    </p>
                                  </RightMessage>
                                )}
                              </>
                            ) : (
                              <>
                                {Array.isArray(item) ? (
                                  <>
                                    {item[0]?.sender === user?.id ? (
                                      <RightMessage width={"75%"}>
                                        <Row
                                          style={{ width: "100%" }}
                                          gutter={[2, 2]}
                                        >
                                          {item.map((img, idx) => (
                                            <>
                                              {img.sender === user?.id ? (
                                                <>
                                                  {attachmentType.includes(
                                                    img.attachment_ext ||
                                                      img.ext
                                                  ) ? (
                                                    <AtchBox
                                                      onClick={() =>
                                                        download(
                                                          img.attachment_URL,
                                                          img.message
                                                        )
                                                      }
                                                    >
                                                      <CgFileDocument />
                                                      <h5>
                                                        {slpitFunc(
                                                          img.message ||
                                                            img.name
                                                        )}
                                                      </h5>
                                                    </AtchBox>
                                                  ) : (
                                                    <>
                                                      {idx < 3 ? (
                                                        <Col
                                                          key={idx}
                                                          span={
                                                            item?.length === 1
                                                              ? "24"
                                                              : "12"
                                                          }
                                                        >
                                                          <StyledUploadPreviewWrapper
                                                            isPhoto={
                                                              item.length
                                                            }
                                                          >
                                                            <ImagePreview
                                                              key={idx}
                                                              src={
                                                                img.attachment_URL
                                                              }
                                                              downloadImgUrl={
                                                                img.attachment_URL
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                padding: "2px",
                                                                minHeight:
                                                                  "200px",
                                                                maxHeight:
                                                                  "200px",
                                                              }}
                                                              isCoverDisProp={
                                                                false
                                                              }
                                                              isDeleteDisProp={
                                                                false
                                                              }
                                                              isDownload={true}
                                                            />
                                                          </StyledUploadPreviewWrapper>
                                                        </Col>
                                                      ) : (
                                                        <Col span="12">
                                                          <StyledUploadPreviewWrapper
                                                            isPhoto={
                                                              item.length
                                                            }
                                                          >
                                                            <ImagePreview
                                                              key={idx}
                                                              src={
                                                                img.attachment_URL
                                                              }
                                                              downloadImgUrl={
                                                                img.attachment_URL
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                padding: "2px",
                                                                minHeight:
                                                                  "200px",
                                                                maxHeight:
                                                                  "200px",
                                                              }}
                                                              isDeleteDisProp={
                                                                false
                                                              }
                                                              isCoverDisProp={
                                                                false
                                                              }
                                                              isDownload={true}
                                                            />
                                                          </StyledUploadPreviewWrapper>
                                                        </Col>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ))}
                                        </Row>
                                        <p>
                                          {getTime(
                                            item.created_at
                                              ? item.created_at
                                              : new Date()
                                          )}
                                        </p>
                                      </RightMessage>
                                    ) : (
                                      <LeftMessage width={"75%"}>
                                        <Row
                                          style={{ width: "100%" }}
                                          gutter={[2, 2]}
                                        >
                                          {item.map((img, idx) => (
                                            <>
                                              {attachmentType.includes(
                                                img.attachment_ext || img.ext
                                              ) ? (
                                                <AtchBox
                                                  fontColor="#2b353f"
                                                  onClick={() =>
                                                    download(
                                                      img.attachment_URL,
                                                      img.message
                                                    )
                                                  }
                                                >
                                                  <CgFileDocument />
                                                  <h5>
                                                    {slpitFunc(
                                                      img.message || img.name
                                                    )}
                                                  </h5>
                                                </AtchBox>
                                              ) : (
                                                <>
                                                  {idx < 3 ? (
                                                    <Col
                                                      key={idx}
                                                      span={
                                                        item.length === 1
                                                          ? "24"
                                                          : "12"
                                                      }
                                                    >
                                                      <StyledUploadPreviewWrapper
                                                        isPhoto={item.length}
                                                      >
                                                        <ImagePreview
                                                          key={idx}
                                                          src={
                                                            img.attachment_URL
                                                          }
                                                          downloadImgUrl={
                                                            img.attachment_URL
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            padding: "2px",
                                                            minHeight: "200px",
                                                            maxHeight: "200px",
                                                          }}
                                                          isCoverDisProp={false}
                                                          isDeleteDisProp={
                                                            false
                                                          }
                                                          isDownload={true}
                                                        />
                                                      </StyledUploadPreviewWrapper>
                                                    </Col>
                                                  ) : (
                                                    <Col span="12">
                                                      <StyledUploadPreviewWrapper
                                                        isPhoto={item.length}
                                                      >
                                                        <ImagePreview
                                                          key={idx}
                                                          src={
                                                            img.attachment_URL
                                                          }
                                                          downloadImgUrl={
                                                            img.attachment_URL
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            padding: "2px",
                                                            minHeight: "200px",
                                                            maxHeight: "200px",
                                                          }}
                                                          isDeleteDisProp={
                                                            false
                                                          }
                                                          isCoverDisProp={false}
                                                          isDownload={true}
                                                        />
                                                      </StyledUploadPreviewWrapper>
                                                    </Col>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ))}
                                        </Row>
                                        <p>
                                          {getTime(
                                            item.created_at
                                              ? item.created_at
                                              : new Date()
                                          )}
                                        </p>
                                      </LeftMessage>
                                    )}
                                  </>
                                ) : item?.attachments ? (
                                  <>
                                    {item?.attachments.map((img, idx) => (
                                      <>
                                        <RightMessage key={idx} width={"75%"}>
                                          <Row
                                            style={{ width: "100%" }}
                                            gutter={[2, 2]}
                                          >
                                            {attachmentType.includes(
                                              img.attachment_ext || img.ext
                                            ) ? (
                                              <AtchBox
                                                onClick={() =>
                                                  download(
                                                    img.attachment_URL,
                                                    img.message
                                                  )
                                                }
                                              >
                                                <CgFileDocument />
                                                <h5>
                                                  {slpitFunc(
                                                    img.message || img.name
                                                  )}
                                                </h5>
                                              </AtchBox>
                                            ) : (
                                              <>
                                                {idx < 3 ? (
                                                  <Col
                                                    span={
                                                      item?.attachments
                                                        ?.length === 1
                                                        ? "24"
                                                        : "12"
                                                    }
                                                  >
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isCoverDisProp={false}
                                                        isDeleteDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                ) : (
                                                  <Col span="12">
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isDeleteDisProp={false}
                                                        isCoverDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                )}
                                              </>
                                            )}
                                          </Row>
                                          <p>
                                            {getTime(
                                              item.created_at
                                                ? item.created_at
                                                : new Date()
                                            )}
                                          </p>
                                        </RightMessage>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <LeftMessage>
                                    <h2>{item.message}</h2>
                                    <p>
                                      {getTime(
                                        item.created_at
                                          ? item.created_at
                                          : new Date()
                                      )}
                                    </p>
                                  </LeftMessage>
                                )}
                              </>
                            )}
                          </div>

                          {item.created_at ? (
                            <>
                              {id === newSortMess?.length - 1 ? (
                                <DateWrap>
                                  <span>{dateConverter(item.created_at)}</span>
                                </DateWrap>
                              ) : (
                                <>
                                  {id === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      {new Date(
                                        sortMessages[id - 1]?.created_at
                                          ? sortMessages[id - 1]?.created_at
                                          : item.created_at
                                      )
                                        .toISOString()
                                        .slice(0, 10) ===
                                      new Date(item.created_at)
                                        .toISOString()
                                        .slice(0, 10) ? (
                                        ""
                                      ) : (
                                        <DateWrap>
                                          <span>
                                            {dateConverter(item.created_at)}
                                          </span>
                                        </DateWrap>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </>
                  )}
                </ChatBox>
                <TextBoxMob>
                  <input
                    accept="application/pdf, application/vnd.ms-excel, image/*"
                    id="myInput"
                    multiple={"multiple"}
                    type="file"
                    ref={upload}
                    style={{ display: "none" }}
                    onChange={fileChangeHandler}
                    max="5"
                  />
                  {/* <AttachMent>
                    <AttachmentSvg />
                  </AttachMent> */}
                  <AttachMent onClick={(e) => upload.current.click()}>
                    <FiPaperclip />
                  </AttachMent>

                  <SendEmail ref={drop}>
                    <Input.Group compact>
                      <Input
                        // disabled={state?.length}
                        onChange={getInputValue}
                        placeholder="Write a Message"
                        style={{
                          width: "calc(100% - 52px)",
                          height: "50px",
                        }}
                        onPressEnter={() => onFinish()}
                        value={inputValue}
                      />
                      <Button
                        onClick={() => onFinish()}
                        disabled={
                          state?.length ? !state?.length : !inputValue.length
                        }
                      >
                        <BiSend />
                      </Button>
                    </Input.Group>
                  </SendEmail>
                </TextBoxMob>
              </ActiveChat>

              <SidebarContainer className="left-panel">
                <Divider onClick={openSiderDivider} />
                <HeaderSearch maxWidth="100%" bgcolor="#eeeeee" />
                <MessagerWrapMob>
                  {adminChats.map((item, id) => (
                    <>
                      <AdminMessage
                        key={id}
                        // background={
                        //   selectedChat.id === item.id ? "#ECF2FF" : "#ffffff"
                        // }
                        onClick={(e) => {
                          openSiderDivider(e);
                          setInputValue("");
                          setSelectedChat(item);
                          localStorage.setItem("chat_id", item.id);
                        }}
                      >
                        <UserAvatar
                          className="userName__icon"
                          size={avatarWidth}
                          src={item?.adminUser?.image?.path}
                          fullName={`${item.adminUser.firstName || ""} ${
                            item.adminUser.lastName || ""
                          }`}
                        />
                        <MessageName>
                          <h2>
                            {upperCase(item.adminUser.firstName)}{" "}
                            {upperCase(item.adminUser.lastName)}
                            <Badge count={item?.unseenMessageCount}></Badge>
                            {/* <Divider type="vertical" /> {upperCase(item.unit_name)} */}
                          </h2>
                          {/* {item?.latestMessage?.message ? (
                          <p>{item?.latestMessage?.message}</p>
                        ) : (
                          <p>Chat Joined</p>
                        )} */}
                        </MessageName>
                      </AdminMessage>
                      <Divider />
                    </>
                  ))}
                </MessagerWrapMob>
              </SidebarContainer>
            </Col>
          </Row>
        </>
      ) : (
        <Container>
          <NoMessageWrapMain>
            <div className="no_message">
              <NoMessages />
              <h2>No Message Available</h2>
            </div>
          </NoMessageWrapMain>
        </Container>
      )}
      <FooterSectionHome />
    </div>
  );
}

export default Message;

export const MsgBtn = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 3px;
  background: #f67c36;
  cursor: pointer;
  align-items: center;
  padding: 0 11px;

  :disabled {
    color: #ffffff;
    border-color: #f67c36;
    background: #f67c36;
    text-shadow: none;
    box-shadow: none;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: #f67c36;
    background: #f67c36;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.forth};
    line-height: 22px;
  }

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    position: fixed;
    right: 80px;
    color: ${({ theme }) => theme.colors.third};
  }
  @media (max-width: 1550px) {
    height: 40px;
  }
`;

export const FilterBtn = styled.div`
  cursor: pointer;
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background: #eeeeee;
  align-items: center;
  justify-content: center;

  @media (max-width: 1550px) {
    height: 35px;
  }
`;

export const FilterBtnPopover = styled.div`
  ${"" /* padding: 15px; */}

  .ant-checkbox-group {
    flex-direction: column;
    display: flex;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  .ant-checkbox-checked::after {
    border: 1px solid #eeeeee;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid #2b353f;
  }
`;

export const TableOneWrap = styled.div`
  .ant-input-group {
    display: flex;
    flex-direction: row-reverse;
  }

  border-radius: 5px;

  .ant-space {
    :hover,
    :focus {
      a {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  .ant-input-affix-wrapper:hover {
    border-color: #eeeeee;
    border-right-width: 1px;
    z-index: 0;
  }

  .ant-input-search .ant-input-affix-wrapper {
    padding-left: 40px;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 2px 0 0 2px;
    border-right: none;
    height: ${({ sHeight }) => sHeight || "40px"};
    background: #eeeeee;
    border-radius: 5px 0 0 5px;
    border: none;
    z-index: 1;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    background: #eeeeee;
    border-radius: 5px;
    border: none;
  }

  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    background: #eeeeee;
  }

  .ant-table-thead > tr > th {
    background-color: #dee8ff;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.forth};
  }
  .ant-table.ant-table-middle {
    font-size: 16px;
  }
  .ant-table-tbody > tr > td {
    cursor: pointer;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }

  .ant-table {
    ${
      "" /* svg {
      margin-top: 10px;
    } */
    }
  }
  /* FOR PAGINATION */
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .pagination__wrap {
    padding-top: 30px;
  }
  .ant-pagination-prev .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-left: 5px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      height: ${({ searchHeight }) => searchHeight || "32px"};
    }

    ${
      "" /* .ant-table-row{
      line-height: 0.5715;
    } */
    }
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-size: 14px;
      line-height: 12px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 6px 4px;
    }
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 5px;
    }
  }

  @media (max-width: 991.98px) {
    .search__filter {
      margin-bottom: 10px;
    }
  }
`;

const SelectUnitWrap = styled(Select)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  ${"" /* height: 40px !important; */}

  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  width: 100%;

  .ant-select-selection-placeholder {
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: ${({ bgColor }) => bgColor || "#fff"} !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px !important;
    align-items: center;
  }
  .ant-select:hover {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
  ,
  .ant-select-selector {
    border-color: #aaaaaa !important;
  }
  .ant-select-focused {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }

  .ant-select-selection-item {
    height: 50px !important;
    align-items: center;
    display: flex;
  }

  @media (max-width: 1550px) {
    font-size: 16px !important;
    height: 50px !important;

    .ant-select-selection-placeholder {
      font-size: 16px;
    }

    .ant-select-selector {
      height: 50px !important;
    }
  }
`;

const StyledUploadPreviewWrapper = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "grid")};
  flex-wrap: wrap;
  margin-top: 10px;

  .ant-image-mask {
    border-radius: 10px;
  }
  .ant-image {
    margin: 0 5px;
  }

  img {
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 575px) {
    .ant-image {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  }
`;

export const AtchBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 32px;
    color: ${({ fontColor }) => fontColor || "#4160ac"};
  }
  h5,
  h4 {
    cursor: pointer;
    margin-left: 15px;
    color: ${({ fontColor }) => fontColor || "#4160ac"};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .dow__svg {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #f67c36;
  }

  @media (max-width: 1550px) {
    h5 {
      font-size: 16px;
      line-height: 20px;
      margin-left: 5px;
    }
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;

  .ant-divider-vertical {
    background: #2b353f;
    width: 1px;
    font-size: 22px;
  }
`;

const UnitDetails = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  img {
    width: 50px;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #f67c36;
    margin-bottom: 0;
  }
`;

// Sidebar Styles
export const SidebarContainer = styled.div`
  padding-top: 115px;
  width: 280px;
  background: #fff;
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.06);
  display: block;
  padding: 80px 15px 15px 15px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden;

  .search__message {
    margin-left: 0 !important;
  }

  .ant-divider-horizontal {
    border-top: 5px solid rgba(0, 0, 0, 0.06);
    width: 60px;
    min-width: 60px;
    margin: auto;
    margin-bottom: 25px;
    min-height: 5px;
    background: #d9d9d9;
    border-radius: 60px;
  }

  @media (max-width: 1550px) {
    width: 100%;
    padding-top: 64px;
  }

  @media (max-width: 991.98px) {
    z-index: 111;
    margin-top: 65px;
    padding: 10px;
  }

  // scrollbar css
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`;

const DateWrap = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  span {
    background: #cecece;
    display: inline-block;
    padding: 2px 15px;
    border-radius: 39px;
    color: #2b353f;
  }
`;

export const SendEmail = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding-left: 20px;
  ${"" /* border-left: 0; */}
  ${"" /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */}

  .ant-btn:active {
    border-color: #fff;
  }

  .ant-input-prefix {
    margin-right: 20px;
  }

  .ant-btn {
    height: 50px;
    border-left: 0;
    color: #2b353f;
    background: #fff;
    border: none;
    svg {
      margin-top: 5px;
      font-size: 24px;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #2b353f;
    border-color: #d9d9d9;
    background: #fff;
  }

  .ant-input:hover {
    border-right: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: #fff;
    border-right: none;
    border-radius: 5px;
    border: 1px solid rgb(221, 221, 221);
    background: rgb(255, 255, 255);
    height: 90px;
  }
  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #fff;
    border: none;
  }
  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px #eeeeee;
    border-right-width: 0;
    outline: 0;
  }

  .ant-input-affix-wrapper:focus {
    border-color: #fff;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fff;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: #fff;
  }

  .ant-input-group.ant-input-group-compact {
    display: flex;
  }

  @media (max-width: 1550px) {
    width: 100%;
  }
`;

export const AttachMent = styled.div`
  min-width: 40px;
  min-height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 22px;
  }
`;

export const ChatBox = styled.div`
  background: #f6f9ff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0 4px 4px 0;
  padding: 20px;

  display: flex;
  flex-direction: column-reverse;

  min-height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);

  overflow-y: scroll;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
    text-align: center;
  }

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    padding: 15px;
    min-height: calc(100vh - 311px);
    max-height: calc(100vh - 311px);
  }
  @media (max-width: 768px) {
    padding: 15px;
    min-height: calc(100vh - 450px);
    max-height: calc(100vh - 450px);
  }
`;

export const TextBox = styled.div`
  ${"" /* background: #ffffff; */}
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  ${"" /* border-radius: 5px; */}
  ${"" /* margin-top: 25px; */}
  display: flex;
  ${"" /* padding: 10px; */}
  background: #ffffff;
  height: 120px;
  align-items: center;
`;
export const TextBoxMob = styled.div`
  ${"" /* background: #ffffff; */}
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 25px;
  display: flex;
  ${"" /* padding: 10px; */}
  position: fixed;
  ${"" /* display: none; */}
  bottom: -1px;
  left: 0;
  ${"" /* z-index: 1000; */}
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  padding: 15px 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

export const LeftMessage = styled.div`
  background: ${({ background }) => background || "#E0E9FB"};
  ${"" /* border-radius: 0px 10px 10px 10px; */}
  border-radius: 20px 20px 20px 0;
  padding: 10px;
  margin-top: 20px;
  width: ${({ width }) => width || "fit-content"};
  align-items: end;
  display: flex;

  .typing {
    position: relative;
    padding: 5px;
  }

  .typing__dot {
    float: left;
    width: 10px;
    height: 10px;
    margin: 0 4px;
    background: #4160ac;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .typing__dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }

  h2 {
    color: #2b353f;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    margin-left: 20px;
  }

  @media (max-width: 1550px) {
    margin-top: 15px;
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const RightMessage = styled.div`
  ${
    "" /* background: #eeeeee;
  border-radius: 10px 0px 10px 10px; */
  }
  padding: 10px;
  width: ${({ width }) => width || "fit-content"};
  margin-left: auto;
  margin-top: 20px;

  justify-content: end;
  align-items: end;
  text-align: end;
  display: flex;
  right: 20px;

  border-radius: 20px 20px 0px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  h2 {
    color: #2b353f;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    margin-left: 20px;
  }

  @media (max-width: 1550px) {
    margin-top: 15px;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const BtnPrice = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-right: 20px;
  }
`;

const ActiveProName = styled.div`
  align-items: center;
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  width: 96%;

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
    margin-bottom: 0;
  }
`;
const ActiveProperty = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 15px;
  display: flex;
`;

const ActiveChat = styled.div`
  width: 100%;
  margin-top: 1px;
`;

const ActiveMessageName = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  width: 94%;

  svg {
    font-size: 24px;
    cursor: pointer;
  }

  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;

    .ant-divider-vertical {
      color: #2b353f;
      border-left: 2px solid #2b353f;
    }
  }

  h3 {
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3px;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    width: 85%;

    h2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #2b353f;
    }
  }
`;

const ActiveName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: ${({ bs }) => bs || "0px 0px 10px rgba(0, 0, 0, 0.1)"};
  border-radius: ${({ br }) => br || "0px 5px 0px 0px"};

  @media (max-width: 1550px) {
    padding: 10px;
  }
`;

const MessageName = styled.div`
  padding: 12px;
  width: 80%;

  .ant-badge-count {
    top: -10px;
    background: #f67c36;
    right: -5px;
    box-shadow: none;
    min-width: 17px;
    height: 17px;
    padding: 0 0px;
    line-height: 19px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
    margin-bottom: 5px;

    .ant-divider-vertical {
      color: #2b353f;
      border-left: 2px solid #2b353f;
    }
  }

  h3 {
    color: #848687;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    h2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0px;
      color: #2b353f;
    }
  }
`;

const AdminMessage = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px 40px;
  background: ${({ background }) => background || "#ffffff"};

  @media (max-width: 1550px) {
    padding: 10px 15px;
  }
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const MessagerWrapMob = styled.div`
  margin-top: 20px;
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 10px 0;
    min-height: 1px;
    background: #d9d9d9;
    border-radius: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

const MessagerWrap = styled.div`
  min-height: calc(100vh - 305px);
  max-height: calc(100vh - 305px);
  overflow-y: auto;

  @media (max-width: 1550px) {
    max-height: calc(100vh - 188px);
    min-height: calc(100vh - 188px);
  }
  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    ${"" /* padding: 0 15px 15px 15px; */}
  }
`;

const RightWrap = styled.div`
  width: 100%;

  ${
    "" /* background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 30px;

  @media (max-width: 1550px) {
    padding: 15px;
  } */
  }
`;

const LeftWrap = styled.div`
  background: #ffffff;
  border-radius: 5px 0px 0px 5px;
  .message__box {
    padding: 25px;
    display: flex;
    gap: 10px;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #2b353f;
    margin-bottom: 0;
    padding: 25px 25px 0 25px;
  }

  @media (max-width: 1550px) {
    .message__box {
      padding: 15px;
    }

    h1 {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 0;
      padding: 15px 15px 0 15px;
    }
  }
`;

const Container = styled.div`
  max-width: ${({ mWid }) => mWid || "1400"}px;
  padding: ${({ padding }) => padding || "0"}px;
  margin: auto;

  .ant__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1550px) {
    padding: ${({ paddings }) => paddings || "0 30px"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "0 15px"};
  }
`;

const NoMessageWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${"" /* top: 25%; */}
  position: absolute;

  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
`;

const NoMessageWrapMain = styled.div`
  width: 100%;
  height: calc(100vh - 120px);

  .no_message {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    display: flex;
  }
`;
