import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import styled from "styled-components";
import StarRatings from "react-star-ratings";
import {
  AvenewImg,
  BathSvg,
  BedSvg,
  NoApplications1,
  Sqft,
} from "../../assets/svgs";
import { ApplicationsCardTop, BedBathBox } from "../applications/Applications";
import { MobCardDetails } from "../category-of-property/CategoryOfProperty";
import { getApplications } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { formattedNumber, toUSCurrency } from "../../utils/fn";
import { useNavigate, createSearchParams } from "react-router-dom";

function Applications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const [applicatiomUnits, setApplicatiomUnits] = useState([]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get all appliation data
  useEffect(() => {
    setIsLoading(true);
    dispatch(getApplications()).then(({ payload }) => {
      setIsLoading(false);
      const arr = Object.values(payload);
      setAllApplications(arr);
    });
  }, []);

  // data transfer to object to array
  useEffect(() => {
    if (allApplications?.length) {
      let unitDetail = [];
      allApplications?.map((item) => {
        unitDetail.push(item?.unitDetails);
      });

      setApplicatiomUnits(unitDetail);
    }
  }, [allApplications]);

  // To navigate to main application page
  const navigateParam = (id) => {
    navigate({
      pathname: `/applications`,
      search: createSearchParams({
        unitId: id,
      }).toString(),
    });
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "calc(100vh - 440px)",
          }}
        >
          <Spin className="ant__spin" size="large" />
        </div>
      ) : (
        <>
          {applicatiomUnits?.length ? (
            <ApplicationsWrap>
              <Row gutter={[20, 20]}>
                {applicatiomUnits.map((items) => (
                  <>
                    <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                      <ApplicationsCard
                        onClick={() => navigateParam(items?.id)}
                      >
                        {items?.images && items?.images?.length ? (
                          <>
                            {items.images.map((img, idx) => (
                              <div key={idx}>
                                {img.isCover === true ? (
                                  <img alt="example" src={img.path} />
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <AvenewImg
                            className="svg__border"
                            style={{
                              background: "#eeeeee",
                              height: "150px",
                              width: "150px",
                            }}
                          />
                        )}
                        <CardDetails>
                          {/* <Row>
                    <Col>
                      <StarRatings
                        rating={rating}
                        starRatedColor="#F67C36"
                        starDimension="20px"
                        starSpacing="5px"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        name="rating"
                      />
                    </Col>
                  </Row> */}
                          <Row>
                            <Col>
                              <h2>{items?.unit_name}</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p>{toUSCurrency(Number(items.price))}</p>
                            </Col>
                          </Row>
                          <Row justify="space-between">
                            {items?.bed ? (
                              <Col className="card__bottom">
                                <p>
                                  <BedSvg className="icon" />
                                </p>
                                <span>Bed : {items?.bed}</span>
                              </Col>
                            ) : (
                              ""
                            )}
                            {items?.bath ? (
                              <Col className="card__bottom">
                                <p>
                                  <BathSvg />
                                </p>
                                <span>Bath : {items?.bath}</span>
                              </Col>
                            ) : (
                              ""
                            )}
                            {items?.sqft ? (
                              <Col className="card__bottom">
                                <p>
                                  <Sqft />
                                </p>
                                <span>
                                  Sq ft : {formattedNumber(items?.sqft)}
                                </span>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </CardDetails>
                      </ApplicationsCard>
                    </Col>
                    <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                      <MobCard>
                        <ApplicationsCardTop
                          onClick={() => navigateParam(items?.id)}
                        >
                          {items?.images && items?.images?.length ? (
                            <>
                              {items.images.map((img, idx) => (
                                <div key={idx}>
                                  {img.isCover === true ? (
                                    <img alt="example" src={img.path} />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            <AvenewImg
                              className="svg__border"
                              style={{
                                background: "#eeeeee",
                              }}
                            />
                          )}
                          <MobCardDetails padding="5">
                            {/* <Row>
                      <Col>
                        <StarRatings
                          rating={rating}
                          starRatedColor="#F67C36"
                          starDimension="15px"
                          starSpacing="3px"
                          // changeRating={this.changeRating}
                          numberOfStars={5}
                          name="rating"
                        />
                      </Col>
                    </Row> */}
                            <Row>
                              <Col>
                                <h2>{items?.unit_name}</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p>{toUSCurrency(Number(items.price))}</p>
                              </Col>
                            </Row>
                          </MobCardDetails>
                        </ApplicationsCardTop>
                        <BedBathBox>
                          <Row justify="space-between">
                            {items?.bed ? (
                              <Col className="card__bottom">
                                <p>
                                  <BedSvg className="icon" />
                                </p>
                                <span>Bed : {items?.bed}</span>
                              </Col>
                            ) : (
                              ""
                            )}
                            {items?.bath ? (
                              <Col className="card__bottom">
                                <p>
                                  <BathSvg />
                                </p>
                                <span>Bath : {items?.bath}</span>
                              </Col>
                            ) : (
                              ""
                            )}
                            {items?.sqft ? (
                              <Col className="card__bottom">
                                <p>
                                  <Sqft />
                                </p>
                                <span>
                                  Sq ft : {formattedNumber(items?.sqft)}
                                </span>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </BedBathBox>
                      </MobCard>
                    </Col>
                  </>
                ))}
              </Row>
            </ApplicationsWrap>
          ) : (
            <NoApplicationWrap>
              <NoApplications1 />
              <h2>No Applications Here</h2>
            </NoApplicationWrap>
          )}
        </>
      )}
    </>
  );
}

export default Applications;

const NoApplicationWrap = styled.div`
  width: 100%;
  ${"" /* max-height: 400px; */}
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    color: #2b353f;
  }
`;

export const MobCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.4s;
  border-radius: 10px;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }

  .svg__border {
    height: "175px";
  }
  @media (max-width: 570px) {
    .svg__border {
      width: 100px;
      height: 100px;
    }
  }
`;

export const CardDetails = styled.div`
  padding: 10px 15px;
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-right: ${({ mr }) => mr || "30px"};
    svg {
      margin-right: 10px;
    }
  }
`;

export const ApplicationsCard = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.4s;

  :hover {
    ${"" /* -ms-transform: scale(0.98);  */}
    /* IE 9 */
    ${"" /* -webkit-transform: scale(0.98); */}
     /* Safari 3-8 */
    ${"" /* transform: scale(0.98); */}
  }
  img {
    width: 150px;
    height: 150px;
  }
`;

export const ApplicationsWrap = styled.div`
  width: 100%;
  padding: 20px 50px;
  max-height: 500px;
  overflow-y: scroll;

  /* scrollbar design */
  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(140, 153, 166, 0.8);
    outline: 1px solid rgb(140, 153, 166, 0.8);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    padding: 20px 10px;
  }

  @media (max-width: 767px) {
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
