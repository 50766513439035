import { Col, Form, Input, Modal, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H2Style, StyledButton } from "./TopSearchSection";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../pages/Profile-verification/BackgroundCheck";
import TextArea from "antd/lib/input/TextArea";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { Option } from "antd/lib/mentions";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { demoDetails } from "../../../store/reducers/user.reducer";

const FreeDemoModal = ({ modalState, onCancel }) => {
  const [closeIcon, setCloseIcon] = useState(true);
  const [loadings, setloadings] = useState(false);
  const [margin, setMargin] = useState("20px");
  const { width } = useWindowSize();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (width < 991) {
      setCloseIcon(false);
    } else {
      setCloseIcon(true);
    }
    if (width < 768) {
      setMargin("-50px");
    } else if (width > 320 && width < 540) {
      setMargin("-100px");
    } else {
      setMargin("5px");
    }
  }, [width]);

  // Form validation message
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  // Form submission function
  const onFinish = (value) => {
    setloadings(true);
    dispatch(demoDetails(value)).then((res) => {
      setloadings(false);
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
        message.success("Form submitted successfully");
      }
      form.resetFields();
    });
  };

  return (
    <>
      <Modal
        visible={modalState}
        destroyOnClose={true}
        onCancel={() => {
          onCancel(false);
        }}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(20px)",
        }}
        footer={null}
        className="free_demo_modal"
        closeIcon={
          <div
            style={{
              display: "flex",
              //   flexDirection: "row",
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
            }}
          >
            <Row justify="space between">
              {closeIcon ? <Col span={12}>CLOSE</Col> : ""}
              <Col span={12}>
                <IoIosClose
                  style={{
                    color: "#fff",
                    fontSize: "40px",
                    marginTop: "7px",
                    marginLeft: margin,
                  }}
                  className="react_close_icon"
                />
              </Col>
            </Row>
          </div>
        }
        bodyStyle={{
          backgroundColor: "#26314B",
          padding: "100px 50px",
          boxShadow: "none",
          border: "none",
        }}
        width={1200}
        centered
      >
        <DemoWrap>
          <Row>
            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <h2>Ready to learn more about the property rental software</h2>
              <p>
                Are you prepared to gain additional information about software
                designed for managing property rentals?
              </p>
              <CustomerRevieWrap>
                <Col span={24}>
                  <h4>More then 2M+ Properties available in Avenew Platform</h4>
                </Col>
                <Col span={24}>
                  <p>
                    Boost revenue, gain your profit from your listed property
                  </p>
                </Col>
                <Row>
                  <Col span={10}>
                    <img src="./images/group_pic.png" alt="Customers" />
                  </Col>
                  <Col xs={24} md={14} lg={14} xl={14} xxl={14}>
                    <span>Loved by Customers across 30+ cities</span>
                  </Col>
                </Row>
              </CustomerRevieWrap>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={{ span: 12, offset: 2 }}
              xl={{ span: 12, offset: 2 }}
              xxl={{ span: 12, offset: 2 }}
            >
              <ModalFormWrap>
                <h5>
                  Tell us about yourself, and we’ll get back to you shortly
                </h5>
                <StyledForm
                  id="demo-modal"
                  wh="100"
                  smwh="100"
                  xswh="100"
                  xxswh="100"
                >
                  <StyledForms>
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={onFinish}
                      validateMessages={validateMessages}
                    >
                      <StyledFormItem
                        style={{ marginTop: "30px" }}
                        wh="49"
                        fd={"column"}
                        smwh={"100"}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "First Name is required",
                            },
                          ]}
                          name="first_name"
                          label="First Name"
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Last Name is required",
                            },
                          ]}
                          name="last_name"
                          label="Last Name"
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </StyledFormItem>
                      <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                            },
                            { max: 10, message: "Invalid Phone Number!" },
                            { min: 10, message: "Invalid Phone Number!" },
                          ]}
                          name="phone_number"
                          label="Phone Number"
                        >
                          <Input
                            placeholder="Phone Number"
                            type="number"
                            min="0"
                            pattern="[0-9]*"
                            onWheel={(e) => e.target.blur()}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              type: "email",
                            },
                          ]}
                          name="email"
                          label="Your Email"
                        >
                          <Input placeholder="Email" type="email" />
                        </Form.Item>
                      </StyledFormItem>

                      <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                        <Form.Item
                          name="no_of_units"
                          label="How many units do you manage?"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Unit"
                            suffixIcon={<IoIosArrowDown />}
                            getPopupContainer={() =>
                              document.getElementById("demo-modal")
                            }
                          >
                            <Option value="0-10">0-10</Option>
                            <Option value="0-20">0-20</Option>
                            <Option value="0-30">0-30</Option>
                            <Option value="0-40">0-40</Option>
                            <Option value="0-50">0-50</Option>
                            <Option value="0-60">0-60</Option>
                            <Option value="0-70">0-70</Option>
                            <Option value="0-80">0-80</Option>
                            <Option value="0-90">0-90</Option>
                            <Option value="0-100">0-100</Option>
                          </Select>
                        </Form.Item>
                      </StyledFormItem>
                      <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                        <Form.Item
                          name="message"
                          label="Your Message"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Your Message is required",
                            },
                          ]}
                        >
                          <TextArea
                            // showCount
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            maxLength={500}
                            placeholder="Type here..."
                          />
                        </Form.Item>
                      </StyledFormItem>
                      <Form.Item>
                        <div className="demo_btn">
                          <StyledButton htmlType="submit" loading={loadings}>
                            Submit
                          </StyledButton>
                        </div>
                      </Form.Item>
                    </Form>
                  </StyledForms>
                </StyledForm>
              </ModalFormWrap>
            </Col>
          </Row>
        </DemoWrap>
      </Modal>
    </>
  );
};

export default FreeDemoModal;

const DemoWrap = styled.div`
  background-color: #26314b;

  .ant-modal-body {
    background-color: #26314b;
  }
  h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 20px;
    }
  }
`;

const CustomerRevieWrap = styled.div`
  background: #3b455b;
  padding: 20px 25px;
  h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px; /* 160% */
  }

  p {
    color: #adadad;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
  }

  span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }

  @media (max-width: 468px) {
    p {
      line-height: 20px;
    }
  }
  @media (max-width: 768px) {
    img {
      margin-bottom: 20px;
    }
  }
`;

const ModalFormWrap = styled.div`
  background: #fff;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;

  h5 {
    color: #848687;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }

  .ant-form-item .ant-select-selection-placeholder {
    color: #2b353f;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }

  .ant-form-item input,
  .ant-input,
  .ant-form-item .ant-select-selector {
    border-radius: 10px;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;
