import React from "react";

const footerCircle = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="134"
      height="195"
      viewBox="0 0 134 195"
      fill="none"
    >
      <path
        d="M101.314 4.28308C101.323 5.14108 101.077 5.98241 100.607 6.70021C100.137 7.41802 99.4638 7.97991 98.6735 8.31454C97.8832 8.64916 97.0113 8.74141 96.1684 8.57957C95.3255 8.41773 94.5498 8.00907 93.9397 7.40555C93.3296 6.80204 92.9127 6.03086 92.7419 5.18997C92.5711 4.34908 92.6541 3.47641 92.9804 2.6828C93.3067 1.88919 93.8616 1.21041 94.5746 0.732734C95.2876 0.255053 96.1264 4.75455e-05 96.9847 6.65278e-09C98.1252 -6.31625e-05 99.2196 0.449728 100.03 1.25168C100.841 2.05362 101.302 3.14297 101.314 4.28308Z"
        fill="#1F293F"
      />
      <path
        d="M85.0881 4.96398C85.2283 5.79798 85.1184 6.65482 84.7723 7.42645C84.4261 8.19809 83.8592 8.84995 83.143 9.29997C82.4268 9.74999 81.5934 9.97797 80.7478 9.95518C79.9022 9.93239 79.0823 9.65984 78.3914 9.1719C77.7005 8.68396 77.1695 8.00251 76.8655 7.21336C76.5614 6.4242 76.4978 5.5627 76.6827 4.73747C76.8676 3.91223 77.2927 3.16017 77.9044 2.57611C78.5162 1.99206 79.2873 1.60214 80.1204 1.4555C80.6772 1.35748 81.248 1.37066 81.7997 1.49422C82.3514 1.61779 82.8733 1.84929 83.3351 2.17544C83.7969 2.50159 84.1896 2.91591 84.4905 3.3945C84.7913 3.8731 84.9944 4.40652 85.0881 4.96398Z"
        fill="#1F293F"
      />
      <path
        d="M69.3216 8.43105C69.6026 9.22783 69.6416 10.0899 69.4337 10.9088C69.2258 11.7277 68.7804 12.4669 68.1534 13.0333C67.5264 13.5997 66.7457 13.9681 65.9098 14.0921C65.0739 14.216 64.22 14.0901 63.4555 13.73C62.691 13.37 62.0501 12.7919 61.6134 12.0686C61.1767 11.3453 60.9637 10.509 61.0013 9.66499C61.0389 8.82097 61.3253 8.00686 61.8246 7.32517C62.3238 6.64348 63.0134 6.12466 63.8068 5.83391C64.8846 5.50966 66.0442 5.59557 67.0624 6.07509C68.0806 6.55461 68.8854 7.39378 69.3216 8.43105Z"
        fill="#1F293F"
      />
      <path
        d="M54.3714 14.5788C54.8647 15.5778 54.9748 16.7229 54.6809 17.7976C54.387 18.8723 53.7094 19.802 52.7762 20.4111C52.2925 20.6963 51.7568 20.8825 51.2004 20.9589C50.644 21.0354 50.078 21.0005 49.5352 20.8563C48.9925 20.7122 48.4838 20.4617 48.0387 20.1193C47.5936 19.7769 47.221 19.3495 46.9425 18.8619C46.3744 17.877 46.2209 16.7068 46.5158 15.6088C46.8107 14.5107 47.5298 13.5748 48.5149 13.0068C49.5 12.4389 50.6705 12.2854 51.7688 12.5802C52.8671 12.875 53.8033 13.5939 54.3714 14.5788Z"
        fill="#1F293F"
      />
      <path
        d="M40.6996 23.2344C41.4216 24.1058 41.7692 25.2275 41.6668 26.3544C41.5643 27.4814 41.02 28.522 40.1527 29.2489C39.5025 29.7938 38.7051 30.1335 37.8616 30.2248C37.0182 30.3161 36.1666 30.1551 35.4148 29.762C34.663 29.3689 34.0449 28.7615 33.6388 28.0168C33.2327 27.2721 33.057 26.4236 33.1338 25.5789C33.2106 24.7342 33.5365 23.9313 34.0703 23.272C34.604 22.6127 35.3216 22.1268 36.132 21.8756C36.9423 21.6245 37.809 21.6196 38.6222 21.8615C39.4353 22.1034 40.1583 22.5812 40.6996 23.2344Z"
        fill="#1F293F"
      />
      <path
        d="M28.7135 34.1255C29.5808 34.8525 30.1252 35.8931 30.2277 37.02C30.3302 38.147 29.9824 39.2687 29.2604 40.1401C28.495 40.962 27.453 41.4723 26.3343 41.5732C25.2156 41.6741 24.099 41.3585 23.1988 40.6868C22.3285 39.9557 21.7843 38.9089 21.686 37.7767C21.5877 36.6445 21.9433 35.5196 22.6746 34.6496C23.4059 33.7795 24.4531 33.2354 25.5856 33.1372C26.718 33.0389 27.8432 33.3944 28.7135 34.1255Z"
        fill="#1F293F"
      />
      <path
        d="M18.8228 46.931C19.5584 47.3541 20.1518 47.9861 20.5277 48.7469C20.9035 49.5076 21.0448 50.3627 20.9338 51.2039C20.8228 52.0451 20.4644 52.8344 19.904 53.4717C19.3436 54.1089 18.6067 54.5653 17.7864 54.7831C16.9662 55.0009 16.0997 54.9703 15.2969 54.6951C14.4941 54.4199 13.7912 53.9125 13.2772 53.2373C12.7633 52.5621 12.4615 51.7495 12.4102 50.9025C12.3589 50.0556 12.5604 49.2125 12.9891 48.4802C13.2675 47.9927 13.6401 47.5653 14.0852 47.2229C14.5303 46.8805 15.039 46.6299 15.5818 46.4858C16.1245 46.3416 16.6907 46.3068 17.247 46.3832C17.8034 46.4596 18.339 46.6458 18.8228 46.931Z"
        fill="#1F293F"
      />
      <path
        d="M11.3044 61.2856C11.8785 61.4389 12.4145 61.7099 12.8783 62.0815C13.342 62.453 13.7233 62.9169 13.9981 63.4438C14.2728 63.9706 14.4349 64.5488 14.474 65.1417C14.5131 65.7345 14.4284 66.329 14.2253 66.8874C14.0222 67.4457 13.7052 67.9558 13.2943 68.385C12.8834 68.8143 12.3877 69.1534 11.8386 69.3807C11.2896 69.6081 10.6993 69.7187 10.1052 69.7057C9.51104 69.6926 8.92613 69.5562 8.3876 69.3049C7.41692 68.8521 6.65114 68.0522 6.24122 67.0628C5.83129 66.0734 5.80686 64.9664 6.17293 63.96C6.53899 62.9536 7.2689 62.1208 8.21875 61.6257C9.16859 61.1307 10.2695 61.0093 11.3044 61.2856Z"
        fill="#1F293F"
      />
      <path
        d="M6.42722 76.6834C7.26159 76.8319 8.03319 77.2244 8.6444 77.8113C9.25561 78.3982 9.67903 79.1531 9.86099 79.9806C10.0429 80.8081 9.97533 81.671 9.66669 82.4601C9.35804 83.2492 8.82218 83.929 8.127 84.4136C7.43182 84.8982 6.60859 85.1658 5.76128 85.1825C4.91397 85.1992 4.08066 84.9643 3.36691 84.5075C2.65316 84.0507 2.09095 83.3925 1.75142 82.6162C1.41189 81.8399 1.3103 80.9804 1.45948 80.1463C1.65937 79.0287 2.29491 78.0362 3.22643 77.3869C4.15794 76.7376 5.30919 76.4846 6.42722 76.6834Z"
        fill="#1F293F"
      />
      <path
        d="M4.28511 101.289C6.65117 101.289 8.56925 99.3712 8.56925 97.0057C8.56925 94.6403 6.65117 92.7227 4.28511 92.7227C1.91906 92.7227 0.000976562 94.6403 0.000976562 97.0057C0.000976562 99.3712 1.91906 101.289 4.28511 101.289Z"
        fill="#1F293F"
      />
      <path
        d="M4.92266 108.895C5.7569 108.746 6.61662 108.847 7.39311 109.187C8.1696 109.526 8.8279 110.088 9.28482 110.802C9.74174 111.516 9.9768 112.349 9.96009 113.196C9.94338 114.043 9.67565 114.866 9.19094 115.561C8.70623 116.256 8.02633 116.792 7.23706 117.1C6.44778 117.409 5.58458 117.476 4.75687 117.294C3.92916 117.112 3.17404 116.689 2.58698 116.078C1.99992 115.467 1.60732 114.696 1.45885 113.862C1.25991 112.744 1.51299 111.593 2.16249 110.662C2.81199 109.73 3.8048 109.095 4.92266 108.895Z"
        fill="#1F293F"
      />
      <path
        d="M8.38499 124.709C9.1815 124.416 10.0471 124.366 10.872 124.565C11.6969 124.765 12.444 125.205 13.0184 125.83C13.5928 126.454 13.9687 127.235 14.0984 128.074C14.2281 128.913 14.1055 129.771 13.7466 130.54C13.3877 131.309 12.8085 131.954 12.0823 132.393C11.3562 132.832 10.5159 133.046 9.66806 133.007C8.82023 132.968 8.00313 132.678 7.32029 132.174C6.63744 131.67 6.11959 130.975 5.83262 130.177C5.6371 129.65 5.54813 129.091 5.57086 128.53C5.59359 127.969 5.72757 127.418 5.96503 126.909C6.20249 126.4 6.5388 125.944 6.95421 125.566C7.36962 125.189 7.85597 124.897 8.38499 124.709Z"
        fill="#1F293F"
      />
      <path
        d="M14.5394 139.519C15.5245 138.951 16.695 138.797 17.7933 139.092C18.8916 139.387 19.8278 140.106 20.3959 141.091C20.964 142.075 21.1175 143.246 20.8226 144.344C20.5277 145.442 19.8086 146.378 18.8235 146.946C18.3397 147.231 17.8042 147.417 17.2478 147.493C16.6914 147.57 16.1253 147.535 15.5825 147.391C15.0397 147.247 14.531 146.996 14.0859 146.654C13.6408 146.311 13.2683 145.884 12.9898 145.396C12.7035 144.909 12.5165 144.37 12.4394 143.811C12.3624 143.251 12.3968 142.681 12.5408 142.135C12.6849 141.589 12.9357 141.076 13.2787 140.627C13.6217 140.178 14.0501 139.801 14.5394 139.519Z"
        fill="#1F293F"
      />
      <path
        d="M23.1972 153.186C24.0974 152.514 25.2139 152.199 26.3326 152.3C27.4513 152.401 28.4933 152.911 29.2587 153.733C29.7976 154.384 30.1315 155.181 30.2184 156.022C30.3053 156.863 30.1413 157.711 29.747 158.459C29.3528 159.207 28.746 159.821 28.003 160.225C27.2601 160.629 26.4142 160.804 25.5719 160.728C24.7297 160.652 23.9289 160.328 23.2702 159.798C22.6115 159.268 22.1245 158.555 21.8706 157.748C21.6166 156.942 21.6071 156.078 21.8432 155.266C22.0792 154.455 22.5504 153.731 23.1972 153.186Z"
        fill="#1F293F"
      />
      <path
        d="M34.0892 165.261C34.8606 164.446 35.9057 163.944 37.0242 163.851C38.1427 163.759 39.256 164.082 40.1509 164.76C41.0182 165.487 41.5625 166.527 41.665 167.654C41.7674 168.781 41.4198 169.903 40.6978 170.774C40.3357 171.208 39.8916 171.566 39.391 171.829C38.8904 172.091 38.343 172.252 37.7801 172.303C37.2173 172.353 36.65 172.293 36.1105 172.124C35.5711 171.956 35.07 171.683 34.6361 171.321C34.2022 170.959 33.8439 170.515 33.5815 170.015C33.3192 169.514 33.158 168.967 33.1073 168.404C33.0565 167.841 33.1171 167.274 33.2856 166.735C33.454 166.196 33.7271 165.695 34.0892 165.261Z"
        fill="#1F293F"
      />
      <path
        d="M46.9432 175.148C47.2216 174.661 47.5942 174.233 48.0393 173.891C48.4844 173.548 48.9931 173.298 49.5359 173.154C50.0786 173.01 50.6446 172.975 51.201 173.051C51.7574 173.128 52.2931 173.314 52.7769 173.599C53.5125 174.022 54.1058 174.654 54.4816 175.415C54.8575 176.176 54.9989 177.031 54.8879 177.872C54.7769 178.713 54.4185 179.502 53.8581 180.14C53.2977 180.777 52.5606 181.233 51.7404 181.451C50.9201 181.669 50.0537 181.638 49.2509 181.363C48.4481 181.088 47.7451 180.58 47.2312 179.905C46.7173 179.23 46.4156 178.417 46.3643 177.57C46.313 176.724 46.5145 175.88 46.9432 175.148Z"
        fill="#1F293F"
      />
      <path
        d="M61.2543 182.666C61.6411 181.596 62.4371 180.724 63.467 180.242C64.497 179.759 65.6765 179.705 66.7463 180.091C67.816 180.478 68.6882 181.274 69.1711 182.304C69.654 183.333 69.7081 184.512 69.3213 185.582C68.8851 186.619 68.0803 187.458 67.0621 187.938C66.044 188.418 64.8843 188.503 63.8066 188.179C63.2758 187.986 62.7887 187.69 62.3728 187.308C61.957 186.926 61.6207 186.466 61.3835 185.953C61.1463 185.441 61.0128 184.887 60.9906 184.322C60.9684 183.758 61.0581 183.195 61.2543 182.666Z"
        fill="#1F293F"
      />
      <path
        d="M76.6575 187.587C76.8042 186.754 77.1942 185.984 77.7784 185.372C78.3626 184.76 79.1148 184.335 79.9402 184.15C80.7657 183.966 81.6275 184.029 82.4168 184.333C83.2062 184.637 83.8878 185.168 84.3758 185.859C84.8639 186.549 85.1365 187.369 85.1593 188.215C85.1821 189.06 84.9541 189.893 84.5039 190.609C84.0538 191.325 83.4017 191.892 82.6299 192.238C81.8581 192.584 81.0012 192.694 80.167 192.554C79.0464 192.353 78.0498 191.72 77.393 190.79C76.7362 189.861 76.472 188.71 76.6575 187.587Z"
        fill="#1F293F"
      />
      <path
        d="M97.0322 194.015C99.3982 194.015 101.316 192.098 101.316 189.732C101.316 187.367 99.3982 185.449 97.0322 185.449C94.6661 185.449 92.748 187.367 92.748 189.732C92.748 192.098 94.6661 194.015 97.0322 194.015Z"
        fill="#1F293F"
      />
      <path
        d="M108.88 189.047C108.739 188.213 108.849 187.357 109.195 186.585C109.542 185.813 110.108 185.161 110.825 184.711C111.541 184.261 112.374 184.033 113.22 184.056C114.065 184.079 114.885 184.352 115.576 184.84C116.267 185.327 116.798 186.009 117.102 186.798C117.406 187.587 117.47 188.449 117.285 189.274C117.1 190.099 116.675 190.851 116.063 191.435C115.451 192.019 114.68 192.409 113.847 192.556C113.29 192.654 112.72 192.641 112.168 192.517C111.616 192.394 111.094 192.162 110.633 191.836C110.171 191.51 109.778 191.096 109.477 190.617C109.176 190.138 108.973 189.605 108.88 189.047Z"
        fill="#1F293F"
      />
      <path
        d="M124.696 185.582C124.415 184.786 124.376 183.923 124.584 183.105C124.792 182.286 125.237 181.547 125.864 180.98C126.491 180.414 127.272 180.045 128.108 179.921C128.944 179.797 129.797 179.923 130.562 180.283C131.326 180.643 131.967 181.221 132.404 181.945C132.841 182.668 133.054 183.504 133.016 184.348C132.979 185.192 132.692 186.006 132.193 186.688C131.694 187.37 131.004 187.889 130.211 188.179C129.133 188.504 127.973 188.418 126.955 187.938C125.937 187.459 125.132 186.62 124.696 185.582Z"
        fill="#1F293F"
      />
      <path
        d="M117.357 6.42416C117.21 7.25704 116.82 8.02793 116.236 8.63954C115.652 9.25115 114.9 9.67611 114.074 9.86097C113.249 10.0458 112.387 9.98225 111.598 9.67825C110.808 9.37425 110.127 8.84344 109.639 8.15272C109.15 7.46199 108.878 6.64228 108.855 5.7969C108.832 4.95152 109.06 4.11836 109.51 3.40236C109.961 2.68635 110.613 2.11958 111.384 1.7735C112.156 1.42743 113.013 1.31751 113.848 1.45765C114.968 1.65806 115.965 2.29158 116.622 3.2211C117.278 4.15062 117.542 5.30133 117.357 6.42416Z"
        fill="#1F293F"
      />
    </svg>
  );
};

export default footerCircle;
