import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { StyledTabs } from "../../UserProfileTab";
import Members from "../Members";
import IdProof from "../IdProof";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import YourSpouse from "./YourSpouse";
import YourWork from "./YourWork";
import CreditHistory from "./CreditHistory";
import CriminalHistory from "./CriminalHistory";
import YourVehicles from "./YourVehicles";
import WhyRentedHere from "./WhyRentedHere";
import Emergency from "./Emergency";
import { useSelector } from "react-redux";
import EmergencyContact from "./EmergencyContact";

function StateProfileTabs({
  onBackClick,
  selectedState,
  onProfileSubmit,
  selectedStateProfile = {},
}) {
  const { applicationProcessData } = useSelector((state) => state.user);
  const { TabPane } = Tabs;
  const { width } = useWindowSize();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("your-spouse");
  const [tabPosition, setTabPosition] = useState("left");

  useEffect(() => {
    if (width < 993) {
      setTabPosition("horizontal");
    } else {
      setTabPosition("left");
    }
  }, [width, location.pathname]);

  const onYourSpouseNext = (val) => {
    setActiveTab(val);
  };

  // useEffect(() => {
  //   if (applicationProcessData?.stateProfile?.length) {
  //     if (
  //       applicationProcessData?.stateProfile[0]?.whyYouRentedHere &&
  //       Object.values(
  //         applicationProcessData?.stateProfile[0]?.whyYouRentedHere || {}
  //       )?.length
  //     ) {
  //       setActiveTab("emergency-contact");
  //     } else if (
  //       applicationProcessData?.stateProfile[0]?.vehicleDetails &&
  //       Object.values(
  //         applicationProcessData?.stateProfile[0]?.vehicleDetails || {}
  //       )?.length
  //     ) {
  //       setActiveTab("why-here");
  //     } else if (
  //       applicationProcessData?.stateProfile[0]?.rentalOrCriminalHistory &&
  //       Object.values(
  //         applicationProcessData?.stateProfile[0]?.rentalOrCriminalHistory || {}
  //       )?.length
  //     ) {
  //       setActiveTab("your-vehicles");
  //     } else if (
  //       applicationProcessData?.stateProfile[0]?.creditHistory &&
  //       Object.values(
  //         applicationProcessData?.stateProfile[0]?.creditHistory || {}
  //       )?.length
  //     ) {
  //       setActiveTab("criminal-history");
  //     } else if (
  //       applicationProcessData?.stateProfile[0]?.workDetails &&
  //       Object.values(
  //         applicationProcessData?.stateProfile[0]?.workDetails || {}
  //       )?.length
  //     ) {
  //       setActiveTab("credit-history");
  //     } else if (
  //       applicationProcessData?.stateProfile[0]?.spouseDetails &&
  //       Object.values(
  //         applicationProcessData?.stateProfile[0]?.spouseDetails || {}
  //       )?.length
  //     ) {
  //       setActiveTab("your-work");
  //     }
  //   } else {
  //   }
  // }, [applicationProcessData]);

  useEffect(() => {
    if (selectedState) {
      if (selectedState?.whyYouRentedHere) {
        setActiveTab("emergency-contact");
      } else if (selectedState?.vehicleDetails) {
        setActiveTab("why-here");
      } else if (selectedState?.rentalOrCriminalHistory) {
        setActiveTab("your-vehicles");
      } else if (selectedState?.creditHistory) {
        setActiveTab("criminal-history");
      } else if (selectedState?.workDetails) {
        setActiveTab("credit-history");
      } else if (selectedState?.spouseDetails) {
        setActiveTab("your-work");
      }
    } else {
    }
  }, [selectedState]);

  const onBackClickTabs = (tab) => {
    switch (tab) {
      case "emergency-contact":
        setActiveTab("why-here");
        break;
      case "why-here":
        setActiveTab("your-vehicles");
        break;
      case "your-vehicles":
        setActiveTab("criminal-history");
        break;
      case "criminal-history":
        setActiveTab("credit-history");
        break;
      case "credit-history":
        setActiveTab("your-work");
        break;
      case "your-work":
        setActiveTab("your-spouse");
        break;

      default:
        break;
    }
  };

  const profileSubmit = (value, fullstate) => {
    onProfileSubmit(value, fullstate);
  };

  return (
    <StyledTabWrapper>
      <StyledTabs
        defaultActiveKey={"your-spouse"}
        activeKey={activeTab}
        onChange={(tabKey) => {
          // setList([]);
          window.scrollTo(0, 0);
          setActiveTab(tabKey);
        }}
        tabPosition={tabPosition}
      >
        <TabPane tab="Co-applicant" key="your-spouse">
          <YourSpouse
            onYourSpouseNext={onYourSpouseNext}
            onBackClick={onBackClick}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
          />
        </TabPane>
        <TabPane tab="Occupation" key="your-work">
          <YourWork
            onYourSpouseNext={onYourSpouseNext}
            onBackClickTabs={onBackClickTabs}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
          />
        </TabPane>
        <TabPane tab="Credit history" key="credit-history">
          <CreditHistory
            onYourSpouseNext={onYourSpouseNext}
            onBackClickTabs={onBackClickTabs}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
          />
        </TabPane>
        <TabPane tab="Rental/Criminal History" key="criminal-history">
          <CriminalHistory
            onYourSpouseNext={onYourSpouseNext}
            onBackClickTabs={onBackClickTabs}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
          />
        </TabPane>
        <TabPane tab="Your vehicles" key="your-vehicles">
          <YourVehicles
            onYourSpouseNext={onYourSpouseNext}
            onBackClickTabs={onBackClickTabs}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
          />
        </TabPane>
        <TabPane tab="Additional details" key="why-here">
          <WhyRentedHere
            onYourSpouseNext={onYourSpouseNext}
            onBackClickTabs={onBackClickTabs}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
          />
        </TabPane>
        <TabPane tab="Emergency Contact" key="emergency-contact">
          {/* <Emergency onYourSpouseNext={onYourSpouseNext} /> */}
          <EmergencyContact
            onBackClickTabs={onBackClickTabs}
            selectedStateProfile={selectedState}
            profileSubmit={profileSubmit}
            onBackClick={onBackClick}
          />
        </TabPane>
      </StyledTabs>
    </StyledTabWrapper>
  );
}

export default StateProfileTabs;

const StyledTabWrapper = styled.div`
  margin-left: 45px !important;

  .ant-tabs > .ant-tabs-nav {
    background: none !important;
    box-shadow: none !important;
    border-radius: 5px;
    min-height: calc(100vh - 260px);
    padding-top: 0 !important;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 20px 8px 8px !important;
  }

  .ant-divider-vertical {
    margin-top: 0px !important;
    height: 13.9em !important;
    margin: 0 263px !important;
    @media (max-width: 1540px) {
      height: 8.9em !important;
    }

    @media (max-width: 993px) {
      display: none !important;
    }
  }
  @media (max-width: 992px) {
    ${"" /* margin-left: 30px; */}
    padding: 15px !important;
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0 25px !important;
  }

  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  @media (max-width: 1550px) {
    margin-left: 0 !important;
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      padding: 8px 20px 8px 8px !important;
    }
  }
  @media (max-width: 1200px) {
    margin-left: 0 !important;
  }
  @media (max-width: 993px) {
    .ant-tabs > .ant-tabs-nav {
      min-height: auto !important;
    }
  }

  @media (max-width: 769px) {
    padding: 0 !important;

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      bottom: 0;
      margin-bottom: 2px;
    }
  }
`;
