import React, { useEffect, useState } from "react";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import {
  Breadcrumb,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Skeleton,
  message,
} from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationProcess,
  getGetMembers,
  getUnitsDetails,
  inviteMember,
} from "../../store/reducers/user.reducer";
import styled from "styled-components";
import { PageTitle } from "../my-account/MyAccountTabs";
import { AvenewImg, BathSvg, BedSvg, PlusSvg, Sqft } from "../../assets/svgs";
import StarRatings from "react-star-ratings";
import Footer from "../../components/footer/Footer";
import HeaderSearch from "../../components/headerSearch/HeaderSearch";
import { IoIosArrowDown } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../Profile-verification/BackgroundCheck";
import { BtnWithEdit } from "../verification-process/sub-tabs/Members";
import ApplyForPropertyModal, {
  StyledButton,
} from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { formattedNumber, toUSCurrency } from "../../utils/fn";
import UnitModalSkeleton from "./UnitModalSkeleton";
import { MobCard } from "../my-account/Applications";
import { ApplicationsCardTop, BedBathBox } from "../applications/Applications";
import { MobCardDetails } from "../category-of-property/CategoryOfProperty";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function UnitApplication() {
  const { user, applicationProcessData } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);
  const [unitDetails, setUnitDetails] = useState({});
  const [members, setMembers] = useState([]);
  const [isAvailable, setIsAvailable] = useState(true);
  const [phoneLength, setPhoneLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState(false);

  // useEffect(() => {
  //   if (user && params?.id) {
  //     if (applicationProcessData?.is_verified) {
  //     } else {
  //       navigate(`/property-details/${params.id}`);
  //     }
  //   }
  // }, [user, params]);

  // set True/False
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // isPopover is open or close
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const hide = () => {
    setOpen(false);
  };

  // page navigation header
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    {
      path: "/dashboard",
      label: "Dashboard",
      isLink: true,
      key: "dashboard",
    },
    {
      path: "/unit-application",
      label: "Unit Application",
      key: "unit-application",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span key={index} className="breadcrumbs_label">
              {breadcrumb.label}
            </span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="property-details">
      <Link to={`/property-details/${unitDetails?.id}`}>
        {unitDetails?.unit_name}
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // get unit details
  useEffect(() => {
    if (params?.id) {
      dispatch(getUnitsDetails()).then(({ payload }) => {
        setUnitDetails(payload[0]);
      });
    }
  }, [params.id]);

  // get members details (which added on profile verification)
  useEffect(() => {
    dispatch(getGetMembers()).then(({ payload }) => {
      if (payload?.members?.length) {
        setMembers(payload?.members);
      } else {
        setMembers([]);
      }
    });
  }, []);

  // USA phone number format -> +1 (123) 456 7890
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const filterSearch = (input, option) => {
    return (option?.value ?? "").toLowerCase().includes(input.toLowerCase());
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // is member check
  const onChangeCheck = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  // Unit apiilication on submit click
  const onFinish = (val) => {
    let dataObject = {};
    val.memberNumber = `+1${val.memberNumber}`;
    val.isInvoiedMember = false;
    let data = [...members];
    data.push(val);
    setMembers((item) => [...item, val]);
    const newData = data.map((values) => {
      return {
        isInvoiedMember: values.isInvoiedMember,
        memberName: values.memberName,
        memberNumber: values.memberNumber,
      };
    });
    dataObject.members = newData;
    dispatch(applicationProcess(dataObject)).then(({ payload }) => {});

    form.resetFields();
  };

  // for add member and sent sms
  const onInvite = (value, id) => {
    let data = {};
    data.phoneNumber = value.memberNumber;
    data.invitedName = user?.firstName + " " + user?.lastName;
    data.applicationId = applicationProcessData?.id;
    data.invitationStatus = "send";
    message.success("Member Invited Successfully");
    dispatch(inviteMember(data)).then((res) => {
      let totalAdults = [...members];
      let newArr = totalAdults.map((val, idx) => {
        if (id === idx) {
          let newVal = { ...val };
          newVal.isInvoiedMember = true;
          newVal.invitation = [{ invitationStatus: "send" }];
          return newVal;
        } else {
          return val;
        }
      });
      setMembers(newArr);
      let dataObject = {};
      const newData = newArr.map((values) => {
        return {
          isInvoiedMember: values.isInvoiedMember,
          memberName: values.memberName,
          memberNumber: values.memberNumber,
        };
      });
      dataObject.members = newData;
      dispatch(applicationProcess(dataObject)).then(({ payload }) => {});
    });
  };

  // form validation messages
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // phone number validation
  const inputChange = () => {
    var cardNum = document.getElementById("ph_no");

    if (cardNum) {
      cardNum.onkeyup = function (e) {
        this.value = this.value.replace(/[^0-9\.]/g, "");
      };
      setPhoneLength(cardNum.value.length);
    }
  };

  // popover for add member (content for add member)
  const content = (
    <PopoverModal>
      <StyledForm
        style={{ marginTop: "25px" }}
        wh="100"
        smwh="100"
        xswh="100"
        xxswh="100"
      >
        <StyledForms>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {/* Add here */}

            <StyledFormItem
              // style={{ marginTop: responsiveMT }}
              wh="100"
              fd={"column"}
              smwh={"100"}
            >
              <Form.Item
                name="memberName"
                label={`Member name`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
              <Form.Item
                name="memberNumber"
                label="Contact Number"
                className="input__affix"
                rules={[
                  {
                    required: true,
                  },
                  { min: 10, message: " " },
                  { max: 10, message: "Enter Valid Contact Number" },
                ]}
              >
                <Input
                  // onChange={() => inputChange()}
                  // id="ph_no"
                  // minLength="10"
                  // maxLength="10"
                  onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  // prefix={`+1`}
                  type="number"
                  min="0"
                  pattern="[0-9]*"
                />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
              <Form.Item>
                <BtnWithEdit>
                  <StyledButton
                    padding={"4px 30px"}
                    backgroundcolor="#F67C36"
                    mt="30px"
                    btnwidthedit="100%"
                    htmlType="submit"
                    // disabled={phoneLength === 10 ? false : true}
                    onClick={hide}
                  >
                    Add
                  </StyledButton>
                </BtnWithEdit>
              </Form.Item>
            </StyledFormItem>
          </Form>
        </StyledForms>
      </StyledForm>
    </PopoverModal>
  );

  return (
    <div>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>
      <UnitApplicationWrap>
        <PageTitle>
          <h2>Unit Application</h2>
        </PageTitle>
        {unitDetails?.id ? (
          <Row gutter={[20, 20]}>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
              <UnitCard>
                {unitDetails?.images && unitDetails?.images?.length ? (
                  <>
                    {unitDetails?.images.map((img, idx) => (
                      <div key={idx}>
                        {img.isCover === true ? (
                          <img alt="example" src={img.path} />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <AvenewImg
                    className="svg__border"
                    style={{
                      background: "#eeeeee",
                      height: "150px",
                      width: "150px",
                    }}
                  />
                )}

                <CardDetails>
                  <Row>
                    <Col>
                      <StarRatings
                        rating={4.2}
                        starRatedColor="#F67C36"
                        starDimension="20px"
                        starSpacing="5px"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        name="rating"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>{unitDetails?.unit_name}</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="card__bottom">
                      <p>
                        <BedSvg className="icon" />
                      </p>
                      <span>Bed : {unitDetails?.bed}</span>
                    </Col>
                    <Col className="card__bottom">
                      <p>
                        <BathSvg />
                      </p>
                      <span>Bath : {unitDetails?.bath}</span>
                    </Col>
                    <Col className="card__bottom">
                      <p>
                        <Sqft />
                      </p>
                      <span>Sq ft : {formattedNumber(unitDetails?.sqft)}</span>
                    </Col>
                  </Row>

                  <p className="price__pos">
                    {toUSCurrency(Number(unitDetails?.price))}
                  </p>
                </CardDetails>
              </UnitCard>
            </Col>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
              <MobCard>
                <ApplicationsCardTop>
                  {unitDetails?.images && unitDetails?.images?.length ? (
                    <>
                      {unitDetails.images.map((img, idx) => (
                        <div key={idx}>
                          {img.isCover === true ? (
                            <img alt="example" src={img.path} />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <AvenewImg
                      className="svg__border"
                      style={{
                        background: "#eeeeee",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                  )}
                  <MobCardDetails padding="10">
                    <Row>
                      <Col>
                        <StarRatings
                          rating={3.5}
                          starRatedColor="#F67C36"
                          starDimension="15px"
                          starSpacing="3px"
                          // changeRating={this.changeRating}
                          numberOfStars={5}
                          name="rating"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h2>{unitDetails?.unit_name}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{toUSCurrency(Number(unitDetails.price))}</p>
                      </Col>
                    </Row>
                  </MobCardDetails>
                </ApplicationsCardTop>
                <BedBathBox>
                  <Row justify="space-between">
                    {unitDetails?.bed ? (
                      <Col className="card__bottom">
                        <p>
                          <BedSvg className="icon" />
                        </p>
                        <span>Bed : {unitDetails?.bed}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {unitDetails?.bath ? (
                      <Col className="card__bottom">
                        <p>
                          <BathSvg />
                        </p>
                        <span>Bath : {unitDetails?.bath}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {unitDetails?.sqft ? (
                      <Col className="card__bottom">
                        <p>
                          <Sqft />
                        </p>
                        <span>
                          Sq ft : {formattedNumber(unitDetails?.sqft)}
                        </span>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </BedBathBox>
              </MobCard>
            </Col>
          </Row>
        ) : (
          <UnitModalSkeleton />
        )}

        <h3>
          After verifying your profiles, you and fellow Avenew members can apply
          for units together. Ready to proceed?
        </h3>

        <Radio.Group
          style={{ marginBottom: "45px" }}
          onChange={onChange}
          value={value}
        >
          <Radio value={false}>No, It‘s just me</Radio>
          <Radio value={true}>Yes add other applicants</Radio>
        </Radio.Group>

        {/* <HeaderSearch bgcolor="#eee" /> */}
        {value ? (
          <>
            <Row justify="space-between">
              <Col>
                <p className="select__applicant">Select other applicants</p>
              </Col>
              <Col>
                <Row
                  className="icon__text"
                  block="true"
                  // onClick={() => setIsAdd(!isAdd)}
                  // onClick={() => addChanges()}
                  gutter={[10, 10]}
                >
                  <Col>
                    <PlusSvg />
                  </Col>
                  <Col>
                    <Popover
                      placement="bottomLeft"
                      content={content}
                      trigger="click"
                      visible={open}
                      onVisibleChange={handleOpenChange}
                    >
                      {/* <p className="new__changes"><FiPlus />+ New Charge</p> */}
                      <p className="add__applicant">Add applicant manually</p>
                    </Popover>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Checkbox.Group
              style={{
                width: "100%",
              }}
              onChange={onChangeCheck}
            >
              <Row gutter={[10, 10]}>
                {members?.map((val, idx) => (
                  <Col key={idx} span={24}>
                    <CheckBoxWrap>
                      <Checkbox value={val.memberName}>
                        {/* {val.memberName} */}
                        <OptionWrap
                          bc={val.isInvoiedMember ? "#E9EFFF" : "#F67C36"}
                          fc={val.isInvoiedMember ? "#4160AC" : "#ffffff"}
                        >
                          <h4>{val.memberName}</h4>
                          <ContactWrap>
                            <h5>{formatPhoneNumber(val.memberNumber)}</h5>
                            <h6
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onInvite(val, idx);
                              }}
                            >
                              {val.isInvoiedMember ? "Invited" : "invite"}
                            </h6>
                            {/* <FaTrash
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            /> */}
                          </ContactWrap>
                        </OptionWrap>
                      </Checkbox>
                    </CheckBoxWrap>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </>
        ) : (
          ""
        )}

        <ApplicationFee>
          <p>Amount Due for Application:</p>
          <h2>$50</h2>
          <StyledButton
            className="btn__width"
            mt="0"
            // loading={isSubmit}
            onClick={() => setModalState(true)}
            // htmlType="submit"
          >
            Pay now
          </StyledButton>
        </ApplicationFee>

        <ApplyForPropertyModal
          unitId={params?.id}
          onCancel={(value) => setModalState(value)}
          modalState={modalState}
          // from={adminRoutes.TEAM}
        />
      </UnitApplicationWrap>

      {/* <Footer /> */}
      <FooterSectionHome />
    </div>
  );
}

export default UnitApplication;

const ApplicationFee = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 50px;

  .btn__width {
    width: 10%;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #000000;
    margin-bottom: 0;
    margin-right: 10px;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #000000;
    margin-bottom: 0;
    margin-right: 30px;
  }
  @media (max-width: 991px) {
    .btn__width {
      width: 20%;
      span {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 454px) {
    flex-direction: column;
    .btn__width {
      width: 100%;
      margin-top: 10px;
    }
    h2 {
      margin-top: 10px;
    }
  }
`;

const PopoverModal = styled.div`
  padding: 8px 4px 0 4px;
  width: 500px;

  .ant-form-item-control-input-content {
    justify-content: end;
    display: flex;
  }

  @media (max-width: 550px) {
    width: 280px;
  }
`;

const CheckBoxWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px 20px;
  .ant-checkbox-wrapper {
    width: 100%;
  }

  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    width: 100%;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    ${
      "" /* border-color: #eeeeee;
    background-color: #2b353f; */
    }
  }

  .ant-checkbox-checked::after {
    border: 1px solid #eeeeee;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #eeeeee;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4160ac;
    border: 1px solid #4160ac;
  }

  .ant-checkbox-inner {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 3px;
  }

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
  }

  .ant-checkbox {
    top: 0.4em;
  }

  .ant-checkbox-inner::after {
    left: 28.5%;
  }

  @media (max-width: 769px) {
    label {
      line-height: 26px;
    }
  }
  @media (max-width: 520px) {
    padding: 15px 10px;
  }
`;

export const CardDetails = styled.div`
  padding: 10px 15px;
  position: relative;
  display: grid;
  justify-content: start;
  width: 90%;

  .ant-skeleton-element {
    width: 100% !important;
  }

  .price__pos {
    position: absolute;
    right: 0;
    top: 10px;
    right: 15px;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-right: ${({ mr }) => mr || "30px"};
    svg {
      margin-right: 10px;
    }
  }
`;

export const ContactWrap = styled.div`
  display: flex;
  svg {
    color: #848687;
    margin-right: 15px;
  }
  @media (max-width: 424px) {
    width: inherit;
    margin-top: 5px;
  }
`;

export const OptionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  h4 {
    display: flex;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 0;
  }

  h5 {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    color: #848687;
    margin-bottom: 0;
    margin-right: 25px;
  }
  h6 {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: ${({ fc }) => fc || "#E9EFFF"};
    margin-bottom: 0;
    margin-right: 25px;
    padding: 3px 10px;
    background: ${({ bc }) => bc || "#E9EFFF"};
    border-radius: 2px;
  }
  @media (max-width: 424px) {
    flex-direction: column;
    h4 {
      width: inherit;
    }
  }
`;

export const UnitApplicationWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 50px 25px;

  .select__applicant {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }
  .add__applicant,
  .icon__text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #4160ac;
    cursor: pointer;
  }

  .ant-select {
    width: 100%;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #eeeeee;
    border-radius: 5px;
    height: 50px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    height: 50px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 18px;
    height: 50px;
    align-items: center;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #848687;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px;
    border: none;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    align-items: center;
    display: flex;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #848687;
  }

  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }

  .ant-picker,
  input {
    width: 100%;
    ${"" /* border: none; */}
    height: 50px;
    background: #eee;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      ${
        "" /* font-family: Mulish;
      font-style: normal; */
      }
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector,
    .ant-picker {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }
  .ant-form-item .ant-form-item-label {
    ${"" /* display: none; */}
  }
  .description__box {
    padding: 14px 20px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #4160ac;
  }
  .ant-radio-inner::after {
    background-color: #4160ac;
  }

  .ant-radio-inner {
    background-color: #eeeeee;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 1550px) {
    h3 {
      font-size: 20px;
      line-height: 26px;
      margin: 20px 0;
    }
  }
  @media (max-width: 768px) {
    padding: 50px 15px;
  }
`;

export const UnitCard = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  ${"" /* cursor: pointer; */}
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  img {
    width: 150px;
    height: 150px;
  }
`;
