import React from "react";
import { TopSectionWrap } from "./TopSection";
import { Col, Divider, Row } from "antd";
import styled from "styled-components";
import { MainContainer } from "../dashbord-new/SearchPart";
import { useNavigate } from "react-router-dom";

const TopTrendingSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <MainContainer bgcolor="#ffffff" containterHeight="auto">
        <TopTrendingSectionWrap margincomponentxs="0 auto 0">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Rectangle />
            </Col>
          </Row>
          <Row className="trend-title">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <h1>Top Trending Listing in Avenew</h1>
            </Col>
          </Row>
          <Row
            // gutter={[20, 10]}
            justify="space-between"
            className="top__trenging"
          >
            <Col onClick={() => navigate("/property-listing")}>
              <img
                className="listing__box"
                src="images/houston.png"
                alt="Trending list1"
              />
              <SpanTag>Houston</SpanTag>
            </Col>
            <Col onClick={() => navigate("/property-listing")}>
              <img
                className="listing__box"
                src="images/sanAntonio.png"
                alt="Trending list2"
              />
              <SpanTag>San Antonio</SpanTag>
            </Col>
            <Col onClick={() => navigate("/property-listing")}>
              <img
                className="listing__box"
                src="images/dallas.png"
                alt="Trending list3"
              />
              <SpanTag>Dallas</SpanTag>
            </Col>
            <Col onClick={() => navigate("/property-listing")} className="none__img">
              <img
                className="listing__box none__img"
                src="images/austin.png"
                alt="Trending list4"
              />
              <SpanTag className="none__img">Austin</SpanTag>
            </Col>
            <Col onClick={() => navigate("/property-listing")} className="none__img">
              <img
                className="listing__box none__img"
                src="images/denton.png"
                alt="Trending list5"
              />
              <SpanTag className="none__img">Denton</SpanTag>
            </Col>
            <Col onClick={() => navigate("/property-listing")} className="none__img">
              <img
                className="listing__box none__img"
                src="images/midland.png"
                alt="Trending list6"
              />
              <SpanTag className="none__img">Midland</SpanTag>
            </Col>
          </Row>
          <Divider />
        </TopTrendingSectionWrap>
      </MainContainer>
    </>
  );
};

export default TopTrendingSection;

export const SpanTag = styled.span`
  color: #2b353f;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  justify: center;
  padding-top: 22px;

  @media (max-width: 991.98px) {
    padding-top: 10px;
    font-size: 16px;
  }
`;

export const TopTrendingSectionWrap = styled.div`
  width: 1400px;
  margin: ${({ margincomponent }) => margincomponent || "50px auto 0"};
  padding: ${({ paddingcomponent }) => paddingcomponent || "0 60px"};

  .house__women__xs {
    display: none;
  }

  .top__trenging .ant-col {
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  h1 {
    color: #2b353f;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }

  .listing__box {
    width: 150px;
    height: 150px;
    border-radius: 8px;
  }

  .trend-title {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .ant-divider-horizontal {
    margin: 100px 0 0 0;
  }

  .divider__long {
    margin-top: 100px;
  }

  @media (max-width: 1400px) {
    margin: ${({ margincomponentsm }) => margincomponentsm || "40px auto 50px"};

    width: 100%;
    padding: 0 45px;

    .divider__long {
      margin-top: 50px;
    }
  }

  @media (max-width: 991.98px) {
    padding: 0 15px;
    margin: ${({ margincomponentxs }) => margincomponentxs || "40px auto 0"};

    .divider__long {
      margin-top: 50px;
    }
    .divider__long__sm {
      margin: 50px auto auto;
      z-index: 1;
      position: relative;
    }

    .house__women__xs {
      width: 180px;
      display: flex;
      margin: auto;
      z-index: 1;
      position: relative;
    }

    .ant-divider-horizontal {
      margin: 30px 0 0 0;
    }

    .trend-title {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .listing__box {
      width: 120px;
      height: 120px;
    }

    .none__img {
      display: none !important;
    }

    h1 {
      font-size: 24px;
    }
  }

  @media (max-width: 425.98px) {
    .listing__box {
      width: 100px;
      height: 100px;
    }
  }
`;

export const Rectangle = styled.div`
  width: 50px;
  height: 5px;
  background-color: #4160ac;
`;
