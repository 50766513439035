import React from "react";

const headerPentagonSvg = ({ wSize = "179", hSize = "210" }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={wSize}
        height={hSize}
        viewBox={`0 0 ${wSize} ${hSize}`}
        fill="none"
      >
        <path
          d="M157.642 164.509V73.4991L78.8345 28.0078L0 73.4991V164.509L78.8345 210L157.642 164.509Z"
          fill="#F5F8FF"
        />
        <mask
          id="mask0_5393_5072"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="21"
          y="0"
          width="158"
          height="182"
        >
          <path
            d="M178.829 136.501V45.4913L100.022 0L21.1875 45.4913V136.501L100.022 181.992L178.829 136.501Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5393_5072)">
          <path
            d="M11.7324 0H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 24.5H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 49H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 73.5H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 97.9688H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 122.469H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 146.969H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
          <path
            d="M11.7324 171.469H184.834"
            stroke="#CEDBF6"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    </>
  );
};

export default headerPentagonSvg;
