import React from "react";
import TopSearchSection from "./TopSearchSection";
import MarketingSection from "./MarketingSection";
import FeatureSection from "./FeatureSection";
import WhyUs from "./WhyUs";
import OwnersCategory from "./OwnersCategory";
import PlanSection from "./PlanSection";
import FooterSection from "./FooterSection";
import { BackTop } from "antd";
import { MdKeyboardArrowUp } from "react-icons/md";
import { backToTopStyle } from "../../../utils/fn";

const OwnersPage = () => {
  return (
    <>
      <TopSearchSection />
      {/* <MarketingSection /> */}
      <FeatureSection />
      <WhyUs />
      <OwnersCategory />
      <PlanSection />
      <FooterSection />

      <BackTop>
        <div style={backToTopStyle}>
          <MdKeyboardArrowUp />
        </div>
      </BackTop>
    </>
  );
};

export default OwnersPage;
