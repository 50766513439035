import {
  BackTop,
  Badge,
  Breadcrumb,
  Col,
  Divider,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { GiShare } from "react-icons/gi";
import { HiDownload } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import { FooterCall, FooterLocation, NoInvoice } from "../../assets/svgs";
import { Container } from "../../components/container/Container";
import Footer from "../../components/footer/Footer";
import { getInvoicesUnit } from "../../store/reducers/user.reducer";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { StyledButtonPay } from "../my-rentals/MyRentals";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import { backToTopStyle, toUSCurrency } from "../../utils/fn";
import { pdf } from "@react-pdf/renderer";
import InvoicePdf from "../../components/invoice-pdf/InvoicePdf";
import * as FileSaver from "file-saver";
import ShareModal from "../../components/share-modal/ShareModal";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function UnitInvoice() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { width } = useWindowSize();
  const { user } = useSelector((state) => state.user);
  const [shareModalState, setshareModalState] = useState(false);
  const [getAllIncoice, setGetAllIncoice] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [getAllIncoiceArr, setGetAllIncoiceArr] = useState([]);
  const [shortedData, setShortedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewTrTable, setViewTrTable] = useState(false);
  const [tableData, setTableData] = useState({});
  const [invoiceDetailsFee, setInvoiceDetailsFee] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalTax, setTotalTax] = useState("");

  // page navigation bar
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/my-rentals",
      label: "My Rentals",
      isLink: true,
    },
    {
      path: `/my-rentals/${params.id}`,
      label: "Invoices",
      isLink: false,
    },
  ];
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // store id in local storage
  useEffect(() => {
    if (params.id) {
      localStorage.setItem("unit_id", params.id);
    }
  }, [params.id]);

  // get unit invoices
  useEffect(() => {
    if (localStorage.getItem("unit_id") && params.id) {
      setIsLoading(true);
      dispatch(getInvoicesUnit()).then(({ payload }) => {
        setGetAllIncoice(payload);
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      });
    }
  }, []);

  // object to array
  useEffect(() => {
    if (getAllIncoice && Object.values(getAllIncoice).length) {
      const arr = Object.values(getAllIncoice);
      setGetAllIncoiceArr(arr);
    }
  }, [getAllIncoice]);

  // sort data on date
  useEffect(() => {
    const val = getAllIncoiceArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.dueDate) - new Date(b.dueDate);
    });

    setShortedData(val);
  }, [getAllIncoiceArr]);

  // store first elemrnt of array
  useEffect(() => {
    if (shortedData?.length) {
      setSelectedInvoice(shortedData[0]);
    }
  }, [shortedData]);

  // date formate
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    // return `${day}-${month}-${year}`;
    return `${month} ${day}, ${year}`;
  }

  // date converter
  const dateConverter = (dateValue) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  // status for to word (rate -> Rent)
  const feeType = (status) => {
    switch (status) {
      case "rate":
        return "Rent";
      case "lateFee":
        return "Late Fee";
      case "parkingFee":
        return "Parking Fee";
      case "avenewFees":
        return "Avenew Fee";
      case "otherFee":
        return "Other Fee";
      case "taxFee":
        return "Tax";
      default:
        return "Other Fee";
    }
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // find value from ID
  const changeHandlerApart = (value) => {
    var selectedData = shortedData?.filter(function (item) {
      return item["id"] === value;
    });
    setSelectedInvoice(selectedData[0]);
  };

  // type to real element (WALLET -> Cash App)
  const sourceTypeFun = (type) => {
    switch (type) {
      case "WALLET":
        return "Cash App";
      case "CARD":
        return "Card";
      case "BANK_ACCOUNT":
        return "ACH";

      default:
        return type;
    }
  };

  // merge and filter data
  useEffect(() => {
    if (selectedInvoice) {
      const arrOfSort = [];
      const payments = selectedInvoice?.payments;
      let invoiceDetailsObj = { ...selectedInvoice };
      if (payments?.length) {
        let sortForeach = payments?.map((value) => {
          return value.squareResponse.createdAt;
        });
        sortForeach.sort();
        sortForeach.reverse();

        sortForeach?.map((val) => {
          payments.filter((obj) => {
            if (obj.squareResponse["createdAt"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      }
      invoiceDetailsObj.payments = arrOfSort;

      setTableData(invoiceDetailsObj);
    }
  }, [selectedInvoice]);

  // Tablw columns
  const columns = [
    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      sorter: (a, b) =>
        new Date(a.squareResponse.createdAt) -
        new Date(b.squareResponse.createdAt),
      render: (_, record) => (
        <span>{dateConverter(record.squareResponse.createdAt)}</span>
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "sourceType",
      render: (_, record) => (
        <span>{sourceTypeFun(record.squareResponse.sourceType)}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => <span>{toUSCurrency(Number(record.amount))}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (_, record) => <span>{toUSCurrency(record.balance)}</span>,
    },
  ];
  const [tenantsData, setTenantsData] = useState([]);

  // filtwe invitaions data from params id
  useEffect(() => {
    if (user) {
      const val = user?.invitations.filter((item) => {
        return item.unit_id === params.id;
      });
      if (val?.length) {
        setTenantsData(val[0]);
      }
    }
  }, [user]);

  const [svgIwdth, setsvgIwdth] = useState(491);
  const [svgIHeight, setsvgIHeight] = useState(366);

  // with and height value from screen value
  useEffect(() => {
    if (width < 991.98) {
      setsvgIwdth(219);
      setsvgIHeight(294);
    } else {
      setsvgIwdth(491);
      setsvgIHeight(366);
    }
  }, [width, location.pathname]);

  // getting all type of fee to separate array
  useEffect(() => {
    if (selectedInvoice?.fees?.length) {
      const arr = [];

      const newArr = selectedInvoice.fees;
      newArr.forEach((val) => {
        arr.push({
          type: val.type,
          amount: val.amount,
          dollerOrPercentage: val.dollerOrPercentage,
          memo: val.memo,
        });
        setInvoiceDetailsFee(arr);
      });
    }
  }, [selectedInvoice]);

  // convert to PR
  function percentageRemaning(num, per) {
    return (num / 100) * per + num;
  }

  // convert to PR
  function percentageRemaningTax(num, per) {
    return (num / 100) * per;
  }

  // Tax amount for total amount
  useEffect(() => {
    let sum = 0;
    if (invoiceDetailsFee?.length) {
      invoiceDetailsFee.map((val) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        } else {
          const finalAmount = percentageRemaning(
            totalAmount,
            Number(val.amount)
          );
          const totalTax = percentageRemaningTax(
            totalAmount,
            Number(val.amount)
          );
          setTotalTax(totalTax);
        }
      });
    }
    setTotalAmount(sum);
  }, [totalAmount]);

  // Total tax count
  useEffect(() => {
    if (invoiceDetailsFee?.length) {
      let sum = 0;
      invoiceDetailsFee.map((val, id) => {
        if (val.type != "taxFee") {
          sum += Number(val["amount"]);
        }
      });
      setTotalAmount(sum);
    }
  }, [invoiceDetailsFee]);

  // download invoice (PFD format)
  const downloadInvoice = async () => {
    const blob = await pdf(
      <InvoicePdf
        //   payableAmount={payableAmount}
        totalTax={totalTax}
        invoiceDetails={selectedInvoice}
        totalAmount={totalAmount}
      />
    ).toBlob();
    FileSaver.saveAs(blob, `${selectedInvoice?.unit_name} Invoice`);

    // html2canvas(invoiceDownloadRef.current, {
    //   windowWidth: "100%",
    //   windowHight: "100%",
    //   scale: 1,
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "mm", [228, 158]);
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   pdf.save("invoice.pdf");
    // });
  };

  const [pdfBlob, setPdfBlob] = useState("");
  const shareInvoice = async () => {
    const blob = await pdf(
      <InvoicePdf
        //   payableAmount={payableAmount}
        //   totalTax={totalTax}
        invoiceDetails={selectedInvoice}
        //   totalAmount={totalAmount}
      />
    ).toBlob();
    setPdfBlob(blob);

    // FileSaver.saveAs(blob, `${selectedInvoice?.unit_name} Invoice`);
  };

  return (
    <>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>
      {isLoading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : Object.keys(selectedInvoice).length ? (
        <Container padding="50px 0">
          <ApplicationsMain>
            <UnitInvoiceLeft>
              <p className="all__invoices">All Invoices</p>

              {shortedData?.map((item, id) => (
                <div>
                  {item?.invoiceClose ? (
                    <Badge.Ribbon text="Closed" color="#F67C36">
                      <InvoiceCard
                        backGround="#e8e8e8"
                        onClick={() => setSelectedInvoice(item)}
                        key={id}
                        br={
                          selectedInvoice.id === item.id
                            ? "1px solid #f67c36"
                            : 0
                        }
                      >
                        <Row>
                          <Col>
                            <span>#{item?.invoiceCode}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1>{item?.unit_name}</h1>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <h2>{toUSCurrency(item?.payableAmount)}</h2>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <h3>Balance:</h3>
                              </Col>
                              <Col>
                                <h2 className="blance__rant">
                                  {" "}
                                  &nbsp; {toUSCurrency(item?.balance)}
                                </h2>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </InvoiceCard>
                      <InvoiceCardBottom
                        onClick={() => setSelectedInvoice(item)}
                        key={id}
                        br={
                          selectedInvoice.id === item.id
                            ? "1px solid #f67c36"
                            : 0
                        }
                      >
                        <Row justify="space-between">
                          <Col>
                            <span>
                              Bill Date :{" "}
                              {dateConverter(item?.invoiceCreatedDate)}
                            </span>
                          </Col>
                          <Col>
                            <span>
                              Due Date : {dateConverter(item?.dueDate)}
                            </span>
                          </Col>
                        </Row>
                      </InvoiceCardBottom>
                    </Badge.Ribbon>
                  ) : (
                    <>
                      <InvoiceCard
                        onClick={() => setSelectedInvoice(item)}
                        key={id}
                        br={
                          selectedInvoice.id === item.id
                            ? "1px solid #f67c36"
                            : 0
                        }
                      >
                        <Row>
                          <Col>
                            <span>#{item?.invoiceCode}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1>{item?.unit_name}</h1>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col>
                            <h2>{toUSCurrency(item?.payableAmount)}</h2>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <h3>Balance:</h3>
                              </Col>
                              <Col>
                                <h2 className="blance__rant">
                                  {" "}
                                  &nbsp; {toUSCurrency(item?.balance)}
                                </h2>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </InvoiceCard>
                      <InvoiceCardBottom
                        onClick={() => setSelectedInvoice(item)}
                        key={id}
                        br={
                          selectedInvoice.id === item.id
                            ? "1px solid #f67c36"
                            : 0
                        }
                      >
                        <Row justify="space-between">
                          <Col>
                            <span>
                              Bill Date :{" "}
                              {dateConverter(item?.invoiceCreatedDate)}
                            </span>
                          </Col>
                          <Col>
                            <span>
                              Due Date : {dateConverter(item?.dueDate)}
                            </span>
                          </Col>
                        </Row>
                      </InvoiceCardBottom>
                    </>
                  )}
                </div>
              ))}
            </UnitInvoiceLeft>

            <UnitInvoiceRight>
              <UnitInvoiceRightWrap>
                <Row justify="space-between">
                  <Col>
                    <span className="tr__id">
                      {" "}
                      #{selectedInvoice?.invoiceCode}
                    </span>
                  </Col>
                  <Col>
                    <Row>
                      <Col
                        onClick={() => {
                          setshareModalState(true);
                          shareInvoice();
                        }}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <GiShare />
                        <span className="share__class">Share</span>
                      </Col>
                      <Col>
                        <Divider type="vertical" />
                      </Col>
                      <Col
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => downloadInvoice()}
                      >
                        <HiDownload />
                        <span className="share__class">Download</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>{selectedInvoice?.unit_name}</h1>
                  </Col>
                </Row>
                {selectedInvoice?.invoiceCreatedDate ? (
                  <BillDateWrap>
                    <Row justify="space-between">
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span>Bill Date</span>
                        <p>
                          &nbsp; &nbsp;{" "}
                          {dateConverter(selectedInvoice?.invoiceCreatedDate)}
                        </p>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Col>
                            <Divider type="vertical" />
                          </Col>
                          <Col
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>Due Date</span>
                            <p>
                              &nbsp; &nbsp;{" "}
                              {dateConverter(selectedInvoice?.dueDate)}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col>ad</Col> */}
                    </Row>
                  </BillDateWrap>
                ) : (
                  ""
                )}
                <ChargesWrap>
                  <h2>Charges</h2>
                  {selectedInvoice?.fees
                    ? selectedInvoice?.fees.map((item, id) => (
                        <>
                          {item.type === "taxFee" ? (
                            <FeesWrap key={id}>
                              <Row
                                justify="space-between"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                gutter={[10, 10]}
                              >
                                <Col>
                                  <p>{feeType(item.type)}</p>
                                  <span>{item.memo}</span>
                                </Col>
                                <Col>
                                  {item.dollerOrPercentage ? (
                                    <h3>
                                      {item.dollerOrPercentage === "$"
                                        ? "$"
                                        : ""}
                                      {item.amount}
                                      {item.dollerOrPercentage === "%"
                                        ? "%"
                                        : ""}
                                    </h3>
                                  ) : (
                                    <h3>{toUSCurrency(Number(item.amount))}</h3>
                                  )}
                                </Col>
                              </Row>
                            </FeesWrap>
                          ) : (
                            <FeesWrap key={id}>
                              <Row
                                justify="space-between"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                gutter={[10, 10]}
                              >
                                <Col>
                                  <p>{feeType(item.type)}</p>
                                  <span>{item.memo}</span>
                                </Col>
                                <Col>
                                  <h3>{toUSCurrency(Number(item.amount))}</h3>
                                </Col>
                              </Row>
                            </FeesWrap>
                          )}
                        </>
                      ))
                    : ""}

                  <Row justify="end">
                    <Col>
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col>
                          <h4>Total Amount : </h4>
                        </Col>
                        <Col>
                          <h5>
                            &nbsp;{" "}
                            {toUSCurrency(
                              Number(selectedInvoice?.payableAmount)
                            )}{" "}
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row justify="end" style={{ marginTop: "20px" }}>
                    <Col xs={24} sm={24} md={10} lg={6} xl={6} xxl={6}>
                      <StyledButtonPay
                        disabled={selectedInvoice?.invoiceClose}
                        onClick={() => {
                          // navigate(`/payment/${item.id}`);
                          localStorage.setItem("isLoad", "true");
                          localStorage.setItem(
                            "invoice_id",
                            selectedInvoice?.id
                          );
                        }}
                      >
                        <a href={`/payment-invoice/${selectedInvoice?.id}`}>
                          Pay Now
                        </a>
                        {/* PAY */}
                      </StyledButtonPay>
                      {/* <form id="payment-form">
                            <div className="pay__btn" id="cash-app-pay"></div>
                          </form> */}
                      {/* <form id="payment-form">
                            <div id="cash-app-pay"></div>
                          </form> */}
                    </Col>
                  </Row>
                </ChargesWrap>
              </UnitInvoiceRightWrap>

              {selectedInvoice?.payments ? (
                <>
                  <Row justify="end" style={{ marginTop: "30px" }}>
                    <Col>
                      <p
                        className="view__tr"
                        onClick={() => setViewTrTable(!viewTrTable)}
                      >
                        {viewTrTable ? "Hide" : "View"}
                        Transactions
                      </p>
                    </Col>
                  </Row>

                  {viewTrTable ? (
                    <TrTable>
                      <Table
                        columns={columns}
                        // rowKey={(record) => record.login.uuid}
                        dataSource={tableData?.payments}
                        // loading={loading}
                        // onChange={handleTableChange}
                        //   pagination={{
                        //     current: 1,
                        //     pageSize: 10,
                        //     size: "small",
                        //     // total: 90,
                        //   }}
                        pagination={false}
                        scroll={{ x: 500 }}
                        // scroll={{ x: 1000 }}
                        // scroll={{ y: 350 }}
                      />
                    </TrTable>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </UnitInvoiceRight>
          </ApplicationsMain>

          <Row>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
              <SelectWrap>
                <Select
                  placeholder="Select Property"
                  // allowClear
                  suffixIcon={<IoIosArrowDown />}
                  // disabled={isEdit}
                  onChange={changeHandlerApart}
                  defaultValue={`#${selectedInvoice?.invoiceCode}`}
                >
                  {shortedData?.map((item, idx) => (
                    <Option
                      key={idx}
                      value={item.id}
                      style={{ height: "80px" }}
                    >
                      {item?.invoiceClose ? (
                        <Badge.Ribbon text="Closed" color="#F67C36">
                          <Row justify="space-between">
                            <Col
                              span={24}
                              style={{
                                marginBottom: "10px",
                                marginTop: "15px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  color: "#F67C36",
                                }}
                              >
                                #{item?.invoiceCode}
                              </span>
                            </Col>
                            <Col>
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "16px",
                                  color: "#777777",
                                }}
                              >
                                Bill Date :{" "}
                                {dateConverter(item?.invoiceCreatedDate)}
                              </span>
                            </Col>
                            <Col>
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "16px",
                                  color: "#777777",
                                }}
                              >
                                Due Date : {dateConverter(item?.dueDate)}
                              </span>
                            </Col>
                          </Row>
                        </Badge.Ribbon>
                      ) : (
                        <Row justify="space-between">
                          <Col
                            span={24}
                            style={{
                              marginBottom: "10px",
                              marginTop: "15px",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#F67C36",
                              }}
                            >
                              #{item?.invoiceCode}
                            </span>
                          </Col>
                          <Col>
                            <span
                              style={{
                                fontWeight: "400",
                                fontSize: "13px",
                                lineHeight: "16px",
                                color: "#777777",
                              }}
                            >
                              Bill Date :{" "}
                              {dateConverter(item?.invoiceCreatedDate)}
                            </span>
                          </Col>
                          <Col>
                            <span
                              style={{
                                fontWeight: "400",
                                fontSize: "13px",
                                lineHeight: "16px",
                                color: "#777777",
                              }}
                            >
                              Due Date : {dateConverter(item?.dueDate)}
                            </span>
                          </Col>
                        </Row>
                      )}
                    </Option>
                  ))}
                </Select>
              </SelectWrap>

              <InvoiceDetailsMobile>
                <TitleWrap>
                  <Row justify="space-between">
                    <Col>
                      <span>#TY56KL</span>
                    </Col>
                    <Col>
                      <Row>
                        <Col style={{ display: "flex", alignItems: "center" }}>
                          <GiShare />
                        </Col>
                        <Col>
                          <Divider type="vertical" />
                        </Col>
                        <Col style={{ display: "flex", alignItems: "center" }}>
                          <HiDownload />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TitleWrap>
                <h1>{selectedInvoice?.unit_name}</h1>
                <DateWraper>
                  <BilDateWrap>
                    <Row justify="space-between">
                      <Col>
                        <span>Bill Date</span>
                      </Col>
                      <Col>
                        <p>
                          {dateConverter(selectedInvoice?.invoiceCreatedDate)}
                        </p>
                      </Col>
                    </Row>
                  </BilDateWrap>
                  <Divider />
                  <DueDateWrap>
                    <Row justify="space-between">
                      <Col>
                        <span>Due Date</span>
                      </Col>
                      <Col>
                        <p>{dateConverter(selectedInvoice?.dueDate)}</p>
                      </Col>
                    </Row>
                  </DueDateWrap>
                </DateWraper>
                <p className="charges__class">Charges</p>

                <Row gutter={[10, 10]}>
                  {selectedInvoice?.fees
                    ? selectedInvoice?.fees.map((item, id) => (
                        <>
                          {item.type === "taxFee" ? (
                            <Col span={24}>
                              <FeesMobileWrap>
                                <Row justify="space-between">
                                  <Col>
                                    <p>{feeType(item.type)}</p>
                                  </Col>
                                  <Col>
                                    <Col>
                                      {item.dollerOrPercentage ? (
                                        <h2>
                                          {item.dollerOrPercentage === "$"
                                            ? "$"
                                            : ""}
                                          {item.amount}
                                          {item.dollerOrPercentage === "%"
                                            ? "%"
                                            : ""}
                                        </h2>
                                      ) : (
                                        <h2>
                                          {toUSCurrency(Number(item.amount))}
                                        </h2>
                                      )}
                                    </Col>
                                  </Col>
                                </Row>
                                {item.memo ? (
                                  <Row>
                                    <Col>
                                      <h4>{item.memo}</h4>
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </FeesMobileWrap>
                            </Col>
                          ) : (
                            <Col span={24}>
                              <FeesMobileWrap>
                                <Row justify="space-between">
                                  <Col>
                                    <p>{feeType(item.type)}</p>
                                  </Col>
                                  <Col>
                                    <h2>{toUSCurrency(Number(item.amount))}</h2>
                                  </Col>
                                </Row>
                                {item.memo ? (
                                  <Row>
                                    <Col>
                                      <h4>{item.memo}</h4>
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </FeesMobileWrap>
                            </Col>
                          )}
                        </>
                      ))
                    : ""}
                </Row>
                <SomethingNew></SomethingNew>
                <TotalAmount>
                  <Row justify="space-between">
                    <Col>
                      <h4>Total Amount </h4>
                    </Col>
                    <Col>
                      <h5>
                        &nbsp;{" "}
                        {toUSCurrency(Number(selectedInvoice?.payableAmount))}{" "}
                      </h5>
                    </Col>
                  </Row>
                </TotalAmount>

                <Row justify="end" style={{ marginTop: "20px" }}>
                  <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <StyledButtonPay
                      disabled={selectedInvoice?.invoiceClose}
                      onClick={() => {
                        // navigate(`/payment/${item.id}`);
                        localStorage.setItem("isLoad", "true");
                        localStorage.setItem("invoice_id", selectedInvoice?.id);
                      }}
                    >
                      <a href={`/payment-invoice/${selectedInvoice?.id}`}>
                        Pay Now
                      </a>
                      {/* PAY */}
                    </StyledButtonPay>
                    {/* <form id="payment-form">
                            <div className="pay__btn" id="cash-app-pay"></div>
                          </form> */}
                    {/* <form id="payment-form">
                            <div id="cash-app-pay"></div>
                          </form> */}
                  </Col>
                </Row>
              </InvoiceDetailsMobile>
            </Col>
          </Row>

          {/* <Footer /> */}
          <FooterSectionHome />

          <BackTop>
            <div style={backToTopStyle}>
              <MdKeyboardArrowUp />
            </div>
          </BackTop>
        </Container>
      ) : (
        <Container padding="50px 0">
          <UnitDetails>
            <Row gutter={[18, 18]}>
              <Col span={24}>
                <StarRatings
                  rating={3}
                  starRatedColor="#F67C36"
                  starDimension="17px"
                  starSpacing="5px"
                  // changeRating={this.changeRating}
                  numberOfStars={5}
                  name="rating"
                />
              </Col>
              <Col span={24}>
                <h1>{tenantsData?.apartment_name}</h1>
              </Col>
              <Col span={24}>
                <Row gutter={[10, 10]} justify="space-between">
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <h2>{tenantsData?.unit_name}</h2>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                    <Row gutter={[20, 20]} justify="space-between">
                      {tenantsData?.address ? (
                        <Col style={{ display: "flex", alignItems: "center" }}>
                          <FooterLocation /> &nbsp; &nbsp;
                          <span className="share__class">
                            {tenantsData?.address}
                          </span>
                        </Col>
                      ) : (
                        ""
                      )}
                      {tenantsData?.phoneNumber ? (
                        <Col style={{ display: "flex", alignItems: "center" }}>
                          <FooterCall /> &nbsp; &nbsp;
                          <span className="share__class">
                            {tenantsData?.phoneNumber}
                          </span>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </UnitDetails>
          <NoInvoiceWrap>
            <NoInvoice hSize={svgIHeight} wSize={svgIwdth} />
            <h1>No Invoice Found</h1>
          </NoInvoiceWrap>

          {/* <Footer /> */}
          <FooterSectionHome />

          <BackTop>
            <div style={backToTopStyle}>
              <MdKeyboardArrowUp />
            </div>
          </BackTop>
        </Container>
      )}

      <ShareModal
        onCancel={(value) => setshareModalState(value)}
        modalState={shareModalState}
        pdfBlob={pdfBlob}
        // selectedUnit={unitDetails}
      />
    </>
  );
}

export default UnitInvoice;

export const NoInvoiceWrap = styled.div`
  margin-top: 50px;
  text-align: center;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #848687;
    width: 36%;
    margin: auto;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #2b353f;
    margin-top: ${({ mt }) => mt || "50px"};
    margin-bottom: ${({ mb }) => mb || "50px"};
  }

  @media (max-width: 991.98px) {
    margin-top: 30px;

    p {
      width: 90%;
      margin: auto;
    }

    h1 {
      font-size: 28px;
      line-height: 36px;
      color: #2b353f;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
`;

const UnitDetails = styled.div`
  max-width: 1200px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 25px 30px;
  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    margin-bottom: 0;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #848687;
  }
`;

const TrTable = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  ${
    "" /* .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  } */
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  ${
    "" /* .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  } */
  }
  .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
  }

  .ant-table-thead > tr > th {
    background-color: #aaaaaa !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 991.98px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;

const TotalAmount = styled.div`
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 0;
  }
  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    margin-bottom: 0;
  }
`;

const SomethingNew = styled.div`
  border-style: dotted;
  border: 1px dashed #dddddd;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: 30px 0;
`;
const FeesMobileWrap = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 15px 20px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 0;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #2b353f;
    margin-bottom: 0;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    margin-bottom: 0;
    color: #777777;
  }
`;

const DateWraper = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 30px;

  .ant-divider {
    margin: 0;
    background: #dddddd;
  }
`;
const DueDateWrap = styled.div`
  padding: 15px;
  background: #ffffff;
  border-radius: 0 0 10px 10px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
  }
`;

const BilDateWrap = styled.div`
  padding: 15px;
  background: #ffffff;
  border-radius: 10px 10px 0 0;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
  }
`;

const TitleWrap = styled.div`
  margin-bottom: 15px;

  .ant-divider {
    background: #dddddd;
  }

  svg {
    color: #aaaaaa;
    font-size: 18px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #f67c36;
  }
`;

const InvoiceDetailsMobile = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 20px;

  h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 30px;
  }
  .charges__class {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 10px;
  }
`;
const SelectWrap = styled.div`
  margin-bottom: 20px;

  .select__antd,
  .ant-select-item-option {
    height: 80px;
    align-items: center;
  }

  .ant-select-item {
    min-height: 81px !important;
  }

  .ant-select-item-option-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2b353f;
    min-height: 81px !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #ecf2ff;
    min-height: 81px !important;
  }

  .ant-select-item-option {
    align-items: center;
    min-height: 81px !important;
  }

  .ant-select {
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
  }
  .ant-select-selector {
    width: 100%;
    height: 60px;
    background: #eeeeee;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px;
    align-items: center;
    @media screen and (max-width: 595px) {
      width: 100%;
    }
  }
  .ant-select-selection-placeholder {
    color: #aaaaaa;
  }

  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
  .ant-select-selection-placeholder {
    color: #aaaaaa;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 60px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: none;
    height: 60px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #2b353f;
  }
`;

const ApplicationsMain = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 991.98px) {
    margin-bottom: 0;
  }
`;

const FeesWrap = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 10px;

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #2b353f;
    margin-bottom: 10px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }
`;

const ChargesWrap = styled.div`
  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 0;
    margin-top: 10px;
  }
  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

const BillDateWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px 25px;
  margin-bottom: 30px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
  }

  .ant-divider-vertical {
    height: 100%;
  }
`;

const UnitInvoiceRightWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;

  h1 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 30px;
  }

  svg {
    color: #aaaaaa;
    font-size: 16px;
    cursor: pointer;
  }

  .tr__id {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
    margin-bottom: 10px;
  }
  .share__class {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
    margin-left: 10px;
    cursor: pointer;
  }
  .ant-divider-vertical {
    background: #dddddd;
  }
`;

const UnitInvoiceRight = styled.div`
  padding: 0 0 0 30px;
  width: 70%;
  .view__tr {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
    cursor: pointer;
  }

  @media (max-width: 1250px) {
    width: 60%;
  }
  @media (max-width: 991.98px) {
    display: none;
  }
`;

const InvoiceCardBottom = styled.div`
  background: #f9f9f9;
  border-radius: 0px 0px 5px 5px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 20px;
  cursor: pointer;
  border: ${({ br }) => br || 0};
  border-top: 0;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }
`;

const InvoiceCard = styled.div`
  ${"" /* background: #ffffff; */}
  background: ${({ backGround }) => backGround || "#ffffff"};

  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
  padding: 15px;
  cursor: pointer;
  border: ${({ br }) => br || 0};
  border-bottom: 0;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #f67c36;
  }

  h1 {
    margin: 10px 0 10px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #848687;
    margin-bottom: 10px;
  }
  .blance__rant {
    color: #71c93c;
  }
`;

const UnitInvoiceLeft = styled.div`
  width: 30%;
  ${"" /* border: 1px solid blue; */}
  padding: 20px;
  max-height: 777px;
  overflow: auto;
  ${
    "" /* 
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px; */
  }

  .all__invoices {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 15px;
  }

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    ${"" /* background-color: rgb(97, 96, 96); */}
    background-color: rgb(171, 184, 196);
    outline: 1px solid rgb(169, 182, 194);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .ant-modal-wrap::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: 1250px) {
    width: 40%;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`;
