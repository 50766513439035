import { Button, Form } from "antd";
import styled from "styled-components";

export const SearchBoxWrap = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 50px 0;
  background: #dddddd;

  .ant-form {
    width: 100%;
  }

  @media (max-width: 991.98px) {
    margin: 20px 0;

    .search__btn {
      flex-direction: column;
      align-items: end !important;
    }

    .ant-form-item-control-input {
      min-height: auto;
    }

    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 25px;
    }
  }
`;
export const SearchBox = styled.div`
  width: 100%;
  padding: 15px 20px;
  background: #fff;

  @media (max-width: 991.98px) {
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
`;

export const H4Tag = styled.h4`
  color: #2b353f;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const H5Tag = styled.h4`
  color: #848687;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PTag = styled.p`
  color: #2b353f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
`;

export const SearchFormDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }

  .ant-select-selection-placeholder,
  .ant-input:placeholder-shown {
    color: #c6c6c6;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
  }

  .ant-input,
  .ant-select {
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.colors.fifth};
    font-size: 16px;
    font-style: normal;
    ${"" /* font-weight: 700; */}
    line-height: normal;
    letter-spacing: 1px;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:focus,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input-focused {
    border-color: none;
    box-shadow: none;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: none;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .ant-form-item-with-help .ant-form-item-explain {
    display: none;
  }

  @media screen and (max-width: 769px) {
    flex-direction: ${({ fd }) => fd || "column"};
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48%"};
    }
  }
`;

export const SearchFormItem = styled(Form.Item)`
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0;
  }
`;

export const SearchButton = styled(Button)`
  ${"" /* padding: 4px 30px; */}
  padding: 0;
  min-height: 84px;
  min-width: 84px;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 991.98px) {
    display: none;
  }
  @media screen and (max-width: 769px) {
    padding: ${({ paddingbtn }) => paddingbtn || "4px 20px"};
  }
`;
export const SearchButtonXs = styled(Button)`
  ${"" /* padding: 4px 30px; */}
  padding: 0;
  min-height: 40px;
  min-width: 62px;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }
`;
