import axios from "axios";
import interceptor from "./axios";
import interceptorInvite from "./axiosInvite";
const API_URL = process.env.REACT_APP_API_URL;
const xAPIKey = process.env.REACT_APP_API_KEY;

const API_URL_INVITE = process.env.REACT_APP_API_URL_INVITE;

const axiosInstance = (baseURL = API_URL, ...options) => {
  return axios.create({
    baseURL,
    // You can add your headers here
    ...options,
  });
};

// export default axiosInstance;

const axiosInstanceInvite = (baseURL = API_URL_INVITE, ...options) => {
  return axios.create({
    baseURL,
    // You can add your headers here
    ...options,
  });
};

export default axiosInstanceInvite;

export const httpAPI = (
  url,
  data,
  method = "POST",
  isFormData = false,
  additionalHeaders = {
    "x-api-key": xAPIKey,
  },
  extraProps = {}
) => {
  let headers = {
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  const http = interceptor(axiosInstance(API_URL, { headers }));
  return http({
    url,
    method,
    data,
    headers,
    ...extraProps,
  });
};

export const httpAPIInvite = (
  url,
  data,
  method = "POST",
  isFormData = false,
  additionalHeaders = {
    "x-api-key": xAPIKey,
  },
  extraProps = {}
) => {
  let headers = {
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  const http = interceptorInvite(
    axiosInstanceInvite(API_URL_INVITE, { headers })
  );
  return http({
    url,
    method,
    data,
    headers,
    ...extraProps,
  });
};
