import React, { useEffect, useState } from "react";
import { GeneralProfileWrap } from "./sub-tabs/GeneralProfile";
import { Checkbox, Col, Form, Popover, Row, Table, message } from "antd";
import { ApartmentTable } from "../MyTransaction/MyTransaction";
import { useDispatch, useSelector } from "react-redux";
import { ViewAllRental } from "../Profile-verification/PriorRentalInformation";
import {
  AddedReference,
  DetailsBox,
  ViewAddedReference,
} from "./sub-tabs/AddReference";
import { AccountCreateLogo, LessSvg, OpsSvg, PlusSvg } from "../../assets/svgs";
import {
  StyledButton,
  StyledForm,
  StyledForms,
} from "../my-account/ChangePassword";
import { IoIosArrowBack } from "react-icons/io";
import { FormWrap } from "../Profile-verification/BackgroundCheck";
import {
  applicationProcess,
  getApplicationProcess,
} from "../../store/reducers/user.reducer";
import ObtainCreditScoreModal from "./ObtainCreditScoreModal";
import {
  formatPhoneNumber,
  formatSSNNumber,
  formattedNumber,
  toUSCurrency,
} from "../../utils/fn";
import { ImagePreview } from "../../components/ImagePreview";

const ApplicantAllDetails = ({
  onMainTabNext,
  onBackClick,
  rentaldata = {},
  appPayloadData = {},
}) => {
  const { applicationProcessData } = useSelector((state) => state.user);
  const [profileDataArr, setProfileDataArr] = useState([]);
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [bankData, setBankData] = useState("");
  const [checked, setChecked] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openIndexs, setOpenIndexs] = useState([]);
  const [openIndexsEmployment, setOpenIndexsEmployment] = useState([]);
  const [modalState, setModalState] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [allDetials, setAllDetials] = useState({});

  useEffect(() => {
    if (appPayloadData?.id) {
      let obj = {};
      obj.applicationPayment = appPayloadData.payment_data;
      obj.bank_verified_date = appPayloadData.bank_verified_date;
      obj.credit_score_data = appPayloadData.credit_score_data;
      obj.credit_score_verified_date =
        appPayloadData.credit_score_verified_date;
      obj.employment_verified_date = appPayloadData.employment_verified_date;
      obj.id = appPayloadData.id;
      obj.incomeAndEmployment = {};
      obj.incomeAndEmployment.instantBankVerification =
        appPayloadData.bank_verification_data;
      obj.incomeAndEmployment.manualVerification =
        appPayloadData.manual_verification_data;
      obj.id = appPayloadData.id;
      obj.is_checked_tnc = appPayloadData.is_checked_tnc;
      obj.is_credit_score_verified = appPayloadData.is_credit_score_verified;
      obj.is_employment_verified = appPayloadData.is_employment_verified;
      obj.is_general_profile_verified =
        appPayloadData.is_general_profile_verified;
      obj.is_paystub_verified = appPayloadData.is_paystub_verified;
      obj.is_rental_history_verified =
        appPayloadData.is_rental_history_verified;
      obj.is_state_profile_verified = appPayloadData.is_state_profile_verified;
      obj.is_verified = appPayloadData.is_verified;
      obj.paystub_verified_date = appPayloadData.paystub_verified_date;
      obj.rentalHistory = appPayloadData.rental_history;
      obj.rental_history_verified_date =
        appPayloadData.rental_history_verified_date;
      obj.stateProfile = appPayloadData.state_profile;
      obj.unit_id = appPayloadData.unit_id;
      obj.updated_at = appPayloadData.updated_at;
      obj.userProfile = {};
      obj.userProfile.generalProfile = appPayloadData.general_profile;
      obj.userProfile.idProof = appPayloadData.id_proof;
      obj.userProfile.members = appPayloadData.members;
      obj.user_id = appPayloadData.user_id;
      obj.review_user_list = appPayloadData.review_user_list;
      setAllDetials(obj);
    } else if (applicationProcessData?.id) {
      setAllDetials(applicationProcessData);
    } else {
      setAllDetials({});
    }
  }, [appPayloadData]);

  // To get default data from redux
  useEffect(() => {
    const manualIncomeData =
      allDetials?.incomeAndEmployment?.manualVerification;
    if (allDetials?.rentalHistory?.references) {
      setNewSetalFields(allDetials?.rentalHistory?.references);
    }
    if (manualIncomeData?.employerDetails?.length) {
      setIncomeData(manualIncomeData?.employerDetails);
    }
    if (allDetials?.incomeAndEmployment?.instantBankVerification?.is_verified) {
      setBankData(
        allDetials?.incomeAndEmployment.instantBankVerification?.is_verified
      );
    }

    if (allDetials?.review_user_list?.length) {
      setProfileDataArr(allDetials?.review_user_list);
    }

    setChecked(allDetials?.is_checked_tnc);
    form.setFieldsValue({
      accepted_tnc: allDetials?.is_checked_tnc,
    });
  }, [allDetials]);

  // split function after 15 words ... (Aven...)
  const slpitFunc = (value) => {
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    } else {
      return value;
    }
  };

  // To define upper case letter for every first letter of word
  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return slpitFunc(str2);
    }
  };

  const onFinish = (value) => {
    setLoading(true);
    if (allDetials?.is_checked_tnc === 1) {
      setLoading(false);
    } else {
      let data = {};
      data.isCheckedTnc = checked;
      dispatch(applicationProcess(data)).then(({ payload }) => {
        setLoading(false);
      });
    }
    if (allDetials?.credit_score_data?.length) {
      onMainTabNext("payment");
    } else {
      setModalState(true);
    }
  };

  // filter data
  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  // filter data
  const openIndexFunEmployment = (idx) => {
    if (openIndexsEmployment?.includes(idx)) {
      var filteredArray = openIndexsEmployment?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexsEmployment(filteredArray);
    } else {
      setOpenIndexsEmployment((val) => [...val, idx]);
    }
  };

  // date format
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type !== "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${month}/${day}/${year}`;
  }

  // date format converter
  const dateConverter = (dateValue = new Date(), month) => {
    var date = new Date(dateValue);
    return formatDate(date);
  };

  // Content for pop over
  const content = (
    <div>
      <ImagePreview
        width={200}
        isCoverDisProp={false}
        key={0}
        src={
          allDetials?.userProfile?.idProof?.frontProof?.path
            ? allDetials?.userProfile?.idProof?.frontProof?.path
            : ""
        }
        height={"auto"}
        isDeleteDisProp={false}
      />
      <ImagePreview
        width={200}
        isCoverDisProp={false}
        key={0}
        src={
          allDetials?.userProfile?.idProof?.backProof?.path
            ? allDetials?.userProfile?.idProof?.backProof?.path
            : ""
        }
        height={"auto"}
        isDeleteDisProp={false}
      />
    </div>
  );

  // Content for pop over
  const contentFirst = (
    <div>
      <ImagePreview
        width={200}
        isCoverDisProp={false}
        key={0}
        src={
          allDetials?.incomeAndEmployment?.manualVerification?.payStubs
            ?.payStub1?.path
            ? allDetials?.incomeAndEmployment?.manualVerification?.payStubs
                ?.payStub1?.path
            : ""
        }
        height={"auto"}
        isDeleteDisProp={false}
      />
    </div>
  );

  // Content for pop over
  const contentSec = (
    <div>
      <ImagePreview
        width={200}
        isCoverDisProp={false}
        key={0}
        src={
          allDetials?.incomeAndEmployment?.manualVerification?.payStubs
            ?.payStub2?.path
            ? allDetials?.incomeAndEmployment?.manualVerification?.payStubs
                ?.payStub2?.path
            : ""
        }
        height={"auto"}
        isDeleteDisProp={false}
      />
    </div>
  );

  const onChange = (e) => {
    if (e.target.checked === true) {
      setChecked(1);
    } else {
      setChecked(0);
    }
  };

  return (
    <>
      <GeneralProfileWrap>
        {/* <Row
          justify="space-between"
          style={{ marginBottom: "0px", paddingBottom: "0px" }}
        >
          <Col>
            <h2>User Profile</h2>
          </Col>
        </Row> */}
        {/* <ApartmentTable style={{ marginTop: "16px" }}>
          <Table
            columns={columns}
            scroll={{ x: 700 }}
            dataSource={[...profileDataArr]}
            pagination={false}
          />
          <Row style={{ padding: "15px" }}>
            <Col>
              Uploaded Document:{" "}
              {allDetials?.id_proof?.IDtype?.length ? (
                <Popover
                  overlayClassName="Id_popover"
                  content={content}
                  title="ID Preview"
                  placement="bottomLeft"
                >
                  <span className="id_type">
                    {allDetials?.id_proof?.IDtype}
                  </span>
                </Popover>
              ) : (
                <span className="id_type">NA</span>
              )}
            </Col>
          </Row>
        </ApartmentTable> */}

        {profileDataArr?.length ? (
          <ViewAllRental>
            <AddedReference>
              <Row justify="space-between">
                <Col>
                  <h2>User Profile</h2>
                </Col>
              </Row>
            </AddedReference>
            <ViewAddedReference>
              <Row justify="space-between" gutter={[15, 15]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  {/* <h2>Employer Details</h2> */}
                  <Row justify="space-between" gutter={[2, 2]}>
                    <Col span={8}>
                      <DetailsBox>
                        <p>Full Name</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        <p>{upperCase(profileDataArr[0]?.full_name)}</p>
                      </DetailsBox>
                    </Col>
                    <Col span={8}>
                      <DetailsBox>
                        <p>Birthday</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        <p>{dateConverter(profileDataArr[0]?.birthday)}</p>
                      </DetailsBox>
                    </Col>
                    <Col span={8}>
                      <DetailsBox>
                        <p>Social Security Number</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        {profileDataArr[0]?.security_number ? (
                          <p>
                            {formatSSNNumber(
                              profileDataArr[0]?.security_number
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                      </DetailsBox>
                    </Col>
                    <Col span={8}>
                      <DetailsBox>
                        <p>Address</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        <p>{profileDataArr[0]?.address}</p>
                      </DetailsBox>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={12}
                  xxl={12}
                  // style={{ marginTop: "22px" }}
                >
                  <Row justify="space-between" gutter={[2, 2]}>
                    <Col span={8}>
                      <DetailsBox>
                        <p>City</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        <p>{profileDataArr[0]?.city}</p>
                      </DetailsBox>
                    </Col>
                    <Col span={8}>
                      <DetailsBox>
                        <p>State</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        <p>{profileDataArr[0]?.state}</p>
                      </DetailsBox>
                    </Col>
                    <Col span={8}>
                      <DetailsBox>
                        <p>Zip Code</p>
                      </DetailsBox>
                    </Col>
                    <Col span={16}>
                      <DetailsBox color="#000000">
                        <p>{profileDataArr[0]?.zip_code}</p>
                      </DetailsBox>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ padding: "15px 15px 0 15px" }}>
                <Col>
                  Uploaded Document:{" "}
                  {allDetials?.userProfile?.idProof?.IDtype?.length ? (
                    <Popover
                      overlayClassName="Id_popover"
                      content={content}
                      title="ID Preview"
                      placement="bottomLeft"
                    >
                      <span className="id_type">
                        {allDetials?.userProfile?.idProof?.IDtype}
                      </span>
                    </Popover>
                  ) : (
                    <span className="id_type">NA</span>
                  )}
                </Col>
              </Row>
            </ViewAddedReference>
          </ViewAllRental>
        ) : (
          ""
        )}

        <h1 className="review_title">Income and Employment</h1>
        <h4 className="sub__title" style={{ marginBottom: "15px" }}>
          Employment Details :
        </h4>

        {incomeData?.map(
          (
            {
              businessName,
              address,
              city,
              state,
              zipcode,
              workPhone,
              position,
              grossMonthlyIncome,
              dateStarted,
              supervisorsName,
              supervisorsNumber,
            },
            idx
          ) => (
            <>
              <ViewAllRental
                mb={openIndexsEmployment?.includes(idx) ? "0" : "25px"}
                key={idx}
                ml="25px"
              >
                <AddedReference
                  bb={
                    openIndexsEmployment?.includes(idx)
                      ? "none"
                      : "1px solid #dddddd"
                  }
                  onClick={() => openIndexFunEmployment(idx)}
                >
                  <Row
                    justify="space-between"
                    style={{ alignItems: "center", padding: "0px" }}
                  >
                    <Col>
                      <Row gutter={[10, 10]}>
                        <Col>
                          <p className="prop_name">{businessName}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      {openIndexsEmployment?.includes(idx) ? (
                        <>
                          <LessSvg
                            style={{ marginBottom: "5px" }}
                            className="less__svg"
                          />
                        </>
                      ) : (
                        <>
                          <PlusSvg />
                        </>
                      )}
                    </Col>
                  </Row>
                </AddedReference>
              </ViewAllRental>
              {openIndexsEmployment?.includes(idx) ? (
                <ViewAddedReference ml="25px">
                  <Row justify="space-between" gutter={[15, 15]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Row justify="space-between" gutter={[2, 2]}>
                        <Col span={8}>
                          <DetailsBox>
                            <p>Address</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{address}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>City</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{city}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>State</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{state}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>Zipcode</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{zipcode ? zipcode : `${" "}`}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>Work Phone</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{formatPhoneNumber(workPhone)}</p>
                          </DetailsBox>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <Row justify="space-between" gutter={[2, 2]}>
                        <Col span={12}>
                          <DetailsBox>
                            <p>Position</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox color="#000000">
                            <p>{position}</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox>
                            <p>Gross Monthly Income</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox color="#000000">
                            <p>{toUSCurrency(grossMonthlyIncome)}</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox>
                            <p>Date Started</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox color="#000000">
                            {dateStarted ? (
                              <p>{dateConverter(dateStarted)}</p>
                            ) : (
                              " "
                            )}
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox>
                            <p>Supervisor's Name</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox color="#000000">
                            <p>{supervisorsName}</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox>
                            <p>Supervisor's Number</p>
                          </DetailsBox>
                        </Col>
                        <Col span={12}>
                          <DetailsBox color="#000000">
                            <p>{formatPhoneNumber(supervisorsNumber)}</p>
                          </DetailsBox>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ViewAddedReference>
              ) : (
                ""
              )}
            </>
          )
        )}

        <ViewAllRental>
          <Row>
            <Col>
              <h4 className="sub__title">Pay Stubs :</h4>
            </Col>
            <Col>
              {allDetials?.incomeAndEmployment?.manualVerification?.payStubs
                ?.payStub1?.path ? (
                <Popover
                  overlayClassName="Id_popover"
                  content={contentFirst}
                  title="ID Preview"
                  placement="bottomLeft"
                >
                  <span className="id_type"> Pay Stub1</span>
                </Popover>
              ) : (
                <span className="id_type">NA</span>
              )}
              {allDetials?.incomeAndEmployment?.manualVerification?.payStubs
                ?.payStub2?.path ? (
                <Popover
                  overlayClassName="Id_popover"
                  content={contentSec}
                  title="ID Preview"
                  placement="bottomLeft"
                >
                  <span className="id_type">Pay Stub2</span>
                </Popover>
              ) : (
                <span className="id_type">NA</span>
              )}
            </Col>
          </Row>
        </ViewAllRental>

        <ViewAllRental>
          <AddedReference>
            <Row style={{ alignItems: "center" }} justify="space-between">
              <Col>
                <h1 className="instant__title">Instant Bank Verification</h1>
              </Col>
              <Col>
                {bankData === 1 ? (
                  <AccountCreateLogo />
                ) : bankData === 0 ? (
                  <OpsSvg />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </AddedReference>
        </ViewAllRental>
        {newSetalFields?.length ? (
          <h1 className="review_title">Rental History</h1>
        ) : (
          <h1 className="review_title">No Rental History</h1>
        )}
        {newSetalFields?.map(
          (
            {
              propertyType,
              propertyName,
              address,
              city,
              state,
              zipcode,
              currentRent,
              managerName,
              phone,
              dateMovedIn,
            },
            idx
          ) => (
            <>
              <ViewAllRental
                mb={openIndexs?.includes(idx) ? "0" : "25px"}
                key={idx}
              >
                <AddedReference
                  bb={openIndexs?.includes(idx) ? "none" : "1px solid #dddddd"}
                  onClick={() => openIndexFun(idx)}
                >
                  <Row
                    justify="space-between"
                    style={{ alignItems: "center", padding: "0px" }}
                  >
                    <Col>
                      <Row gutter={[10, 10]}>
                        <Col>
                          <p className="prop_name">{propertyName}</p>
                        </Col>
                        <Col>
                          <span>{propertyType}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      {openIndexs?.includes(idx) ? (
                        <>
                          <LessSvg
                            style={{ marginBottom: "5px" }}
                            className="less__svg"
                          />
                        </>
                      ) : (
                        <>
                          <PlusSvg />
                        </>
                      )}
                    </Col>
                  </Row>
                </AddedReference>
              </ViewAllRental>
              {openIndexs?.includes(idx) ? (
                <ViewAddedReference>
                  <Row justify="space-between" gutter={[15, 15]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <h2>Address</h2>
                      <Row justify="space-between" gutter={[2, 2]}>
                        <Col span={8}>
                          <DetailsBox>
                            <p>Address</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{address}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>City</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{city}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>State</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{state}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>Zipcode</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{zipcode ? zipcode : `${" "}`}</p>
                          </DetailsBox>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <h2>Contact Details</h2>
                      <Row justify="space-between" gutter={[2, 2]}>
                        <Col span={8}>
                          <DetailsBox>
                            <p>Rent</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{toUSCurrency(currentRent)}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>Manager’s name</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{managerName}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>Phone Number</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            <p>{formatPhoneNumber(phone)}</p>
                          </DetailsBox>
                        </Col>

                        <Col span={8}>
                          <DetailsBox>
                            <p>Move in date</p>
                          </DetailsBox>
                        </Col>
                        <Col span={16}>
                          <DetailsBox color="#000000">
                            {dateMovedIn ? (
                              <p>{dateConverter(dateMovedIn)}</p>
                            ) : (
                              ""
                            )}
                          </DetailsBox>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ViewAddedReference>
              ) : (
                ""
              )}
            </>
          )
        )}

        <FormWrap
          style={{
            bottom: "0",
            width: "100%",
            minHeight: "0px",
            paddingLeft: "0px",
            marginTop: "21px",
          }}
          mt="0"
        >
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                layout="vertical"
                // requiredMark={"optional"}
                form={form}
                onFinish={onFinish}
              >
                {/* <Form.Item name="rentedBefore"><Input /></Form.Item> */}
                <Form.Item
                  className="review__check"
                  name="accepted_tnc"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "You must accept the terms and conditions"
                              )
                            ),
                    },
                  ]}
                >
                  <Row justify="space-between">
                    <Col>
                      <h5>
                        <Checkbox onChange={onChange} checked={checked}>
                          Terms and Conditions
                        </Checkbox>
                        | Please check and verify before moving forward
                      </h5>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col
                      xs={10}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="back__btn"
                    >
                      <IoIosArrowBack
                        onClick={() => onBackClick("review-details")}
                      />
                      <h2 onClick={() => onBackClick("review-details")}>
                        back
                      </h2>
                    </Col>
                    <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Row justify="end" style={{ alignItems: "center" }}>
                        {/* <Col>
                          <p className="cancel__btn">CANCEL</p>
                        </Col> */}
                        <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                          <StyledButton
                            mt="0"
                            loading={loading}
                            htmlType="submit"
                          >
                            next
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </FormWrap>
      </GeneralProfileWrap>
      <ObtainCreditScoreModal
        modalState={modalState}
        onCancel={(val) => setModalState(val)}
        onMainTabNext={onMainTabNext}
      />
    </>
  );
};

export default ApplicantAllDetails;
