import { Col, Popover, Row } from "antd";
import React from "react";
import { BathSvg, BedSvg, Sqft } from "../../assets/svgs";
import styled from "styled-components";

const MyMarker = ({ title, tooltip, img, $hover }) => {
  const handleClick = () => {
  };

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  return (
    <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
      {/* <span className="circleText" title={tooltip}>
        {text}
      </span> */}
      <MapWrap className="popover__class">
        <Popover
          content={
            <ApplicationsCard>
              <ApplicationsCardTop>
                <img src={img} />
              </ApplicationsCardTop>
              <BedBathBox>
                <Row justify="space-between">
                  <Col>
                    <h2>#325456</h2>
                  </Col>
                  <Col>
                    <h3>$500-$1200</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Lombard St, Baltimore | Maryland</h4>
                  </Col>
                </Row>
                <Row gutter={[5, 5]}>
                  <Col className="card__bottom">
                    <span>Bed : 2</span>
                  </Col>
                  <Col className="card__bottom">
                    <span>Bath : 3</span>
                  </Col>
                  <Col className="card__bottom">
                    <span>Sq ft : 2022</span>
                  </Col>
                </Row>
              </BedBathBox>
            </ApplicationsCard>
          }
          className="circleText"
          trigger="click"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <g opacity="0.5">
              <circle cx="13" cy="13" r="13" fill="#F67C36" />
              <circle cx="13" cy="13" r="13" fill="#F67C36" />
            </g>
            <circle cx="13" cy="13" r="7" fill="#F67C36" />
            <circle cx="13" cy="13" r="7" fill="#F67C36" />
          </svg>
        </Popover>
      </MapWrap>
    </div>
  );
};

export default MyMarker;

const BedBathBox = styled.div`
  span {
    color: #848687;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 4px 10px;
  }
`;

const ApplicationsCard = styled.div`
  h2 {
    color: #f67c36;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  h3 {
    color: #2b353f;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  h4 {
    color: #777;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  background: #ffffff;
  ${"" /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */}
  cursor: pointer;
  transition: transform 0.4s;
  max-width: 355px;
  margin: auto;
  border-radius: 10px;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
`;

const ApplicationsCardTop = styled.div`
  padding: 5px;
  display: flex;
  border-radius: 5px 5px 0 0;
  img {
    width: 270px;
    height: 160px;
  }
`;

const MapWrap = styled.div`
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-inner,
  .ant-popover {
    background: red;
  }
`;
