import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
// import OtpInput from "react-otp-input";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";
import { StyledForm, StyledForms } from "../../pages/my-account/ChangePassword";
// import { StyledFormItem } from "../../pages/login/Login";

function OtpModal({
  modalState,
  onCancel,
  onFinishOtp = console.log(),
  otpValue,
  onFinishResendOtp,
  errorMessage = "",
  loadingsOtp = false,
}) {
  const [form] = Form.useForm();

  const [otp, setOtp] = useState("");
  const [widthSquare, setwidthSquare] = useState("2.5rem");
  const [height, setHeight] = useState("2.5rem");
  const [fontSize, setFontSize] = useState("1.6rem");
  const [margin, setMargin] = useState("0 10px");
  const [modalWidth, setModalWidth] = useState(420);

  const { width } = useWindowSize();
  const location = useLocation();

  // OTP box width and height size for responsive screen
  useEffect(() => {
    if (width < 1550 && 425 < width) {
      setwidthSquare("2.5rem");
      setHeight("2.5rem");
      setFontSize("1.6rem");
      setModalWidth(480);
      setMargin("0 10px");
    } else if (425 > width) {
      setwidthSquare("2.2rem");
      setHeight("2.2rem");
      setFontSize("1.2rem");
      setMargin("0 7px");
    } else {
      setwidthSquare("3rem");
      setHeight("3rem");
      setFontSize("2rem");
      setModalWidth(500);
      setMargin("0 10px");
    }
  }, [width, location.pathname]);

  const handleChange = (val) => {
    setOtp(val);
    otpValue(val);
  };

  //  get 6 digits
  const onFinish = (value) => {
    const obj = Object.values(value);
    const arr = [];
    obj.map((val) => {
      arr.push(val);
    });

    const otpValueInput = arr.join("");
    setOtp(otpValueInput);

    otpValue(otpValueInput);
    if (otpValueInput?.length === 6) {
      // onFinishOtp();
    }
  };

  // for get 6 separate digits of OTP
  const [state, setState] = useState({
    value: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    disable: true,
  });

  // function called onChange in input change on box
  const handleChanges = (value1, event) => {
    setState({ [value1]: event.target.value });
    // console.log(event.target.value[0], ">>");

    if (event.target.value.length > 1) {
      // console.log(value1, event.target.value, ">>");
      form.setFieldsValue({
        0: event.target.value[0],
        1: event.target.value[1],
        2: event.target.value[2],
        3: event.target.value[3],
        4: event.target.value[4],
        5: event.target.value[5],
      });
    }
  };

  // function called on key press in box
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      // onCancel={() => {
      //   onCancel(false);
      // }}
      width={modalWidth}
      closable={false}
      footer={null}
      style={{ borderRadius: "5px", top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <OtpModalWrap>
        <StyledForm
          wh="100"
          smwh="100"
          xswh="100"
          xxswh="100"
          // style={{ paddingTop: "40px" }}
        >
          <StyledForms>
            <h5>You will get OTP via Email</h5>
            <h2>OTP (One time password) Verification</h2>
            {/* <p>Please enter the verification code sent to your mail</p> */}
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              form={form}
            >
              <StyledFormItem>
                <StyledFormItemd name="0">
                  <Input
                    name="otp1"
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.bblur()}
                    // autoComplete="off"
                    className="otpInput"
                    value={state.otp1}
                    // onKeyPress={keyPressed}
                    onChange={(e) => handleChanges("otp1", e)}
                    tabIndex="1"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </StyledFormItemd>
                <StyledFormItemd name="1">
                  <Input
                    name="otp2"
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.bblur()}
                    // autoComplete="off"
                    className="otpInput"
                    value={state.otp2}
                    onChange={(e) => handleChanges("otp2", e)}
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </StyledFormItemd>
                <StyledFormItemd name="2">
                  <Input
                    name="otp3"
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.bblur()}
                    // autoComplete="off"
                    className="otpInput"
                    value={state.otp3}
                    onChange={(e) => handleChanges("otp3", e)}
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </StyledFormItemd>
                <StyledFormItemd name="3">
                  <Input
                    name="otp4"
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.bblur()}
                    // autoComplete="off"
                    className="otpInput"
                    value={state.otp4}
                    onChange={(e) => handleChanges("otp4", e)}
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </StyledFormItemd>

                <StyledFormItemd name="4">
                  <Input
                    name="otp5"
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.bblur()}
                    // autoComplete="off"
                    className="otpInput"
                    value={state.otp5}
                    onChange={(e) => handleChanges("otp5", e)}
                    tabIndex="5"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </StyledFormItemd>

                <StyledFormItemd name="5">
                  <Input
                    name="otp6"
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.bblur()}
                    // autoComplete="off"
                    className="otpInput"
                    value={state.otp6}
                    onChange={(e) => handleChanges("otp5", e)}
                    tabIndex="6"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </StyledFormItemd>
              </StyledFormItem>

              <p>
                <span
                  onClick={() => onFinishResendOtp()}
                  style={{ color: "#F67C36", cursor: "pointer" }}
                >
                  Resend
                </span>
              </p>

              {errorMessage?.length ? <h4>* Invalid Code</h4> : ""}
              <VerifyButton loading={loadingsOtp} htmlType="submit">
                Verify
              </VerifyButton>
              {/* <VerifyButton
                disabled={otp.length === 6 ? false : true}
                htmlType="submit"
                loading={loadingsOtp}
              >
                Verify
              </VerifyButton> */}
              {/* <h3 onClick={() => onFinishResendOtp()}>RESEND CODE</h3> */}
            </Form>

            {/* {errorMessage?.length ? <h4>* Invalid Code</h4> : ""}
              <VerifyButton
                disabled={otp.length === 6 ? false : true}
                htmlType="submit"
                loading={loadingsOtp}
              >
                Verify
              </VerifyButton>
              <h3 onClick={() => onFinishResendOtp()}>RESEND CODE</h3>
            </Form> */}
          </StyledForms>
        </StyledForm>
      </OtpModalWrap>
    </Modal>
  );
}

export default OtpModal;

const StyledFormItemd = styled(Form.Item)`
  ${"" /* width: ${({ wh }) => wh || "15"}%; */}
  margin-bottom: ${({ mb }) => mb || "0"};
  display: flex;
  .ant-input {
    padding: 4px 0 4px 11px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
  .ant-form-item-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #232333;
  }
  input,
  .ant-select-selector {
    width: 100%;
    width: 60px;
    height: 50px !important;
    background: ${({ bgColor }) => bgColor || "#eee"} !important ;
    font-size: 22px !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    align-items: center !important;
    border: 0 !important;
    text-align: center;
    @media screen and (max-width: 595px) {
      width: 100%;
    }
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ ftColor }) => ftColor || "#fff"};
  }
  .ant-input:placeholder-shown {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
    border: 0;
  }
  .ant-picker-input > input:placeholder-shown {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
  }
  @media (max-width: 1550px) {
    input,
    .ant-select-selector {
      height: 60px !important;
      font-size: 16px !important;
    }
  }

  @media (max-width: 1024px) {
    width: ${({ sWh }) => sWh};
  }

  @media (max-width: 769px) {
    width: ${({ xsWh }) => xsWh};
  }
`;

const StyledFormItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const VerifyButton = styled(Button)`
  margin: auto;
  margin-top: 20px;

  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-radius: ${({ br }) => br || "5px"};
  background: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  align-items: center;
  padding: 0 11px;
  text-transform: uppercase;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  :hover,
  :focus {
    color: #fff;
    border-color: ${({ theme }) => theme.colors.secondary};
    background: ${({ theme }) => theme.colors.secondary};
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.forth};
    line-height: 22px;
  }

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    position: fixed;
    right: 80px;
    color: ${({ theme }) => theme.colors.third};
  }

  @media (max-width: 1550px) {
    height: 40px;
  }
`;

const OtpModalWrap = styled.div`
  h4 {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
    text-align: center;
  }
  h5 {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #4160ac;
  }

  ${"" /* padding-bottom: 20px; */}

  .ant-btn[disabled],
  .ant-btn[disabled]:hover {
    color: #fff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
    text-shadow: none;
    box-shadow: none;
  }

  h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 20px;
    width: 80%;
    margin: auto;
  }
  h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #f67c36;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
    cursor: pointer;
    :hover,
    :focus {
      color: #f67c3690;
    }
  }
  p {
    margin-top: 24px;
    margin-bottom: 35px;
    text-align: center;
    color: #777777;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #888888;
  }

  @media (max-width: 1550px) {
    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #2b353f;
      margin-bottom: 16px;
    }
    h5 {
      font-size: 16px;
      line-height: 24px;
    }
    p {
      margin-top: 20px;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      text-transform: uppercase;
      color: #888888;
      margin-bottom: 28px;
    }
  }
`;
