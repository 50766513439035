import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Row } from "antd";
import {
  StyledForm,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { applicationProcess } from "../../../../store/reducers/user.reducer";
import { IoIosArrowBack } from "react-icons/io";
import { RiArrowGoBackLine } from "react-icons/ri";

function CriminalHistory({
  onYourSpouseNext,
  onBackClickTabs,
  profileSubmit,
  selectedStateProfile,
}) {
  const CheckboxGroup = Checkbox.Group;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [undoFields, setUndoFields] = useState(false);

  const onFinish = (value) => {
    setIsSubmit(true);
    let data = {};
    data.stateProfile = [];
    let stateData = {};
    stateData.stateName = selectedStateProfile.stateName;
    stateData.id = selectedStateProfile.id;
    stateData.rentalOrCriminalHistory = checkedList;
    data.stateProfile.push(stateData);

    dispatch(applicationProcess(data)).then(({ payload }) => {
      if (selectedStateProfile?.id) {
      } else {
        const payloadDat = payload?.stateProfile;
        var stateValue = payloadDat?.filter(function (item) {
          return item["stateName"] === stateData.stateName;
        });
        profileSubmit(stateValue?.length ? stateValue[0] : {}, payloadDat);
      }
      setIsSubmit(false);
      onYourSpouseNext("your-vehicles");
    });
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const plainOptions = [
    "Been evicted or asked to move out?",
    "Moved out of a dwelling before the end of the lease term without the owner’s consent?",
    "Declared bankruptcy?",
    "Been sued for rent?",
    "Been sued for property damage?",
    "Been charged, detained, or arrested for a felony or sex crime that was resolved by conviction, probation, deferred adjudication, courtordered community supervision, or pretrial diversion?",
    "Been charged, detained, or arrested for a felony or sex-related crime that has not been resolved by any method?",
  ];
  const onChange = (list) => {
    setCheckedList(list);
    setUndoFields(true);
  };

  useEffect(() => {
    if (selectedStateProfile?.rentalOrCriminalHistory) {
      setCheckedList(selectedStateProfile?.rentalOrCriminalHistory);
    } else {
    }
  }, [selectedStateProfile]);

  const undoHandler = () => {
    if (selectedStateProfile?.rentalOrCriminalHistory) {
      setCheckedList(selectedStateProfile?.rentalOrCriminalHistory);
    } else {
      setCheckedList([]);
    }
    setUndoFields(false);
  };

  return (
    <GeneralProfileWrap>
      <h1>
        Have you, your spouse, or any occupant listed in this Application ever:
      </h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <OptionsWrap>
                <CheckboxGroup
                  options={plainOptions}
                  value={checkedList}
                  onChange={onChange}
                />
              </OptionsWrap>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("criminal-history")}
                    />
                    <h2 onClick={() => onBackClickTabs("criminal-history")}>
                      back
                    </h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      {undoFields ? (
                        <Col>
                          <p className="cancel__btn" onClick={undoHandler}>
                            <RiArrowGoBackLine />
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default CriminalHistory;

const OptionsWrap = styled.div`
  label {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #000000;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #eeeeee;
    background-color: #2b353f;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #eeeeee;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #eeeeee;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2b353f;
    border: 1px solid #2b353f;
  }

  .ant-checkbox-inner {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 50%;
  }

  .ant-checkbox-inner {
    width: 22px;
    height: 21px;
  }

  .ant-checkbox {
    top: 0.4em;
  }

  .ant-checkbox-inner::after {
    left: 28.5%;
  }

  @media (max-width: 769px) {
    label {
      line-height: 26px;
    }
  }
`;
