import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Image,
  message,
  Row,
  Skeleton,
} from "antd";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import StarRatings from "react-star-ratings";
import { FaHeart } from "react-icons/fa";
import { IoMdShare } from "react-icons/io";
import PropertyDetailsTabs from "./PropertyDetailsTabs";
import AboutAvenew from "../../components/aboutAvenew/AboutAvenew";
import Footer from "../../components/footer/Footer";
import ApplyForPropertyModal from "./apply-for-property-modal/ApplyForPropertyModal";
import { AvenewImg, BathSvg, BedSvg, SearchIconSvg } from "../../assets/svgs";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrRemoveFavorites,
  createChat,
  getUnitsDetails,
} from "../../store/reducers/user.reducer";
import { DeleteBtn } from "../Profile-verification/PriorRentalInformation";
import "./PropertyDetails.css";
import ApplyingToProperty from "../../components/applyingToProperty/ApplyingToProperty";
import { toUSCurrency, upperCase } from "../../utils/fn";
import ShareModal from "../../components/share-modal/ShareModal";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import Header from "../../components/layout/header/Header";
import HomeHeader from "../../components/layout/dashbord-new/HomeHeader";
import LoginModal from "../login/login-modal/LoginModal";
import UnderVerificationModal from "../verification-process/sub-tabs/state-profile-tabs/UnderVerificationModal";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";

function PropertyDetails() {
  const location = useLocation();
  const params = useParams();
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const { applicationProcessData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(false);
  const [imageVisible, setImageVisible] = useState("block");
  const [modalStateApplyingProp, setModalStateApplyingProp] = useState(false);
  const [shareModalState, setshareModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [unitDetails, setUnitDetails] = useState([]);
  const [unitImage, setUnitImage] = useState([]);
  const { allUnit } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loginModalState, setLoginModalState] = useState(false);
  const [favoriteUserUnits, setFavoriteUserUnits] = useState([]);
  const [verificationModal, setVerificationModal] = useState(false);

  const handleAbsoluteClick = (id) => {
    setVisible(true);
  };

  useEffect(() => {
    if (width < 768) {
      setImageVisible("none");
    } else {
      setImageVisible("block");
    }
  }, [width, location.pathname]);

  // Set all favorite units data in state
  useEffect(() => {
    if (user?.favoriteUnits) {
      setFavoriteUserUnits(user.favoriteUnits);
    }
  }, [user]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // page navigation header
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/property-details",
      label: upperCase(unitDetails?.unit_name),
      isLink: false,
    },
  ];
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // Get Units Details- name, ID, address, Etc.
  useEffect(() => {
    if (params?.id) {
      localStorage.setItem("unit-details", params.id);
      // if (!Object.values(allUnit)?.length) {
      dispatch(getUnitsDetails()).then(({ payload }) => {
        setUnitDetails(payload[0]);
      });
      // }
    }
  }, [params.id, allUnit]);

  // get unit value from params id
  useEffect(() => {
    if (params?.id) {
      var arr = [];
      for (var item in allUnit) {
        if (allUnit.hasOwnProperty(item)) {
          arr.push(allUnit[item]);
        }
      }
      var orgValue = arr.filter(function (item) {
        return item["id"] == params.id;
      });
      setUnitDetails(orgValue[0]);
    }
  }, []);

  // apply for unit application
  const clickToApply = () => {
    if (user) {
      if (applicationProcessData?.id) {
        if (
          applicationProcessData?.userProfile &&
          applicationProcessData?.stateProfile?.length &&
          applicationProcessData?.applicationPayment
          // &&
          // applicationProcessData?.is_verified
        ) {
          navigate(`/unit-application/${unitDetails.id}`);
        } else if (
          applicationProcessData?.userProfile &&
          applicationProcessData?.stateProfile?.length &&
          applicationProcessData?.applicationPayment
          // &&
          // !applicationProcessData?.is_verified
        ) {
          setVerificationModal(true);
        } else {
          setModalStateApplyingProp(true);
        }
      } else {
        setModalStateApplyingProp(true);
      }
    } else {
      setLoginModalState(true);
    }
  };

  useEffect(() => {
    if (searchParams.get("apply")) {
      setModalStateApplyingProp(true);
    }
  }, []);

  const onLogin = (payload) => {
    if (payload?.id) {
      if (
        payload?.userProfile &&
        payload?.stateProfile &&
        payload?.applicationPayment
      ) {
        navigate(`/unit-application/${unitDetails.id}`);
      } else {
        setModalStateApplyingProp(true);
      }
    }
  };

  // direact message to Admin
  const clickToMessage = () => {
    let value = {};
    value.unit_id = unitDetails.id;
    value.adminUserId = unitDetails.user_id;
    value.prospectUserId = user.id;
    // value.unit_name = unitDetails?.unit_name;
    setIsBtnLoading(true);

    dispatch(createChat(value)).then(({ payload }) => {
      if (payload.chat) {
        navigate({
          pathname: `/message`,
          search: createSearchParams({
            id: payload.chat.id,
          }).toString(),
        });
        setIsBtnLoading(false);
      } else if (payload.Error) {
        setIsBtnLoading(false);
        message.error("API error");
      }
    });
  };

  // add and remove from favorites
  const getFavorites = (unitId) => {
    var value = {};
    value.unit_id = unitId;
    value.user_id = user?.id;
    if (favoriteUserUnits?.length) {
      if (favoriteUserUnits.includes(unitId)) {
        value.isFavorite = false;
        const newFav = favoriteUserUnits.filter(function (ele) {
          return ele != unitId;
        });
        setFavoriteUserUnits([...newFav]);
      } else {
        let arr = [];
        arr.push(unitId);
        setFavoriteUserUnits((val) => [...val, ...arr]);
        value.isFavorite = true;
      }
    } else {
      let arr = [];
      arr.push(unitId);
      setFavoriteUserUnits((val) => [...val, ...arr]);
      value.isFavorite = true;
    }
    dispatch(addOrRemoveFavorites(value));
  };

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    // return `${day}-${month}-${year}`;
    return `${month} ${day}, ${year}`;
  }

  useEffect(() => {
    if (unitDetails?.images && unitDetails?.images?.length) {
      const newImg = unitDetails?.images?.filter(
        (item) => item?.isCover !== true
      );
      setUnitImage(newImg);
    }
  }, [unitDetails]);

  return (
    <>
      {/* <HomeHeader /> */}
      <Header />

      <BreadcrumbTop style={{ marginTop: "80px" }}>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>

      <ImageWrapper className="image-grid">
        <div className="image-grid">
          <Image.PreviewGroup
            preview={{
              visible,
              onVisibleChange: (vis) => setVisible(vis),
              current: 5,
              // countRender: (cur) => setCurrentImg(cur),
            }}
          >
            <ImageAnt>
              {unitDetails?.images && unitDetails?.images?.length ? (
                <>
                  {unitDetails?.images.map((img, idx) => (
                    <div key={idx}>
                      {imageVisible === "none" ? (
                        <>
                          {img.isCover === true ? (
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <Image
                                className="image-grid-col-2 image-grid-row-2 antimage"
                                src={img.path}
                                alt="architecture"
                              />
                              <ViewMoreImage
                                style={{
                                  bottom: "10px",
                                  left: "10px",
                                }}
                                right="none"
                              >
                                <p>{unitDetails?.images?.length - 1} Photos</p>
                              </ViewMoreImage>
                              <FavShare>
                                <RoundBtn>
                                  {favoriteUserUnits?.includes(
                                    unitDetails?.id
                                  ) ? (
                                    <FaHeart
                                      onClick={(e) => {
                                        e.preventDefault();
                                        getFavorites(unitDetails?.id);
                                      }}
                                      style={{ color: "#f67c36" }}
                                    />
                                  ) : (
                                    <FaHeart
                                      onClick={(e) => {
                                        e.preventDefault();
                                        getFavorites(unitDetails?.id);
                                      }}
                                    />
                                  )}
                                </RoundBtn>

                                <RoundBtn>
                                  <IoMdShare
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setshareModalState(true);
                                    }}
                                  />
                                </RoundBtn>
                              </FavShare>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          {img.isCover === true ? (
                            <Image
                              className="image-grid-col-2 image-grid-row-2 antimage"
                              src={img.path}
                              alt="architecture"
                            />
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <AvenewImg
                    style={{ background: "#eeeeee" }}
                    className="image-grid-col-2 image-grid-row-2 antimage"
                  />
                </>
              )}
            </ImageAnt>
            {unitImage?.map((img, idxv) => (
              <div
                key={idxv}
                style={{
                  display: idxv > 3 ? "none" : imageVisible,
                  position: "relative",
                  // display: imageVisible,
                }}
              >
                <Image
                  style={{
                    width: "100%",
                  }}
                  key={idxv}
                  src={img.path}
                  alt="architecture"
                  className="mob__img"
                />
                {unitDetails?.images?.length > 5 && idxv === 3 ? (
                  <ViewMoreImage onClick={() => handleAbsoluteClick(idxv)}>
                    <p>{unitDetails?.images?.length - 5} Photos +</p>
                  </ViewMoreImage>
                ) : (
                  ""
                )}
              </div>
            ))}
            {unitDetails?.images ? (
              <>
                {unitDetails?.images.length === 0 ? (
                  <>
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                  </>
                ) : unitDetails?.images.length === 1 ? (
                  <>
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                  </>
                ) : unitDetails?.images.length === 2 ? (
                  <>
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                  </>
                ) : unitDetails?.images.length === 3 ? (
                  <>
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                  </>
                ) : unitDetails?.images.length === 4 ? (
                  <>
                    <AvenewImg
                      style={{ background: "#eeeeee" }}
                      alt="architecture"
                      className="mob__img"
                    />
                  </>
                ) : unitDetails?.images.length === 5 ? (
                  ""
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <AvenewImg
                  style={{ background: "#eeeeee" }}
                  alt="architecture"
                  className="mob__img"
                />
                <AvenewImg
                  style={{ background: "#eeeeee" }}
                  alt="architecture"
                  className="mob__img"
                />
                <AvenewImg
                  style={{ background: "#eeeeee" }}
                  alt="architecture"
                  className="mob__img"
                />
                <AvenewImg
                  style={{ background: "#eeeeee" }}
                  alt="architecture"
                  className="mob__img"
                />
              </>
            )}
          </Image.PreviewGroup>
        </div>
      </ImageWrapper>
      <TitleWrapMobile>
        <Row>
          <Col xs={24} sm={24} md={0}>
            <div className="last__update__mobile">
              <span>Last updated : Jun 11, 2022</span>
            </div>
          </Col>
        </Row>
      </TitleWrapMobile>
      <PropertyDetail>
        <PropertyName>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <h2> {upperCase(unitDetails?.unit_name)}</h2>
            </Col>
          </Row>
          <Row>
            <Col
              xs={0}
              sm={0}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="bed__bath"
            >
              {unitDetails?.bed ? (
                <DeleteBtn
                  style={{ cursor: "default" }}
                  widthBtn="fit-content"
                  paddingBtn="10px 15px"
                >
                  <p>
                    <BedSvg className="icon" />
                  </p>
                  <h5>Bed : {unitDetails?.bed}</h5>
                </DeleteBtn>
              ) : (
                ""
              )}
              {unitDetails?.bath ? (
                <DeleteBtn
                  style={{ cursor: "default" }}
                  widthBtn="fit-content"
                  paddingBtn="10px 15px"
                >
                  <p>
                    <BathSvg className="icon" />
                  </p>
                  <h5>Bath : {unitDetails?.bath}</h5>
                </DeleteBtn>
              ) : (
                ""
              )}
              {/* <StarRatings
                rating={3.5}
                starDimension="20px"
                starSpacing="5px"
                starRatedColor="#F67C36"
              /> */}
            </Col>
          </Row>

          <Row className="price__update" justify="space-between">
            <Col>
              <Row gutter={[15, 15]}>
                <Col>
                  {unitDetails?.id ? (
                    <h3>{toUSCurrency(Number(unitDetails?.price))}</h3>
                  ) : (
                    <Skeleton.Input
                      active
                      style={{
                        width: "100%",
                        height: 20,
                      }}
                    ></Skeleton.Input>
                  )}
                </Col>
                <Col>
                  <span className="for__month">/ Month</span>
                </Col>
                <Col></Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[20, 20]}>
                <Col>
                  {unitDetails?.updatedAt ? (
                    <span className="last__update">
                      Last updated :{" "}
                      {formatDate(new Date(unitDetails?.updatedAt))}
                    </span>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                  {favoriteUserUnits?.includes(unitDetails?.id) ? (
                    <FaHeart
                      onClick={(e) => {
                        e.preventDefault();
                        getFavorites(unitDetails?.id);
                      }}
                      style={{ color: "#f67c36" }}
                    />
                  ) : (
                    <FaHeart
                      onClick={(e) => {
                        e.preventDefault();
                        getFavorites(unitDetails?.id);
                      }}
                    />
                  )}
                </Col>
                <Col>
                  <IoMdShare
                    onClick={(e) => {
                      e.preventDefault();
                      setshareModalState(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PropertyName>
      </PropertyDetail>
      <TitleWrapMobile>
        <Row>
          {/* <Col xs={24} sm={24} md={0}>
            <div className="mobile__title">
              <h2>{upperCase(unitDetails?.unitName)}</h2>
            </div>
          </Col> */}
          <Col xs={24} sm={24} md={0}>
            <div className="mobile__star">
              <StarRatings
                rating={3.5}
                starDimension="25px"
                starSpacing="5px"
                starRatedColor="#F67C36"
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <div className="mobile__price">
              <Row gutter={[15, 15]} style={{ alignItems: "center" }}>
                <Col>
                  <h3>{toUSCurrency(Number(unitDetails?.price))}</h3>
                </Col>
                <Col>
                  <span className="for__month">/ Month</span>
                </Col>
                <Col></Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <div className="mobile__divider">
              <Divider />
            </div>
          </Col>
        </Row>
      </TitleWrapMobile>
      <PropertyDetailsTabs
        clickToApply={clickToApply}
        unitDetails={unitDetails}
        clickToMessage={clickToMessage}
        isBtnLoading={isBtnLoading}
      />
      <AboutAvenew />
      {/* <Footer /> */}
      <FooterSectionHome />
      <ApplyForPropertyModal
        unitId={params?.id}
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
      <ApplyingToProperty
        unitDetails={unitDetails}
        onCancel={(value) => setModalStateApplyingProp(value)}
        modalState={modalStateApplyingProp}
      />
      <ShareModal
        onCancel={(value) => setshareModalState(value)}
        modalState={shareModalState}
        selectedUnit={unitDetails}
      />
      <LoginModal
        modalState={loginModalState}
        onCancel={(value) => setLoginModalState(value)}
        onLogin={onLogin}
      />
      <UnderVerificationModal
        verificationModal={verificationModal}
        onCancel={(value) => setVerificationModal(value)}
      />

      <FooterMobile>
        {/* <SearchIsonWrap>
          <SearchIconSvg />
        </SearchIsonWrap> */}
        <AppluBtn
          br="100px 0px 0px 100px"
          mr="0 10px"
          onClick={() => clickToApply()}
        >
          Apply Now
        </AppluBtn>
        <AppluBtn br="0 100px 100px 0">Message</AppluBtn>
      </FooterMobile>
    </>
  );
}

export default PropertyDetails;

const FavShare = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

const RoundBtn = styled.div`
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 11px 0;
  svg {
    width: 18px !important;
  }
`;

const ViewMoreImage = styled.div`
  position: absolute;
  bottom: 10px;
  right: ${({ right }) => right || "10px"};
  background: #00000090;
  border-radius: 3px;
  padding: 8px 20px;
  cursor: pointer;
  p {
    margin-bottom: 0;
    color: #ffffff;
  }
`;

export const TitleWrapMobile = styled.div`
  .mobile__divider {
    .ant-divider-horizontal {
      margin: 0 30px 20px 30px;
      background: #d9d9d9;
      width: auto;
      min-width: auto;
    }
  }
  .last__update__mobile {
    padding: 12px 30px;
    background: #ecf2ff;
  }
  span {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
  }
  .mobile__title {
    padding: 24px 30px 20px 30px;
    h2 {
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      color: #2b353f;
      margin-bottom: 0;
    }
  }
  .mobile__star {
    padding: 20px 30px 20px;
  }
  .mobile__price {
    padding: 0 30px 30px;
  }

  .mobile__price {
    h3 {
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: #2b353f;
      margin-bottom: 0;
    }
  }
`;

export const AppluBtn = styled(Button)`
  width: 135px;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ bgColor }) => bgColor};
  border: none;
  text-transform: uppercase;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: ${({ br }) => br};
  margin: ${({ mr }) => mr || 0};

  span {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.secondary};

    border-color: ${({ bgColor }) => bgColor}90;
    background: ${({ bgColor }) => bgColor}90;
  }
`;
export const SearchIsonWrap = styled.div`
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 15px 0;
`;
export const FooterMobile = styled.div`
  position: fixed;
  display: none;
  bottom: -1px;
  left: 0;
  z-index: 1000;
  ${"" /* background: ; */}
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  padding: 15px 0;
  justify-content: center;

  @media (max-width: 769px) {
    display: flex;
  }
`;

export const PostImageWrapper = styled.div`
  display: flex;
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top; */

  /* margin-bottom: 10px; */
  .height-one {
    padding-top: ${({ ho }) => ho || "35"}%;
    /* padding-top: 35%; */
  }
  .height-two {
    padding-top: 35%;
  }
  .height-three {
    padding-top: 25%;
  }
  .ant-image {
    width: 100%;
  }
  .slide {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 408px;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ bgColor }) => bgColor || "#4160AC"};
  border-color: ${({ bgColor }) => bgColor || "#4160AC"};
  text-transform: uppercase;
  margin-top: 10px;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    color: ${({ fcolor }) => fcolor || "#ffffff"};
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ bgColor }) => bgColor || "#4160AC"}90;
    background: ${({ bgColor }) => bgColor || "#4160AC"}90;
  }

  :disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: ${({ bgColor }) => bgColor || "#4160AC"};
    background: ${({ bgColor }) => bgColor || "#4160AC"};
    text-shadow: none;
    box-shadow: none;
  }

  @media (max-width: 1550px) {
    height: 40px;
    margin-top: 15px;
    span {
      font-size: 14px;
      line-height: 28px;
    }
  }
`;

export const ApplyWrap = styled.div`
  position: sticky;
  bottom: 1rem;
  align-self: flex-end;
  color: #000000;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  width: 350px;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 15px;
  }

  @media (max-width: 1550px) {
    padding: 20px;
    width: 290px;

    h2 {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const ImageAnt = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  height: 370px;

  .ant-image {
    width: 100%;
  }

  .image-grid > img,
  .image-grid > svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    width: 100%;
    height: auto !important;
  }
  .antimage {
    height: 385px !important;
  }

  .image-grid-col-2,
  svg {
    grid-column: span 2;
    height: 370px;
  }

  .image-grid-row-2,
  svg {
    grid-row: span 2;
  }
`;

export const ImageWrapper = styled.div`
  .ant-image-preview-mask {
    background: rgb(0 0 0 / 84%);
    backdrop-filter: blur(10px);
  }

  .image-grid {
    --gap: 0px;
    --num-cols: 4;
    --row-height: 185px;

    box-sizing: border-box;
    padding: 0;

    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
  }

  .image-grid > img,
  .mob__img {
    width: 100%;
    height: 100%;
    height: 200px;

    object-fit: cover;
  }

  .image-grid-col-2 {
    grid-column: span 2;
    height: 385px;
  }

  .image-grid-row-2 {
    grid-row: span 2;
  }
  .ant-image {
    width: 100%;
  }
  /* Anything udner 1024px */
  @media screen and (max-width: 769px) {
    .image-grid {
      --num-cols: 2;
      --row-height: 185px;
    }
    .mob__img {
      display: none;
    }
  }
`;

export const PropertyDetail = styled.div`
  width: 100%;

  @media screen and (max-width: 1550px) {
    padding: 0 30px;
  }
`;
export const BreadcrumbTop = styled.div`
  background-color: #ecf2ff;
  width: 100%;
`;

export const PropertyName = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 40px 0;

  .bed__bath {
    display: flex;
    gap: 10px;
  }

  .price__update {
    margin-top: 30px;
    .ant-row {
      align-items: center;
    }
    svg {
      color: #848687;
      font-size: 23px;
      cursor: pointer;
    }
  }
  h2 {
    font-weight: 400;
    font-size: 50px;
    line-height: 61px;
    color: ${({ theme }) => theme.colors.fifth};
    margin-bottom: 15px;
  }
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.fifth};
    margin-bottom: 0;
  }
  .for__month {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
  }
  .last__update {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #848687;
  }

  @media (max-width: 1550px) {
    padding: 30px 0;

    h2 {
      font-size: 40px;
      line-height: 48px;
    }
    .price__update {
      margin-top: 20px;
    }
    h3 {
      font-size: 26px;
      line-height: 31px;
    }
  }

  @media (max-width: 769px) {
    padding: 0;

    h2 {
      font-size: 30px;
      line-height: 36px;
      padding-top: 20px;
    }
    .price__update {
      display: none;
    }
  }
`;
export const BreadCrumbsWrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 17px 0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #ecf2ff;

  .ant-breadcrumb {
    .ant-breadcrumb-link {
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: ${(props) => props.theme.colors.secondary};
    }
    > :last-child {
      color: #2b353f !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      a {
        color: #2b353f !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }
    > :not(:last-child) {
      color: ${(props) => props.theme.colors.secondary} !important;
      a {
        color: ${(props) => props.theme.colors.secondary} !important;
        &:hover {
          color: ${(props) => props.theme.colors.secondary};
        }
      }
    }
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .breadcrumbs_label {
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: 1550px) {
    padding: 17px 30px;
    width: 100%;
  }

  @media (max-width: 991px) {
    padding: 15px 15px 17px 15px;
  }
`;
