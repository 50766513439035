import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InformationWrap,
  ViewAllRental,
} from "../../Profile-verification/PriorRentalInformation";
import { FormWrap, GeneralProfileWrap } from "./GeneralProfile";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../Profile-verification/BackgroundCheck";
import { PlusSvg, LessSvg } from "../../../assets/svgs";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import data from "../../Profile-verification/state.json";
import styled from "styled-components";
import moment from "moment";
import { applicationProcess } from "../../../store/reducers/user.reducer";
import {
  dateConverter,
  formatedValue,
  formatPhoneNumber,
  formattedNumber,
  toUSCurrency,
} from "../../../utils/fn";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FaEdit, FaTrash } from "react-icons/fa";

function AddReference({
  onClickBack,
  setrentaldata,
  checkFilledData,
  setAppPayloadData,
  checkFillDataFunc,
  activeTab,
}) {
  const { Option } = Select;
  const monthFormat = "MM/DD/YYYY";
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);

  const [stateValue, setstateValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const [citesValue, setCitesValue] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditIndex, setIsEditIndex] = useState("");
  const [editValue, setEditValue] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment().add(0, "days"));
  const [oldReference, setOldReference] = useState([]);
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [openIndexs, setOpenIndexs] = useState([]);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  // default tab based on filled data
  useEffect(() => {
    if (applicationProcessData?.rentalHistory?.references?.length) {
      setNewSetalFields(applicationProcessData?.rentalHistory?.references);
    }
  }, [applicationProcessData]);

  // on click submit
  const onFinish = (value) => {
    setIsSubmit(true);
    value.currentRent = formatedValue(value.currentRent);
    if (isEdit) {
      value.id = editValue.id;
      let editObj = [...newSetalFields];
      let changedObj = editObj[isEditIndex];
      changedObj = value;
      editObj[isEditIndex] = changedObj;
      setNewSetalFields(editObj);
      setNewSetalFields((val) => [...val]);
      setIsEdit(false);
      let data = {};
      data.rentalHistory = {};
      data.rentalHistory.isPreviousRented = true;
      data.rentalHistory.references = editObj;
      if (sessionStorage.getItem("applyingProperty")) {
        const unitDetails = JSON.parse(
          sessionStorage.getItem("applyingProperty")
        );
        data.unit_id = unitDetails?.id;
      }

      dispatch(applicationProcess(data)).then(({ payload }) => {
        setIsSubmit(false);
        setrentaldata(payload);
        setAppPayloadData(payload);
        checkFillDataFunc(payload);
      });
    } else if (isNewAdd) {
      setIsSubmit(false);
      let data = {};
      data.rentalHistory = {};
      data.rentalHistory.isPreviousRented = true;
      data.rentalHistory.references = [...newSetalFields, value];
      if (sessionStorage.getItem("applyingProperty")) {
        const unitDetails = JSON.parse(
          sessionStorage.getItem("applyingProperty")
        );
        data.unit_id = unitDetails?.id;
      }
      dispatch(applicationProcess(data)).then(({ payload }) => {
        setNewSetalFields(payload?.rental_history?.references);
        checkFillDataFunc(payload);
        setIsSubmit(false);
        setrentaldata(payload);
        setAppPayloadData(payload);
      });
    } else {
      checkFilledData();
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    if (newSetalFields?.length) {
      setIsNewAdd(false);
    } else {
      setIsNewAdd(true);
    }
  }, [newSetalFields, activeTab]);

  const addChanges = () => {
    form.resetFields();
    setIsNewAdd(true);
  };

  // filter data
  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  // form data
  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  // set city data
  useEffect(() => {
    if (stateValue) {
      setCitesValue(data[stateValue].sort());
    }
  }, [stateValue]);

  const onCancel = () => {
    form.resetFields();
    setIsNewAdd(false);
    setIsEdit(false);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onDelete = (e, item, idx) => {
    e.stopPropagation();
    const newDeletedVal = newSetalFields?.filter((_, index) => index !== idx);
    setNewSetalFields(newDeletedVal);
    let data = {};
    data.rentalHistory = {};
    data.rentalHistory.isPreviousRented = true;
    data.rentalHistory.deleted_ids = [item.id];
    data.rentalHistory.references = newDeletedVal;
    dispatch(applicationProcess(data));
  };

  const isEditable = (item, idx) => {
    setIsEdit(true);
    setIsEditIndex(idx);
    addChanges();
    setEditValue(item);
    form.setFieldsValue({
      propertyType: item.propertyType,
      propertyName: item.propertyName,
      address: item.address,
      state: item.state,
      city: item.city,
      zipcode: item.zipcode,
      currentRent: item.currentRent,
      managerName: item.managerName,
      phone: item.phone,
      note: item.note,
      ...(item.dateMoveOut ? { dateMoveOut: moment(item.dateMoveOut) } : {}),
      ...(item.dateMovedIn ? { dateMovedIn: moment(item.dateMovedIn) } : {}),
    });
  };

  // disabled date
  const disabledDate = (current) => {
    // Can not select days before today and today
    if (selectedDate) {
      return current && current < moment(selectedDate);
    }
  };

  return (
    <GeneralProfileWrap>
      <h1>Add Reference</h1>

      <InformationWrap>
        <FormWrap mt="0">
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                layout="vertical"
                name="dynamic_form_nest_item"
                validateMessages={validateMessages}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                {newSetalFields?.map((item, idx) => (
                  <>
                    {/* {oldReference?.length ? ( */}
                    <ViewAllRental
                      mb={openIndexs?.includes(idx) ? "0" : "25px"}
                      key={idx}
                    >
                      <AddedReference
                        bb={
                          openIndexs?.includes(idx)
                            ? "none"
                            : "1px solid #dddddd"
                        }
                        onClick={() => openIndexFun(idx)}
                      >
                        <Row
                          justify="space-between"
                          style={{ alignItems: "center" }}
                        >
                          <Col>
                            <span>{item.propertyType}</span>
                            <h5>{item.propertyName}</h5>
                          </Col>
                          <Col>
                            <FaEdit
                              onClick={(e) => {
                                e.stopPropagation();
                                isEditable(item, idx);
                              }}
                              className="icon_id"
                            />
                            <Popconfirm
                              key={"del-c"}
                              title="Are you sure?"
                              onConfirm={(e) => onDelete(e, item, idx)}
                              onCancel={(e) => {
                                e.stopPropagation();
                              }}
                              okText="Yes"
                              cancelText="No"
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: "red" }}
                                />
                              }
                            >
                              <FaTrash
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className="icon_id"
                              />
                            </Popconfirm>

                            {openIndexs?.includes(idx) ? (
                              <>
                                <IoIosArrowUp
                                  style={{ marginBottom: "0" }}
                                  className="less__svg updown__arrow"
                                />
                              </>
                            ) : (
                              <>
                                <IoIosArrowDown className="updown__arrow" />
                              </>
                            )}
                          </Col>
                        </Row>
                      </AddedReference>
                    </ViewAllRental>

                    {openIndexs?.includes(idx) ? (
                      <ViewAddedReference>
                        <Row justify="space-between" gutter={[15, 15]}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <h2>Address</h2>
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Address</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item.address}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>City</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item.city}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>State</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item.state}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Zipcode</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {item?.zipcode ? item.zipcode : `${" "}`}
                                  </p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Move out date</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {item?.dateMoveOut
                                      ? dateConverter(item.dateMoveOut)
                                      : `${" "}`}
                                  </p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <h2>Contact Details</h2>
                            <Row justify="space-between" gutter={[2, 2]}>
                              <Col span={8}>
                                <DetailsBox>
                                  <p>Rent</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{toUSCurrency(item.currentRent)}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Manager’s name</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item.managerName}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Phone Number</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{formatPhoneNumber(item.phone)}</p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Move in date</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>
                                    {item?.dateMovedIn
                                      ? dateConverter(item.dateMovedIn)
                                      : `${" "}`}
                                  </p>
                                </DetailsBox>
                              </Col>

                              <Col span={8}>
                                <DetailsBox>
                                  <p>Note</p>
                                </DetailsBox>
                              </Col>
                              <Col span={16}>
                                <DetailsBox color="#000000">
                                  <p>{item.note}</p>
                                </DetailsBox>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ViewAddedReference>
                    ) : (
                      ""
                    )}
                  </>
                ))}
                {isNewAdd ? (
                  <>
                    <StyledFormItem
                      style={{ marginTop: "25px" }}
                      wh="49"
                      fd={"column"}
                      smwh={"100"}
                    >
                      <Form.Item
                        name="propertyType"
                        label="Property Type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select suffixIcon={<IoIosArrowDown />}>
                          <Option value="Single Family Home">
                            Single-Family Home
                          </Option>
                          <Option value="Condo">Condo(Condominium)</Option>
                          <Option value="Townhouse">Townhouse</Option>
                          <Option value="Apartment">Apartment</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="propertyName"
                        label="Property Name"
                        rules={[
                          {
                            // required: true,
                            whitespace: true,
                            // message: "Property Name is required",
                          },
                        ]}
                      >
                        <Input
                        //   defaultValue={item.propertyName}
                        //   onChange={(e) => {
                        //     onNameOfComplexChange(
                        //       e.target.value,
                        //       "propertyName",
                        //       idx
                        //     );
                        //   }}
                        // placeholder="Phone Number of Complex"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                          {
                            // required: true,
                            whitespace: true,
                            // message: "Address is required",
                          },
                        ]}
                      >
                        <Input
                        //   onChange={(e) => {
                        //     onNameOfComplexChange(e.target.value, "address", idx);
                        //   }}
                        //   defaultValue={item.address}
                        // placeholder="Address Line 1"
                        />
                      </Form.Item>
                      <Form.Item
                        name="state"
                        label="State"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <Select
                          showSearch
                          // placeholder="State"
                          onChange={(value) => {
                            onChange(value);
                            // onNameOfComplexChange(value, "state", idx);
                          }}
                          suffixIcon={<IoIosArrowDown />}
                          //   defaultValue={item.state}
                        >
                          {states.length &&
                            states.map((state, idx) => (
                              <Option key={idx} value={state}>
                                {state}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        name="city"
                        label="City"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <Select
                          //   onChange={(value) => {
                          //     onNameOfComplexChange(value, "city", idx);
                          //   }}
                          showSearch
                          // placeholder="City"
                          // onChange={}
                          suffixIcon={<IoIosArrowDown />}
                          //   defaultValue={item.city}
                        >
                          {citesValue
                            ? citesValue.map((cites, idx) => (
                                <Option key={idx} value={cites}>
                                  {cites}
                                </Option>
                              ))
                            : ""}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="zipcode"
                        label="Zip code"
                        rules={[
                          // {
                          //   required: true,
                          // },
                          { max: 6, message: "Invalid Zip code!" },
                        ]}
                      >
                        <Input
                          //   defaultValue={item.zipcode}
                          //   onChange={(e) => {
                          //     onNameOfComplexChange(e.target.value, "zipcode", idx);
                          //   }}
                          type="number"
                          pattern="[0-9]*"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          // placeholder="Zip code"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        className="input__affix"
                        name="currentRent"
                        label="Rent"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <Input
                          type="number"
                          step="0.01"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          prefix={`$`}
                          pattern="[0-9]*"
                        />
                      </Form.Item>
                      <Form.Item
                        name="managerName"
                        label="Manager’s name"
                        rules={[
                          {
                            // required: true,
                            whitespace: true,
                            // message: "Manager’s name is required",
                          },
                        ]}
                      >
                        <Input
                        //   onChange={(e) => {
                        //     onNameOfComplexChange(
                        //       e.target.value,
                        //       "managerName",
                        //       idx
                        //     );
                        //   }}
                        //   defaultValue={item.address}
                        // placeholder="Address Line 1"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        className="input__affix"
                        name="phone"
                        label="Phone Number"
                        rules={[
                          // {
                          //   required: true,
                          // },
                          { max: 10, message: "Invalid Phone Number!" },
                          { min: 10, message: "Invalid Phone Number!" },
                        ]}
                      >
                        <Input
                          pattern="[0-9]*"
                          type="number"
                          min="0"
                          onWheel={(e) => e.target.blur()}
                          // prefix={`+1`}
                          onKeyDown={(e) =>
                            e.key === "." ? e.preventDefault() : ""
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="dateMovedIn"
                        label="Move in date"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <DatePicker
                          className="date__picker"
                          placeholder=""
                          format={monthFormat}
                          onChange={(date) => {
                            setSelectedDate(date);
                            form.setFieldsValue({ dateMoveOut: "" });
                          }}
                          disabledDate={(current) => {
                            return moment().add(-1, "days") <= current;
                          }}
                          allowClear={false}
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        name="dateMoveOut"
                        label="Move out date"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <DatePicker
                          className="date__picker"
                          placeholder=""
                          format={monthFormat}
                          disabledDate={disabledDate}
                          onChange={(date) => setSelectedDate(date)}
                          // picker="day"
                          // disabledDate={(current) => {
                          //   return moment().add(-1, "days") <= current;
                          // }}
                          allowClear={false}
                        />
                      </Form.Item>

                      <Form.Item
                        className="input__affix"
                        name="note"
                        label="Note"
                        rules={
                          [
                            // {
                            //   required: true,
                            // },
                          ]
                        }
                      >
                        <Input.TextArea className="description__box" />
                      </Form.Item>
                    </StyledFormItem>
                  </>
                ) : (
                  <Form.Item style={{ marginTop: "25px" }}>
                    <Row justify="end">
                      {/* <Col>
                        <h5>
                          <Checkbox>
                            Terms and Condition | Permission to connect
                          </Checkbox>
                        </h5>
                      </Col> */}
                      <Col>
                        <Row
                          className="icon__text"
                          block="true"
                          // onClick={() => setIsAdd(!isAdd)}
                          onClick={() => addChanges()}
                        >
                          <Col>
                            <PlusSvg />
                          </Col>
                          <Col>
                            <p>Add Another Reference</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                )}

                <Form.Item>
                  <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col
                      xs={10}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="back__btn"
                    >
                      <IoIosArrowBack
                        onClick={() => {
                          onClickBack();
                        }}
                      />
                      <h2
                        onClick={() => {
                          onClickBack();
                        }}
                      >
                        back
                      </h2>
                    </Col>
                    <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Row justify="end" style={{ alignItems: "center" }}>
                        {isNewAdd && newSetalFields?.length ? (
                          <Col>
                            <p className="cancel__btn" onClick={onCancel}>
                              Cancel
                            </p>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col>
                          <StyledButton
                            mt="0"
                            loading={isSubmit}
                            htmlType="submit"
                          >
                            {isEdit
                              ? "Update"
                              : isNewAdd
                              ? "Add"
                              : "Review Details"}
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </FormWrap>
      </InformationWrap>
    </GeneralProfileWrap>
  );
}

export default AddReference;

export const AddedReference = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-bottom: ${({ bb }) => bb || "1px solid #dddddd"};
  padding: 20px;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    background: #4160ac;
    padding: 2px 5px;
  }

  .prop_name {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;
  }

  .icon_id {
    color: #4160ac;
    margin-right: 10px;
  }
  .updown__arrow {
    color: #4160ac;
    font-size: 22px;
  }

  h3 {
    color: #2b353f;
    font-size: 18px;
    font-weight: 4000;
    line-height: 22px;
    margin-bottom: 0;
  }

  .instant__title {
    margin: 0;
  }

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

export const ViewAddedReference = styled.div`
  background: #fafafa;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 20px;
  margin-bottom: 25px;
  margin-left: ${({ ml }) => ml || "0"};

  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    ${"" /* text-transform: uppercase; */}
    color: #777777;
    margin-bottom: 5px;
  }
`;

export const DetailsBox = styled.div`
  background: #ffffff;
  padding: 10px 12px;
  min-height: 37px;
  ${"" /* max-height: 37px; */}
  overflow: auto;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color || "#777777"};
    margin-bottom: 0;
  }
`;
