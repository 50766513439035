import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import AboutUs from "../../pages/about-us/AboutUs";
import Applications from "../../pages/applications/Applications";
import CategoryOfProperty from "../../pages/category-of-property/CategoryOfProperty";
import Dashboard from "../../pages/dashboard/Dashboard";
import Message from "../../pages/message/Message";
import MyAccount from "../../pages/my-account/MyAccount";
import MyRentals from "../../pages/my-rentals/MyRentals";
import MyTransaction from "../../pages/MyTransaction/MyTransaction";
import PaymentConfirmation from "../../pages/payment-confirmation/PaymentConfirmation";
import Payment from "../../pages/payment/Payment";
import PaymentInvoice from "../../pages/payment/PaymentInvoice";
import PrivacyPolicy from "../../pages/privacy-policy/PrivacyPolicy";
// import ProfileVerification from "../../pages/Profile-verification/ProfileVerification";
import PropertyDetails from "../../pages/property-details/PropertyDetails";
import TermsConditions from "../../pages/terms-conditions/TermsConditions";
import UnitInvoice from "../../pages/unit-invoices/UnitInvoice";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import Favorite from "../../pages/favorite/Favorite";
import PageNotFound from "../../pages/page-not-found/PageNotFound";
import VerificationProcess from "../../pages/verification-process/VerificationProcess";
import UnitApplication from "../../pages/unit-application/UnitApplication";
import ApplicationInvitationListModal from "../invitationModal/ApplicationInvitationListModal";
import OwnersPage from "../layout/newOwnersPage/OwnersPage";
import OwnersContactUs from "../layout/newOwnersPage/ownersContactUsPage/OwnersContactUs";

function Content() {
  const location = useLocation();
  const { width } = useWindowSize();
  const params = useParams();

  const [headerHeight, setHeaderHeight] = useState("80px");
  const [currentPath, setCurrentPath] = useState("/");

  useEffect(() => {
    // header height accordingly screen size
    if (width < 1551) {
      setHeaderHeight("65px");
    } else {
      setHeaderHeight("80px");
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === `/signup/${params?.id}` ||
      location.pathname.includes("signup")
    ) {
      // redirect home page if user already login
      setCurrentPath("/");
    } else {
      // redirect login page if user not login
      setCurrentPath(location.pathname);
      setCurrentPath("/");
    }
  }, [location]);

  return (
    <div style={{ marginTop: headerHeight }}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/owners-page" element={<OwnersPage />} />
        <Route path="/owners-contact-us" element={<OwnersContactUs />} />
        {/* <Route exact={true} path="/" element={<Dashboard />} /> */}
        {/* <Route
          exact={true}
          path="/property-details/:id"
          // element={<PropertyDetails />}
          element={
            localStorage.getItem("idToken") &&
            localStorage.getItem("user_id") ? (
              <PropertyDetails />
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}
        <Route exact={true} path="/my-rentals" element={<MyRentals />} />
        <Route exact={true} path="/favorite" element={<Favorite />} />
        <Route exact={true} path="/message" element={<Message />} />
        <Route exact={true} path="/account" element={<MyAccount />} />
        <Route exact={true} path="/applications" element={<Applications />} />
        <Route exact={true} path="/search" element={<CategoryOfProperty />} />
        <Route exact={true} path="/payment/:id" element={<Payment />} />
        <Route
          exact={true}
          path="/payment-invoice/:id"
          element={<PaymentInvoice />}
        />
        <Route exact={true} path="/my-rentals/:id" element={<UnitInvoice />} />
        <Route
          exact={true}
          path="/payment-confirmation/:id"
          element={<PaymentConfirmation />}
        />
        <Route
          exact={true}
          path="/payment-confirmation"
          element={<PaymentConfirmation />}
        />
        <Route
          exact={true}
          path="/my-transaction"
          element={<MyTransaction />}
        />
        <Route
          exact={true}
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          exact={true}
          path="/terms-conditions"
          element={<TermsConditions />}
        />
        <Route exact={true} path="/about-us" element={<AboutUs />} />
        <Route
          exact={true}
          path="/application-process"
          element={<VerificationProcess />}
        />
        <Route
          exact={true}
          path="/verification-process"
          element={<VerificationProcess />}
        />
        <Route
          exact={true}
          path="/unit-application/:id"
          element={<UnitApplication />}
        />
        {/* <Route exact={true} path="/invitation-list" element={<ApplicationInvitationListModal />} /> */}
      </Routes>
    </div>
  );
}

export default Content;
