import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { IoIosArrowDown } from "react-icons/io";
import data from "../../../Profile-verification/state.json";
import { useSelector } from "react-redux";

function Emergency({ onYourSpouseNext }) {
  const [form] = Form.useForm();
  const [isSubmit, setIsSubmit] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);
  const [citesValue, setCitesValue] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const { Option } = Select;

  const onFinish = (value) => {
    onYourSpouseNext("emergency-contact");
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    if (stateValue) {
      setCitesValue(data[stateValue].sort());
    }
  }, [stateValue]);

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  return (
    <GeneralProfileWrap>
      <h1>Emergency Contact</h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="name"
                  label="Name"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="state"
                  label="State"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                    {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    // onChange={}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {citesValue
                      ? citesValue.map((cites) => (
                          <Option value={cites}>{cites}</Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="Zip code"
                  rules={[{ max: 6, message: "Invalid Zip code!" }]}
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min="0"
                    pattern="[0-9]*"
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="workPhone"
                  label="Work phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                  rules={[
                    { max: 10, message: "Invalid Phone Number!" },
                    { min: 10, message: "" },
                  ]}
                >
                  <Input type="number" min="0" pattern="[0-9]*" />
                </Form.Item>
                <Form.Item
                  name="homePhone"
                  label="Home phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                  rules={[
                    { max: 10, message: "Invalid Phone Number!" },
                    { min: 10, message: "" },
                  ]}
                >
                  <Input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min="0"
                    pattern="[0-9]*"
                  />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="relationship"
                  label="Relationship"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="end"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col>
                    <p className="cancel__btn">CANCEL</p>
                  </Col>
                  <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                    <StyledButton mt="0" loading={isSubmit} htmlType="submit">
                      next
                    </StyledButton>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default Emergency;
