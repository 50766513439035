import React from "react";

const footerSquare = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg width="192" height="223" viewBox="0 0 192 223" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M172.003 183.305C161.302 183.305 152.627 191.977 152.627 202.676C152.627 213.374 161.302 222.047 172.003 222.047C182.704 222.047 191.379 213.374 191.379 202.676C191.379 191.977 182.704 183.305 172.003 183.305Z" fill="#1F293F"/>
    <path d="M125.963 202.676C125.963 199.053 127.037 195.512 129.051 192.5C131.064 189.488 133.925 187.14 137.273 185.754C140.62 184.368 144.304 184.005 147.858 184.712C151.412 185.418 154.676 187.163 157.239 189.724C159.801 192.286 161.546 195.55 162.252 199.103C162.959 202.656 162.597 206.338 161.21 209.685C159.823 213.032 157.475 215.893 154.462 217.905C151.45 219.918 147.907 220.992 144.284 220.992C141.878 220.992 139.495 220.518 137.273 219.598C135.05 218.677 133.03 217.328 131.329 215.627C129.628 213.927 128.278 211.907 127.357 209.685C126.437 207.463 125.963 205.081 125.963 202.676Z" fill="#1F293F"/>
    <path d="M116.564 185.415C107.029 185.415 99.2986 193.143 99.2986 202.676C99.2986 212.209 107.029 219.937 116.564 219.937C126.1 219.937 133.83 212.209 133.83 202.676C133.83 193.143 126.1 185.415 116.564 185.415Z" fill="#1F293F"/>
    <path d="M72.6794 202.676C72.6794 199.471 73.6302 196.338 75.4114 193.672C77.1927 191.007 79.7245 188.93 82.6866 187.703C85.6486 186.477 88.9081 186.156 92.0526 186.781C95.1972 187.406 98.0855 188.95 100.353 191.216C102.62 193.483 104.164 196.371 104.789 199.515C105.415 202.658 105.093 205.917 103.866 208.878C102.64 211.84 100.562 214.371 97.896 216.152C95.2302 217.932 92.0962 218.883 88.89 218.883C84.5907 218.883 80.4675 217.175 77.4274 214.136C74.3874 211.097 72.6794 206.975 72.6794 202.676Z" fill="#1F293F"/>
    <path d="M61.1667 187.525C52.7965 187.525 46.0113 194.308 46.0113 202.676C46.0113 211.044 52.7965 217.828 61.1666 217.828C69.5368 217.828 76.3223 211.045 76.3223 202.676C76.3223 194.308 69.5368 187.525 61.1667 187.525Z" fill="#1F293F"/>
    <path d="M19.3961 202.677C19.3961 199.889 20.2231 197.163 21.7725 194.845C23.3218 192.527 25.5238 190.72 28.1003 189.653C30.6768 188.586 33.512 188.307 36.2472 188.851C38.9824 189.395 41.4948 190.737 43.4667 192.709C45.4387 194.68 46.7817 197.192 47.3257 199.926C47.8698 202.661 47.5906 205.495 46.5234 208.071C45.4561 210.647 43.6489 212.849 41.3301 214.398C39.0113 215.947 36.2852 216.773 33.4965 216.773C29.7568 216.773 26.1702 215.288 23.5259 212.645C20.8816 210.001 19.3961 206.415 19.3961 202.677Z" fill="#1F293F"/>
    <path d="M5.77512 189.684C-1.40306 189.684 -7.22193 195.501 -7.22193 202.678C-7.22193 209.854 -1.40306 215.672 5.77512 215.672C12.9533 215.672 18.7725 209.854 18.7725 202.678C18.7725 195.501 12.9533 189.684 5.77512 189.684Z" fill="#1F293F"/>
    <path d="M172.003 146.621C161.302 146.621 152.627 155.294 152.627 165.992C152.627 176.691 161.302 185.363 172.003 185.363C182.704 185.363 191.379 176.691 191.379 165.992C191.379 155.294 182.704 146.621 172.003 146.621Z" fill="#1F293F"/>
    <path d="M125.963 165.991C125.972 162.371 127.055 158.834 129.074 155.828C131.093 152.823 133.957 150.482 137.306 149.103C140.654 147.724 144.336 147.369 147.887 148.081C151.438 148.793 154.698 150.541 157.255 153.105C159.813 155.668 161.553 158.932 162.256 162.483C162.959 166.035 162.593 169.715 161.205 173.06C159.817 176.404 157.469 179.261 154.457 181.272C151.445 183.282 147.905 184.355 144.284 184.355C141.874 184.355 139.487 183.88 137.261 182.957C135.036 182.033 133.014 180.68 131.312 178.974C129.61 177.268 128.262 175.243 127.344 173.015C126.426 170.788 125.957 168.401 125.963 165.991Z" fill="#1F293F"/>
    <path d="M116.564 148.731C107.029 148.731 99.2986 156.459 99.2986 165.992C99.2986 175.526 107.029 183.254 116.564 183.254C126.1 183.254 133.83 175.526 133.83 165.992C133.83 156.459 126.1 148.731 116.564 148.731Z" fill="#1F293F"/>
    <path d="M72.6794 165.997C72.6794 162.791 73.6302 159.658 75.4114 156.993C77.1927 154.328 79.7245 152.25 82.6866 151.024C85.6486 149.797 88.9081 149.476 92.0526 150.101C95.1972 150.727 98.0855 152.27 100.353 154.537C102.62 156.803 104.164 159.691 104.789 162.835C105.415 165.979 105.093 169.237 103.866 172.199C102.64 175.16 100.562 177.691 97.896 179.472C95.2302 181.253 92.0962 182.203 88.89 182.203C84.5907 182.203 80.4675 180.496 77.4274 177.456C74.3874 174.417 72.6794 170.295 72.6794 165.997Z" fill="#1F293F"/>
    <path d="M61.1667 150.841C52.7965 150.841 46.0113 157.625 46.0113 165.993C46.0113 174.361 52.7965 181.145 61.1666 181.145C69.5368 181.145 76.3223 174.361 76.3223 165.993C76.3223 157.625 69.5368 150.841 61.1667 150.841Z" fill="#1F293F"/>
    <path d="M19.3961 165.993C19.3961 163.205 20.2231 160.479 21.7725 158.161C23.3218 155.843 25.5238 154.036 28.1003 152.969C30.6768 151.902 33.512 151.623 36.2472 152.167C38.9824 152.711 41.4948 154.053 43.4667 156.025C45.4387 157.996 46.7817 160.508 47.3257 163.243C47.8698 165.977 47.5906 168.812 46.5234 171.388C45.4561 173.963 43.6489 176.165 41.3301 177.714C39.0113 179.263 36.2852 180.09 33.4965 180.09C31.643 180.096 29.8066 179.736 28.093 179.03C26.3794 178.323 24.8225 177.285 23.5119 175.975C22.2013 174.665 21.1627 173.108 20.4564 171.395C19.75 169.682 19.3898 167.846 19.3961 165.993Z" fill="#1F293F"/>
    <path d="M5.77512 153C-1.40306 153 -7.22193 158.818 -7.22193 165.994C-7.22193 173.171 -1.40306 178.988 5.77512 178.988C12.9533 178.988 18.7725 173.171 18.7725 165.994C18.7725 158.818 12.9533 153 5.77512 153Z" fill="#1F293F"/>
    <path d="M172.003 109.988C161.302 109.988 152.627 118.661 152.627 129.359C152.627 140.058 161.302 148.73 172.003 148.73C182.704 148.73 191.379 140.058 191.379 129.359C191.379 118.661 182.704 109.988 172.003 109.988Z" fill="#1F293F"/>
    <path d="M125.963 129.363C125.963 125.741 127.037 122.199 129.051 119.187C131.064 116.175 133.925 113.828 137.273 112.441C140.62 111.055 144.304 110.692 147.858 111.399C151.412 112.106 154.676 113.85 157.239 116.412C159.801 118.973 161.546 122.237 162.252 125.79C162.959 129.343 162.597 133.026 161.21 136.373C159.823 139.72 157.475 142.58 154.462 144.593C151.45 146.605 147.907 147.68 144.284 147.68C141.878 147.68 139.495 147.206 137.273 146.285C135.05 145.365 133.03 144.016 131.329 142.315C129.628 140.614 128.278 138.595 127.357 136.373C126.437 134.15 125.963 131.769 125.963 129.363Z" fill="#1F293F"/>
    <path d="M116.564 112.098C107.029 112.098 99.2986 119.826 99.2986 129.36C99.2986 138.893 107.029 146.621 116.564 146.621C126.1 146.621 133.83 138.893 133.83 129.36C133.83 119.826 126.1 112.098 116.564 112.098Z" fill="#1F293F"/>
    <path d="M72.6794 129.364C72.6794 126.158 73.6302 123.025 75.4114 120.36C77.1927 117.695 79.7245 115.617 82.6866 114.391C85.6486 113.164 88.9081 112.843 92.0526 113.469C95.1972 114.094 98.0855 115.637 100.353 117.904C102.62 120.171 104.164 123.058 104.789 126.202C105.415 129.346 105.093 132.604 103.866 135.566C102.64 138.527 100.562 141.058 97.896 142.839C95.2302 144.62 92.0962 145.57 88.89 145.57C84.5907 145.57 80.4675 143.863 77.4274 140.823C74.3874 137.784 72.6794 133.662 72.6794 129.364Z" fill="#1F293F"/>
    <path d="M61.1667 114.208C52.7965 114.208 46.0113 120.992 46.0113 129.36C46.0113 137.728 52.7965 144.512 61.1666 144.512C69.5368 144.512 76.3223 137.728 76.3223 129.36C76.3223 120.992 69.5368 114.208 61.1667 114.208Z" fill="#1F293F"/>
    <path d="M19.3962 129.361C19.3867 126.571 20.2055 123.841 21.7491 121.517C23.2927 119.192 25.4914 117.378 28.067 116.303C30.6427 115.229 33.4793 114.943 36.2177 115.482C38.956 116.02 41.473 117.359 43.4498 119.328C45.4266 121.298 46.7743 123.81 47.3223 126.546C47.8702 129.281 47.5936 132.118 46.5278 134.697C45.462 137.275 43.6549 139.48 41.3351 141.031C39.0153 142.582 36.2873 143.41 33.4965 143.41C29.7652 143.41 26.1859 141.932 23.5429 139.298C20.9 136.665 19.4089 133.092 19.3962 129.361Z" fill="#1F293F"/>
    <path d="M5.77512 116.367C-1.40306 116.367 -7.22193 122.185 -7.22193 129.361C-7.22193 136.538 -1.40306 142.355 5.77512 142.355C12.9533 142.355 18.7725 136.538 18.7725 129.361C18.7725 122.185 12.9533 116.367 5.77512 116.367Z" fill="#1F293F"/>
    <path d="M172.003 73.3085C161.302 73.3085 152.627 81.9813 152.627 92.6797C152.627 103.378 161.302 112.051 172.003 112.051C182.704 112.051 191.379 103.378 191.379 92.6797C191.379 81.9813 182.704 73.3085 172.003 73.3085Z" fill="#1F293F"/>
    <path d="M125.963 92.6827C125.972 89.0621 127.055 85.5256 129.074 82.5198C131.093 79.514 133.957 77.1738 137.306 75.7948C140.654 74.4159 144.336 74.06 147.887 74.7722C151.438 75.4843 154.698 77.2325 157.255 79.796C159.813 82.3595 161.553 85.6232 162.256 89.1749C162.959 92.7266 162.593 96.407 161.205 99.751C159.817 103.095 157.469 105.953 154.457 107.963C151.445 109.974 147.905 111.047 144.284 111.047C141.874 111.047 139.487 110.572 137.261 109.648C135.036 108.725 133.014 107.371 131.312 105.665C129.61 103.959 128.262 101.935 127.344 99.7068C126.426 97.479 125.957 95.0921 125.963 92.6827Z" fill="#1F293F"/>
    <path d="M116.564 75.4186C107.029 75.4186 99.2986 83.1468 99.2986 92.68C99.2986 102.213 107.029 109.941 116.564 109.941C126.1 109.941 133.83 102.213 133.83 92.68C133.83 83.1468 126.1 75.4186 116.564 75.4186Z" fill="#1F293F"/>
    <path d="M72.6794 92.6802C72.6794 89.4749 73.6302 86.3414 75.4114 83.6763C77.1927 81.0111 79.7245 78.9339 82.6866 77.7072C85.6486 76.4806 88.9081 76.1597 92.0526 76.785C95.1972 77.4103 98.0855 78.9539 100.353 81.2204C102.62 83.4869 104.164 86.3747 104.789 89.5185C105.415 92.6622 105.093 95.9208 103.866 98.8821C102.64 101.843 100.562 104.375 97.896 106.155C95.2302 107.936 92.0962 108.887 88.89 108.887C84.5907 108.887 80.4675 107.179 77.4274 104.14C74.3874 101.101 72.6794 96.9785 72.6794 92.6802Z" fill="#1F293F"/>
    <path d="M61.1667 77.5287C52.7965 77.5287 46.0113 84.3123 46.0113 92.6804C46.0113 101.048 52.7965 107.832 61.1666 107.832C69.5368 107.832 76.3223 101.048 76.3223 92.6804C76.3223 84.3123 69.5368 77.5287 61.1667 77.5287Z" fill="#1F293F"/>
    <path d="M19.3961 92.6805C19.3961 89.8924 20.2231 87.1669 21.7725 84.8487C23.3218 82.5305 25.5238 80.7236 28.1003 79.6567C30.6768 78.5897 33.512 78.3106 36.2472 78.8545C38.9824 79.3984 41.4948 80.741 43.4667 82.7125C45.4387 84.684 46.7817 87.1958 47.3257 89.9303C47.8698 92.6648 47.5906 95.4992 46.5234 98.0751C45.4561 100.651 43.6489 102.853 41.3301 104.402C39.0113 105.951 36.2852 106.777 33.4965 106.777C31.643 106.784 29.8066 106.423 28.093 105.717C26.3794 105.011 24.8225 103.973 23.5119 102.663C22.2013 101.352 21.1627 99.7958 20.4564 98.0826C19.75 96.3694 19.3898 94.5335 19.3961 92.6805Z" fill="#1F293F"/>
    <path d="M-7.22466 92.6807C-7.21518 90.1128 -6.44497 87.6052 -5.01106 85.4747C-3.57716 83.3441 -1.544 81.6861 0.831759 80.71C3.20751 79.7338 5.81921 79.4833 8.33726 79.9901C10.8553 80.4969 13.1669 81.7382 14.9798 83.5574C16.7927 85.3765 18.0256 87.6919 18.5233 90.2112C19.0209 92.7305 18.7608 95.3407 17.7756 97.7122C16.7905 100.084 15.1247 102.11 12.9884 103.536C10.8521 104.962 8.34096 105.723 5.77239 105.723C4.06153 105.723 2.36759 105.385 0.787554 104.729C-0.792477 104.073 -2.22749 103.111 -3.43502 101.9C-4.64256 100.688 -5.59895 99.25 -6.24929 97.6679C-6.89964 96.0859 -7.23097 94.3911 -7.22466 92.6807Z" fill="#1F293F"/>
    <path d="M152.582 56.0496C152.582 52.2666 153.702 48.568 155.799 45.4194C157.897 42.2708 160.879 39.8126 164.37 38.3542C167.862 36.8958 171.707 36.5023 175.421 37.2231C179.136 37.9439 182.555 39.7469 185.247 42.4053C187.939 45.0636 189.785 48.4586 190.552 52.1631C191.319 55.8676 190.973 59.7161 189.558 63.2246C188.142 66.7331 185.721 69.7447 182.598 71.8807C179.475 74.0167 175.79 75.1817 172.006 75.229C169.466 75.2607 166.946 74.788 164.591 73.8384C162.235 72.8887 160.092 71.4811 158.285 69.6969C156.478 67.9128 155.044 65.7877 154.065 63.445C153.086 61.1023 152.582 58.5886 152.582 56.0496Z" fill="#1F293F"/>
    <path d="M125.963 56.0509C125.963 52.4283 127.037 48.887 129.051 45.8749C131.064 42.8628 133.925 40.5152 137.273 39.1289C140.62 37.7426 144.304 37.3798 147.858 38.0865C151.412 38.7933 154.676 40.5377 157.239 43.0993C159.801 45.6609 161.546 48.9246 162.252 52.4776C162.959 56.0306 162.597 59.7134 161.21 63.0602C159.823 66.4071 157.475 69.2677 154.462 71.2803C151.45 73.293 147.907 74.3672 144.284 74.3672C139.429 74.3545 134.776 72.4207 131.343 68.9885C127.91 65.5563 125.975 60.9048 125.963 56.0509Z" fill="#1F293F"/>
    <path d="M99.2965 56.0471C99.287 52.6196 100.296 49.2664 102.194 46.4125C104.093 43.5587 106.797 41.3327 109.962 40.0167C113.128 38.7006 116.613 38.3538 119.976 39.0201C123.339 39.6865 126.429 41.336 128.853 43.7596C131.277 46.1833 132.927 49.2719 133.593 52.6341C134.26 55.9962 133.913 59.4805 132.597 62.6453C131.28 65.8101 129.054 68.5131 126.199 70.4114C123.345 72.3097 119.991 73.318 116.562 73.3085C111.983 73.3085 107.592 71.4899 104.354 68.2528C101.116 65.0156 99.2965 60.6252 99.2965 56.0471Z" fill="#1F293F"/>
    <path d="M72.6794 56.0513C72.6794 52.846 73.6302 49.7125 75.4114 47.0474C77.1927 44.3822 79.7245 42.305 82.6866 41.0783C85.6486 39.8517 88.9081 39.5308 92.0526 40.1561C95.1972 40.7814 98.0855 42.325 100.353 44.5915C102.62 46.858 104.164 49.7458 104.789 52.8895C105.415 56.0333 105.093 59.2919 103.866 62.2532C102.64 65.2146 100.562 67.7457 97.896 69.5265C95.2302 71.3073 92.0962 72.2578 88.89 72.2578C84.5907 72.2578 80.4675 70.5504 77.4274 67.511C74.3874 64.4717 72.6794 60.3495 72.6794 56.0513Z" fill="#1F293F"/>
    <path d="M46.0114 56.0486C46.0019 53.0499 46.8828 50.1157 48.5423 47.6177C50.2018 45.1197 52.5654 43.1703 55.3339 42.0161C58.1023 40.862 61.151 40.5551 64.094 41.1343C67.037 41.7135 69.7418 43.1527 71.8662 45.2698C73.9905 47.3869 75.4389 50.0866 76.0276 53.0271C76.6162 55.9675 76.3188 59.0164 75.1731 61.7878C74.0274 64.5592 72.085 66.9284 69.5917 68.5954C67.0984 70.2624 64.1662 71.1523 61.1667 71.1523C57.1555 71.1524 53.3081 69.5626 50.4673 66.7314C47.6265 63.9003 46.0241 60.0588 46.0114 56.0486Z" fill="#1F293F"/>
    <path d="M19.3962 56.0488C19.3867 53.2587 20.2055 50.5285 21.7491 48.204C23.2927 45.8795 25.4914 44.0652 28.067 42.9909C30.6427 41.9166 33.4793 41.6305 36.2177 42.169C38.956 42.7075 41.473 44.0464 43.4498 46.0159C45.4266 47.9855 46.7743 50.4972 47.3223 53.233C47.8702 55.9688 47.5936 58.8057 46.5278 61.3843C45.462 63.963 43.6549 66.1674 41.3351 67.7185C39.0153 69.2696 36.2873 70.0976 33.4965 70.0977C29.7652 70.0977 26.1859 68.619 23.5429 65.9857C20.9 63.3524 19.4089 59.7792 19.3962 56.0488Z" fill="#1F293F"/>
    <path d="M-7.22196 56.049C-7.22196 53.479 -6.45993 50.9667 -5.03178 48.8299C-3.60362 46.693 -1.57355 45.0275 0.801382 44.044C3.17632 43.0606 5.78948 42.8033 8.31069 43.3047C10.8319 43.806 13.1478 45.0436 14.9655 46.8608C16.7832 48.6781 18.0212 50.9934 18.5227 53.514C19.0242 56.0345 18.7668 58.6472 17.783 61.0215C16.7993 63.3959 15.1335 65.4253 12.9961 66.8531C10.8587 68.2809 8.34571 69.043 5.77509 69.043C4.06827 69.043 2.37828 68.7069 0.80138 68.0539C-0.775516 67.4009 -2.20844 66.4438 -3.41534 65.2372C-4.62225 64.0305 -5.57966 62.5981 -6.23284 61.0215C-6.88601 59.445 -7.22196 57.7554 -7.22196 56.049Z" fill="#1F293F"/>
    <path d="M172.003 -0.000123764C161.302 -0.0001247 152.627 8.6726 152.627 19.371C152.627 30.0694 161.302 38.7422 172.003 38.7422C182.704 38.7422 191.379 30.0694 191.379 19.371C191.379 8.6726 182.704 -0.000122829 172.003 -0.000123764Z" fill="#1F293F"/>
    <path d="M125.963 19.3673C125.963 15.7447 127.037 12.2034 129.051 9.19133C131.064 6.17924 133.925 3.83154 137.273 2.44523C140.62 1.05891 144.304 0.696204 147.858 1.40294C151.412 2.10968 154.676 3.85416 157.239 6.41574C159.801 8.97731 161.546 12.241 162.252 15.794C162.959 19.347 162.597 23.0298 161.21 26.3766C159.823 29.7235 157.475 32.5841 154.462 34.5968C151.45 36.6094 147.907 37.6836 144.284 37.6836C141.878 37.6836 139.495 37.2098 137.273 36.2893C135.05 35.3688 133.03 34.0197 131.329 32.3189C129.628 30.6181 128.278 28.5989 127.357 26.3766C126.437 24.1544 125.963 21.7726 125.963 19.3673Z" fill="#1F293F"/>
    <path d="M116.564 2.10998C107.029 2.10998 99.2986 9.83814 99.2986 19.3714C99.2986 28.9046 107.029 36.6328 116.564 36.6328C126.1 36.6328 133.83 28.9046 133.83 19.3714C133.83 9.83814 126.1 2.10998 116.564 2.10998Z" fill="#1F293F"/>
    <path d="M72.6794 19.3676C72.6794 16.1623 73.6302 13.0289 75.4114 10.3638C77.1927 7.69863 79.7245 5.62138 82.6866 4.39474C85.6486 3.1681 88.9081 2.84718 92.0526 3.47252C95.1972 4.09785 98.0855 5.64138 100.353 7.9079C102.62 10.1744 104.164 13.0621 104.789 16.2059C105.415 19.3496 105.093 22.6083 103.866 25.5696C102.64 28.531 100.562 31.0621 97.896 32.8429C95.2302 34.6237 92.0962 35.5742 88.89 35.5742C84.5907 35.5742 80.4675 33.8668 77.4274 30.8274C74.3874 27.7881 72.6794 23.6659 72.6794 19.3676Z" fill="#1F293F"/>
    <path d="M61.1667 4.21611C52.7965 4.21611 46.0113 10.9998 46.0113 19.3678C46.0113 27.7359 52.7965 34.5195 61.1666 34.5195C69.5368 34.5195 76.3223 27.7359 76.3223 19.3678C76.3223 10.9998 69.5368 4.21611 61.1667 4.21611Z" fill="#1F293F"/>
    <path d="M19.3961 19.3679C19.3961 16.5798 20.2231 13.8544 21.7725 11.5361C23.3218 9.21793 25.5238 7.41113 28.1003 6.34417C30.6768 5.27722 33.512 4.99808 36.2472 5.54201C38.9824 6.08594 41.4948 7.42851 43.4667 9.39999C45.4387 11.3715 46.7817 13.8833 47.3257 16.6178C47.8698 19.3523 47.5906 22.1867 46.5234 24.7626C45.4561 27.3384 43.6489 29.54 41.3301 31.089C39.0113 32.638 36.2852 33.4648 33.4965 33.4648C31.643 33.4711 29.8066 33.1108 28.093 32.4046C26.3794 31.6984 24.8225 30.6603 23.5119 29.35C22.2013 28.0398 21.1627 26.4832 20.4564 24.77C19.75 23.0569 19.3898 21.2209 19.3961 19.3679Z" fill="#1F293F"/>
    <path d="M5.77512 6.37526C-1.40306 6.37526 -7.22193 12.1928 -7.22193 19.3692C-7.22193 26.5456 -1.40306 32.3633 5.77512 32.3633C12.9533 32.3633 18.7725 26.5456 18.7725 19.3692C18.7725 12.1928 12.9533 6.37526 5.77512 6.37526Z" fill="#1F293F"/>
    </svg>
    
  );
};

export default footerSquare;
