import React, { useEffect, useState } from "react";
import { Form, message, Select, Input, Row, Col } from "antd";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineUpload } from "react-icons/ai";
import { StyledButton } from "../property-details/PropertyDetails";
import { ImagePreview } from "../../components/ImagePreview";
import data from "./state.json";
import { useDispatch, useSelector } from "react-redux";
import { application, me } from "../../store/reducers/user.reducer";
import { imagePath } from "../../utils/fn";

function BackgroundCheck({ onSubmit = console.log() }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const drop = React.useRef(null);
  const upload = React.useRef(null);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    if (stateValue) {
      setCitesValue(data[stateValue].sort());
    }
  }, [stateValue]);

  useEffect(() => {
    if (user?.backgroundProfile) {
      setState([user?.backgroundProfile?.proofImg]);
      form.setFieldsValue({
        zipcode: user?.backgroundProfile?.zipcode,
        state: user?.backgroundProfile?.state,
        identification: user?.backgroundProfile?.identification,
        city: user?.backgroundProfile?.city,
        addressLink1: user?.backgroundProfile?.addressLink1,
        addressLine2: user?.backgroundProfile?.addressLine2,
      });
    }
  }, [user]);

  useEffect(() => {
    form.setFieldsValue({
      proofImg: state[0],
      // identification: "Driving License",
    });
  }, [form, state]);

  const onFinish = (value) => {
    const values = {};
    values.user_id = user?.id;
    values.backgroundProfile = value;
    setIsSubmit(true);
    dispatch(application(values)).then((res) => {
      dispatch(me());
      setIsSubmit(false);
      onSubmit();
    });
  };

  const fileChangeHandler = async (event) => {
    event.preventDefault();
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerDrag = (files) => {
    setState((s) => [
      ...s,
      ...Array.from(files).map((file) => ({
        image: file,
        path: URL.createObjectURL(file),
      })),
    ]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    // return () => {
    //   drop.current.removeEventListener('dragover', handleDragOver);
    //   drop.current.removeEventListener('drop', handleDrop);
    // };
  }, []);

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  return (
    <FormWrap>
      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          <Form
            layout="vertical"
            // requiredMark={"optional"}
            form={form}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <StyledFormSelect wh="49">
              <Form.Item name="identification" label="Form of Identification">
                <Select
                  placeholder="Select Apartment"
                  suffixIcon={<IoIosArrowDown />}
                  // defaultValue="Driving License"
                >
                  <Option value="Driving License">Driving License</Option>
                  <Option value="Passport">Passport</Option>
                  <Option value="Health Card">Health Card</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="proofImg"
                //   label="Image of Property"
                rules={[
                  {
                    required: true,
                    message: "Identification Picture is required.",
                  },
                ]}
              >
                <UploadBox ref={drop} onClick={(e) => upload.current.click()}>
                  <UploadInput>
                    <p className="ant-upload-drag-icon">
                      <AiOutlineUpload className="upload__icon" />
                    </p>
                    <p className="ant-upload-text" style={{ fontSize: "16px" }}>
                      Upload
                    </p>
                  </UploadInput>
                </UploadBox>
                <input
                  accept="image/*"
                  id="myInput"
                  multiple={false}
                  type="file"
                  ref={upload}
                  style={{ display: "none" }}
                  onChange={fileChangeHandler}
                />
                <StyledUploadPreviewWrapper isPhoto={state.length}>
                  {state.map((img, idx) => (
                    <ImagePreview
                      isCoverDisProp={false}
                      key={idx}
                      src={img.path ? img.path : imagePath(img.path)}
                      height={95}
                      width={95}
                      onDelete={() => {
                        const st = [...state];
                        st.splice(idx, 1);
                        setState(st);
                        // upload.current.value = null;
                      }}
                    />
                  ))}
                </StyledUploadPreviewWrapper>
              </Form.Item>
            </StyledFormSelect>

            <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
              <Form.Item
                name="addressLink1"
                label="Address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Address Line 1" />
              </Form.Item>
              <Form.Item
                name="addressLine2"
                label=" "
                //   rules={[
                //     {
                //       required: true,
                //     },
                //   ]}
              >
                <Input placeholder="Address Line 2" />
              </Form.Item>
            </StyledFormItem>

            <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="State"
                  onChange={onChange}
                  suffixIcon={<IoIosArrowDown />}
                >
                  {states.length &&
                    states.map((state) => (
                      <Option value={state}>{state}</Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="City"
                  // onChange={}
                  suffixIcon={<IoIosArrowDown />}
                >
                  {citesValue
                    ? citesValue.map((cites) => (
                        <Option value={cites}>{cites}</Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>
              <Form.Item
                name="zipcode"
                label="Zip code"
                rules={[
                  {
                    required: true,
                  },
                  { max: 6, message: "Invalid Zip code!" },
                ]}
              >
                <Input
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Zip code"
                  pattern="[0-9]*"
                />
              </Form.Item>
            </StyledFormItem>

            <Form.Item>
              <Row justify="end" style={{ alignItems: "center" }}>
                <Col>
                  <p className="cancel__btn">CANCEL</p>
                </Col>
                <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                  <StyledButton loading={isSubmit} htmlType="submit">
                    Submit
                  </StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </FormWrap>
  );
}

export default BackgroundCheck;

export const StyledUploadPreviewWrapper = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  flex-wrap: wrap;
  margin-top: 10px;

  .ant-image-mask {
    border-radius: 5px;
  }

  img {
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
  }

  @media (max-width: 575px) {
    .ant-image {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  }
`;

export const StyledFormItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .input__affix .ant-input-affix-wrapper {
    min-height: auto;
    background: #eeeeee;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 11px;
  }
  .input__affix input {
    border: none !important;
  }

  .input__affix
    .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #eeeeee;
  }

  .ant-input-prefix {
    margin-right: 4px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #eeeeee;
  }

  .input__affix
    .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #eeeeee;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #eeeeee;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background: #eeeeee;
  }

  .ant-picker-status-error.ant-picker {
    border-color: #ff4d4f;
    border: 1px solid #ff4d4f;

    .ant-form-item input {
      height: 50px;
    }
  }

  .date__picker {
    .ant-picker-input > input {
      height: 38px;
    }
  }

  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border: none;
  }
  .ant-input-group-addon:last-child {
    padding: 0;
    background: #eee;
    border: none;
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border-right-color: ${({ theme }) => theme.colors.third};
    border-radius: 3px;
    height: 40px;
    width: 40px;
    font-size: 18px;
    padding-top: 4px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: ${({ theme }) => theme.colors.third};
    border-color: ${({ theme }) => theme.colors.third};
  }
  .ant-radio-button-wrapper:last-child,
  .ant-radio-button-wrapper:first-child {
    border-radius: 3px;
    height: 40px;
    width: 40px;
    font-size: 18px;
    padding-top: 4px;
    margin-right: 5px;
    border: none;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: #eee;
  }
  .ant-radio-button-wrapper {
    padding: 0;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #000;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 50px;
  }
  .ant-form-item {
    width: ${({ wh }) => wh || "49"}%;

    .ant-form-item-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: ${({ ftColor }) => ftColor || "#232333"};
    }
    input {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
  .ant-form-item .ant-form-item-label {
    ${"" /* display: none; */}
  }

  .description__box {
    height: 80px;
    padding: 14px 20px;
  }

  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-form-item {
    width: ${({ wh }) => wh || "49"}%;
    .ant-form-item-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: ${({ ftColor }) => ftColor || "#232333"};
    }
    .ant-select-selector {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }
  .ant-picker {
    width: 100%;
    border: none;
    height: 50px;
    background: #eee;
    border-radius: 5px;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }
  .ant-picker-input > input::placeholder {
    color: #aaaaaa;
  }
  .ant-picker-input > input {
    border: none;
  }
  .ant-picker-status-error.ant-picker,
  .ant-picker-status-error.ant-picker:not([disabled]):hover {
    background: #eee;
  }

  @media screen and (max-width: 1550px) {
    flex-direction: ${({ flexDirection }) => flexDirection || "row"};
    .ant-form-item {
      width: ${({ flexDirectionSmwh }) => flexDirectionSmwh}%;
    }
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 40px;
    }

    .ant-form-item {
      input,
      .ant-picker {
        height: 40px;
      }
    }
    .description__box {
      height: 64px;

      padding: 12px 16px;
    }
    .ant-form-item {
      .ant-select-selector {
        height: 40px;
      }
    }
  }

  .ant-input-prefix {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 1280px) {
    .ant-input-prefix {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 624px) {
    flex-direction: ${({ fd }) => fd || "column"};
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48"}%;
    }
  }
`;

export const FormWrap = styled.div`
  width: 72%;
  padding-left: 50px;
  min-height: 558px;
  .cancel__btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: ${({ mt }) => mt || "10px"};
  }
`;

export const UploadInput = styled.div`
  display: flex;
  height: 100%;
  background: ${({ background }) => background || "#eeeeee"};
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  p,
  h6 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
  }
  .upload__icon {
    color: #777777;
  }
  .ant-upload-text {
    padding-left: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
  }
  .ant-upload-drag-icon {
    padding-right: 5px;
    padding-top: 2px;
  }
`;

export const UploadBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #777777;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
  ${"" /* height: 50px; */}
  height: ${({ height }) => height || "50px"};
  margin-top: ${({ mt }) => mt || "33px"};

  @media (max-width: 1550px) {
    height: ${({ heightSm }) => heightSm || "40px"};
  }
`;

export const StyledFormSelect = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }

  .description__box {
    padding: 14px 20px;
  }

  @media (max-width: 1550px) {
    .ant-form-item {
      .ant-select-selector {
        height: 40px;
        background: #eeeeee;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        align-items: center;
        @media screen and (max-width: 595px) {
          width: 100%;
        }
      }
      .ant-select-selection-placeholder {
        color: #aaaaaa;
      }
    }
    ${
      "" /* .ant-form-item .ant-form-item-label {
      display: none;
    } */
    }
    .description__box {
      padding: 14px 20px;
    }
  }
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  // width: 50%;
  width: ${({ wh }) => wh || "50"}%;
  max-width: 100%;
  margin: auto;
  // min-height: 100vh;

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  @media (max-width: 1199px) {
    width: ${({ smwh }) => smwh || "50"}%;
  }
  @media (max-width: 768px) {
    width: ${({ xswh }) => xswh || "50"}%;
  }
  @media (max-width: 576px) {
    width: ${({ xxswh }) => xxswh || "50"}%;
  }
`;

export const StyledForms = styled.div`
  position: relative;
  .ant-form-item {
    margin-bottom: 20px;
  }
  .or__option {
    align-items: center;
    margin: 18px 0;
    text-align: center;
    p {
      margin: 0 15px 0 15px;
      font-weight: 400;
      font-size: 18px;
      color: #aaa;
    }
    .ant-divider {
      border-top: 1px solid #eee;
    }
  }

  .demo_btn {
    display: flex;
    justify-content: end;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 1550px) {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .or__option {
      align-items: center;
      margin: 0px 0;
      p {
        margin: 0;
        font-size: 16px;
      }
      .ant-divider {
        border-top: 1px solid #eee;
      }
    }
  }
  @media screen and (max-width: 991.98px) {
    padding-top: 0px;
  }
  @media screen and (max-width: 350px) {
    .or__option {
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
`;
