import { Col, Modal, Row } from "antd";
import React from "react";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import { VerifyButton } from "../../../login/OtpComponent";
import { WaitingApprovalSvg } from "../../../../assets/svgs";

const UnderVerificationModal = ({ verificationModal, onCancel }) => {
  return (
    <>
      <Modal
        footer={null}
        visible={verificationModal}
        onCancel={() => {
          onCancel(false);
        }}
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.30)",
          backdropFilter: "blur(10px)",
        }}
        width={700}
      >
        <ModalWrap>
          <Row justify="center">
            <Col>
              <WaitingApprovalSvg />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h3>
                Your profile is complete, It is now being reviewed by our team.
                Once verified, you can see which properties you qualify for.
              </h3>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <VerifyButton onClick={() => onCancel(false)}>Okay</VerifyButton>
            </Col>
          </Row>
        </ModalWrap>
      </Modal>
    </>
  );
};

export default UnderVerificationModal;

const ModalWrap = styled.div`
  width: 100%;
  padding: 25px 25px;
  h3 {
    color: #2b353f;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-top: 20px;
  }
  @media (max-width: 576px) {
    h3 {
      font-size: 20px;
    }
  }
`;
