import React from "react";
import { StyledButton } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row } from "antd";
import styled from "styled-components";
import {
  HalfcircleTop,
  SeekerCircle,
  SeekerSquare,
} from "../../../assets/svgs";
import { H2Style } from "./MultiApplicationPart";

function PropertySeeker() {
  const url = window.location.host;
  const part = url.split(".");
  const subDomain = part[0];

  return (
    <MainContainer
      paddingbotom="50px"
      bgcolor="#FFF6F1"
      containterHeight="auto"
    >
      <TopTrendingSectionWrap
        margincomponentxs="0 auto 0"
        margincomponentsm="0 auto 50px"
        margincomponent="0 auto 100px"
      >
        {/* <TopTrendingSectionWrap margincomponent="0 auto 0"> */}
        <PropertySeekerWrap>
          <Row
            gutter={[20, 20]}
            style={{ alignItems: "center" }}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row>
                <Col span={24}>
                  <Rectangle className="divider__long__sm" />
                </Col>
                <Col span={24}>
                  <H2Style
                    lineheightxs="32px"
                    lineHeight="50px"
                    marginBottom="20px"
                    marginTop="15px"
                    paddingBottomxs="15px"
                    paddingtopxs="12px"
                  >
                    Are you a Property Seeker?
                  </H2Style>
                </Col>
                <Col className="find__pro__btn">
                  <StyledButton
                    btnwidth="203px"
                    btnwidthsm="170px"
                    style={{ marginLeft: "0px", color: "#ffffff" }}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Find property
                  </StyledButton>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <img src="/images/property-seeker.png" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row>
                <Col span={24}>
                  <Rectangle className="divider__long__sm" />
                </Col>
                <Col span={24}>
                  <H2Style
                    lineheightxs="32px"
                    paddingBottomxs="15px"
                    paddingtopxs="12px"
                    marginBottom="20px"
                    marginTop="15px"
                  >
                    Are you a Property Owner?
                  </H2Style>
                </Col>
                <Col className="find__pro__btn">
                  <a
                    href={`https://${subDomain}.admin.avenew.app/login`}
                    target="_blank"
                  >
                    <StyledButton
                      btnwidth="203px"
                      btnwidthsm="170px"
                      style={{ marginLeft: "0px", color: "#ffffff" }}
                    >
                      Get listed
                    </StyledButton>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="circle__top__svg">
            <HalfcircleTop />
          </div>
        </PropertySeekerWrap>
        {/* </TopTrendingSectionWrap> */}
      </TopTrendingSectionWrap>

      <div className="seeker__square">
        <SeekerSquare />
      </div>
      <div className="seeker__circle">
        <SeekerCircle />
      </div>
    </MainContainer>
  );
}

export default PropertySeeker;

const MainContainer = styled.div`
  padding-top: 1px;
  width: 100%;
  position: relative;
  background-color: ${({ bgcolor }) => bgcolor || "#ecf2ff"};
  height: ${({ containterHeight }) =>
    containterHeight || "calc(100vh - 110px)"};

  .footer__left__square {
    position: absolute;
    top: 50%;
    left: 0;
  }
  .footer__bottom__square {
    position: absolute;
    bottom: 65px;
    right: 0;
  }
  .footer__top__square {
    position: absolute;
    top: 20%;
    right: 3%;
  }
  .footer__circle {
    position: absolute;
    top: 0;
    right: 50%;
  }
  .circle__svg svg {
    position: absolute;
    top: 100px;
  }
  .dream__square {
    position: absolute;
    bottom: 30px;
    left: 70px;
  }
  .seeker__square {
    position: absolute;
    bottom: 100px;
    left: 30px;
  }
  .dream__circle {
    position: absolute;
    bottom: 10px;
    right: 0;
  }
  .seeker__circle {
    position: absolute;
    bottom: 10px;
    right: 0;
  }

  .halfCircle__svg {
    position: absolute;
    top: 60px;
    left: 80px;
  }
  .orangeline__svg {
    position: absolute;
    top: 60px;
    right: 80px;
  }

  .square__svg svg {
    position: absolute;
    bottom: 0;
    left: 80px;
    z-index: 1;
  }

  .mob__image {
    display: flex;
    margin: auto;
    @media (max-width: 991.98px) {
      padding: 30px 0;
    }
  }

  ${
    "" /* overflow: hidden;
  :after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 120%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: perspective(400px) rotateX(45deg);
    -moz-transform: perspective(400px) rotateX(45deg);
    -ms-transform: perspective(400px) rotateX(45deg);
    -o-transform: perspective(400px) rotateX(45deg);
    transform: perspective(100px) rotateY(343deg) rotateX(271deg);
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background: #fff;
    border-radius: 0;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 0%;
    -webkit-transform: perspective(400px) rotateX(45deg);
    -moz-transform: perspective(400px) rotateX(45deg);
    -ms-transform: perspective(400px) rotateX(45deg);
    -o-transform: perspective(400px) rotateX(45deg);
    transform: perspective(15px) rotateY(9deg) rotateX(277deg);
  } */
  }

  @media (max-width: 1550px) {
    padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};

    .square__svg svg {
      bottom: -60px;
      left: 20px;
    }

    .orangeline__svg {
      top: 0;
      right: 20px;
    }

    .halfCircle__svg {
      top: 0;
      left: 20px;
    }
  }

  @media (max-width: 991.98px) {
    ${
      "" /* :after {
      top: 6px;
    }
    :before {
      transform: perspective(100px) rotateY(38deg) rotateX(277deg);
    } */
    }

    padding-bottom: ${({ paddingbotom }) => paddingbotom || 0};
    .square__svg svg {
      width: 90px;
    }

    .footer__circle {
      position: absolute;
      top: 150px;
      right: 60%;
    }

    .footer__top__square {
      position: absolute;
      top: 20px;
      right: 3%;
    }

    .seeker__square {
      position: absolute;
      bottom: auto;
      left: 30px;
      top: 150px;
    }

    .dream__circle {
      position: absolute;
      bottom: auto;
      top: 120px;
      right: 0;
    }

    .dream__circle svg {
      width: 117px;
      height: 116px;
    }

    .dream__square {
      position: absolute;
      bottom: auto;
      top: 50%;
      left: 25px;
    }

    .dream__square svg {
      width: 89px;
      height: 82px;
    }
  }

  @media (max-width: 425.98px) {
    :after {
      top: 3px;
    }

    :before {
      transform: perspective(100px) rotateY(71deg) rotateX(277deg);
    }
  }
`;

export const PropertySeekerWrap = styled.div`
  position: relative;
  z-index: 1;
  img {
    margin-top: -27px;
    width: 100%;
  }

  .circle__top__svg {
    position: absolute;
    top: 0;
    left: 50px;
  }
  @media (max-width: 991.98px) {
    text-align: center;

    img {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .find__pro__btn {
      margin: auto;
    }
    .circle__top__svg {
      position: absolute;
      right: 0;
      left: auto;
    }
  }
`;
