import { Col, Modal, Row, message } from "antd";
import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookShareCount,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
} from "react-share";
import styled from "styled-components";
import { CopySvg } from "../../assets/svgs";
import { StyledTitle } from "../../pages/property-details/tabs/floor-plan-modal/FloorPlanModal";

function ShareModal({ modalState, onCancel, selectedUnit }) {
  const { user } = useSelector((state) => state.user);

  const [modalWidth, setModalWidth] = useState(750);
  const shareUrl = "dev.avenew.app";
  const title = `I am ${user?.firstName}`;
  const copyLinkUrl = `http://dev.avenew.app/property-details/${selectedUnit?.id}`;

  const copyLink = () => {
    navigator.clipboard.writeText(copyLinkUrl);
    message.success("Link Copied");
  };

  return (
    <div>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          onCancel(false);
        }}
        width={modalWidth}
        footer={null}
        style={{ borderRadius: "5px", top: "50px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <StyledTitle>Share this place</StyledTitle>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <SharedBtn onClick={() => copyLink()}>
              <CopySvg className="copy__svg" />
              <h4>Copy Link</h4>
            </SharedBtn>
          </Col>
          <Col span={12}>
            <EmailShareButton
              style={{ width: "100%" }}
              title={title}
              url={copyLinkUrl}
              size={32}
              round={true}
            >
              <SharedBtn>
                <EmailIcon size={40} round={true} />
                <h4>Email</h4>
              </SharedBtn>
            </EmailShareButton>
          </Col>
          <Col span={12}>
            <WhatsappShareButton
              style={{ width: "100%" }}
              title={title}
              url={copyLinkUrl}
              size={32}
              round={true}
            >
              <SharedBtn>
                <WhatsappIcon size={40} round={true} />
                <h4>WhatsApp</h4>
              </SharedBtn>
            </WhatsappShareButton>
          </Col>
          <Col span={12}>
            <FacebookShareButton
              style={{ width: "100%" }}
              title={title}
              url={copyLinkUrl}
              size={32}
              round={true}
            >
              <SharedBtn>
                <FacebookIcon size={40} round={true} />
                <h4>Facebook</h4>
              </SharedBtn>
            </FacebookShareButton>
          </Col>
          <Col span={12}>
            <TwitterShareButton
              style={{ width: "100%" }}
              title={title}
              url={copyLinkUrl}
              size={32}
              round={true}
            >
              <SharedBtn>
                <TwitterIcon size={40} round={true} />
                <h4>Twitter</h4>
              </SharedBtn>
            </TwitterShareButton>
          </Col>
          <Col span={12}>
            <LinkedinShareButton
              style={{ width: "100%" }}
              title={title}
              url={copyLinkUrl}
              size={32}
              round={true}
            >
              <SharedBtn>
                <LinkedinIcon size={40} round={true} />
                <h4>Linkedin</h4>
              </SharedBtn>
            </LinkedinShareButton>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default ShareModal;

const SharedBtn = styled.div`
  width: 100% !important;
  height: 76px !important;
  outline: none !important;
  cursor: pointer !important;
  border: 1px solid rgb(221, 221, 221) !important;
  border-radius: 12px !important;
  box-sizing: border-box !important;
  background: rgb(255, 255, 255) !important;
  margin-bottom: 16px !important;
  align-items: center;
  display: flex;
  padding: 0 20px;

  :hover {
    background: rgb(247, 247, 247) !important;
  }

  .copy__svg {
    width: 38px;
    height: 38px;
  }

  h4 {
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    color: rgb(34, 34, 34) !important;
    text-overflow: ellipsis !important;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;
