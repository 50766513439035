import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import {
  FullAccess,
  KitchenSvg,
  LeafSvg,
  PoolSvg,
  TvSvg,
  WashingMachineSvg,
} from "../../../assets/svgs";
import { ApplyWrap, StyledButton } from "../PropertyDetails";
import ApplyForPropertyModal from "../apply-for-property-modal/ApplyForPropertyModal";
import { OverviewTab } from "./Overview";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {
  MdFitnessCenter,
  MdLocalDining,
  MdOutlineAcUnit,
  MdOutlineAirlineSeatIndividualSuite,
  MdOutlineBed,
  MdOutlineCable,
  MdOutlineOutdoorGrill,
  MdOutlinePets,
  MdOutlinePool,
  MdOutlinePower,
  MdOutlineRoofing,
  MdOutlineSignalWifiStatusbarConnectedNoInternet4,
  MdOutlineWaterDrop,
  MdOutlineYard,
} from "react-icons/md";
import { FaRestroom } from "react-icons/fa";
import { GiGate, GiWashingMachine } from "react-icons/gi";
import { RiFridgeLine } from "react-icons/ri";
import { useSelector } from "react-redux";

function Amenities({ ammenities, clickToApply, isBtnLoading, clickToMessage }) {
  const location = useLocation();
  const { width } = useWindowSize();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [showData, setShowData] = useState(4);

  const AmenitiesType = [
    "24 hour access",
    "Garden",
    "Pool",
    "Washing machine",
    "Kitchen",
    "TV",
    "24 hour access",
    "Pool",
    "TV",
    "Washing machine",
    "Kitchen",
    "Pool",
    "Washing machine",
    "Garden",
    "24 hour access",
    "Kitchen",
    "TV",
    "Garden",
    "24 hour access",
    "Kitchen",
    "TV",
    "Pool",
    "Garden",
    "Washing machine",
  ];

  // sizes for responsive screens
  useEffect(() => {
    if (width < 770) {
      setShowData(1);
    } else {
      setShowData(4);
    }
  }, [width, location.pathname]);

  // profile is verified or not (store in state)
  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        // applicationProcessData?.rentalHistory &&
        // applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);

  // const returnTag = (val = []) => {
  //   return val[0]?.map((item) => (
  //     <Col>
  //       <ListWrap>
  //         <p>{item}</p>
  //       </ListWrap>
  //     </Col>
  //   ));
  // };

  const returnTag = (val = []) => {
    return val[0]?.map((item) => (
      <Col span={24}>
        <p>{item}</p>
      </Col>
    ));
  };

  return (
    <OverviewTab>
      <AmenitiesTabWrap>
        <h2>Amenities</h2>
        <AmenitiesTypes>
          <Row gutter={[20, 20]}>
            {ammenities?.slice(0, showData)?.map((val, id) => (
              <>
                {Object.values(val)[0]?.length ? (
                  <Col key={id} xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <Row>
                      <Col span={24}>
                        <h6>{Object.keys(val)}</h6>
                      </Col>
                      <Row gutter={[10, 10]}>
                        {returnTag(Object.values(val))}
                      </Row>
                    </Row>
                  </Col>
                ) : (
                  ""
                )}
              </>
            ))}
          </Row>

          {ammenities?.length && showData === 5 ? (
            <Row>
              <Col onClick={() => setShowData(4)}>
                <span>View Less</span>
              </Col>
            </Row>
          ) : ammenities?.length > 4 ? (
            <Row>
              <Col onClick={() => setShowData(ammenities?.length)}>
                <span>View More</span>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </AmenitiesTypes>
        {/* <AmenitiesTypes>
          <Row>
            {ammenities?.slice(0, showData)?.map((type, id) => (
              <Col key={id} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                {type === "24 hour access" ? (
                  <FullAccess />
                ) : type === "Kitchen" ? (
                  <KitchenSvg />
                ) : type === "TV" ? (
                  <TvSvg />
                ) : type === "Pool" ? (
                  <PoolSvg />
                ) : type === "Washing machine" ? (
                  <WashingMachineSvg />
                ) : type === "Washer and Dryer" ? (
                  <WashingMachineSvg />
                ) : type === "Garden" ? (
                  <LeafSvg />
                ) : type === "Bedroom" ? (
                  <MdOutlineBed style={{ fontSize: "24px" }} />
                ) : type === "Washroom" ? (
                  <FaRestroom style={{ fontSize: "24px" }} />
                ) : type === "Dining Area" ? (
                  <MdLocalDining style={{ fontSize: "24px" }} />
                ) : type === "Master Suite" ? (
                  <MdOutlineAirlineSeatIndividualSuite
                    style={{ fontSize: "24px" }}
                  />
                ) : type === "24/7 Water" ? (
                  <MdOutlineWaterDrop style={{ fontSize: "24px" }} />
                ) : type === "Yard" ? (
                  <MdOutlineYard style={{ fontSize: "24px" }} />
                ) : type === "Roof" ? (
                  <MdOutlineRoofing style={{ fontSize: "24px" }} />
                ) : type === "Outdoor areas" ? (
                  <MdOutlineOutdoorGrill style={{ fontSize: "24px" }} />
                ) : type === "Energy" ? (
                  <MdOutlinePower style={{ fontSize: "24px" }} />
                ) : type === "Gym" ? (
                  <MdFitnessCenter style={{ fontSize: "24px" }} />
                ) : type === "Ac" ? (
                  <MdOutlineAcUnit style={{ fontSize: "24px" }} />
                ) : type === "washer and dryer" ? (
                  <GiWashingMachine style={{ fontSize: "24px" }} />
                ) : type === "Fridge" ? (
                  <RiFridgeLine style={{ fontSize: "24px" }} />
                ) : type === "Pets allowed" ? (
                  <MdOutlinePets style={{ fontSize: "24px" }} />
                ) : type === "Internet access" ? (
                  <MdOutlineSignalWifiStatusbarConnectedNoInternet4
                    style={{ fontSize: "24px" }}
                  />
                ) : type === "Gated Access" ? (
                  <GiGate style={{ fontSize: "24px" }} />
                ) : type === "Swimming pool" ? (
                  <MdOutlinePool style={{ fontSize: "24px" }} />
                ) : type === "Cable ready" ? (
                  <MdOutlineCable style={{ fontSize: "24px" }} />
                ) : type === "Dishwasher" ? (
                  <GiWashingMachine style={{ fontSize: "24px" }} />
                ) : type === "Ac" ? (
                  <MdOutlineAcUnit style={{ fontSize: "24px" }} />
                ) : (
                  ""
                )}
                <p>{type}</p>
              </Col>
            ))}
          </Row>
          {ammenities?.length < showData + 1 ? (
            ""
          ) : (
            <Row>
              <Col onClick={() => setShowData(ammenities?.length)}>
                <span>View More</span>
              </Col>
            </Row>
          )}
        </AmenitiesTypes> */}
      </AmenitiesTabWrap>
      <ApplyWrap ml={4} pr={2.8}>
        <h2>Interested in this property?</h2>
        <StyledButton
          loading={isBtnLoading}
          onClick={() => {
            clickToMessage();
          }}
          bgColor={({ theme }) => theme.colors.secondary}
        >
          Message
        </StyledButton>
        <StyledButton
          bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
          fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
          onClick={() => {
            clickToApply();
          }}
        >
          {isProfileVerifyed ? "Apply  now" : "Apply now"}
        </StyledButton>
      </ApplyWrap>
      <ApplyForPropertyModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </OverviewTab>
  );
}

export default Amenities;

export const AmenitiesTypes = styled.div`
  ${
    "" /* .ant-col {
    display: flex !important;
    align-items: center;
  } */
  }
  svg {
    margin-right: 30px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    margin-top: 30px;
    display: flex;
  }

  h6 {
    color: #4160ac;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
  }
`;
export const AmenitiesTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1400px;
  margin: auto;
`;

export const AmenitiesTabWrap = styled.div`
  width: 70%;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: justify;
    color: #000000;
    margin-bottom: 20px;
  }
  p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const ListWrap = styled.div`
  display: flex;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  align-items: center;
  margin-top: 10px;
  transition: transform 0.4s;

  p {
    margin-bottom: 0;
    margin-right: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #484c4c;
  }

  svg {
    color: #aaaaaa;
    cursor: pointer;
    :hover {
      color: #000;
    }
  }
  ${
    "" /* .ant-list-bordered.ant-list-lg .ant-list-header,
  .ant-list-bordered.ant-list-lg .ant-list-footer {
    display: none;
  }

  .ant-list-bordered.ant-list-lg .ant-list-item {
    padding: 16px 24px;
    display: inline-block;
  } */
  }
`;
