import React from "react";

const noApplicationsvg = ({
  wSize = "300",
  hSize = "300",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 385 275"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M335.667 104.551C325.179 70.264 298.201 42.808 266.279 28.3375C235.826 14.5325 197.326 7.70146 168.467 28.959C149.47 42.951 138.707 65.369 121.31 81.0605C112.664 88.8595 103.199 91.395 92.9084 96.059C67.8394 107.427 46.8569 129.356 45.1299 158.28C42.2424 206.647 80.5774 240.098 123.356 251.071C179.891 265.569 222.142 265.569 260.972 249.91C281.14 241.776 301.672 231.491 316.197 214.65C338.197 189.179 344.247 153.006 339.259 120.297C338.446 114.968 337.246 109.706 335.667 104.551Z"
        fill="#4160AC"
      />
      <path
        opacity="0.8"
        d="M335.667 104.551C325.179 70.264 298.201 42.808 266.279 28.3375C235.826 14.5325 197.326 7.70146 168.467 28.959C149.47 42.951 138.707 65.369 121.31 81.0605C112.664 88.8595 103.199 91.395 92.9084 96.059C67.8394 107.427 46.8569 129.356 45.1299 158.28C42.2424 206.647 80.5774 240.098 123.356 251.071C179.891 265.569 222.142 265.569 260.972 249.91C281.14 241.776 301.672 231.491 316.197 214.65C338.197 189.179 344.247 153.006 339.259 120.297C338.446 114.968 337.246 109.706 335.667 104.551Z"
        fill="white"
      />
      <path
        d="M150.048 22H99.6016V84.8595H150.048V22Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M147.985 24.5703H101.664V82.2873H147.985V24.5703Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M133.632 42.4531H116.016V64.4036H133.632V42.4531Z"
        fill="#D6D6D6"
      />
      <path
        d="M187.452 41.875H153.242V84.5055H187.452V41.875Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M186.092 43.5625H154.594V82.8105H186.092V43.5625Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M176.321 55.7422H164.375V70.6307H176.321V55.7422Z"
        fill="#D6D6D6"
      />
      <path
        d="M126.633 127.976V93.7656H84.0023V127.976H126.633Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M124.625 126.368V95.375H86.004V126.368H124.625Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M112.758 116.844V104.898H97.8693V116.844H112.758Z"
        fill="#D6D6D6"
      />
      <path
        d="M174.272 93.5078H130.602V147.919H174.272V93.5078Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M172.509 95.7031H132.359V145.731H172.509V95.7031Z"
        stroke="#A3A3A3"
        strokeMiterlimit="10"
      />
      <path
        d="M160.064 111.219H144.812V130.221H160.064V111.219Z"
        fill="#D6D6D6"
      />
      <path
        d="M44.3281 140.07L55.3006 245.252"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M53.2792 224.702L48.5547 219.867"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M50.0496 221.444C50.0401 220.64 49.8585 219.846 49.517 219.118C49.1755 218.389 48.6819 217.742 48.0696 217.22C46.7936 216.082 40.0671 213.255 39.4291 215.62C38.7526 218.194 45.6496 223.254 50.0496 221.444Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M50.0661 221.441L43.6641 217.453"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M54.1882 236.613L49.8047 231.828"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M51.3175 233.405C51.308 232.6 51.1264 231.807 50.7849 231.079C50.4434 230.35 49.9499 229.703 49.3375 229.181C48.0615 228.043 41.335 225.216 40.697 227.581C40.004 230.155 46.8845 235.215 51.3175 233.405Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M51.3161 233.409L44.9141 229.422"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M50.2587 195.485L45.2812 190.227"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M47.0206 192.257C47.0112 191.452 46.8296 190.659 46.488 189.93C46.1465 189.202 45.653 188.555 45.0406 188.033C43.7646 186.894 37.0381 184.067 36.4001 186.432C35.7071 189.006 42.5876 194.066 47.0206 192.257Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M47.0192 192.253L40.6172 188.266"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M51.2403 207.475L46.7578 202.641"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48.2706 204.218C48.2612 203.413 48.0796 202.62 47.738 201.891C47.3965 201.163 46.903 200.516 46.2906 199.994C45.0146 198.855 38.2881 196.028 37.6501 198.393C36.9571 200.967 43.8376 206.027 48.2706 204.218Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M48.2692 204.214L41.8672 200.227"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M48.2969 176.06L43.4844 171.242"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M44.9917 172.827C44.9822 172.022 44.8006 171.229 44.4591 170.5C44.1176 169.772 43.6241 169.125 43.0117 168.603C41.7357 167.464 35.0147 164.637 34.3767 167.002C33.6837 169.576 40.5642 174.636 44.9917 172.827Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M44.9903 172.823L38.5938 168.836"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M46.0213 156.563L41.4453 151.734"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M42.9583 153.307C42.9484 152.504 42.7666 151.711 42.425 150.984C42.0835 150.256 41.5902 149.61 40.9783 149.089C39.7023 147.956 33.0033 145.118 32.3378 147.488C31.6448 150.062 38.5033 155.106 42.9583 153.307Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M42.9567 153.308L36.5547 149.32"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M52.6953 218.048L56.4793 211.883"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M55.327 213.728C55.1699 212.94 55.1839 212.127 55.368 211.344C55.5521 210.561 55.9019 209.827 56.394 209.191C57.406 207.81 63.401 203.658 64.512 205.841C65.722 208.217 60.035 214.586 55.327 213.728Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M55.3281 213.724L61.0866 208.219"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M49.4062 186.569L53.1902 180.398"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M52.036 182.252C51.8808 181.463 51.8957 180.65 52.0797 179.868C52.2637 179.085 52.6126 178.351 53.103 177.714C54.1205 176.334 60.11 172.181 61.2265 174.365C62.4365 176.741 56.7495 183.11 52.036 182.252Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M52.0391 182.248L57.8031 176.742"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M47.9688 172.734L51.7527 166.562"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M50.6059 168.419C50.4514 167.635 50.4661 166.826 50.6492 166.047C50.8322 165.268 51.1794 164.538 51.6674 163.904C52.6794 162.523 58.6744 158.365 59.7854 160.554C60.9954 162.908 55.3084 169.277 50.6059 168.419Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M50.6016 168.42L56.3656 162.914"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M45.8516 152.46L49.6356 146.289"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48.4814 148.14C48.3261 147.351 48.341 146.538 48.525 145.756C48.709 144.973 49.0579 144.239 49.5484 143.602C50.5604 142.222 56.5554 138.064 57.6719 140.247C58.8819 142.629 53.1894 148.992 48.4814 148.14Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M48.4766 148.136L54.2406 142.625"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.2333 139.486C44.0762 138.698 44.0902 137.884 44.2742 137.102C44.4583 136.319 44.8082 135.585 45.3003 134.949C46.3123 133.568 52.3073 129.416 53.4183 131.599C54.6283 133.975 48.9523 140.344 44.2333 139.486Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M44.2344 139.49L49.9984 133.984"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M44.5523 139.702C43.7519 139.775 42.945 139.676 42.186 139.411C41.427 139.147 40.7336 138.723 40.1523 138.167C38.8873 137.012 35.3838 130.616 37.6718 129.741C40.1523 128.806 45.9108 135.131 44.5523 139.702Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M44.5683 139.725L39.6953 133.422"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M73.2821 154.781L64.3281 245.256"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M64.7739 239.905L60.9844 233.734"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M62.1554 235.581C62.3125 234.793 62.2985 233.98 62.1144 233.197C61.9303 232.414 61.5805 231.68 61.0884 231.044C60.0599 229.663 54.0649 225.5 52.9539 227.7C51.7439 230.076 57.4364 236.439 62.1554 235.581Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M62.1426 235.587L56.7031 230.367"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M67.8051 210.705L64.0156 204.539"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1757 206.393C65.331 205.603 65.3162 204.789 65.1322 204.006C64.9482 203.223 64.5993 202.487 64.1087 201.85C63.0912 200.475 57.0962 196.322 55.9852 198.506C54.7752 200.882 60.4677 207.251 65.1757 206.393Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1739 206.397L59.7344 201.172"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M66.5629 222.673L62.7734 216.508"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.9335 218.353C64.0906 217.564 64.0767 216.75 63.8926 215.966C63.7086 215.183 63.3587 214.447 62.8665 213.81C61.849 212.435 55.854 208.283 54.743 210.466C53.533 212.853 59.2255 219.217 63.9335 218.353Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.9317 218.358L58.4922 213.133"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M69.8231 191.275L66.0391 185.109"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M67.1927 186.958C67.3441 186.167 67.3243 185.353 67.1345 184.57C66.9448 183.787 66.5895 183.054 66.0927 182.42C65.0807 181.04 59.0802 176.893 57.9692 179.076C56.7922 181.452 62.4792 187.821 67.1927 186.958Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M67.195 186.962L61.75 181.742"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M71.8465 171.759L68.0625 165.594"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M69.2119 167.447C69.3633 166.656 69.3434 165.842 69.1537 165.059C68.964 164.277 68.6087 163.544 68.1119 162.91C67.0999 161.529 61.1049 157.377 59.9939 159.56C58.8114 161.942 64.5039 168.305 69.2119 167.447Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M69.2184 167.446L63.7734 162.227"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M65.4141 232.805L70.3861 227.547"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M68.875 229.118C68.8849 228.315 69.0667 227.522 69.4082 226.795C69.7497 226.067 70.2431 225.421 70.855 224.9C72.131 223.756 78.852 220.923 79.49 223.294C80.1885 225.868 73.308 230.928 68.875 229.118Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M68.875 229.116L75.651 224.914"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M68.6797 201.32L73.6517 196.062"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M72.1406 197.636C72.1501 196.831 72.3317 196.038 72.6732 195.31C73.0147 194.581 73.5083 193.934 74.1206 193.412C75.3966 192.274 82.1176 189.441 82.7556 191.806C83.4541 194.38 76.5736 199.44 72.1406 197.636Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M72.1406 197.639L78.9166 193.438"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M70.1172 187.485L75.0892 182.227"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M73.5781 183.798C73.588 182.994 73.7698 182.202 74.1113 181.474C74.4529 180.747 74.9462 180.101 75.5581 179.579C76.8341 178.435 83.5551 175.603 84.1931 177.973C84.8916 180.547 78.0111 185.607 73.5781 183.798Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M73.5781 183.804L80.3541 179.602"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M72.2188 167.211L77.1963 161.953"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M75.6797 163.527C75.6892 162.722 75.8707 161.929 76.2123 161.2C76.5538 160.472 77.0473 159.825 77.6597 159.303C78.9357 158.164 85.6567 155.332 86.2947 157.697C86.9877 160.271 80.1127 165.336 75.6797 163.527Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M75.6797 163.522L82.4502 159.32"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M73.3047 154.183C73.3142 153.379 73.4959 152.587 73.8374 151.859C74.179 151.131 74.6725 150.485 75.2847 149.964C76.5607 148.82 83.2817 145.988 83.9197 148.353C84.6127 150.932 77.7377 155.992 73.3047 154.183Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M73.3047 154.186L80.0752 149.984"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M73.5803 154.487C72.7819 154.394 72.0128 154.131 71.3249 153.716C70.637 153.3 70.0463 152.742 69.5928 152.078C68.5918 150.687 66.4798 143.707 68.8998 143.322C71.5343 142.899 75.8463 150.269 73.5803 154.487Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M73.5822 154.49L70.1172 147.312"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7656 112.242V245.254"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8743 225.255L56.4688 219.508"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8154 221.234C57.8874 220.434 57.7877 219.628 57.5231 218.869C57.2585 218.11 56.835 217.416 56.2809 216.834C55.1314 215.564 48.7349 212.055 47.8549 214.343C46.8979 216.829 53.2174 222.571 57.8154 221.234Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8104 221.233L51.8594 216.602"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8743 200.255L56.4688 194.508"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8154 196.234C57.8874 195.433 57.7877 194.627 57.5231 193.868C57.2585 193.109 56.835 192.416 56.2809 191.834C55.1314 190.569 48.7349 187.054 47.8549 189.342C46.8979 191.828 53.2174 197.57 57.8154 196.234Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8104 196.24L51.8594 191.609"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8743 170.912L56.4688 165.164"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8154 166.887C57.8874 166.087 57.7877 165.281 57.5231 164.522C57.2585 163.763 56.835 163.069 56.2809 162.487C55.1314 161.222 48.7349 157.708 47.8549 160.001C46.8979 162.482 53.2174 168.224 57.8154 166.887Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8104 166.889L51.8594 162.258"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8743 151.373L56.4688 145.625"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8154 147.351C57.8874 146.55 57.7877 145.744 57.5231 144.985C57.2585 144.226 56.835 143.533 56.2809 142.951C55.1314 141.686 48.7349 138.171 47.8549 140.459C46.8979 142.945 53.2174 148.687 57.8154 147.351Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8104 147.35L51.8594 142.719"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8743 131.75L56.4688 126.008"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8154 127.729C57.8874 126.929 57.7877 126.122 57.5231 125.363C57.2585 124.605 56.835 123.911 56.2809 123.329C55.1314 122.064 48.7349 118.555 47.8549 120.843C46.8979 123.323 53.2174 129.071 57.8154 127.729Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8104 127.733L51.8594 123.102"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7812 232.883L65.1868 227.141"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8445 228.859C63.77 228.059 63.868 227.253 64.1318 226.494C64.3955 225.735 64.8189 225.041 65.3735 224.459C66.5285 223.189 72.9195 219.68 73.7995 221.968C74.7565 224.459 68.437 230.201 63.8445 228.859Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8438 228.863L70.1467 223.984"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7812 193.133L65.1868 187.391"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8441 189.112C63.7704 188.312 63.8686 187.505 64.1324 186.746C64.3961 185.987 64.8191 185.294 65.3731 184.712C66.5281 183.447 72.9191 179.938 73.7991 182.226C74.7561 184.706 68.4366 190.454 63.8441 189.112Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8438 189.113L70.1467 184.234"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7812 173.508L65.1868 167.766"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8445 169.484C63.77 168.684 63.868 167.878 64.1318 167.119C64.3955 166.36 64.8189 165.666 65.3735 165.084C66.5285 163.814 72.9195 160.305 73.7995 162.593C74.7565 165.084 68.437 170.826 63.8445 169.484Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8438 169.488L70.1467 164.609"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7812 147.57L65.1868 141.828"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8441 143.549C63.7704 142.749 63.8686 141.943 64.1324 141.184C64.3961 140.425 64.8191 139.731 65.3731 139.149C66.5281 137.884 72.9191 134.375 73.7991 136.663C74.7561 139.149 68.4366 144.891 63.8441 143.549Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8438 143.55L70.1467 138.672"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7812 127.185L65.1868 121.438"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8441 123.163C63.7704 122.363 63.8686 121.557 64.1324 120.798C64.3961 120.039 64.8191 119.345 65.3731 118.763C66.5281 117.498 72.9191 113.984 73.7991 116.272C74.7561 118.758 68.4366 124.5 63.8441 123.163Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8438 123.165L70.1467 118.281"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.5007 114.117C60.4263 113.317 60.5243 112.51 60.788 111.751C61.0518 110.992 61.4752 110.299 62.0297 109.717C63.1847 108.447 69.5757 104.938 70.4557 107.226C71.4237 109.717 65.1042 115.459 60.5007 114.117Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M60.5156 114.113L66.8186 109.234"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8248 114.403C60.0208 114.391 59.2284 114.208 58.5009 113.866C57.7733 113.523 57.1274 113.029 56.6063 112.417C55.4678 111.136 52.6463 104.415 55.0113 103.777C57.5908 103.073 62.6398 109.959 60.8248 114.403Z"
        fill="#4160AC"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8238 114.391L56.6328 107.609"
        stroke="#263238"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
      <path
        d="M137.014 245.329C135.061 239.334 130.287 224.522 130.672 223.95C131.145 223.235 131.145 219.418 131.145 219.418C131.145 219.418 115.635 221.327 104.663 212.021C93.6904 202.715 80.5619 191.742 80.5619 191.742C80.5619 191.742 78.6534 197.451 76.7449 203.435C75.1334 208.479 71.9819 236.727 71.0469 245.329H137.014Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.6328 228.711C101.416 231.285 106.228 235.822 109.77 239.634"
        stroke="#6E6E6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.3984 226.664L97.2619 227.451"
        stroke="#6E6E6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.552 125.953C150.552 125.953 134.239 132.795 126.786 137.833C119.334 142.871 104.236 157.567 99.803 163.408C95.37 169.249 84.4965 181.558 81.6805 184.952C78.8645 188.345 77.0495 190.391 77.8305 196.029C78.6115 201.666 83.468 210.73 88.704 215.361C93.94 219.992 104.407 225.778 117.904 225.228C125.752 224.909 130.389 223.215 134.618 218.986C138.848 214.756 145.294 203.679 150.931 198.245C156.569 192.811 166.232 181.745 166.232 181.745C166.232 181.745 192.01 191.007 194.425 191.007C196.84 191.007 208.928 184.16 209.127 182.147C209.325 180.134 182.545 162.413 180.131 157.98C177.716 153.547 172.706 145.478 172.706 145.478C172.706 145.478 160.606 127.961 150.552 125.953Z"
        fill="#7D7D7D"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.153 157.96C177.733 153.533 172.701 145.475 172.701 145.475C170.172 141.918 167.393 138.545 164.385 135.383C163.389 135.546 162.44 135.921 161.602 136.483C155.354 141.086 158.302 152.592 158.967 161.783C159.424 167.447 161.113 172.944 163.917 177.887L154.188 194.992C159.66 189.294 166.288 181.72 166.288 181.72C166.288 181.72 192.066 190.982 194.481 190.982C196.895 190.982 208.984 184.135 209.182 182.122C209.38 180.109 182.568 162.393 180.153 157.96Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.081 130.653C143.081 130.653 139.116 128.673 136.338 138.193C133.561 147.714 140.7 172.711 144.275 173.107C147.85 173.503 155.385 154.858 156.573 149.303C157.761 143.748 154.191 127.083 143.081 130.653Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.376 151.323C155.376 151.323 153.319 153.072 150.344 155.349C146.923 157.967 146.29 162.84 143.876 160.018C141.461 157.197 137.859 137.43 140.075 133.398C142.292 129.367 150.525 123.933 151.147 123.531C151.768 123.13 157.389 130.582 157.796 136.016C158.203 141.45 155.376 151.323 155.376 151.323Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.147 123.531C150.547 123.933 142.286 129.372 140.075 133.398C139.201 134.982 139.234 139.008 139.734 143.474C140.526 144.013 141.384 144.587 142.308 145.196C149.772 150.118 155.376 151.323 155.376 151.323L155.789 129.801C154.001 126.221 151.537 123.273 151.147 123.531Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187 128.761L178.541 143.259C175.72 148.094 172.491 153.709 166.859 154.941C161.227 156.173 155.788 156.954 152.763 152.12C149.738 147.285 146.52 140.845 148.533 131.984C150.546 123.124 153.973 120.506 158.202 117.283C162.432 114.06 166.65 112.047 171.485 113.851C176.319 115.655 187 128.761 187 128.761Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.88 133.743C169.456 134.491 168.747 134.892 168.301 134.634C167.856 134.375 167.834 133.567 168.257 132.819C168.681 132.071 169.39 131.675 169.841 131.928C170.292 132.181 170.309 132.995 169.88 133.743Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.63 137.884C178.206 138.632 177.497 139.033 177.051 138.78C176.606 138.527 176.584 137.708 177.007 136.96C177.431 136.212 178.14 135.816 178.591 136.069C179.042 136.322 179.059 137.141 178.63 137.884Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.422 127.712C168.436 127.53 169.477 127.558 170.48 127.796C171.483 128.033 172.427 128.475 173.252 129.092"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.484 132.062C178.484 132.062 182.582 132.277 182.406 135.253"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.945 142.578C162.945 142.578 164.045 146.082 169.369 147.666"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.179 134.75L175.936 137.659C175.936 137.659 173.186 144.54 172.696 145.459C172.207 146.377 168.456 144.513 167.422 142.852"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.764 127.153C153.764 127.153 154.369 127.153 155.964 126.146C157.559 125.14 159.39 126.955 163.213 125.541C167.035 124.128 161.601 118.694 166.837 118.292C172.073 117.891 174.895 126.35 181.335 129.369C187.776 132.389 192.214 131.184 195.03 123.93C197.846 116.675 193.826 112.451 190.603 109.234C187.38 106.016 190.603 105.384 189.596 100.373C188.59 95.3627 183.546 93.3222 178.723 95.1372C173.899 96.9522 175.093 98.3602 172.475 98.3602C169.857 98.3602 167.442 94.9337 160.193 96.3417C152.944 97.7497 147.307 105.406 146.498 112.655C145.69 119.904 148.11 127.554 149.116 127.961C150.123 128.368 153.764 127.153 153.764 127.153Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.775 129.804C153.775 129.804 155.766 123.622 153.654 121.79C151.542 119.959 148.038 121.13 147.318 123.864C146.597 126.597 145.459 131.503 146.652 132.757C147.846 134.011 150.178 133.632 150.178 133.632"
        fill="white"
      />
      <path
        d="M153.775 129.804C153.775 129.804 155.766 123.622 153.654 121.79C151.542 119.959 148.038 121.13 147.318 123.864C146.597 126.597 145.459 131.503 146.652 132.757C147.846 134.011 150.178 133.632 150.178 133.632"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M220.551 245.329L211.894 213.522C211.255 211.173 209.861 209.1 207.927 207.621C205.992 206.143 203.625 205.343 201.191 205.344H166.557C163.791 205.343 161.124 206.376 159.08 208.24C157.035 210.104 155.762 212.665 155.508 215.42L152.758 245.329H220.551Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.857 180.131C195.857 180.131 169.875 191.813 168.67 193.018C167.466 194.222 160.008 245.334 160.008 245.334H249.625C249.625 245.334 235.726 191.406 233.917 190.202C232.107 188.997 208.342 179.125 207.737 179.125C207.132 179.125 195.857 180.131 195.857 180.131Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.15 182.328C227.15 182.328 229.719 182.449 230.186 183.148C230.654 183.846 231.836 188.169 231.352 189.198C230.868 190.226 229.834 189.313 229.152 188.032C228.47 186.75 227.865 185.463 227.865 185.463L226.578 184.528L227.15 182.328Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.85 179.758C223.85 179.758 227.123 180.11 227.59 181.276C228.058 182.442 227.937 185.483 227.469 187.232C227.002 188.981 226.188 190.153 225.484 189.57C224.78 188.987 223.383 182.794 223.383 182.095C223.383 181.397 223.498 179.758 223.85 179.758Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.522 178.125C221.522 178.125 224.558 178.945 225.141 179.775C225.724 180.605 225.724 185.149 225.141 186.898C224.558 188.647 223.156 189.098 222.452 188.652C221.748 188.207 221.055 185.264 221.055 183.862C221.055 182.459 221.522 178.125 221.522 178.125Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.661 177.622C217.661 177.622 220.747 176.434 221.693 177.622C222.639 178.81 222.166 187.819 221.457 188.77C220.747 189.722 218.607 188.77 218.607 187.582C218.607 186.394 217.805 183.155 217.805 183.155L214.125 181.659C214.125 181.659 216 177.622 217.661 177.622Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.784 183.151L217.438 180.352"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.415 181.02C194.415 181.02 190.494 181.801 190.235 184.942C189.977 188.082 190.758 203.493 192.848 205.061C194.938 206.628 205.157 199.308 205.157 199.308C205.157 199.308 213.225 203.504 215.057 202.184C216.888 200.864 215.315 187.29 211.399 182.065C207.483 176.84 194.415 181.02 194.415 181.02Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.391 228.48L182.362 207.453"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.195 203.484L231.958 220.721L228.785 244.751"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.988 177.64C189.828 177.64 195.374 172.366 195.374 165.859C195.374 159.353 189.828 154.078 182.988 154.078C176.147 154.078 170.602 159.353 170.602 165.859C170.602 172.366 176.147 177.64 182.988 177.64Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.625 176.556C188.244 176.313 188.837 176.009 189.396 175.648"
        stroke="#6E6E6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.153 160.469C172.153 160.469 169.227 168.389 174.05 173.724C177.444 177.486 182.119 177.915 185.666 177.117"
        stroke="#6E6E6E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.44 173.484C193.44 173.484 193.242 181.338 193.242 183.555C193.242 185.771 199.886 195.655 203.912 196.238C207.938 196.821 209.55 189.39 209.753 185.969C209.957 182.548 210.76 177.719 210.76 177.719C210.76 177.719 199.105 180.128 193.44 173.484Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.143 181.047L206.097 178.137C202.203 178.17 196.747 177.406 193.447 173.484C193.447 173.484 193.321 178.555 193.266 181.696C202.616 183.67 208.143 181.047 208.143 181.047Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.412 167.642C189.412 167.642 191.827 174.286 195.253 177.509C198.68 180.732 205.324 184.962 209.955 182.542C214.586 180.122 217.605 176.904 218.815 169.457C220.025 162.01 218.815 144.889 218.815 144.889C218.815 144.889 218.21 141.264 211.764 140.054C205.318 138.844 194.247 141.061 192.03 145.494C189.814 149.927 189.203 155.762 189.203 159.387C189.203 163.011 189.412 167.642 189.412 167.642Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.49 162.386C205.006 162.386 205.425 161.69 205.425 160.83C205.425 159.97 205.006 159.273 204.49 159.273C203.973 159.273 203.555 159.97 203.555 160.83C203.555 161.69 203.973 162.386 204.49 162.386Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.081 160.096C215.081 160.959 214.663 161.658 214.146 161.658C213.629 161.658 213.211 160.959 213.211 160.096C213.211 159.232 213.629 158.539 214.146 158.539C214.663 158.539 215.081 159.238 215.081 160.096Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.68 157.401C201.68 157.401 202.51 154.701 206.041 155.113"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.07 155.107C212.07 155.107 214.149 152.203 217.053 155.938"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.064 161.156C212.064 161.156 211.233 163.439 212.064 164.456C212.894 165.474 213.312 167.575 211.855 167.987C210.775 168.254 209.667 168.395 208.555 168.405"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.766 171.938C203.766 171.938 206.466 174.434 211.862 173.186"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.391 144.525L219.543 154.282C219.543 154.282 224.323 150.547 223.492 144.316C222.662 138.084 219.131 137.672 219.131 137.672L215.391 144.525Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.205 158.177C188.205 158.177 188.986 160.927 191.401 159.112C193.815 157.297 196.461 151.533 196.461 151.533C196.461 151.533 193.243 155.762 195.256 156.972C197.269 158.182 205.706 155.96 214.99 146.902C224.274 137.843 222.443 132.602 218.01 128.977C213.577 125.353 203.512 131.177 203.512 131.177C203.512 131.177 196.461 128.977 187.606 134.197C178.751 139.416 180.351 151.72 181.765 153.931C183.178 156.142 183.376 160.19 188.205 158.177Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.395 160.38C190.395 160.38 188.784 155.342 186.166 155.545C183.548 155.749 181.936 163.795 185.159 167.425C188.382 171.055 190.192 168.03 190.192 168.03"
        fill="white"
      />
      <path
        d="M190.395 160.38C190.395 160.38 188.784 155.342 186.166 155.545C183.548 155.749 181.936 163.795 185.159 167.425C188.382 171.055 190.192 168.03 190.192 168.03"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.971 167.207C188.972 167.464 188.897 167.715 188.755 167.929C188.613 168.143 188.411 168.311 188.174 168.41C187.936 168.509 187.675 168.535 187.423 168.486C187.171 168.436 186.939 168.313 186.757 168.132C186.575 167.951 186.451 167.719 186.4 167.467C186.35 167.215 186.375 166.954 186.473 166.717C186.571 166.479 186.738 166.276 186.951 166.133C187.165 165.99 187.416 165.914 187.673 165.914C188.016 165.914 188.346 166.05 188.589 166.292C188.832 166.535 188.97 166.863 188.971 167.207Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.193 198.456L221.243 211.546C221.243 211.546 244.2 225.038 245.608 225.643C247.016 226.248 250.844 229.872 251.449 234.701C252.054 239.53 249.249 242.357 245.812 242.357C242.374 242.357 239.168 241.549 236.55 239.338C233.932 237.127 216.409 215.374 216.409 215.374C216.409 215.374 212.586 213.174 211.58 212.349C210.573 211.524 209.561 209.33 209.561 209.33C209.561 209.33 207.548 210.336 206.14 210.138C204.732 209.94 202.516 205.304 202.516 205.304C202.516 205.304 204.716 198.055 205.337 197.054C205.959 196.053 211.387 195.844 211.387 195.844L215.193 198.456Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.93 207.316L209.146 206.508L209.548 207.52V209.329"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.248 209.555L199.28 194.248C199.28 194.248 205.121 192.048 206.733 191.63C208.344 191.212 213.173 195.48 214.185 195.656C215.197 195.832 215.791 198.274 215.192 198.478C214.592 198.681 212.574 197.669 212.574 197.669L206.524 198.681C206.524 198.681 204.714 208.141 202.701 210.358C201.049 212.022 199.226 213.508 197.262 214.791C197.262 214.791 177.731 238.155 176.323 240.355C174.915 242.555 168.073 246.597 163.233 243.776C158.393 240.954 161.033 234.112 162.43 231.676C163.827 229.239 194.248 209.555 194.248 209.555Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M214.183 195.63L209.002 194.508L205.328 195.327"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.141 193.911L208.597 192.883L210.23 193.086"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.093 140.477C131.093 140.477 134.723 150.146 134.118 159.809C133.513 169.473 136.532 201.092 137.341 204.513C138.149 207.934 139.15 213.781 139.15 213.781C139.15 213.781 170.764 229.082 176.407 231.298C180.549 232.847 184.785 234.133 189.09 235.148C189.09 235.148 200.97 231.117 202.18 230.11C203.39 229.104 206.41 230.512 206.41 230.512C206.41 230.512 212.855 232.327 213.257 232.932C213.659 233.537 213.455 241.589 213.455 241.589C213.455 241.589 214.264 243.789 213.659 244.009C213.054 244.229 206.41 245.818 204.193 245.213C201.977 244.608 196.141 241.99 196.141 241.99C196.141 241.99 191.708 243.602 189.492 243.2C187.275 242.799 184.459 242.194 184.459 242.194C184.459 242.194 147.004 236.144 136.934 233.735C126.863 231.326 120.626 229.912 119.62 226.288C118.613 222.663 108.339 178.157 107.938 175.743"
        fill="#7D7D7D"
      />
      <path
        d="M131.093 140.477C131.093 140.477 134.723 150.146 134.118 159.809C133.513 169.473 136.532 201.092 137.341 204.513C138.149 207.934 139.15 213.781 139.15 213.781C139.15 213.781 170.764 229.082 176.407 231.298C180.549 232.847 184.785 234.133 189.09 235.148C189.09 235.148 200.97 231.117 202.18 230.11C203.39 229.104 206.41 230.512 206.41 230.512C206.41 230.512 212.855 232.327 213.257 232.932C213.658 233.537 213.455 241.589 213.455 241.589C213.455 241.589 214.264 243.789 213.659 244.009C213.054 244.229 206.41 245.818 204.193 245.213C201.977 244.608 196.141 241.99 196.141 241.99C196.141 241.99 191.708 243.602 189.492 243.2C187.275 242.799 184.459 242.194 184.459 242.194C184.459 242.194 147.004 236.144 136.934 233.735C126.863 231.326 120.626 229.912 119.62 226.288C118.613 222.663 108.339 178.157 107.938 175.743"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M198.628 237.445H211.201C212.411 237.445 213.572 237.926 214.428 238.782C215.285 239.638 215.765 240.8 215.765 242.01V245.31H194.062V242.01C194.062 240.8 194.543 239.638 195.4 238.782C196.256 237.926 197.417 237.445 198.628 237.445Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.196 245.197C206.396 245.802 213.057 244.19 213.662 243.992C214.267 243.794 213.458 241.572 213.458 241.572C213.458 241.572 213.662 233.52 213.26 232.915C212.859 232.31 206.413 230.495 206.413 230.495C206.413 230.495 203.393 229.087 202.183 230.094C200.973 231.1 189.093 235.132 189.093 235.132C184.789 234.117 180.553 232.831 176.41 231.282C174.414 230.501 169.178 228.081 163.238 225.281C155.653 227.316 155.043 233.933 155.23 237.227C168.98 239.658 184.462 242.177 184.462 242.177C184.462 242.177 187.284 242.782 189.495 243.184C191.706 243.585 196.144 241.974 196.144 241.974C196.144 241.974 201.985 244.592 204.196 245.197Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.211 232.508C206.211 232.508 209.637 236.534 210.842 238.145C212.046 239.757 213.46 241.566 213.46 241.566"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.18 233.719C201.18 233.719 206.416 239.158 207.824 240.165C209.232 241.171 211.047 244.592 211.047 244.592"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.945 237.141C196.945 237.141 203.589 241.97 204.398 242.575C205.321 243.395 206.195 244.269 207.016 245.193"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.786 227.425C166.351 226.76 164.822 226.045 163.238 225.297C155.653 227.332 155.043 233.948 155.23 237.243L160.686 238.2C161.357 232.194 165.394 228.883 167.786 227.425Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.627 241.758H311.5V245.3H226.398V244.959C226.407 244.108 226.751 243.294 227.355 242.695C227.96 242.096 228.776 241.759 229.627 241.758Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M311.496 241.758H259.719V245.327H311.496V241.758Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M317.15 201.586H265.373L259.719 241.455H311.496L317.15 201.586Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M311.496 241.453L316.446 206.445C309.753 211.654 300.106 219.7 289.062 228.858C280.63 235.876 273.568 239.544 268.398 241.453H311.496Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M289.68 228.061C292.42 228.061 294.641 226.197 294.641 223.898C294.641 221.598 292.42 219.734 289.68 219.734C286.94 219.734 284.719 221.598 284.719 223.898C284.719 226.197 286.94 228.061 289.68 228.061Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M332.195 245.328H337.063"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3984 245.328H328.348"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M281.211 87.5725C282.248 82.5789 279.04 77.6903 274.046 76.6534C269.053 75.6166 264.164 78.8242 263.127 83.8177C262.091 88.8113 265.298 93.6999 270.292 94.7367C275.285 95.7736 280.174 92.566 281.211 87.5725Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.891 84.6328V120.212"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.891 89.8825L277.143 84.4375"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.498 92.6078L268.484 89.5938"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M261.386 102.949C264.528 101.637 266.012 98.0265 264.7 94.8842C263.388 91.742 259.777 90.2581 256.635 91.57C253.493 92.8818 252.009 96.4926 253.321 99.6349C254.633 102.777 258.243 104.261 261.386 102.949Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.82 96.5312V120.291"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.82 100.034L262.329 96.3984"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.56 101.851L256.547 99.8438"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.392 98.3779C321.797 98.3779 324.558 95.6175 324.558 92.2124C324.558 88.8073 321.797 86.0469 318.392 86.0469C314.987 86.0469 312.227 88.8073 312.227 92.2124C312.227 95.6175 314.987 98.3779 318.392 98.3779Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.195 91.5V115.266"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.195 95.0105L321.704 91.375"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M317.935 96.8255L315.922 94.8125"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M322.327 107.062H259.352V126.934H322.327V107.062Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.923 88.9297L259.523 107.063H322.328L290.923 88.9297Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M303.597 105.445H288.016V126.934H303.597V105.445Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M303.597 105.445H288.016V106.358H303.597V105.445Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M299.697 126.929H291.914V113.498C291.914 112.467 292.324 111.478 293.053 110.748C293.782 110.019 294.771 109.609 295.803 109.609C296.314 109.609 296.82 109.709 297.292 109.904C297.765 110.099 298.194 110.385 298.556 110.746C298.917 111.107 299.204 111.536 299.4 112.009C299.596 112.481 299.697 112.987 299.697 113.498V126.929Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.925 110.539H254.828V126.929H290.925V110.539Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.925 110.539H254.828V111.271H290.925V110.539Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.951 111.898H258.797V126.935H286.951V111.898Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.883 98.5547L253.727 109.615H292.04L272.883 98.5547Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M292.04 109.609H253.727V110.539H292.04V109.609Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M295.805 99.2031L285.883 104.934H305.727L295.805 99.2031Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M305.735 104.938H285.891V105.856H305.735V104.938Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M262.111 113.102H259.828V115.384H262.111V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M265.532 113.102H263.25V115.384H265.532V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.947 113.102H266.664V115.384H268.947V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.368 113.102H270.086V115.384H272.368V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.79 113.102H273.508V115.384H275.79V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.212 113.102H276.93V115.384H279.212V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.626 113.102H280.344V115.384H282.626V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.048 113.102H283.766V115.384H286.048V113.102Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M262.111 116.453H259.828V118.736H262.111V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M265.532 116.453H263.25V118.736H265.532V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.947 116.453H266.664V118.736H268.947V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.368 116.453H270.086V118.736H272.368V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.79 116.453H273.508V118.736H275.79V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.212 116.453H276.93V118.736H279.212V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.626 116.453H280.344V118.736H282.626V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.048 116.453H283.766V118.736H286.048V116.453Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M262.111 119.812H259.828V122.095H262.111V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M265.532 119.812H263.25V122.095H265.532V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.947 119.812H266.664V122.095H268.947V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.368 119.812H270.086V122.095H272.368V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.79 119.812H273.508V122.095H275.79V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.212 119.812H276.93V122.095H279.212V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.626 119.812H280.344V122.095H282.626V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.048 119.812H283.766V122.095H286.048V119.812Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M262.111 123.164H259.828V125.447H262.111V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M265.532 123.164H263.25V125.447H265.532V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.947 123.164H266.664V125.447H268.947V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.368 123.164H270.086V125.447H272.368V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.79 123.164H273.508V125.447H275.79V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.212 123.164H276.93V125.447H279.212V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.626 123.164H280.344V125.447H282.626V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.048 123.164H283.766V125.447H286.048V123.164Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M324.114 126.929H301.086V105.44L312.603 99.2031L324.114 105.44V126.929Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.875 106.358L312.604 99.1642L299.332 106.358L298.914 105.594L312.604 98.1797L326.288 105.594L325.875 106.358Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M301.086 105.44V105.963L312.603 99.7256L324.114 105.963V105.44L312.603 99.2031L301.086 105.44Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M316.83 112.805H312.969V115.489H316.83V112.805Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M316.83 116.375H312.969V119.059H316.83V116.375Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M316.83 109.062H312.969V111.928H316.83V109.062Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M312.088 109.062H308.227V111.928H312.088V109.062Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M312.088 116.375H308.227V119.059H312.088V116.375Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M312.088 112.805H308.227V115.489H312.088V112.805Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M316.83 119.945H312.969V122.415H316.83V119.945Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M312.088 119.945H308.227V122.415H312.088V119.945Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.602 135.219H269.694C267.567 135.219 265.844 136.942 265.844 139.069V149.618C265.844 151.744 267.567 153.468 269.694 153.468H309.602C311.728 153.468 313.452 151.744 313.452 149.618V139.069C313.452 136.942 311.728 135.219 309.602 135.219Z"
        fill="#4160AC"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M280.065 140.688C281.165 140.688 281.759 141.359 281.759 142.536V142.766H280.659V142.447C280.659 141.925 280.45 141.727 280.082 141.727C279.713 141.727 279.532 141.925 279.532 142.447C279.532 143.96 281.781 144.241 281.781 146.336C281.781 147.513 281.198 148.184 280.065 148.184C278.932 148.184 278.344 147.513 278.344 146.336V145.885H279.444V146.435C279.444 146.957 279.675 147.145 280.043 147.145C280.412 147.145 280.643 146.957 280.643 146.435C280.643 144.922 278.388 144.642 278.388 142.546C278.366 141.347 278.943 140.688 280.065 140.688Z"
        fill="#263238"
      />
      <path
        d="M283.585 143.866H285.163V144.911H283.585V147.061H285.576V148.106H282.43V140.797H285.576V141.842H283.585V143.866Z"
        fill="#263238"
      />
      <path
        d="M286.281 140.797H287.431V147.083H289.328V148.128H286.281V140.797Z"
        fill="#263238"
      />
      <path
        d="M290.99 143.866H292.602V144.911H291.018V147.061H293.009V148.106H289.852V140.797H292.998V141.842H291.007L290.99 143.866Z"
        fill="#263238"
      />
      <path
        d="M297.031 145.374V146.347C297.031 147.524 296.443 148.195 295.315 148.195C294.188 148.195 293.594 147.524 293.594 146.347V142.536C293.594 141.359 294.182 140.688 295.315 140.688C296.448 140.688 297.031 141.359 297.031 142.536V143.245H295.931V142.447C295.931 141.925 295.7 141.727 295.332 141.727C294.963 141.727 294.738 141.925 294.738 142.447V146.413C294.738 146.935 294.969 147.122 295.332 147.122C295.695 147.122 295.931 146.935 295.931 146.413V145.362L297.031 145.374Z"
        fill="#263238"
      />
      <path
        d="M297.396 140.797H300.955V141.842H299.745V148.128H298.595V141.82H297.391L297.396 140.797Z"
        fill="#263238"
      />
      <path
        d="M305.245 161.85C305.245 161.85 302.88 158.033 302.116 157.45C301.351 156.867 299.52 156.9 299.217 158.209C298.915 159.518 299.674 160.194 300.207 160.728C300.9 161.605 301.537 162.523 302.116 163.478C302.77 164.539 302.913 166.173 303.491 167.328C304.811 169.973 307.401 172.218 310.481 172.234C313.891 172.234 317.158 169.314 317.631 165.915C317.818 164.474 317.851 160.459 317.857 159.073C317.862 158.763 317.754 158.462 317.554 158.226C317.298 157.93 316.945 157.735 316.558 157.675C316.171 157.616 315.776 157.696 315.442 157.901L315.189 158.055L314.92 157.631C314.724 157.297 314.433 157.029 314.084 156.861C313.787 156.705 313.447 156.649 313.116 156.703C312.784 156.756 312.479 156.916 312.247 157.159L311.983 157.45L311.675 156.949C311.004 156.141 309.552 156.064 308.925 156.911V150.861C308.925 150.384 308.735 149.927 308.398 149.589C308.061 149.252 307.603 149.063 307.126 149.062C306.649 149.063 306.192 149.252 305.855 149.589C305.517 149.927 305.328 150.384 305.328 150.861L305.245 161.85Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M311.938 157.43V160.939"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M315.148 158.031V161.161"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M308.891 156.891V160.477"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M307.094 144.164V147.728"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.969 149.922H312.988"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M300.781 149.922H303.801"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.281 147.726L310.519 146.219"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M304.482 147.726L303.25 146.219"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M327.403 63.2031H252.125C249.795 63.2031 247.906 65.0918 247.906 67.4216V173.247C247.906 175.577 249.795 177.466 252.125 177.466H327.403C329.733 177.466 331.622 175.577 331.622 173.247V67.4216C331.622 65.0918 329.733 63.2031 327.403 63.2031Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M327.061 63.2032H252.448C251.237 63.2032 250.076 63.6842 249.22 64.5403C248.364 65.3964 247.883 66.5575 247.883 67.7682V68.7472H331.648V67.7572C331.647 67.1566 331.528 66.562 331.297 66.0077C331.065 65.4533 330.727 64.9501 330.301 64.527C329.874 64.1038 329.369 63.769 328.813 63.5418C328.257 63.3147 327.661 63.1996 327.061 63.2032Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.928 67.1599C319.638 67.1599 320.215 66.5837 320.215 65.8729C320.215 65.1621 319.638 64.5859 318.928 64.5859C318.217 64.5859 317.641 65.1621 317.641 65.8729C317.641 66.5837 318.217 67.1599 318.928 67.1599Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M323.685 67.1599C324.396 67.1599 324.972 66.5837 324.972 65.8729C324.972 65.1621 324.396 64.5859 323.685 64.5859C322.975 64.5859 322.398 65.1621 322.398 65.8729C322.398 66.5837 322.975 67.1599 323.685 67.1599Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M292.016 177.859V201.669"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default noApplicationsvg;
