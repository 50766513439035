import React from "react";

const dreamCircle = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      width="180"
      height="244"
      viewBox="0 0 180 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4733_9807"
        styles="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="15"
        y="15"
        width="214"
        height="214"
      >
        <path
          d="M226.963 139.002C236.373 81.0474 197.008 26.4397 139.039 17.0323C81.0697 7.62494 26.4484 46.9803 17.0387 104.935C7.62905 162.89 46.9942 217.497 104.963 226.905C162.932 236.312 217.553 196.957 226.963 139.002Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4733_9807)">
        <path
          d="M-4.1084 13.75C10.2481 13.75 10.2481 26.7981 24.6372 26.7981C39.0264 26.7981 38.9938 13.75 53.3829 13.75C67.772 13.75 67.772 26.7981 82.1285 26.7981C96.485 26.7981 96.5177 13.75 110.874 13.75C125.231 13.75 125.263 26.7981 139.62 26.7981C153.976 26.7981 154.009 13.75 168.398 13.75C182.787 13.75 182.787 26.7981 197.144 26.7981C211.5 26.7981 211.533 13.75 225.922 13.75C240.311 13.75 240.311 26.7981 254.7 26.7981"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 42.8125C10.2481 42.8125 10.2481 55.8606 24.6372 55.8606C39.0264 55.8606 38.9938 42.8125 53.3829 42.8125C67.772 42.8125 67.772 55.8606 82.1285 55.8606C96.485 55.8606 96.5177 42.8125 110.874 42.8125C125.231 42.8125 125.263 55.8606 139.62 55.8606C153.976 55.8606 154.009 42.8125 168.398 42.8125C182.787 42.8125 182.787 55.8606 197.144 55.8606C211.5 55.8606 211.533 42.8125 225.922 42.8125C240.311 42.8125 240.311 55.8606 254.7 55.8606"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 71.8477C10.2481 71.8477 10.2481 84.8958 24.6372 84.8958C39.0264 84.8958 38.9938 71.8477 53.3829 71.8477C67.772 71.8477 67.772 84.8958 82.1285 84.8958C96.485 84.8958 96.5177 71.8477 110.874 71.8477C125.231 71.8477 125.263 84.8958 139.62 84.8958C153.976 84.8958 154.009 71.8477 168.398 71.8477C182.787 71.8477 182.787 84.8958 197.144 84.8958C211.5 84.8958 211.533 71.8477 225.922 71.8477C240.311 71.8477 240.311 84.8958 254.7 84.8958"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 100.941C10.2481 100.941 10.2481 113.99 24.6372 113.99C39.0264 113.99 38.9938 100.941 53.3829 100.941C67.772 100.941 67.772 113.99 82.1285 113.99C96.485 113.99 96.5177 100.941 110.874 100.941C125.231 100.941 125.263 113.99 139.62 113.99C153.976 113.99 154.009 100.941 168.398 100.941C182.787 100.941 182.787 113.99 197.144 113.99C211.5 113.99 211.533 100.941 225.922 100.941C240.311 100.941 240.311 113.99 254.7 113.99"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 129.945C10.2481 129.945 10.2481 142.993 24.6372 142.993C39.0264 142.993 38.9938 129.945 53.3829 129.945C67.772 129.945 67.772 142.993 82.1285 142.993C96.485 142.993 96.5177 129.945 110.874 129.945C125.231 129.945 125.263 142.993 139.62 142.993C153.976 142.993 154.009 129.945 168.398 129.945C182.787 129.945 182.787 142.993 197.144 142.993C211.5 142.993 211.533 129.945 225.922 129.945C240.311 129.945 240.311 142.993 254.7 142.993"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 159.008C10.2481 159.008 10.2481 172.056 24.6372 172.056C39.0264 172.056 38.9938 159.008 53.3829 159.008C67.772 159.008 67.772 172.056 82.1285 172.056C96.485 172.056 96.5177 159.008 110.874 159.008C125.231 159.008 125.263 172.056 139.62 172.056C153.976 172.056 154.009 159.008 168.398 159.008C182.787 159.008 182.787 172.056 197.144 172.056C211.5 172.056 211.533 159.008 225.922 159.008C240.311 159.008 240.311 172.056 254.7 172.056"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 188.043C10.2481 188.043 10.2481 201.091 24.6372 201.091C39.0264 201.091 38.9938 188.043 53.3829 188.043C67.772 188.043 67.772 201.091 82.1285 201.091C96.485 201.091 96.5177 188.043 110.874 188.043C125.231 188.043 125.263 201.091 139.62 201.091C153.976 201.091 154.009 188.043 168.398 188.043C182.787 188.043 182.787 201.091 197.144 201.091C211.5 201.091 211.533 188.043 225.922 188.043C240.311 188.043 240.311 201.091 254.7 201.091"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M-4.1084 217.105C10.2481 217.105 10.2481 230.154 24.6372 230.154C39.0264 230.154 38.9938 217.105 53.3829 217.105C67.772 217.105 67.772 230.154 82.1285 230.154C96.485 230.154 96.5177 217.105 110.874 217.105C125.231 217.105 125.263 230.154 139.62 230.154C153.976 230.154 154.009 217.105 168.398 217.105C182.787 217.105 182.787 230.154 197.144 230.154C211.5 230.154 211.533 217.105 225.922 217.105C240.311 217.105 240.311 230.154 254.7 230.154"
          stroke="#CEDBF6"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};

export default dreamCircle;
