import { Button, Col, Divider, Input, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SendEmail } from "../../footer/Footer";
import {
  EmailLogo,
  FacebookSvg,
  FooterBottomCircle,
  FooterCall,
  FooterCircle,
  FooterEmail,
  FooterLocation,
  FooterSquare,
  FooterTopSquare,
  InstaGram,
  LinkedLn,
  SendMail,
  TwitterLogo,
} from "../../../assets/svgs";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MainContainer } from "./SearchPart";
import { TopTrendingSectionWrap } from "../newLandingPage/TopTrendingSection";
import { HeaderWrap } from "../newOwnersPage/TopSearchSection";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

const FooterSectionHome = () => {
  const { width } = useWindowSize();
  const [sides, setSides] = useState("end");

  useEffect(() => {
    if (width > 991.98) {
      setSides("end");
    } else {
      setSides("center");
    }
  }, [width]);

  function getItem(label, key, children, type) {
    return {
      key,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Quick Link", "sub1", [
      getItem(<Link to="/account">My Account</Link>),
      getItem(<Link to="/about-us">About Us</Link>),
      getItem(<Link to="/career">Career</Link>),
      getItem(<Link to="/contact-us">Contact Us</Link>),
      getItem("Career"),
      getItem("Sitemap"),
    ]),
    getItem("Featured City", "sub2", [
      getItem("Alberta"),
      getItem("British Columbia"),
      getItem("Manitoba"),
      getItem("New Brunswick"),
      getItem("Newfoundland and Labrador"),
      getItem("Northwest Territories"),
    ]),
    getItem("Policy", "sub3", [
      getItem("Privacy policy"),
      getItem("Terms and Condition"),
      getItem("Payment Policy"),
      getItem("Property Owner Policy"),
    ]),
    getItem("Get into Touch", "sub4", [
      getItem(
        <Row>
          <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
            <FooterLocation />
          </Col>
          <Col span={21}>
            <p>4517 Washington Ave. Manchester, Kentucky 39495</p>
          </Col>
        </Row>
      ),
      getItem(
        <Row>
          <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
            <FooterCall />
          </Col>
          <Col span={21}>
            <p> +1 123 456 779</p>
          </Col>
        </Row>
      ),
      getItem(
        <Row>
          <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
            <FooterEmail />
          </Col>
          <Col span={21}>
            <p> support@avenew.app</p>
          </Col>
        </Row>
      ),
    ]),
  ];

  return (
    <>
      <MainContainer bgcolor="#26314B" containterHeight="auto">
        <TopTrendingSectionWrap
          margincomponent="0 auto 0"
          paddingcomponent="60px 0"
          margincomponentxs="30px 0"
        >
          {/* <FooterHomeWrap>
            <Row
              style={{ alignItems: "center" }}
              justify={sides}
              gutter={[10, 10]}
            >
              <Col>
                <span>Follow us</span>
              </Col>
              <Col>
                <FacebookSvg wSize="34" hSize="34" />
              </Col>
              <Col>
                <TwitterLogo />
              </Col>
              <Col>
                <LinkedLn />
              </Col>
              <Col>
                <InstaGram />
              </Col>
            </Row>
          </FooterHomeWrap> */}
          <NewsLetterWrap>
            <Row justify="space-between" style={{ alignItems: "center" }}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <h2>Subscribe to our newsletter and stay updated</h2>
              </Col>
              <Col
                className="footer__email"
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={10}
                xxl={10}
              >
                <SendEmail>
                  <Input.Group compact>
                    <Input
                      prefix={<EmailLogo />}
                      placeholder="Enter Your Email"
                      style={{
                        width: "calc(100% - 52px)",
                        height: "50px",
                      }}
                      type="email"
                    />
                    <Button>
                      <SendMail />
                    </Button>
                  </Input.Group>
                </SendEmail>
              </Col>
            </Row>
          </NewsLetterWrap>
          <NewsLetter>
            <Row justify="space-between">
              <Col xs={0} sm={0} md={0} lg={18}>
                <Row>
                  <Col xs={0} sm={0} md={0} lg={8}>
                    <div>
                      <FooterRectWrap />
                      <h2>Quick Link</h2>
                      <p>My Account</p>
                      <p>About Us</p>
                      <p>Career</p>
                      <p>Contact Us</p>
                    </div>
                  </Col>
                  {/* <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
                <FooterRectWrap />
                <h2>Featured City</h2>
                <p>Alberta</p>
                <p>British Columbia</p>
                <p>Manitoba</p>
                <p>New Brunswick</p>
                <p>Newfoundland and Labrador</p>
                <p>Northwest Territories</p>
              </Col> */}
                  <Col xs={0} sm={0} md={0} lg={8}>
                    <div>
                      <FooterRectWrap />
                      <h2>Policy</h2>
                      <p>Privacy policy</p>
                      <p>Terms and Condition</p>
                      <p>Payment Policy</p>
                      <p>Property Owner Policy</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} sm={0} md={0} lg={6}>
                <Row style={{ alignItems: "center" }}>
                  <Col>
                    <FooterRectWrap />
                    <h2>Get into Touch</h2>
                  </Col>
                </Row>
                <Row style={{ alignItems: "center" }}>
                  <Col
                    xs={2}
                    sm={2}
                    md={1}
                    lg={3}
                    xl={2}
                    xxl={2}
                    style={{
                      textAlign: "center",
                      marginTop: "-7px",
                    }}
                  >
                    <FooterEmail />
                  </Col>
                  <Col>
                    <p> support@avenew.app</p>
                  </Col>
                </Row>
              </Col>

              {/* <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
                <FooterRectWrap />
                <h2>Get into Touch</h2>
                <Row>
                  <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
                    <FooterLocation />
                  </Col>
                  <Col span={21}>
                    <p>4517 Washington Ave. Manchester, Kentucky 39495</p>
                  </Col>
                </Row>

                <Row>
                  <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
                    <FooterCall />
                  </Col>
                  <Col span={21}>
                    <p> +1 123 456 779</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
                    <FooterEmail />
                  </Col>
                  <Col span={21}>
                    <p> support@avenew.app</p>
                  </Col>
                </Row>
              </Col> */}

              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <Menu
                  mode="inline"
                  items={[
                    getItem("Quick Link", "sub1", [
                      getItem(<Link to="/account">My Account</Link>),
                      getItem(<Link to="/about-us">About Us</Link>),
                      getItem(<Link to="/career">Career</Link>),
                      getItem(<Link to="/contact-us">Contact Us</Link>),
                      getItem("Career"),
                      getItem("Sitemap"),
                    ]),
                  ]}
                  selectable={false}
                />
                <Divider />
                {/* <Menu
                  mode="inline"
                  items={[
                    getItem("Featured City", "sub2", [
                      getItem("Alberta"),
                      getItem("British Columbia"),
                      getItem("Manitoba"),
                      getItem("New Brunswick"),
                      getItem("Newfoundland and Labrador"),
                      getItem("Northwest Territories"),
                    ]),
                  ]}
                  selectable={false}
                /> 
                <Divider /> */}
                <Menu
                  mode="inline"
                  items={[
                    getItem("Policy", "sub3", [
                      getItem("Privacy policy"),
                      getItem("Terms and Condition"),
                      getItem("Payment Policy"),
                      getItem("Property Owner Policy"),
                    ]),
                  ]}
                  selectable={false}
                />
                {/* <Divider />
                <Menu
                  mode="inline"
                  items={[
                    getItem("Get into Touch", "sub4", [
                      getItem(
                        <Row>
                          <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
                            <FooterLocation />
                          </Col>
                          <Col span={21}>
                            <p>
                              4517 Washington Ave. Manchester, Kentucky 39495
                            </p>
                          </Col>
                        </Row>
                      ),
                      getItem(
                        <Row>
                          <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
                            <FooterCall />
                          </Col>
                          <Col span={21}>
                            <p> +1 123 456 779</p>
                          </Col>
                        </Row>
                      ),
                      getItem(
                        <Row>
                          <Col xs={2} sm={2} md={1} lg={3} xl={3} xxl={3}>
                            <FooterEmail />
                          </Col>
                          <Col span={21}>
                            <p> support@avenew.app</p>
                          </Col>
                        </Row>
                      ),
                    ]),
                  ]}
                  selectable={false}
                /> */}
              </Col>
            </Row>
          </NewsLetter>
        </TopTrendingSectionWrap>
        <FooterBottom>
          <HeaderWrap>
            <FooterBottomWrap>
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col>
                  <h4>© 2025 Avenew.com</h4>
                </Col>
                {/* <Col>
                  <h5>Site Map</h5>
                </Col> */}
              </Row>
            </FooterBottomWrap>
          </HeaderWrap>
        </FooterBottom>
        <div className="footer__circle">
          <FooterCircle />
        </div>
        <div className="footer__top__square">
          <FooterTopSquare />
        </div>
        <div className="footer__bottom__square">
          <FooterBottomCircle />
        </div>
        <div className="footer__left__square">
          <FooterSquare />
        </div>
      </MainContainer>
    </>
  );
};

export default FooterSectionHome;

const FooterHomeWrap = styled.div`
  padding: 50px 0;
  z-index: 1;
  position: relative;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #8f97ac;
  }
`;

const FooterBottom = styled.div`
  width: 100%;
  background-color: #1d263c;
  h5,
  h4 {
    margin-bottom: 0;
  }
`;

const FooterBottomWrap = styled.div`
  width: 1400px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
  }
  h5 {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
  }
  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }
`;

const FooterRectWrap = styled.div`
  width: 25px;
  height: 5px;
  margin-bottom: 10px;
  background-color: #4160ac;
`;

const NewsLetterWrap = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  padding: 20px 75px;
  margin-bottom: 70px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);

  .footer__email {
    justify-content: end;
    display: flex;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 0;
  }

  @media (max-width: 1550px) {
    margin-bottom: 55px;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media (max-width: 1400px) {
    padding: 30px;
    margin-bottom: 30px;

    h2 {
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }

  @media (max-width: 900px) {
    padding: 20px;
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }
  }
`;

const NewsLetter = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #8f97ac;
  }

  span {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    ${"" /* line-height: 24px; */}
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    ${"" /* line-height: 40px; */}
    letter-spacing: 0.05em;
    color: #9ea6ba;
    ${"" /* overflow: scroll; */}
  }

  .ant-divider-horizontal {
    background: rgba(255, 255, 255, 0.1);
    margin: 10px 0;
    height: 1px;
  }
  .social__icon {
    margin-top: 36px;
    margin-bottom: 38px;
    justify-content: space-between;
    cursor: pointer;
  }

  a {
    color: #848687;
  }

  .ant-menu {
    background: transparent;
  }

  .ant-menu-submenu-title {
    padding-left: 0 !important;
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    letter-spacing: 0.05em !important;
    color: #848687 !important;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 25px !important;
  }

  .ant-menu-submenu-arrow,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #999;
  }

  .ant-menu-item {
    ${"" /* height: 25px !important; */}
  }

  @media (max-width: 1440px) {
    padding: 0;
  }
  @media (max-width: 991.98px) {
    .avenew__bottom__logo {
      text-align: center;
    }
    .icon__wrap {
      justify-content: center;
    }
  }
`;
