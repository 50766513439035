import React, { useState } from "react";
import TopSearchSection, {
  H2Style,
  HeaderWrap,
  LeftSidehWrap,
  MainContainer,
  RightSidehWrap,
  SearchWrap,
  SideBarLogin,
  SideMainWrap,
  SidebarContainer,
  StyledButton,
} from "../TopSearchSection";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import {
  EmailSvg,
  HeaderPentagonSvg,
  HeaderStyleSvg,
  LocationSvg,
  MenuSvg,
  PhoneSvg,
} from "../../../../assets/svgs";
import { IoIosArrowDown } from "react-icons/io";
import {
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "../../header/Header";
import styled from "styled-components";
import { OwnersContainer } from "../MarketingSection";
import { RectangleWrap } from "../FeatureSection";
import FooterSection from "../FooterSection";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { logout, ownerContacts } from "../../../../store/reducers/user.reducer";

const OwnersContactUs = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [openSideBar, setopenSider] = useState(true);
  const [loadings, setloadings] = useState(false);
  const [checked, setChecked] = useState(0);
  const [allNotifications, setAllNotifications] = useState([]);

  const url = window.location.host;
  const part = url.split(".");
  const subDomain = part[0];

  // for logout
  const handleLogout = () => {
    dispatch(logout());
  };

  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSiderMenu");
    if (x.length > 0) {
      document.body.classList.remove("openSiderMenu");
      setopenSider(true);
      document.body.style.overflow = "hidden";
    } else {
      document.body.classList.add("openSiderMenu");
      setopenSider(false);
      document.body.style.overflow = "visible";
    }
  };

  const onChange = (e) => {
    if (e.target.checked === true) {
      setChecked(1);
    } else {
      setChecked(0);
    }
  };

  const onFinish = (value) => {
    setloadings(true);
    value.accepted_tnc = checked;
    dispatch(ownerContacts(value)).then((res) => {
      setloadings(false);
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
        message.success("Form submitted successfully");
      }
      form.resetFields();
    });
  };

  // Form validation message
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  // header dropdown header
  const menu = (
    <>
      {localStorage.getItem("user_id") && localStorage.getItem("idToken") ? (
        <StyledHeaderDropdownContentWrappeer>
          <StyledHeaderDropdownMenuItem key="0">
            <Link to="/account" className="nav-link">
              Account
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="1">
            <Link to="/favorite" className="nav-link">
              Wishlist
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="2">
            <Link to="/application-process" className="nav-link">
              User Profile Verification
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="3">
            <Link to={user ? "/applications" : "/"} className="nav-link">
              Applications
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="4">
            <Link to="/message" className="nav-link">
              <Badge count={allNotifications?.length}>Messages</Badge>
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="5">
            <Link to="/my-transaction" className="nav-link">
              My Payments or Billing
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="6">
            <Link to="/my-rentals" className="nav-link">
              My Rentals
            </Link>
          </StyledHeaderDropdownMenuItem>
          <Divider />

          <StyledHeaderDropdownMenuItem key="7">
            <Link to="/owners-contact-us" className="nav-link need__help">
              Need a Help?
            </Link>
          </StyledHeaderDropdownMenuItem>
          <StyledHeaderDropdownMenuItem key="8" bgColor="#f9f9f9">
            <Link
              to="/"
              onClick={handleLogout}
              className="nav-link logout__title"
            >
              {localStorage.getItem("user_id") &&
              localStorage.getItem("idToken")
                ? "Log out"
                : " Login"}
            </Link>
          </StyledHeaderDropdownMenuItem>
        </StyledHeaderDropdownContentWrappeer>
      ) : (
        <StyledHeaderDropdownContentWrappeer>
          <StyledHeaderDropdownMenuItem key="10">
            {/* <Link to="/" onClick={handleLogout}>
              {localStorage.getItem("user_id") &&
              localStorage.getItem("idToken")
                ? "Log out"
                : " Login"}
            </Link> */}
            <a
              href={`https://${subDomain}.admin.avenew.app/login`}
              target="_blank"
              // className="nav-link need__help"
            >
              Login
            </a>
          </StyledHeaderDropdownMenuItem>
          <Divider />
          <StyledHeaderDropdownMenuItem key="11">
            <Link to="/owners-contact-us" className="nav-link need__help">
              Need a Help?
            </Link>
          </StyledHeaderDropdownMenuItem>
        </StyledHeaderDropdownContentWrappeer>
      )}
    </>
  );

  const prefixSelector = (
    <SelectArrow>
      <Form.Item noStyle>
        <PrefixStyle>
          <span>+1</span>
          {/* <Select defaultValue="+1">
            <Option value="+1">+1</Option>
          </Select> */}
        </PrefixStyle>
      </Form.Item>
    </SelectArrow>
  );

  return (
    <>
      <MainContainer style={{ height: "100%" }}>
        <SidebarContainer className="sidebar-panel">
          <SideMainWrap>
            <LeftSidehWrap
              style={{ flexDirection: "column", paddingBottom: "20px" }}
            >
              <Link to="/owners-page">
                <span>Home</span>
              </Link>
              <span> Pricing</span>
              {/* <span onClick={() => navigate("/owners-contact-us")}>
                Contact Us
              </span> */}
            </LeftSidehWrap>
            <a
              href={`https://${subDomain}.admin.avenew.app/login`}
              target="_blank"
            >
              <StyledButton
                paddingBtn="4px 10px"
                // onClick={() => navigate("/login")}
              >
                Create an account
              </StyledButton>{" "}
            </a>
            <SideBarLogin>
              <a
                href={`https://${subDomain}.admin.avenew.app/login`}
                target="_blank"
              >
                <p style={{ marginTop: "25px" }}>Log in</p>
              </a>
              <Divider />
              {/* <Link to="/owners-contact-us" className="nav-link need__help">
                Need a Help?
              </Link> */}
            </SideBarLogin>
          </SideMainWrap>
        </SidebarContainer>
        <HeaderWrap>
          <SearchWrap>
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col xs={20} sm={20} md={20} lg={0} xl={0} xxl={0}>
                <LeftSidehWrap>
                  <Link to="/" className="nav-link">
                    <img
                      src="/images/avenewLogoSearch.png"
                      alt="Avenew Logo"
                      className="avenew_logo"
                    />
                  </Link>
                </LeftSidehWrap>
              </Col>
              <Col
                xs={4}
                sm={4}
                md={4}
                lg={0}
                xl={0}
                xxl={0}
                className="menu__header"
                onClick={openSider}
              >
                <MenuSvg />
              </Col>
              <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                <LeftSidehWrap>
                  <Link to="/" className="nav-link">
                    <img
                      src="/images/avenewLogoSearch.png"
                      alt="Avenew Logo"
                      className="avenew_logo"
                    />
                  </Link>
                  <span onClick={() => navigate("/owners-page")}>Home</span>
                  <span> Pricing</span>
                  <span onClick={() => navigate("/owners-contact-us")}>
                    Contact Us
                  </span>
                </LeftSidehWrap>
              </Col>
              <Col
                className="right__header"
                xs={0}
                sm={0}
                md={0}
                lg={12}
                xl={12}
                xxl={12}
                // style={{
                //   alignItems: "center",
                //   display: "flex",
                //   justifyContent: "end",
                // }}
              >
                <RightSidehWrap>
                  <h1 onClick={() => navigate("/")}>Find property</h1>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                    // overlayClassName="header_dropdown"
                  >
                    <img
                      src="/images/userProfileIcon.svg"
                      className="user__icon"
                    />
                  </Dropdown>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                    // overlayClassName="header_dropdown"
                  >
                    <IoIosArrowDown className="header__arrow" />
                  </Dropdown>
                  <a
                    href={`https://${subDomain}.admin.avenew.app/login`}
                    target="_blank"
                  >
                    <StyledButton
                    // onClick={() => navigate("/login")}
                    >
                      Create an account
                    </StyledButton>
                  </a>
                </RightSidehWrap>
              </Col>
            </Row>
          </SearchWrap>
          <div className="pentagon__svg">
            <HeaderPentagonSvg />
          </div>
        </HeaderWrap>
        <div className="circle__svg">
          <HeaderStyleSvg />
        </div>
        <HeaderWrap>
          <ContactTitleWrap>
            <ContentWrap>
              <Row>
                <Col span={24}>
                  <H2Style className="contact_title">
                    Ready to Connect? Let's Start the Conversation!
                  </H2Style>
                </Col>
                <Col span={24}>
                  <p>
                    Thank you for considering Avenew as your software partner.
                    We're excited to collaborate with you to create solutions
                    that drive your business forward. Whether you have
                    questions, ideas, or specific requirements, we're here to
                    assist you every step of the way.
                  </p>
                </Col>
              </Row>
            </ContentWrap>
          </ContactTitleWrap>
        </HeaderWrap>
      </MainContainer>
      <OwnersContainer>
        <ContactDetailWrap>
          <Row>
            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <Col span={24}>
                <RectangleWrap />
              </Col>
              <H2Style className="h2_style">Get In Touch with Us</H2Style>
              <p>
                Simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s,
              </p>
              <Row gutter={[20, 20]}>
                <Col span={4}>
                  <LocationSvg />
                </Col>
                <Col span={18}>
                  <h4>Address</h4>
                  <h5>4517 Washington Ave. Manchester, Kentucky 39495</h5>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={4}>
                  <PhoneSvg />
                </Col>
                <Col span={18}>
                  <h4>Phone number</h4>
                  <h5>+1 123 456 7890</h5>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={4}>
                  <EmailSvg />
                </Col>
                <Col span={18}>
                  <h4>Write us</h4>
                  <h5>support@avenew.com</h5>
                </Col>
              </Row>
              <img src="./images/dallasMap.png" alt="Dallas Map"></img>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={{ span: 12, offset: 2 }}
              xl={{ span: 12, offset: 2 }}
              xxl={{ span: 12, offset: 2 }}
            >
              <FormWrap>
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    label="Your name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Your Name is required ",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Phone number"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                      },
                      { max: 10, message: "Invalid Phone Number!" },
                      { min: 10, message: "Invalid Phone Number!" },
                    ]}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      type="number"
                      min="0"
                      pattern="[0-9]*"
                      onWheel={(e) => e.target.blur()}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Company"
                    name="company"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Company Name is required ",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Comment"
                    name="comment"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="accepted_tnc"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "You must accept the terms and conditions"
                                )
                              ),
                        // required: true,
                        // message: "You must accet terms and conditions",
                      },
                    ]}
                  >
                    <Checkbox onChange={onChange} checked={checked}>
                      Accept{" "}
                      <a href="#" target="blank">
                        terms and conditions
                      </a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <StyledButton
                          htmlType="submit"
                          loading={loadings}
                          style={{ margin: "0px" }}
                        >
                          Send message
                        </StyledButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </FormWrap>
            </Col>
          </Row>
        </ContactDetailWrap>
      </OwnersContainer>
      <FooterSection />
    </>
  );
};

export default OwnersContactUs;

const ContactTitleWrap = styled.div`
  width: 1200px;
  padding: 0 40px;
  margin: 50px auto 80px;

  @media (max-width: 1400px) {
    margin: 40px auto 50px;
    width: 100%;
  }
`;

const FormWrap = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);

  a {
    color: #4160ac;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  .ant-input {
    border: none;
    border-bottom: 1px solid #ddd;
    background: #fff;
  }
  .ant-form-item-label {
    color: #777777;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px; /* 230.769% */
    letter-spacing: 0.65px;
    text-transform: uppercase;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  .ant-input-group-addon {
    background-color: #fff;
    width: 20%;
    boder-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: none;
    border-left: none;
  }
  .ant-select-selection-item {
    color: #2b353f;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px; /* 166.667% */
    letter-spacing: 0.9px;
  }

  .ant-input:focus,
  .ant-input .ant-input-status-error:focus {
    box-shadow: none;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:focus {
    box-shadow: none;
  }

  .ant-form-item input {
    color: #aaa;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px; /* 166.667% */
    letter-spacing: 0.9px;
  }
`;

const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0px 170px;
  padding-bottom: 100px;

  .contact_title {
    text-align: center;
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 30px;
    color: #848687;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }
  @media (max-width: 991px) {
    padding: 0px 100px;
  }
  @media (max-width: 769px) {
    padding: 0px 30px;
  }
`;

const ContactDetailWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;

  p {
    color: #848687;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .h2_style {
    line-height: normal;
    font-size: 40px;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  h4 {
    color: #848687;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
    letter-spacing: 0.65px;
    text-transform: uppercase;
  }

  svg {
    width: 100%;
  }

  h5 {
    margin-top: -5px;
    color: #2b353f;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px; /* 187.5% */
  }

  img {
    margin-top: 50px;
    display: flex;
    width: 100%;
  }

  @media (max-width: 1400px) {
    margin: 0;
    margin-bottom: 30px;
    width: 100%;
    padding: 0px 30px;
    h5 {
      font-size: 12px;
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 50px;
    padding: 50px 30px;
    .h2_style {
      font-size: 30px;
    }
    img {
      padding-bottom: 30px;
    }
    h5 {
      width: 100%;
      line-height: 20px;
    }
    h4 {
      line-height: 18px;
    }
  }
`;

const SelectArrow = styled.div`
  border: none;
  .ant-select-arrow {
    color: #2b353f;
  }
`;
const PrefixStyle = styled.div`
  color: #2b353f;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px; /* 166.667% */
  letter-spacing: 0.9px;
`;
