import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaBath } from "react-icons/fa";
import { IoIosBed, IoIosClose } from "react-icons/io";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import styled from "styled-components";
import { SqFtSvg } from "../../../assets/svgs";
import { StyledTitle } from "../tabs/floor-plan-modal/FloorPlanModal";
import { StyledForm, StyledForms } from "../../my-account/ChangePassword";
import { StyledFormSelect } from "../../Profile-verification/BackgroundCheck";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  applicationStart,
  getUnitsDetails,
} from "../../../store/reducers/user.reducer";

function ApplyForPropertyModal({ modalState, onCancel }) {
  const lengthOFPlan = [1, 2];
  const { Option } = Select;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unitDetails, setUnitDetails] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectWrap, setSelectWrap] = useState("");
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.user);

  const [selectedSubUnit, setSelectedSubUnit] = useState("");
  const [allProperty, setAllProperty] = useState({
    property: [
      ...lengthOFPlan.map((id) => {
        return { ...id, selected: false };
      }),
    ],
  });

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment().add(-1, "days");
  };
  const [selectId, setSelectID] = useState(1);

  const selectWrapFun = (id) => {
    allProperty.property[id].selected = !allProperty.property[id].selected;
    setSelectWrap(allProperty);
  };

  // get unit details (from API)
  useEffect(() => {
    if (params.id && modalState) {
      localStorage.setItem("unit-details", params.id);
      dispatch(getUnitsDetails()).then(({ payload }) => {
        setUnitDetails(payload[0]);
      });
    }
  }, [params.id, modalState]);

  // on submit sent application to Admin
  const onFinish = (value) => {
    value.unitId = unitDetails.id;
    value.adminUserId = unitDetails?.user_id;
    value.org_id = unitDetails?.org_id;
    value.prospectUserId = user.id;
    value.apartment_id = unitDetails?.apartment_id;
    // value.userObj = user;
    // value.creditScoreVerified = true;
    // value.backgroundCheckVerified = true;
    // value.incomeVerified = true;
    // value.previousRental = true;
    setIsSubmitLoading(true);
    dispatch(applicationStart(value)).then(({ payload }) => {
      setIsSubmitLoading(false);
      onCancel(false);
      if (payload.Error) {
        message.info("Application already exists");
      } else {
        form.resetFields();
        navigate({
          pathname: `/applications`,
          search: createSearchParams({
            unitId: payload?.unitId,
          }).toString(),
        });
      }
    });
  };

  // form alert messages
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={"600px"}
      footer={null}
      style={{ borderRadius: "5px", top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <StyledTitle>Apply for Property</StyledTitle>
      {/* <SelectFloor>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <h4>Select Floor plan</h4>
          </Col>
          {allProperty.property.map((value, id) => (
            <Col key={id} span={24}>
              <PropertyCard
                br={selectedSubUnit === id}
                onClick={() => {
                  selectWrapFun(id);
                  setSelectedSubUnit(id);
                }}
              >
                <Row justify="space-between">
                  <Col className="floor__wrap">
                    <Col>
                      <ImageWrap>
                        <img src="/images/floor-plan.png" />
                      </ImageWrap>
                    </Col>
                    <Col className="property__id">
                      <PropertyId>
                        <p>#895625</p>
                      </PropertyId>
                      <h2>E6</h2>
                    </Col>
                  </Col>
                  <Row gutter={[20, 20]}>
                    <Col className="title__icon">
                      <IoIosBed />
                      <p>Beds : 02</p>
                    </Col>
                    <Col className="title__icon">
                      <FaBath />
                      <p>Baths : 02</p>
                    </Col>
                    <Col className="title__icon">
                      <SqFtSvg />
                      <p>Sq. ft : 1500</p>
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={1} xl={1} xxl={1}>
                      <Divider type="vertical" />
                    </Col>
                    <Col>
                      <h3>$5898.00 - $6265.00</h3>
                    </Col>
                  </Row>
                </Row>
              </PropertyCard>
            </Col>
          ))}
        </Row>
      </SelectFloor> */}
      <PropertyDetails>
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms mb="0">
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <AvailableDate>
                    <StyledFormSelect wh="100">
                      <Form.Item
                        name="selectedDate"
                        // label="Form of Identification"
                        rules={[
                          {
                            required: true,
                            message: "Date is required.",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="Move-In date"
                          format="DD MMM, YYYY"
                          disabledDate={disabledDate}
                          allowClear={false}
                        />
                      </Form.Item>
                    </StyledFormSelect>
                  </AvailableDate>
                </Col>
                {/* <Col span={24}>
                  <StyledFormSelect wh="100">
                    <Form.Item
                      name="selectedAvailableUnit"
                      // label="Form of Identification"
                    >
                      <SelectWrap
                        placeholder="Select Available Unit"
                        // onChange={}
                        bgColor="#E3E9F4"
                      >
                        <Option value="Select Available Unit A">
                          Select Available Unit A
                        </Option>
                        <Option value="Select Available Unit B">
                          Select Available Unit B
                        </Option>
                        <Option value="Select Available Unit C">
                          Select Available Unit C
                        </Option>
                      </SelectWrap>
                    </Form.Item>
                  </StyledFormSelect>
                </Col> */}
                <Col span={24}>
                  <StyledFormSelect wh="100">
                    <Form.Item
                      name="leaseLength"
                      rules={[
                        {
                          required: true,
                          message: "Lease Length is required.",
                        },
                      ]}
                      // label="Form of Identification"
                    >
                      <SelectWrap
                        placeholder="Lease Length"
                        // onChange={}
                        bgColor="#E3E9F4"
                      >
                        <Option value="3 Months">3 Months</Option>
                        <Option value="6 Months">6 Months</Option>
                        <Option value="9 Months">9 Months</Option>x
                        <Option value="12 Months">12 Months</Option>
                      </SelectWrap>
                    </Form.Item>
                  </StyledFormSelect>
                </Col>
              </Row>
              <Form.Item>
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <StyledButton loading={isSubmitLoading} htmlType="submit">
                      SUBMIT
                    </StyledButton>
                  </Col>
                  <Col span={24} className="cancel__btn">
                    <span onClick={() => onCancel(false)}>CANCEL</span>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </PropertyDetails>
    </Modal>
  );
}

export default ApplyForPropertyModal;

export const StyledButton = styled(Button)`
  width: ${({ btnwidthedit }) => btnwidthedit || "100%"};
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  background: ${({ backgroundcolor }) => backgroundcolor || "#4160AC"};
  padding: ${({ padding }) => padding || "4px 15px"};
  border-color: ${({ backgroundcolor }) => backgroundcolor || "#4160AC"};
  text-transform: uppercase;
  margin-top: ${({ mt }) => mt || "20px"};

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    ${"" /* line-height: 22px; */}
    /* identical to box height */
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ backgroundcolor }) => backgroundcolor || "#4160AC"}90;
    background: ${({ backgroundcolor }) => backgroundcolor || "#4160AC"}90;
  }

  :disabled {
    border-color: ${({ backgroundcolor }) => backgroundcolor || "#4160AC"}90;
    background: ${({ backgroundcolor }) => backgroundcolor || "#4160AC"}90;
  }

  @media (max-width: 1550px) {
    height: 40px;
    span {
      letter-spacing: 0.5px;
    }
  }
`;

export const SelectWrap = styled(Select)`
  width: 100%;
  .ant-select-selection-placeholder {
    color: #2b353f !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #2b353f !important;
  }
  span {
    color: #2b353f !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: #eeeeee !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 50px !important;
    align-items: center;
    padding: 0 25px !important;
  }
  .ant-select:hover {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }

  .ant-select-selector {
    border-color: #aaaaaa !important;
  }

  .ant-select-focused {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
  .ant-select-arrow {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
  }
  .ant-select-item-option {
    min-height: 57px !important;
  }
  .ant-select-item,
  .ant-select-item-option,
  .ant-select-item-option-selected {
    min-height: 57px !important;
    height: 57px !important;
  }

  @media (max-width: 1550px) {
    .ant-select-selector {
      height: 40px !important;
    }
  }
`;

export const AvailableDate = styled.div`
  ${"" /* background: #eeeeee; */}
  ${"" /* border-radius: 5px; */}
  ${"" /* padding: 20px 25px; */}
  display: flex;
  svg {
    margin-right: 20px;
    width: 19px;
    height: 19px;
  }
  h2 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
  }
  .ant-picker {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    background: #eeeeee;
  }
  .ant-picker-input {
    flex-direction: row-reverse;
  }
  .ant-picker-input > input {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
  }
  input::placeholder {
    color: #2b353f;
  }
  .ant-picker-suffix {
    color: #2b353f;
    margin-left: 0;
  }
  .ant-picker-focused {
    border-color: #eee;
    box-shadow: 0 0 0 2px #eee20;
    border-right-width: 1px;
    outline: 0;
  }
  @media (max-width: 1550px) {
    .ant-picker {
      height: 40px;
    }
  }
`;

export const PropertyDetails = styled.div`
  margin-top: 20px;
  .cancel__btn {
    text-align: center;
    span {
      transition: transform 0.4s;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #777777;
      cursor: pointer;
      display: inline-block;
      :hover {
        -ms-transform: scale(0.95); /* IE 9 */
        -webkit-transform: scale(0.95); /* Safari 3-8 */
        transform: scale(0.95);
      }
    }
  }
`;

export const PropertyId = styled.div`
  background: #4160ac;
  padding: 2px 8px;
  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin-bottom: 0;
  }
`;
export const ImageWrap = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 8px 3px;
  display: inline-block;
  img {
    width: 45px;
  }
`;
export const PropertyCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  border: 3px solid rgb(246, 124, 54);
  border: ${({ br }) => (br ? "3px solid rgb(246, 124, 54)" : "none")};
  cursor: pointer;
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
    margin-top: 5px;
  }
  h3 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #4160ac;
  }
  .floor__wrap {
    display: flex;
  }

  .title__icon {
    display: flex;
    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #848687;
      margin-bottom: 0;
    }
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }
  .property__id {
    margin-left: 12px;
  }
  .ant-divider-vertical {
    height: 2.5em;
    background: #d9d9d9;
  }
  @media (max-width: 769px) {
    .floor__wrap {
      margin-bottom: 15px;
    }
  }
`;

export const SelectFloor = styled.div`
  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
    margin-bottom: 0;
  }
  .ant-row {
    align-items: center;
  }
`;
