import React from "react";

const footerBottomCircle = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="216"
      viewBox="0 0 130 216"
      fill="none"
    >
      <path
        d="M195.05 128.375C206.421 80.2112 176.584 31.9511 128.408 20.5834C80.2319 9.21559 31.9599 39.0449 20.5893 87.209C9.21869 135.373 39.0554 183.633 87.2315 195.001C135.408 206.369 183.68 176.539 195.05 128.375Z"
        fill="#1F293F"
      />
      <path
        d="M129.201 11.6201C129.201 12.7889 128.855 13.9313 128.205 14.9031C127.556 15.8749 126.633 16.6323 125.552 17.0795C124.472 17.5268 123.284 17.6438 122.137 17.4158C120.991 17.1878 119.938 16.625 119.111 15.7986C118.284 14.9722 117.722 13.9192 117.493 12.773C117.265 11.6267 117.382 10.4385 117.83 9.35878C118.277 8.27902 119.035 7.35613 120.007 6.70681C120.979 6.0575 122.122 5.71094 123.291 5.71094C124.856 5.718 126.355 6.34284 127.462 7.44951C128.569 8.55617 129.194 10.0551 129.201 11.6201Z"
        fill="#404B65"
      />
      <path
        d="M89.4933 19.0884C90.0817 20.0995 90.3566 21.2625 90.2832 22.43C90.2099 23.5974 89.7918 24.7169 89.0816 25.6465C88.3714 26.5761 87.4011 27.2741 86.2937 27.652C85.1864 28.0299 83.9918 28.0708 82.8613 27.7694C81.7307 27.4679 80.7152 26.8379 79.9431 25.9589C79.171 25.08 78.6772 23.9917 78.5243 22.8319C78.3714 21.6722 78.5663 20.4932 79.0842 19.4442C79.602 18.3953 80.4195 17.5236 81.4333 16.9396C82.1032 16.5496 82.8436 16.2959 83.6118 16.1928C84.3801 16.0898 85.1611 16.1396 85.91 16.3392C86.659 16.5389 87.3613 16.8846 87.9762 17.3564C88.5911 17.8282 89.1066 18.4168 89.4933 19.0884Z"
        fill="#404B65"
      />
      <path
        d="M58.8371 45.4084C59.8509 45.9924 60.6686 46.864 61.1865 47.913C61.7043 48.9619 61.899 50.141 61.7461 51.3007C61.5932 52.4604 61.0994 53.5487 60.3274 54.4277C59.5553 55.3066 58.5397 55.9367 57.4092 56.2381C56.2786 56.5395 55.084 56.4987 53.9767 56.1208C52.8694 55.7429 51.8991 55.0449 51.1889 54.1153C50.4786 53.1856 50.0605 52.0662 49.9872 50.8987C49.9139 49.7313 50.1888 48.5683 50.7771 47.5572C51.5682 46.2115 52.8575 45.2315 54.366 44.8293C55.8745 44.4272 57.4809 44.6352 58.8371 45.4084Z"
        fill="#404B65"
      />
      <path
        d="M45.4577 83.524C46.6267 83.5347 47.7662 83.8916 48.7323 84.5498C49.6983 85.2079 50.4475 86.1377 50.885 87.2214C51.3225 88.3052 51.4287 89.4943 51.1902 90.6385C50.9517 91.7826 50.3792 92.8303 49.5451 93.6492C48.711 94.468 47.6528 95.0212 46.5043 95.2388C45.3557 95.4564 44.1684 95.3286 43.0925 94.8716C42.0166 94.4146 41.1004 93.6489 40.4597 92.6713C39.8191 91.6937 39.4828 90.5481 39.4934 89.3795C39.4969 88.6024 39.6544 87.8338 39.9567 87.1179C40.259 86.402 40.7002 85.7531 41.2547 85.2087C41.8093 84.6643 42.4661 84.2351 43.1876 83.9459C43.909 83.6567 44.6806 83.5133 45.4577 83.524Z"
        fill="#404B65"
      />
      <path
        d="M52.928 123.223C53.9393 122.635 55.1026 122.36 56.2704 122.433C57.4381 122.507 58.5578 122.925 59.4877 123.635C60.4176 124.345 61.1157 125.315 61.4937 126.422C61.8717 127.529 61.9126 128.723 61.6111 129.854C61.3096 130.984 60.6794 131.999 59.8002 132.771C58.9211 133.543 57.8325 134.037 56.6725 134.19C55.5124 134.342 54.3332 134.148 53.284 133.63C52.2348 133.112 51.3628 132.295 50.7787 131.281C49.9982 129.927 49.786 128.319 50.1888 126.809C50.5916 125.298 51.5766 124.009 52.928 123.223Z"
        fill="#404B65"
      />
      <path
        d="M79.2563 153.846C79.838 152.831 80.7077 152.012 81.7552 151.492C82.8028 150.972 83.9811 150.774 85.1411 150.924C86.3011 151.073 87.3906 151.564 88.2717 152.333C89.1528 153.102 89.7859 154.115 90.0907 155.244C90.3956 156.373 90.3587 157.567 89.9845 158.675C89.6103 159.783 88.9157 160.755 87.9887 161.468C87.0616 162.181 85.9438 162.603 84.7768 162.68C83.6097 162.758 82.4459 162.488 81.4325 161.904C80.7585 161.519 80.1673 161.006 79.6927 160.392C79.2181 159.778 78.8696 159.076 78.6673 158.327C78.4651 157.578 78.4129 156.797 78.514 156.028C78.6151 155.259 78.8674 154.517 79.2563 153.846Z"
        fill="#404B65"
      />
      <path
        d="M117.407 167.272C117.407 166.104 117.754 164.961 118.403 163.99C119.053 163.018 119.976 162.26 121.056 161.813C122.136 161.366 123.325 161.249 124.471 161.477C125.618 161.705 126.671 162.268 127.497 163.094C128.324 163.92 128.887 164.973 129.115 166.12C129.343 167.266 129.226 168.454 128.779 169.534C128.331 170.614 127.574 171.536 126.602 172.186C125.63 172.835 124.487 173.182 123.318 173.182C122.541 173.185 121.77 173.035 121.052 172.739C120.333 172.443 119.68 172.008 119.131 171.459C118.581 170.909 118.146 170.257 117.85 169.538C117.554 168.819 117.404 168.049 117.407 167.272Z"
        fill="#404B65"
      />
    </svg>
  );
};

export default footerBottomCircle;
