import React, { useState } from "react";
import { MainContainer, StyledButton } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import styled from "styled-components";
import { Col, Row } from "antd";
import { H2Style } from "./MultiApplicationPart";
import { DreamCircle, DreamSquare, DreamTriangle } from "../../../assets/svgs";
import LoginModal from "../../../pages/login/login-modal/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationProcess,
  me,
} from "../../../store/reducers/user.reducer";
import { useNavigate } from "react-router-dom";

function YourDreamHome() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [btnValue, setBtnValue] = useState("");
  const [loginModalState, setLoginModalState] = useState(false);

  const onLogin = (payload) => {
    if (payload?.id) {
      dispatch(me());
      dispatch(getApplicationProcess());
    }
  };

  return (
    <MainContainer bgcolor="#FFFfff" containterHeight="auto">
      <TopTrendingSectionWrap margincomponent="0 auto 0">
        <YourDreamHomeWrap>
          <Row
            // gutter={[40, 40]}
            style={{ alignItems: "center" }}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Row>
                <Col span={24}>
                  <Rectangle className="divider__long__sm" />
                </Col>
                <Col span={24}>
                  <H2Style
                    className="your__dream"
                    lineheightxs="32px"
                    marginBottom="20px"
                    marginTop="15px"
                    paddingBottomxs="20px"
                    paddingtopxs="12px"
                  >
                    Your dream home is just one application away! Apply now and
                    discover your options
                  </H2Style>
                </Col>
                <Col className="find__pro__btn">
                  {user ? (
                    <StyledButton
                      btnwidth="186px"
                      btnwidthsm="170px"
                      style={{ marginLeft: "0px", color: "#ffffff" }}
                      onClick={() => navigate("/property-listing")}
                    >
                      Apply Now
                    </StyledButton>
                  ) : (
                    <StyledButton
                      btnwidth="186px"
                      btnwidthsm="170px"
                      style={{ marginLeft: "0px", color: "#ffffff" }}
                      onClick={() => setLoginModalState(true)}
                    >
                      Signup now
                    </StyledButton>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <img src="/images/dream-home.png" />
              <div className="dream__triangle">
                <DreamTriangle />
              </div>
            </Col>
          </Row>
        </YourDreamHomeWrap>
      </TopTrendingSectionWrap>
      <div className="dream__square">
        <DreamSquare />
      </div>
      <div className="dream__circle">
        <DreamCircle />
      </div>

      <LoginModal
        onLogin={onLogin}
        modalState={loginModalState}
        onCancel={(value) => setLoginModalState(value)}
      />
    </MainContainer>
  );
}

export default YourDreamHome;

const YourDreamHomeWrap = styled.div`
  position: relative;
  z-index: 1;
  .dream__triangle {
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    ${"" /* position: absolute; */}
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 991.98px) {
    img {
      margin-top: 40px;
    }
    .divider__long__sm {
      margin: auto;
    }
    .find__pro__btn {
      margin: auto;
    }
    .your__dream {
      text-align: center;
    }

    .dream__triangle {
      display: none;
    }
  }
`;
