import React from "react";

const FooterLocation = ({
  wSize = "13",
  hSize = "18",
  bgColor = "#ffffff",
  color = "#848687",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0C2.91107 0 0 2.8215 0 6.3C0 11.025 6.5 18 6.5 18C6.5 18 13 11.025 13 6.3C13 2.8215 10.0889 0 6.5 0ZM6.5 8.55C5.21857 8.55 4.17857 7.542 4.17857 6.3C4.17857 5.058 5.21857 4.05 6.5 4.05C7.78143 4.05 8.82143 5.058 8.82143 6.3C8.82143 7.542 7.78143 8.55 6.5 8.55Z"
        fill={color}
      />
    </svg>
  );
};

export default FooterLocation;
