import React, { useEffect } from "react";
import { ApplicationsWrap } from "./Applications";
import { Col, Form, Input, Row, Button } from "antd";
import styled from "styled-components";

function ChangePassword() {
  const onFinish = (value) => {
    // console.log(value);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ChangePasswordWrap>
      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={"optional"}
            validateMessages={validateMessages}
          >
            <StyledFormItem wh="100" smwh="100" tabSmwh="100">
              <Form.Item
                name="oldPassword"
                label="Old Password"
                className="password-w-icon"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 8,
                    message: "password is not correct.",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem wh="100" smwh="100" tabSmwh="100">
              <Form.Item
                name="newPassword"
                label="New Password"
                className="password-w-icon"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 8,
                    message: "password should contain minimum 8 characters",
                  },
                ]}
              >
                <Input type="password" placeholder="New Password" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem wh="100" smwh="100" tabSmwh="100">
              <Form.Item
                name="confirm"
                label="Re-Type New Password"
                dependencies={["newPassword"]}
                rules={[
                  { required: true, message: "Please confirm your password." },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match."
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input type="password" placeholder="Re-Type New Password" />
              </Form.Item>
            </StyledFormItem>
            <Form.Item>
              <Row justify="end">
                <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={5}>
                  <StyledButton htmlType="submit">Save</StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </ChangePasswordWrap>
  );
}

export default ChangePassword;

export const ChangePasswordWrap = styled.div`
  width: 100%;
  padding: 20px 50px;
  min-height: 500px;

  @media (max-width: 991.98px) {
    padding: 20px 0px;
  }
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  // width: 50%;
  width: ${({ wh }) => wh || "50"}%;
  max-width: 100%;
  margin: auto;
  // min-height: 100vh;

  @media (max-width: 1199px) {
    width: ${({ smwh }) => smwh || "50"}%;
  }
  @media (max-width: 768px) {
    width: ${({ xswh }) => xswh || "50"}%;
  }
  @media (max-width: 576px) {
    width: ${({ xxswh }) => xxswh || "50"}%;
  }
`;

export const StyledForms = styled.div`
  .ant-form-item {
    margin-bottom: ${({ mb }) => mb || "20px"};
  }
  .review__check {
    margin-bottom: 80px;
  }
  .or__option {
    align-items: center;
    margin: 18px 0;
    p {
      margin: 0 15px 0 15px;
      font-weight: 400;
      font-size: 18px;
      color: ${({ theme }) => theme.colors.forth};
    }
    .ant-divider {
      border-top: 1px solid ${({ theme }) => theme.colors.forth};
    }
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
  }

  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }
`;

export const StyledFormItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .input__affix .ant-input-affix-wrapper {
    min-height: auto;
    background: #eeeeee;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0;
  }
  .input__affix input {
    border-left: none !important;
  }

  .input__affix
    .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #eeeeee;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #eeeeee;
  }

  .ant-input-prefix {
    margin-left: 4px;
    font-weigth: 400;
    font-size: 18px;
    font-height: 21px;
  }

  @media screen and (max-width: 1440px) {
    font-weigth: 400;
    font-size: 16px;
    font-height: 21px;
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;

    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      // max-width: 470px;
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
  .ant-form-item .ant-form-item-label {
    display: none;
  }
  .description__box {
    padding: 14px 20px;
  }
  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }

  @media screen and (max-width: 1600px) {
    flex-direction: ${({ fd }) => fd || "row"};
  }
  @media screen and (max-width: 991.98px) {
    flex-direction: ${({ sfd }) => sfd || "row"};
    .ant-form-item {
      width: ${({ tabSmwh }) => tabSmwh || "48"}%;
    }
  }
  @media screen and (max-width: 424px) {
    flex-direction: ${({ fd }) => fd || "column"};
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48"}%;
    }
  }
`;

export const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }

  span {
    font-weight: ${({ fw }) => fw || "700"};
    font-size: ${({ fs }) => fs || "18px"};
    text-transform: uppercase;
  }

  // width: 100%;
  width: ${({ wh }) => wh || "100%"};
  height: ${({ hg }) => hg || "50px"};
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  // background: #323548;
  ${"" /* background: ${({ bgColor }) => bgColor || "#323548"}; */}
  background: ${({ theme }) => theme.colors.secondary};
`;
