import React from "react";

const locationSvg = ({ wSize = "50", hSize = "50" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wSize}
      height={hSize}
      viewBox={`0 0 50 50`}
      fill="none"
    >
      <rect width="50" height="50" fill="#F67C36" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2062 23.6878C20.0334 27.3421 23.1657 30.3439 26.8201 32.3016L29.6913 29.4303C30.0829 29.0388 30.6049 28.9082 30.9964 29.1693C32.4321 29.6913 33.9982 29.9523 35.6949 29.9523C36.478 29.9523 37 30.4744 37 31.2575V35.6949C37 36.478 36.478 37 35.6949 37C23.4267 37 13.5078 27.0811 13.5078 14.8129C13.5078 14.0299 14.0299 13.5078 14.8129 13.5078H19.3809C20.1639 13.5078 20.686 14.0299 20.686 14.8129C20.686 16.3791 20.947 17.9452 21.4691 19.5114C21.5996 19.9029 21.4691 20.425 21.208 20.8165L18.2062 23.6878Z"
        fill="white"
      />
    </svg>
  );
};

export default locationSvg;
