import React from "react";

const dreamSquare = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="198"
      height="184"
      viewBox="0 0 198 184"
      fill="none"
    >
      <path
        d="M45.6016 32.0938H197.003V183.458H45.6016V32.0938Z"
        fill="#FFF6F1"
      />
      <path
        d="M1.00386 1H152.405V152.364H1.00386V1Z"
        stroke="#FBC8AA"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.3013 23.293H130.104V130.07H23.3013V23.293Z"
        stroke="#FBC8AA"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M45.6015 45.5859H107.807V107.776H45.6015V45.5859Z"
        stroke="#FBC8AA"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default dreamSquare;
