import React from "react";

const seekerCircle = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="242"
      viewBox="0 0 130 242"
      fill="none"
    >
      <path
        d="M218.573 143.858C231.315 89.8848 197.879 35.8044 143.893 23.0657C89.907 10.3269 35.8132 43.7537 23.0713 97.7265C10.3294 151.699 43.7645 205.78 97.7507 218.519C151.737 231.257 205.831 197.83 218.573 143.858Z"
        fill="#FFFCFA"
      />
      <path
        d="M100.285 21.3892C100.945 22.5223 101.253 23.8255 101.17 25.1338C101.088 26.4421 100.62 27.6965 99.8238 28.7383C99.028 29.78 97.9406 30.5621 96.6998 30.9856C95.4589 31.4091 94.1203 31.4549 92.8534 31.1171C91.5865 30.7794 90.4484 30.0733 89.5832 29.0883C88.718 28.1034 88.1647 26.8838 87.9933 25.5842C87.822 24.2846 88.0403 22.9634 88.6207 21.788C89.201 20.6125 90.1171 19.6357 91.2532 18.9813C92.0038 18.5443 92.8335 18.2599 93.6944 18.1445C94.5553 18.029 95.4305 18.0848 96.2698 18.3085C97.1091 18.5323 97.896 18.9196 98.5851 19.4483C99.2742 19.977 99.8519 20.6366 100.285 21.3892Z"
        fill="#FBC8AA"
      />
      <path
        d="M65.9322 50.8852C67.0683 51.5396 67.9846 52.5164 68.5649 53.6919C69.1452 54.8673 69.3634 56.1885 69.1921 57.4882C69.0207 58.7878 68.4674 60.0073 67.6022 60.9922C66.737 61.9772 65.5989 62.6833 64.332 63.021C63.0651 63.3588 61.7265 63.313 60.4856 62.8895C59.2448 62.4661 58.1574 61.6839 57.3616 60.6422C56.5657 59.6004 56.0971 58.346 56.015 57.0377C55.9328 55.7294 56.2408 54.4262 56.9001 53.2931C57.7866 51.7852 59.2314 50.687 60.9219 50.2363C62.6123 49.7857 64.4124 50.0188 65.9322 50.8852Z"
        fill="#FBC8AA"
      />
      <path
        d="M50.9388 93.5983C52.2487 93.6102 53.5257 94.0103 54.6082 94.7478C55.6908 95.4853 56.5304 96.5272 57.0206 97.7416C57.5109 98.9561 57.6298 100.289 57.3626 101.571C57.0953 102.853 56.4538 104.027 55.5191 104.945C54.5845 105.862 53.3986 106.482 52.1115 106.726C50.8245 106.97 49.4939 106.827 48.2883 106.314C47.0826 105.802 46.0559 104.944 45.338 103.849C44.6201 102.753 44.2433 101.47 44.2552 100.16C44.259 99.2892 44.4355 98.4278 44.7743 97.6256C45.113 96.8234 45.6074 96.0963 46.2289 95.4862C46.8503 94.8761 47.5864 94.3951 48.3948 94.0711C49.2033 93.747 50.0679 93.5863 50.9388 93.5983Z"
        fill="#FBC8AA"
      />
      <path
        d="M59.3097 138.086C60.4431 137.426 61.7467 137.118 63.0553 137.201C64.3638 137.283 65.6186 137.751 66.6606 138.547C67.7026 139.343 68.4849 140.43 68.9085 141.67C69.3321 142.911 69.3779 144.249 69.0401 145.516C68.7022 146.782 67.996 147.92 67.0108 148.785C66.0256 149.65 64.8058 150.203 63.5058 150.374C62.2059 150.546 60.8844 150.328 59.7087 149.747C58.5329 149.167 57.5558 148.251 56.9012 147.115C56.0266 145.598 55.7888 143.796 56.2402 142.103C56.6916 140.411 57.7954 138.966 59.3097 138.086Z"
        fill="#FBC8AA"
      />
      <path
        d="M88.8138 172.399C89.4656 171.262 90.4402 170.344 91.6141 169.762C92.788 169.179 94.1084 168.957 95.4083 169.125C96.7083 169.293 97.9291 169.842 98.9165 170.704C99.9038 171.566 100.613 172.701 100.955 173.966C101.297 175.231 101.255 176.569 100.836 177.811C100.416 179.052 99.6381 180.141 98.5993 180.94C97.5605 181.739 96.3078 182.212 95 182.299C93.6922 182.386 92.388 182.084 91.2525 181.429C90.4972 180.998 89.8346 180.423 89.3028 179.735C88.771 179.047 88.3804 178.261 88.1538 177.422C87.9271 176.582 87.8687 175.706 87.982 174.844C88.0952 173.983 88.378 173.152 88.8138 172.399Z"
        fill="#FBC8AA"
      />
    </svg>
  );
};

export default seekerCircle;
