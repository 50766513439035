import React, { useState, useRef } from "react";
import { TreeSelect } from "antd";
import { FiSearch } from "react-icons/fi";
import "./style.css";
import styled from "styled-components";

const HeaderSearch = ({ bgcolor, maxWidth }) => {
  const inputRef = useRef(null);
  const [placeHolderVal, setPlaceHolderVal] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [stateDatas, setStateDatas] = useState();

  const onButtonClick = () => {
    inputRef.current.focus();
  };

  // get input value
  const onChange = (val) => {
    setSearchTerm(val);
  };

  return (
    <StyledHeaderSearch
      maxWidth={maxWidth}
      className="search__message"
      bgcolor={bgcolor}
    >
      <FiSearch className="header_search_icon" onClick={onButtonClick} />
      <TreeSelect
        treeData={stateDatas}
        onSearch={onChange}
        showSearch={true}
        showArrow={false}
        listHeight={628}
        style={{ width: "100%" }}
        treeDefaultExpandAll={true}
        dropdownClassName="header_tree_select_dropdown"
        ref={inputRef}
        // onFocus={(e) =>
        //   (e.target.placeholder = placeHolderVal ? "" : "Search...")
        // }
        onSelect={(val, node) => {
          setPlaceHolderVal(val);
          inputRef.current.blur();
        }}
        onBlur={(e) => (e.target.placeholder = "")}
        placeholder="Search..."
        allowClear
      ></TreeSelect>
    </StyledHeaderSearch>
  );
};

export default HeaderSearch;

// HEADER TREE SELECT WRAPPER
export const StyledHeaderSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "433px"};
  margin-top: 2px;
  background: #cbcbcb;
  background: ${({ bgcolor }) => bgcolor || "#cbcbcb"};
  border-radius: 5px;
  height: 45px;
  padding-left: 18px;
  .ant-select-selector {
    height: auto;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }
  .ant-select {
    ${"" /* width: 80% !important; */}
  }

  .ant-select-clear {
    color: #777777 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .ant-select-clear {
    opacity: 1;
    background: none;
    font-size: 16px;
    color: white;
  }
  // .ant-select-selection-item {
  //   background: none;
  //   border: none;
  // }
  // .ant-select-selection-item-remove {
  //   color: #ffffff;
  //   font-size: 15px;
  // }
  // .ant-select-selection-overflow-item {
  //   // display: none;
  // }

  .header_search_icon {
    font-size: 20px;
    cursor: pointer;
    color: #777777;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
  }

  input::placeholder {
    color: white;
    font-size: 14px;
    line-height: 14px;
  }
  @media (max-width: 991.98px) {
    margin-left: 10px;
  }
`;
