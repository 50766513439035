import { Breadcrumb, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import {
  BathSvg,
  BedSvg,
  PaymentConformation,
  PaymentProcess,
  Sqft,
} from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { ApplicationsCard, CardDetails } from "../my-account/Applications";
import { StyledButtonPay } from "../my-rentals/MyRentals";
import { toUSCurrency } from "../../utils/fn";

function PaymentConfirmation() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { payment } = useSelector((state) => state.user);
  const [tenantsData, setTenantsData] = useState([]);
  const [mrWidth, setMrWidth] = useState("30px");
  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (width < 400) {
      setMrWidth("0");
    } else {
      setMrWidth("30px");
    }
  }, [width, location.pathname]);

  // page navigation bar
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/account",
      label: "My Account",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const params = useParams();

  const [transactionId, setTransactionId] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [lastPaindAmount, setLastPaindAmount] = useState("");

  // date converter
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day} ${month}, ${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    if (date != "Invalid Date") {
      return formatDate(date);
    } else {
      return "";
    }
  };

  // getting transaction data
  useEffect(() => {
    if (payment && Object.values(payment).length) {
      setTransactionId(payment?.transactionId);
      setPaymentDate(dateConverter(payment.squareResponse.createdAt));
      setPaidAmount(
        toUSCurrency(Number(payment.squareResponse.totalMoney.amount) / 100)
      );
      setLastPaindAmount(payment.amount);
    } else {
      navigate("/my-transaction");
    }
  }, [payment]);

  return (
    <div>
      <PaymentConfirmationWrap
        mb={payment?.squareResponse?.status === "COMPLETED" ? "40px" : "15px"}
      >
        {payment?.squareResponse?.status === "COMPLETED" ? (
          <>
            <PaymentConformation />
            <h1>Your rent transaction has completed successfully.</h1>
          </>
        ) : (
          <PendingSvg>
            <PaymentProcess />

            <h1>Your transaction is in process.</h1>
            <PayDetails
              mb={
                payment?.squareResponse?.status === "COMPLETED" ? "0" : "40px"
              }
            >
              <Row justify="center">
                <Col>
                  <span>
                    It will take 3-5 business days for the payment to be
                    reflected as complete.
                  </span>
                </Col>
              </Row>
            </PayDetails>
          </PendingSvg>
        )}

        <Row style={{ alignItems: "center" }} justify="center">
          <Col>
            <h2>Transaction id</h2>
          </Col>
          <Col>
            <h3>: #{transactionId}</h3>
          </Col>
        </Row>
        <Divider />
        <PayDetails>
          <Row justify="space-between">
            <Col>
              <Row>
                <Col>
                  <span>Payment date : </span>
                </Col>
                <Col>
                  <p> &nbsp; {paymentDate}</p>
                </Col>
              </Row>
            </Col>
            <Col className="payment__divider">
              <Divider type="vertical" />
            </Col>
            <Col>
              <Row>
                <Col>
                  <span>Paid amount : </span>
                </Col>
                <Col>
                  <p> &nbsp; {paidAmount}</p>
                </Col>
              </Row>
            </Col>
            <Col className="payment__divider">
              <Divider type="vertical" />
            </Col>
            <Col>
              <Row>
                <Col>
                  <span>Balance : </span>
                </Col>
                <Col>
                  <p>
                    &nbsp;
                    {toUSCurrency(Number("0"))}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </PayDetails>
        <ApplicationsCard>
          <img src="/images/property1.png" />
          <CardDetails mr={mrWidth}>
            <Row>
              <Col>
                <StarRatings
                  rating={3.5}
                  starRatedColor="#F67C36"
                  starDimension="20px"
                  starSpacing="5px"
                  // changeRating={this.changeRating}
                  numberOfStars={5}
                  name="rating"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2>{payment?.unit_name}</h2>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col className="card__bottom">
                <p>
                  <BedSvg className="icon" />
                </p>
                <span>Bed : 02</span>
              </Col>
              <Col className="card__bottom">
                <p>
                  <BathSvg />
                </p>
                <span>Bath : 02</span>
              </Col>
              <Col className="card__bottom">
                <p>
                  <Sqft />
                </p>
                <span>Sq ft : 1300</span>
              </Col>
            </Row>
          </CardDetails>
        </ApplicationsCard>
        <Row
          justify="center"
          style={{ marginTop: "30px", marginBottom: "50px" }}
        >
          <Col>
            <StyledButtonPay
              onClick={() => {
                navigate("/my-transaction");
              }}
            >
              Go to Transactions
            </StyledButtonPay>
          </Col>
        </Row>
      </PaymentConfirmationWrap>
    </div>
  );
}

export default PaymentConfirmation;

const PendingSvg = styled.div`
  svg {
    font-size: 250px;
    color: #f3ae86;
  }
`;

const PayDetails = styled.div`
  margin-bottom: ${({ mb }) => mb || "20px"};

  span,
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #848687;
    margin-bottom: ${({ mb }) => mb || "0px"};
  }
  p {
    color: #2b353f;
  }
  .ant-divider {
    background: #dddddd;
    min-height: 40px;
  }

  @media (max-width: 650px) {
    .payment__divider {
      display: none;
    }
  }
`;

const PaymentConfirmationWrap = styled.div`
  ${
    "" /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  }
  text-align: center;
  max-width: 640px;
  margin: auto;

  .ant-divider {
    background: #dddddd;
  }

  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    ${"" /* margin-bottom: 40px; */}
    margin-bottom: ${({ mb }) => mb || "40px"};
  }
  h3,
  h2 {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  h3 {
    color: #4160ac;
  }

  @media (max-width: 650px) {
    padding: 15px;
  }
`;
