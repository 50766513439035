import React, { useState } from "react";
import { H2Style, MainContainer } from "./SearchPart";
import {
  Rectangle,
  TopTrendingSectionWrap,
} from "../newLandingPage/TopTrendingSection";
import { Col, Row, Select, Tabs } from "antd";
import styled from "styled-components";
import ApplicationProcessTabs from "./benefits-avenew-tabs/ApplicationProcessTabs";
import { SelectSvg } from "../../../assets/svgs";

function BenefitsAvenew() {
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [activeTab, setActiveTab] = useState("Application Process");

  return (
    <MainContainer bgcolor="#ffffff" containterHeight="auto">
      <TopTrendingSectionWrap
        paddingcomponent="0 15px"
        margincomponent="0 auto 100px"
      >
        <Row>
          <Col span={24}>
            <Rectangle style={{ margin: "auto" }} />
          </Col>
        </Row>
        <Row justify="center" style={{ textAlign: "center" }}>
          <Col>
            <H2Style
              marginBottomSm="20px"
              marginbottomxs="20px"
              marginBottom="30px"
              marginTopSm="10px"
              margintopxs="10px"
              marginTop="10px"
            >
              Benefits of using Avenew
            </H2Style>
          </Col>
        </Row>
        <BenefitsAvenewWrap>
          <Row>
            <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
              <StyledTabs
                defaultActiveKey={"Application Process"}
                activeKey={activeTab}
                onChange={(tabKey) => {
                  // setList([]);
                  setActiveTab(tabKey);
                }}
              >
                <TabPane tab="Application Process" key="Application Process">
                  <ApplicationProcessTabs />
                </TabPane>
                <TabPane tab="Qualified Properties" key="Qualified Properties">
                  <ApplicationProcessTabs />
                </TabPane>
                <TabPane
                  tab="Time, Money and Effort Savings"
                  key="Time, Money and Effort Savings"
                >
                  <ApplicationProcessTabs />
                </TabPane>
                <TabPane
                  tab="Transparency and Decision-Making"
                  key="Transparency and Decision-Making"
                >
                  <ApplicationProcessTabs />
                </TabPane>
                <TabPane tab="Selection Process" key="Selection Process">
                  <ApplicationProcessTabs />
                </TabPane>
              </StyledTabs>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
              <Select
                placeholder="Application Process"
                // suffixIcon={<SelectSvg />}
                defaultValue="Application Process"
                // onChange={(value) => setSelectedSignFormat(value)}
              >
                <Option value="Application Process">Application Process</Option>
                <Option value="Qualified Properties">
                  Qualified Properties
                </Option>
                <Option value="Time, Money and Effort Savings">
                  Time, Money and Effort Savings
                </Option>
                <Option value="Transparency and Decision-Making">
                  Transparency and Decision-Making
                </Option>
                <Option value="Selection Process">Selection Process</Option>
              </Select>
              <ApplicationProcessTabs />
            </Col>
          </Row>
        </BenefitsAvenewWrap>
      </TopTrendingSectionWrap>
    </MainContainer>
  );
}

export default BenefitsAvenew;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.third};
  }
  .ant-tabs-tab-btn:active {
    color: ${(props) => props.theme.colors.third};
  }
  .ant-tabs-tab {
    padding: 12px 18px;
    color: #75819f;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.third};
  }
`;

const BenefitsAvenewWrap = styled.div`
  .ant-select-arrow {
    font-size: 14px;
    ${"" /* color: #777; */}
  }

  .ant-select {
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  input,
  .ant-select-selector {
    width: 100%;
    height: 50px !important;
    background: ${({ bgColor }) => bgColor || "#fff"} !important ;
    font-size: 18px !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    align-items: center !important;
    border: 0 !important;
    box-shadow: ${({ boxShadow }) => boxShadow || "none"} !important ;
    color: #f67c36;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 595px) {
      width: 100%;
    }
  }

  .ant-input:placeholder-shown {
    color: #f67c36;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 1550px) {
    input,
    .ant-select-selector {
      height: 40px !important;
      font-size: 16px !important;
    }
  }

  @media (max-width: 1024px) {
    width: ${({ sWh }) => sWh};
  }

  @media (max-width: 769px) {
    width: ${({ xsWh }) => xsWh};
  }
`;
