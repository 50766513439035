import React from "react";
import styled from "styled-components";
import { DoubleQuote } from "../../../assets/svgs";
import { Col, Row } from "antd";
import { UserAvatar } from "../../../ui/Avatar";

function OurClientCard() {
  return (
    <OurClientCardWrap>
      <Row style={{ flexDirection: "column" }}>
        <Col>
          <DoubleQuote />
        </Col>
        <Col>
          <p>
            When an unknown printer took a galley of type and scrambled it to
            make a type specimen book. It has survived not only five centuries,
            but also the leap into electronic typesetting, remaining essentially
            unchanged.
          </p>
          <Row gutter={[10, 10]} style={{ alignItems: "center" }}>
            <Col>
              <UserAvatar
                className="userName__icon"
                size={40}
                src="/images/1image.png"
              />
            </Col>
            <Col>
              <h4>Mark Jecno</h4>
            </Col>
          </Row>
        </Col>
      </Row>
    </OurClientCardWrap>
  );
}

export default OurClientCard;

const OurClientCardWrap = styled.div`
  ${"" /* width: 365px; */}
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 35px 30px;
  p {
    color: #2b353f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 25px 0 30px;
  }

  h4 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }

  ${'' /* @media (max-width: 1400px) {
    padding: 23px 20px;
  } */}
`;
