import { Button, Col, Modal, Row } from "antd";
import React from "react";
import { IoIosClose } from "react-icons/io";
import { StyledTitle } from "../../pages/property-details/tabs/floor-plan-modal/FloorPlanModal";
import { AvenewImg, FooterCall, FooterLocation } from "../../assets/svgs";
import styled from "styled-components";
import { useState } from "react";
import { toUSCurrency, upperCase } from "../../utils/fn";

const ApplicationInvitationListModal = ({
  modalState,
  onCancel,
  dataModal,
  statusBtn,
  isLoadingApprove = false,
  isLoadingDeny = false,
  isLoadingCansel = false,
  invitationModalData,
}) => {
  const [selectedInvitaion, setSelectedInvitaion] = useState({});

  // slip word/sentence after 25 characters (Avenew Deve...)
  const slpitFunc = (value) => {
    if (value.length > 25) {
      return value.slice(0, 25) + "...";
    } else {
      return value;
    }
  };

  // to upper case address
  const upperCaseCut = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return slpitFunc(str2);
    }
  };

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          onCancel(false);
        }}
        width={"950px"}
        footer={null}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(20px)",
        }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <StyledTitle>Invitations</StyledTitle>
        {invitationModalData?.map((item, id) => (
          <UnitCard key={id}>
            {item?.unitObj?.images && item?.unitObj?.images?.length ? (
              <>
                {item?.unitObj?.images.map((img, idx) => (
                  <div key={idx}>
                    {img.isCover === true ? (
                      <img className="ave__svg" alt="example" src={img.path} />
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </>
            ) : (
              <AvenewImg
                className="svg__border, ave__svg"
                style={{ background: "#eeeeee" }}
              />
            )}
            <CardDetails>
              <Row>
                <Col>
                  <h2>{upperCase(item?.unit_name)}</h2>
                </Col>
                <Col className="price__pos">
                  <Row gutter={[10, 10]}>
                    <Col>
                      {item?.rate ? (
                        <Row>
                          <Col>
                            <p>{toUSCurrency(Number(item?.rate))}</p>
                          </Col>
                        </Row>
                      ) : item?.unitObj ? (
                        <Row>
                          <Col>
                            <p>{toUSCurrency(Number(item?.unitObj?.price))}</p>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col>
                      <span>
                        <StyledButton
                          loading={
                            isLoadingDeny && selectedInvitaion.id === item.id
                          }
                          onClick={() => {
                            statusBtn("denied", item);
                            setSelectedInvitaion(item);
                          }}
                        >
                          Deny
                        </StyledButton>
                      </span>
                    </Col>
                    <Col>
                      <span>
                        <StyledButtonApprove
                          loading={
                            isLoadingApprove && selectedInvitaion.id === item.id
                          }
                          onClick={() => {
                            statusBtn("approved", item);
                            setSelectedInvitaion(item);
                          }}
                        >
                          Approve
                        </StyledButtonApprove>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col>
                  <h3>
                    <FooterLocation /> {upperCaseCut(item?.address)}
                  </h3>
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                {item?.phoneNumber ? (
                  <Row>
                    <Col>
                      <h3>
                        <FooterCall /> {item?.phoneNumber}
                      </h3>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Row>
            </CardDetails>
          </UnitCard>
        ))}
      </Modal>
    </>
  );
};

export default ApplicationInvitationListModal;

const UnitCard = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  padding: 10px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;

  img,
  .ave__svg {
    width: 100px;
    height: 100px;
  }
`;

const CardDetails = styled.div`
  padding: 10px 15px;
  position: relative;
  display: grid;
  justify-content: start;
  width: 90%;

  .price__pos {
    position: absolute;
    right: 0;
    justify-content: space-between;
    top: 10px;
    padding-right: 10px;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    ${"" /* margin-top: 5px; */}
    margin-bottom: 10px;
  }

  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 10px;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 97px;
  height: 34px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background: #2b353f;
  border-color: #2b353f;
  text-transform: uppercase;
  ${"" /* margin-top: 27px; */}

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: #2b353f;
    background: #2b353f;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 0;
    margin-top: 0;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

const StyledButtonApprove = styled(Button)`
  width: 92px;
  height: 34px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;
  ${"" /* margin-top: 30px; */}

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 0;
    margin-top: 0;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;
