import { BackTop, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import SearchPart from "../../components/layout/dashbord-new/SearchPart";
import TopTrendingSection from "../../components/layout/newLandingPage/TopTrendingSection";
import MultiApplicationPart from "../../components/layout/dashbord-new/MultiApplicationPart";
import WelcomeToAvenew from "../../components/layout/dashbord-new/WelcomeToAvenew";
import FindQualify from "../../components/layout/dashbord-new/FindQualify";
import DiscoverYourProperty from "../../components/layout/dashbord-new/DiscoverYourProperty";
import ArtHomebuying from "../../components/layout/dashbord-new/ArtHomebuying";
import BenefitsAvenew from "../../components/layout/dashbord-new/BenefitsAvenew";
import PropertySeeker from "../../components/layout/dashbord-new/PropertySeeker";
import YourDreamHome from "../../components/layout/dashbord-new/YourDreamHome";
import LogosWrap from "../../components/layout/dashbord-new/LogosWrap";
import OurClient from "../../components/layout/dashbord-new/OurClient";
import FooterSectionHome from "../../components/layout/dashbord-new/FooterSectionHome";
import {
  me,
  updateApplicationInvitation,
  updateInvitation,
} from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../components/container/Container";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";
import { backToTopStyle } from "../../utils/fn";
import InvitationModal from "../../components/invitationModal/InvitationModal";
import ApplicationInvitationListModal from "../../components/invitationModal/ApplicationInvitationListModal";
import ApplicationInvitationModal from "../../components/invitationModal/ApplicationInvitationModal";

function DashbordNew() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [invitaionsData, setInvitaionsData] = useState([]);
  const [viewNotification, setViewNotification] = useState(false);
  const [dismiss, setDismiss] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [viewMoreModalState, setViewMoreModalState] = useState(false);
  const [applicationDataModal, setApplicationDataModal] = useState({});
  const [applicationModalState, setApplicationModalState] = useState(false);
  const [invitationModalData, setInvitationModalData] = useState([]);
  const [modalStates, setModalStates] = useState(false);
  const [isLoadingDeny, setIsLoadingDeny] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingCansel, setIsLoadingCansel] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [seeView, setSeeView] = useState(true);

  const { width } = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (invitaionsData?.length) {
      setDataModal(invitaionsData[0]);
      setInvitationModalData(invitaionsData);
    }
  }, [invitaionsData]);

  // to change invitation status
  const statusBtn = (status, dataModal) => {
    const objCopy = { ...dataModal };
    objCopy.invitaionRequest = status;
    objCopy.prospect_email = user?.email;
    objCopy.prospect_id = user?.id;
    objCopy.unitObj = {};

    if (status === "denied" || status === "approved") {
      setViewNotification(false);
    }
    if (status === "denied") {
      setIsLoadingDeny(true);
    } else if (status === "approved") {
      setIsLoadingApprove(true);
    } else if (status === "cancel") {
      setIsLoadingCansel(true);
    }
    dispatch(updateInvitation(objCopy)).then((res) => {
      setViewMoreModalState(false);
      setModalState(false);
      setIsLoadingDeny(false);
      setIsLoadingApprove(false);
      setIsLoadingCansel(false);
      if (status === "approved") {
        navigate("/my-rentals");
      }

      if (status === "denied" || status === "approved") {
        dispatch(me());
      }
    });
  };

  // to change invitation status
  const applicationStatusBtn = (status, dataModal) => {
    let objCopy = { ...dataModal };
    objCopy.invitationStatus = status;
    if (status === "reject") {
      setIsLoadingDeny(true);
    } else if (status === "accept") {
      setIsLoadingApprove(true);
    } else if (status === "cancel") {
      setIsLoadingCansel(true);
    }
    dispatch(updateApplicationInvitation(objCopy)).then((res) => {
      setIsLoadingCansel(false);
      setApplicationModalState(false);
      if (status === "accept") {
        navigate("/application-process");
      }
      if (status === "reject" || status === "accept") {
        dispatch(me());
      }
    });
  };

  useEffect(() => {
    if (user) {
      const data = user?.invitations.filter((item) => {
        return (
          item.invitaionRequest === "sent" || item.invitaionRequest === "cancel"
        );
      });
      setInvitaionsData(data);
    }
  }, [user]);

  // for responsive screens
  useEffect(() => {
    if (width < 768) {
      setSeeView(false);
    } else {
      setSeeView(true);
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (
      invitaionsData[0]?.invitaionRequest === "cancel" ||
      invitaionsData[0]?.invitaionRequest === "sent"
    ) {
      setViewNotification(true);
    } else {
      setViewNotification(false);
    }
  }, [invitaionsData]);

  return (
    <>
      {false ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <>
          {/* {viewNotification &&
          localStorage.getItem("dismiss") != "dismiss" &&
          dismiss ? (
            <>
              <ViewNotification>
                <ViewNotificationWrap>
                  <Row style={{ width: "100%" }} justify="space-between">
                    <Col style={{ display: "flex" }}>
                      {invitaionsData?.length <= 1 ? (
                        <>
                          {seeView ? (
                            <h3>
                              Invitation Pending from{" "}
                              {invitaionsData[0]?.unit_name}
                            </h3>
                          ) : (
                            <h3>Invitation Pending </h3>
                          )}

                          {seeView ? (
                            <h2
                              className="dismiss__btn"
                              onClick={() => {
                                localStorage.setItem("dismiss", "dismiss");
                                setDismiss(false);
                              }}
                            >
                              Dismiss
                            </h2>
                          ) : (
                            <IoIosClose
                              onClick={() => {
                                localStorage.setItem("dismiss", "dismiss");
                                setDismiss(false);
                              }}
                              className="modal_close_icon_header"
                            />
                          )}
                          <h2
                            className="view__btn"
                            onClick={() => {
                              setDataModal(invitaionsData[0]);
                              setModalState(true);
                            }}
                          >
                            View
                          </h2>
                        </>
                      ) : (
                        <>
                          {invitaionsData?.length > 1 ? (
                            <Col style={{ display: "flex" }}>
                              <h3>
                                You got more {invitaionsData?.length} Invitation
                              </h3>
                              <h2
                                onClick={() => {
                                  setViewMoreModalState(true);
                                }}
                                className="view__more"
                              >
                                View more
                              </h2>
                            </Col>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </ViewNotificationWrap>
              </ViewNotification>
              {applicationInvitaionsData?.map((item, id) => (
                <ViewNotification key={id}>
                  <ViewNotificationWrap>
                    {seeView ? (
                      <h3>
                        Invitation Pending from {capitalize(item.invitedName)}{" "}
                        gsss
                      </h3>
                    ) : (
                      <h3>Invitation Pending </h3>
                    )}
                    <h2
                      className="font__view"
                      onClick={() => {
                        setApplicationDataModal(item);
                        setApplicationModalState(true);
                      }}
                    >
                      View
                    </h2>
                    {seeView ? (
                      <h2
                        className="font__view"
                        onClick={() => {
                          localStorage.setItem("dismiss", "dismiss");
                          setDismiss(false);
                        }}
                      >
                        Dismiss
                      </h2>
                    ) : (
                      <IoIosClose
                        onClick={() => {
                          localStorage.setItem("dismiss", "dismiss");
                          setDismiss(false);
                        }}
                        className="modal_close_icon_header"
                      />
                    )}
                  </ViewNotificationWrap>
                </ViewNotification>
              ))}
            </>
          ) : (
            ""
          )} */}
          <SearchPart />
          {/* <TopTrendingSection /> */}
          {/* <MultiApplicationPart /> */}
          <WelcomeToAvenew />
          <FindQualify />
          <ArtHomebuying />
          <DiscoverYourProperty />
          {/* <BenefitsAvenew /> */}
          <PropertySeeker />
          <YourDreamHome />
          {/* <LogosWrap /> */}
          {/* <OurClient /> */}
          <FooterSectionHome />

          <BackTop>
            <div style={backToTopStyle}>
              <MdKeyboardArrowUp />
            </div>
          </BackTop>

          <InvitationModal
            onCancel={(value) => setModalState(value)}
            modalState={modalState}
            onSubmit={() => setModalStates(true)}
            dataModal={dataModal}
            statusBtn={statusBtn}
            isLoadingApprove={isLoadingApprove}
            isLoadingDeny={isLoadingDeny}
            isLoadingCansel={isLoadingCansel}
          />

          <ApplicationInvitationListModal
            modalState={viewMoreModalState}
            onCancel={(value) => setViewMoreModalState(value)}
            invitationModalData={invitationModalData}
            statusBtn={statusBtn}
            isLoadingApprove={isLoadingApprove}
            isLoadingDeny={isLoadingDeny}
          />

          <ApplicationInvitationModal
            onCancel={(value) => setApplicationModalState(value)}
            modalState={applicationModalState}
            statusBtn={applicationStatusBtn}
            dataModal={applicationDataModal}
            isLoadingApprove={isLoadingApprove}
            isLoadingDeny={isLoadingDeny}
            isLoadingCansel={isLoadingCansel}
          />
        </>
      )}
    </>
  );
}

export default DashbordNew;
