import { Col, Row } from "antd";
import React from "react";
import { OwnersContainer } from "./MarketingSection";
import styled from "styled-components";
import {
  AccountingSvg,
  ApplicationSvg,
  EsignSvg,
  FileStorageSvg,
  TenantDetailSvg,
  TenantSvg,
} from "../../../assets/svgs";
import ScheduleDemo from "./ScheduleDemo";
import { H2Style, HeaderWrap } from "./TopSearchSection";

const FeatureSection = () => {
  return (
    <>
      <OwnersContainer>
        <HeaderWrap>
          <FeatureWrap>
            <Row>
              <Col span={24}>
                <RectangleWrap />
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <H2Style
                  paddingtopSm="10px"
                  paddingtop="25px"
                  marginBottom="50px"
                  marginBottomSm="30px"
                  fontSize="40px"
                  lineHeight="normal"
                >
                  The Premier platform for managing rental properties.
                </H2Style>
                <FeatureContentWrap>
                  <Row gutter={[20, 20]}>
                    <Col span={24}>
                      <Row>
                        <Col span={3}>
                          <AccountingSvg />
                        </Col>
                        <Col span={20}>
                          <h2>Accounting</h2>
                          <span>
                            Easily manage finances for your rental property with
                            Avenew's user-friendly accounting tools.
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={3}>
                          <TenantSvg />
                        </Col>
                        <Col span={20}>
                          <h2>Tenant Screening</h2>
                          <span>
                            Conduct thorough background checks and verify rental
                            histories to make informed tenant selection
                            decisions.
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={3}>
                          <FileStorageSvg />
                        </Col>
                        <Col span={20}>
                          <h2>File Storing and Sharing</h2>
                          <span>
                            Conveniently store and share important property
                            documents securely on the platform.
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      {" "}
                      <Row>
                        <Col span={3}>
                          <ApplicationSvg />
                        </Col>
                        <Col span={20}>
                          <h2>Application</h2>
                          <span>
                            Streamline the rental application process by
                            allowing tenants to apply online
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={3}>
                          <EsignSvg />
                        </Col>
                        <Col span={20}>
                          <h2>E-Signature</h2>
                          <span>
                            Digitally sign rental agreements and other documents
                            for a faster and more <br /> convenient leasing
                            process
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </FeatureContentWrap>
              </Col>
              <Col
                style={{ justifyContent: "end", display: "flex" }}
                xs={24}
                sm={24}
                md={24}
                lg={14}
                xl={14}
                xxl={14}
              >
                {/* <TenantDetailSvg /> */}
                <img src="./images/tenantDetails.png" alt="Tenant details" />
              </Col>
            </Row>
            <ScheduleDemo />
          </FeatureWrap>
        </HeaderWrap>
      </OwnersContainer>
    </>
  );
};

export default FeatureSection;

export const FeatureWrap = styled.div`
  width: 1400px;
  height: 100%;
  margin: 0 auto;
  h1 {
    margin-bottom: 50px;
    padding-top: 25px;
    color: #2b353f;
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
  }
  svg {
    width: 50%;
  }

  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;

    img {
      width: 100%;
    }
  }
`;
export const RectangleWrap = styled.div`
  width: 50px;
  height: 5px;
  background-color: #4160ac;
`;

const FeatureContentWrap = styled.div`
  width: 100%;
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }
  span {
    color: #848687;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;
