import React from "react";

const locationSvg = ({ wSize = "50", hSize = "50" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={wSize}
      height={hSize}
      viewBox={`0 0 50 50`}
      fill="none"
    >
      <rect width="50" height="50" fill="#F67C36" />
      <path
        d="M24.5 12C19.2546 12 15 16.0755 15 21.1C15 27.925 24.5 38 24.5 38C24.5 38 34 27.925 34 21.1C34 16.0755 29.7454 12 24.5 12ZM24.5 24.35C22.6271 24.35 21.1071 22.894 21.1071 21.1C21.1071 19.306 22.6271 17.85 24.5 17.85C26.3729 17.85 27.8929 19.306 27.8929 21.1C27.8929 22.894 26.3729 24.35 24.5 24.35Z"
        fill="white"
      />
    </svg>
  );
};

export default locationSvg;
