import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "./SearchPart";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/reducers/user.reducer";
import { Divider } from "antd";
import styled from "styled-components";

const SideBarMobile = ({ onApplyNow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickTab = (tab) => {
    document.body.style.overflow = "visible";
    navigate(tab);
  };

  // Log out function
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      {localStorage.getItem("user_id") && localStorage.getItem("idToken") ? (
        <SidebarContainer className="sidebar-panel">
          <SideMainWrap>
            <SideBarLogin style={{ top: "20px", position: "relative" }}>
              <h3 onClick={() => onClickTab("/account")}>Account</h3>
              <h3 onClick={() => onClickTab("/favorite")}>Wishlist</h3>
              <h3 onClick={() => onClickTab("/application-process")}>
                User Profile Verification
              </h3>
              <h3 onClick={() => onClickTab("/applications")}>Applications</h3>
              <h3 onClick={() => onClickTab("/message")}>Messages</h3>
              <h3 onClick={() => onClickTab("/my-transaction")}>
                My Payments or Billing
              </h3>
              <h3 onClick={() => onClickTab("/my-rentals")}>My Rentals</h3>
            </SideBarLogin>
            <SideBarLogin>
              <Divider />
              {/* <Link to="/" className="nav__link__help">
                Need a Help?
              </Link> */}
              <h3 onClick={() => handleLogout()} className="nav__link__logout">
                Log out
              </h3>
            </SideBarLogin>
          </SideMainWrap>
        </SidebarContainer>
      ) : (
        <SidebarContainer className="sidebar-panel">
          <SideMainWrap>
            <StyledButton
              paddingbtn="4px 10px"
              onClick={() => onApplyNow("signup")}
            >
              Create an account
            </StyledButton>
            <SideBarLogin>
              <h3 onClick={() => onApplyNow("signin")}>Login</h3>
              <Divider />
              {/* <Link to="/" className="nav-link need__help">
                Need a Help?
              </Link> */}
            </SideBarLogin>
          </SideMainWrap>
        </SidebarContainer>
      )}
    </>
  );
};

export default SideBarMobile;

export const SidebarContainer = styled.div`
  padding: 15px;
  width: 280px;
  background: #ecf2ff;
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.06);
  display: block;
  padding: 80px 15px 15px 15px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  transition: ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 33px;
    color: #2b353f;
  }

  @media (max-width: 1550px) {
    width: 225px;
    padding: 15px;
  }

  @media (max-width: 991.98px) {
    z-index: 111;
    margin-top: 90px;
    padding: 15px;
  }

  // scrollbar css
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
  @media (orientation: landscape) {
    top: 90px;
    max-height: calc(100vh - 90px);
    margin-top: 0;
    overflow-y: scroll;
  }
`;

export const SideMainWrap = styled.div`
  height: calc(100vh - 250px);
  position: relative;
`;

export const SideBarLogin = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;

  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    color: #2b353f;
  }
  .ant-divider-horizontal {
    margin: 15px 30px;
    background: #dddddd;
    width: 75%;
    min-width: 75%;
  }
  .nav__link__help {
    font-weight: 400 !important;
    color: #2b353f !important;
  }
  .nav__link__logout {
    margin-top: 20px;
    font-weight: 400 !important;
    color: #848687 !important;
  }

  @media (orientation: landscape) {
    position: relative;
  }
`;
